
import { BaseClient } from '../BaseClient';
import { Empty } from '../compiled-protos/common';
import {
  PTO,
  PTOList,
  TimeoffRequest,
  TimeoffRequestList,
  TimeoffRequestType,
} from '../compiled-protos/timesheet_line';
import { TimesheetLineServiceClient as TimeoffRequestServiceClient } from '../compiled-protos/timesheet_line.client';
import { User } from '../compiled-protos/user';
import { NULL_TIME } from '../constants';

class TimeoffRequestClient extends BaseClient {
  self: TimeoffRequestServiceClient

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TimeoffRequestServiceClient(this.transport)
  }

  public async Create(req: TimeoffRequest) {
    let res = TimeoffRequest.create()
    try {
      res = await this.self.createTimeoffRequest(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Get(req: TimeoffRequest) {
    let res=  TimeoffRequest.create()
    try{
     res = await this.self.getTimeoffRequest(req, this.getMetaDataWithoutCache()).response
    }
    catch(err){
      console.error(err)
    }
    return res
  }

  public async Update(req: TimeoffRequest) {
    let res = TimeoffRequest.create()
    try {
      res = await this.self.updateTimeoffRequest(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Delete(req: TimeoffRequest) {
    let res = TimeoffRequest.create()
    try {
      res = await this.self.deleteTimeoffRequest(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGet(req: TimeoffRequest) {
    const res = await this.self.batchGetTimeoffRequest(req, this.getMetaDataWithoutCache()).response
    return res
  }

  public async GetTimeoffRequestTypes(filter?: TimeoffRequestType) {

    const res = await this.self.getTimeoffRequestTypes(filter ? filter : TimeoffRequestType.create(), this.getMetaData()).response
    return res
  }

  public async getTimeoffRequestById(id: number) {
    const req = TimeoffRequest.create();
    req.id = id;
    req.isActive = 1;
    try {
      return await this.Get(req);
    } catch (e) {
      return null;
    }
  }
  loadTimeoffRequests = async (config: GetTimesheetConfig) => {
    const req = TimeoffRequest.create();
    if (config.startDate && config.endDate) {
      req.dateRange = ['>=', config.startDate, '<=', config.endDate];
    }
    if (config.departmentID) {
      req.departmentCode = config.departmentID;
    }
    if (config.technicianUserID) {
      req.userId = config.technicianUserID;
    }
    if (config.payrollProcessed) {
      req.payrollProcessed = true;
    }
    if (config.approved) {
      req.requestStatus = config.approved === true ? 1 : 2;
    }
    req.pageNumber = config.page || 0;
    req.orderBy = 'time_started';
    if (config.requestType !== 9) {
      req.requestType = config.requestType || 0;
      req.fieldMask = ['AdminApprovalUserId'];
    }
    if (config.requestType === 9) {
      req.fieldMask = ['PayrollProcessed'];
      req.notEquals = ['AdminApprovalDatetime'];
      req.adminApprovalDatetime = NULL_TIME;
      req.requestTypeList = '9,10,11';
    } else {
      req.notEquals = ['AdminApprovalDatetime'];
      req.adminApprovalDatetime = NULL_TIME;
      req.requestTypeList = '9,10,11';
    }
    if (config.departmentID) {
      const user = User.create();
      user.employeeDepartmentID = config.departmentID;
      req.user = user;
    }
    req.isActive = 1;
    return await this.BatchGet(req);
  };

  public async deleteTimeoffRequestById(id: number) {
    const req = TimeoffRequest.create();
    req.id = id;
    return await this.Delete(req);
  }

  public async processTimeoffRequest(id: number) {
    const req = TimeoffRequest.create();
    req.id = id;
    req.payrollProcessed = true;
    req.fieldMask = ['PayrollProcessed'];
    return await this.Update(req);
  }

  public async PTOInquiry(userID: number) {
    const req = PTO.create();
    req.id = userID;
    let res = PTO.create()
    try {
      res = await this.self.pTOInquiry(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
  public async BatchGetPTOInquiry() {
    const req = Empty.create()
    let res = PTOList.create()
    try {
      res = await this.self.batchGetPTOInquiry(req, this.getMetaDataWithoutCache()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public getPTOInquiryByUserId = async (userId: number) =>
    await this.PTOInquiry(userId);

  public getTimeoffRequestTypes = async () =>
    await (await this.GetTimeoffRequestTypes)

  public getTimeoffRequestByFilter = async (filter: TimeoffRequest) => {
    return await this.BatchGet(filter);
  };

  public upsertTimeoffRequest = async (data: TimeoffRequest) => {
    const id = data.id;
    if (id !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    return await this[id != 0 ? 'Update' : 'Create'](data);
  };
}

interface GetTimesheetConfig {
  page?: number;
  departmentID?: number;
  requestType?: number;
  technicianUserID?: number;
  startDate?: string;
  endDate?: string;
  payrollProcessed?: boolean;
  approved?: boolean;
  orderBy?: string;
  orderDir?: string;
}

export {
  TimeoffRequest,
  TimeoffRequestList,
  TimeoffRequestClient,
  PTOList,
  PTO,
};

export type { GetTimesheetConfig }
