import { type Requisition } from '@kalos/kalos-rpc';
import { Button, Dialog, DialogContent, DialogTitle, DialogTrigger } from '@kalos/ui';
import { useState } from 'react';

import { useRequisitionCreateMutation } from '../../../hooks/react-query/useToolFundQuery';
import { RequisitionForm } from './RequisitionForm';

interface RequisitionFormDialogProps {
  userId: number;
}

export const RequisitionFormDialog = ({ userId }: RequisitionFormDialogProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const createRequisition = useRequisitionCreateMutation();

  const handleSubmit = async (data: Requisition) => {
    try {
      await createRequisition.mutateAsync(data);
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error submitting requisition:', error);
    }
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen} modal={true}>
      <DialogTrigger asChild>
        <Button>New Tool Request</Button>
      </DialogTrigger>
      <DialogContent
        className="max-h-svh overflow-auto"
        onPointerDownOutside={(e) => {
          if (
            e.target instanceof Element &&
            (e.target.closest('.pac-container') || e.target.closest('.pac-item'))
          ) {
            e.preventDefault();
          }
        }}
      >
        <DialogTitle>Request New Tool</DialogTitle>
        <RequisitionForm
          userId={userId}
          onSubmit={handleSubmit}
          isSubmitting={createRequisition.isPending}
        />
      </DialogContent>
    </Dialog>
  );
};
