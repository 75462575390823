import './Services.module.less';

import {
  ActivityLog,
  Event,
  File,
  Payment,
  PaymentClient,
  QuoteLine,
  QuoteUsed,
  QuoteUsedClient,
  ServicesRendered,
  type User,
} from '@kalos/kalos-rpc';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ZoomIn from '@mui/icons-material/ZoomIn';
import { default as IconButton } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { format } from 'date-fns';
import { type FC, useCallback, useMemo, useReducer, useState } from 'react';

import {
  ENDPOINT,
  OPTION_BLANK,
  PAYMENT_COLLECTED_LIST,
  PAYMENT_NOT_COLLECTED_LIST,
  SERVICE_STATUSES,
  SIGNATURE_PAYMENT_TYPE_LIST,
} from '../../../../constants';
import {
  ActivityLogClientService,
  EventClientService,
  FileClientService,
  formatDateTime,
  formatDateTimeDay,
  makeFakeRows,
  QuoteLineClientService,
  S3ClientService,
  ServicesRenderedClientService,
  timestamp,
} from '../../../../tools/helpers';
import { ConfirmDelete } from '../../ConfirmDelete';
import { Form } from '../../Form';
import { type Columns, type Data, InfoTable } from '../../InfoTable';
import { Modal } from '../../Modal';
import { PlainForm, type Schema } from '../../PlainForm';
import { QuoteSelector } from '../../QuoteSelector';
import { SectionBar } from '../../SectionBar';
import { createVerboseLogging } from '../utils';
import { SERVICES_RENDERED_PAYMENT_INITIAL } from './constants';
import { ACTIONS, reducer, sortById } from './servicesReducer';

const { NO_STATUS, ENROUTE, ON_CALL, ADMIN, PAYMENT, SIGNATURE, COMPLETED, INCOMPLETE, SIGNED_AS } =
  SERVICE_STATUSES;

export type ServicesRenderedPaymentType = {
  servicesRenderedId: number;
  servicesRendered: string;
  technicianNotes: string;
  paymentCollected: number;
  amountCollected: number;
  paymentType: string;
  dateProcessed: string;
  paymentId: number;
};
export type PaymentAndSignatureType = {
  signature: string;
  authorizedSignorName: string;
  authorizedSignorRole: string;
  signorNotes: string;
  amountCollected: number;
  date: string;
  paymentType: string;
};
export type PaymentType = {
  paymentCollected: number;
  amountCollected: number;
  date: string;
  paymentType: string;
};
export type SignatureType = {
  signature: string;
  authorizedSignorName: string;
  authorizedSignorRole: string;
  signorNotes: string;
};
export type SavedSignatureType = {
  signatureData: string;
  authorizedSignorName: string;
  authorizedSignorRole: string;
  signorNotes: string;
};

type SingatureError = { key: string; message: string };

interface Props {
  serviceCallId: number;
  loggedUser: User;
  archived: boolean;
  servicesRenderedProp: ServicesRendered[];
  loading: boolean;
  serviceRenderedPayment?: ServicesRenderedPaymentType;
  paidServicesFetch: () => Promise<Payment[]>;
  currentStatus: string;
  updateServicesRenderedProp: (serviceRendered: ServicesRendered[]) => void;
  updateStatus: (status: string) => void;
  onUpdateMaterials: () => Promise<void>;
  updateServiceRenderedPayment?: (data: ServicesRenderedPaymentType) => void;
}

const COLUMNS_SERVICES_RENDERED: Columns = [
  { name: 'Date/Time' },
  { name: 'Employee' },
  { name: 'Services Rendered' },
  { name: '' },
  { name: 'Employee Notes' },
];

const COLUMNS_SERVICES_RENDERED_HISTORY: Columns = [
  { name: 'Date/Time' },
  { name: 'Employee' },
  { name: 'Status' },
];

const SCHEMA_SIGNATURE: Schema<SignatureType> = [
  [
    {
      label: 'Signature',
      name: 'signature',
      type: 'signature',
    },
  ],
  [
    {
      label: 'Authorized Signor Name',
      name: 'authorizedSignorName',
      required: true,
    },
    {
      label: 'Authorized Signor Role',
      name: 'authorizedSignorRole',
      required: true,
    },
    {
      label: 'Signor Notes',
      name: 'signorNotes',
      required: true,
      multiline: true,
    },
  ],
];
const SCHEMA_SIGNATURE_SAVED: Schema<SavedSignatureType> = [
  [
    {
      label: 'Authorized Signor Name',
      name: 'authorizedSignorName',
      disabled: true,
    },
    {
      label: 'Authorized Signor Role',
      name: 'authorizedSignorRole',
      disabled: true,
    },
    {
      label: 'Signor Notes',
      name: 'signorNotes',
      multiline: true,
      disabled: true,
    },
  ],
];

const SCHEMA_PAYMENT: Schema<PaymentType> = [
  [
    {
      label: 'Payment Type',
      name: 'paymentType',
      options: [OPTION_BLANK, ...SIGNATURE_PAYMENT_TYPE_LIST],
    },
    {
      label: 'Amount Collected',
      name: 'amountCollected',
      type: 'number',
      startAdornment: '$',
    },
    {
      label: 'Payment Collected?',
      name: 'paymentCollected',
      type: 'checkbox',
    },
    {
      label: 'Date Processed',
      name: 'date',
      type: 'date',
    },
  ],
];
const PAYMENT_SIGNATURE_INITIAL: PaymentAndSignatureType = {
  signature: '',
  authorizedSignorName: '',
  authorizedSignorRole: '',
  signorNotes: '',
  date: timestamp(),
  paymentType: OPTION_BLANK,
  amountCollected: 0,
};

const handleCheckAmountValid = (data: ServicesRenderedPaymentType) => {
  const errors: { [key: string]: string } = {};
  if (data.paymentCollected && data.amountCollected <= 0) {
    errors.amountCollected = "This field can't be 0 or negative";
  } else if (data.amountCollected < 0) {
    errors.amountCollected = "This field can't be negative";
  }
  if (data.technicianNotes.trim().replaceAll(' ', '').length > 2000) {
    errors.technicianNotes = "This field can't contain more than 2000 characters.";
  }
  if (data.servicesRendered.trim().replaceAll(' ', '').length > 2000) {
    errors.servicesRendered = "This field can't contain more than 2000 characters.";
  }

  return Object.keys(errors).length ? errors : undefined;
};

export const Services: FC<Props> = ({
  serviceCallId,
  loggedUser,
  servicesRenderedProp,
  loading,
  serviceRenderedPayment,
  archived,
  currentStatus,
  paidServicesFetch,
  updateServicesRenderedProp,
  onUpdateMaterials,
  updateStatus,
  updateServiceRenderedPayment,
}) => {
  const SIGNATURE_INITIAL: SignatureType = useMemo(
    () => ({
      signature: '',
      authorizedSignorName: loggedUser.firstname + ' ' + loggedUser.lastname,
      authorizedSignorRole: '',
      signorNotes: '',
    }),
    [loggedUser.firstname, loggedUser.lastname],
  );

  const [state, dispatch] = useReducer(reducer, {
    paymentForm: PAYMENT_SIGNATURE_INITIAL,
    viewPayment: undefined,
    viewSignature: undefined,
    openMaterials: false,
    signatureForm: SIGNATURE_INITIAL,
    servicesRendered: sortById(servicesRenderedProp),
    deleting: undefined,
    saving: false,
    editing: SERVICES_RENDERED_PAYMENT_INITIAL,
    serviceRenderedPayment: serviceRenderedPayment || SERVICES_RENDERED_PAYMENT_INITIAL,
    changingStatus: false,
    pendingQuotable: [],
    pendingNewQuotable: [],
  });

  const [isRenderedPaymentValid, setIsRenderedPaymentValid] = useState<boolean>(true);
  const [isRenderedPaymentEditValid, setIsRenderedPaymentEditValid] = useState<boolean>(true);
  const [signatureFormErrors, setSignatureFormErrors] = useState<SingatureError[]>([]);
  const bucket = 'testbuckethelios';
  const hasEditingAccess = useMemo(() => {
    return !!loggedUser.permissionGroups.find(
      ({ name }) => name === 'Manager' || name === 'ServiceCallBillingAccess',
    );
  }, [loggedUser.permissionGroups]);

  const SCHEMA_ON_CALL: Schema<ServicesRenderedPaymentType> = [
    [
      {
        label: 'Services Rendered',
        name: 'servicesRendered',
        multiline: true,
      },
      {
        name: 'servicesRenderedId',
        type: 'hidden',
      },
      {
        label: 'Employee Notes',
        name: 'technicianNotes',
        multiline: true,
        helperText: 'For internal use',
      },
    ],
    [
      {
        label: 'Payment Collected',
        name: 'paymentCollected',
        type: 'checkbox',
      },

      {
        label: 'Id',
        name: 'paymentId',
        type: 'hidden',
      },
      {
        label: 'Payment Type',
        name: 'paymentType',
        options: [
          OPTION_BLANK,
          ...(state.editing?.paymentCollected || state.serviceRenderedPayment.paymentCollected
            ? PAYMENT_COLLECTED_LIST
            : PAYMENT_NOT_COLLECTED_LIST),
        ],
      },
      {
        label: 'Amount Collected',
        name: 'amountCollected',
        type: 'number',
        startAdornment: '$',
      },
      {
        label: 'Date Processed',
        name: 'dateProcessed',
        type: 'date',
      },
    ],
  ];

  const handleDeleting = useCallback(
    (deleting?: ServicesRendered) => () => dispatch({ type: ACTIONS.SET_DELETING, data: deleting }),
    [],
  );
  const handleDelete = useCallback(async () => {
    if (state.deleting) {
      dispatch({ type: ACTIONS.SET_DELETING, data: undefined });
      const req = ServicesRendered.create();
      req.id = state.deleting.id;
      const paymentClientService = new PaymentClient(ENDPOINT);
      const paymentReq = Payment.create();
      paymentReq.servicesRenderedId = state.deleting.id;
      try {
        const foundPayment = await paymentClientService.Get(paymentReq);
        if (foundPayment) {
          await paymentClientService.Delete(foundPayment);
          //just to keep it safe, we will refetch the payments
        }
      } catch {
        console.log('no payment found');
      }
      //update the job status to the most recent SR status

      await ServicesRenderedClientService.Delete(req);
      if (onUpdateMaterials) {
        onUpdateMaterials();
      }

      const servicesRendered = state.servicesRendered.filter((el) => el.id != req.id);
      await paidServicesFetch();
      updateServicesRenderedProp(servicesRendered);
      dispatch({ type: ACTIONS.SET_SERVICES_RENDERED, data: servicesRendered });
    }
  }, [
    state.deleting,
    paidServicesFetch,
    state.servicesRendered,
    onUpdateMaterials,
    updateServicesRenderedProp,
  ]);

  const handleSavePendingQuotable = useCallback(
    async (servicesRenderedId: number) => {
      const tempPendingQuotable = state.pendingQuotable;
      const tempPendingNewQuotable = state.pendingNewQuotable;
      const quoteUsedClientService = new QuoteUsedClient(ENDPOINT);
      if (tempPendingQuotable.length > 0 || tempPendingNewQuotable.length > 0) {
        for (let i = 0; i < tempPendingQuotable.length; i++) {
          const quotePart = tempPendingQuotable[i];
          const req = QuoteUsed.create();
          req.quoteLineId = quotePart.quoteLineId;
          req.quotedPrice = quotePart.quotedPrice;
          req.quantity = quotePart.quantity;
          req.servicesRenderedId = servicesRenderedId;
          req.billable = quotePart.isBillable === true ? 1 : 0;

          try {
            await quoteUsedClientService.Create(req);
          } catch {
            console.log('failed to add quotable item');
          }
        }
        for (let i = 0; i < tempPendingNewQuotable.length; i++) {
          const quotePart = tempPendingNewQuotable[i];
          const req = QuoteUsed.create();
          const quotelineReq = QuoteLine.create();
          quotelineReq.description = quotePart.description;
          quotelineReq.adjustment = quotePart.quotedPrice.toString();
          quotelineReq.warranty = 2;
          const quotelineRes = await QuoteLineClientService.Create(quotelineReq);
          req.quoteLineId = quotelineRes!.id;
          req.quotedPrice = quotePart.quotedPrice;
          req.quantity = quotePart.quantity;
          req.lmpc = quotePart.isLmpc === true ? 1 : 0;
          req.servicesRenderedId = servicesRenderedId;
          req.billable = quotePart.isBillable == true ? 1 : 0;

          try {
            await quoteUsedClientService.Create(req);
          } catch {
            console.log('failed to add quotable item');
          }
        }
        dispatch({ type: ACTIONS.SET_PENDING_NEW_QUOTABLE, data: [] });
        dispatch({ type: ACTIONS.SET_PENDING_QUOTABLE, data: [] });
      }
    },
    [state.pendingQuotable, state.pendingNewQuotable],
  );
  const getLastStatusForLoggedUser = useCallback(() => {
    let latestSr = ServicesRendered.create();
    for (let i = 0; i < state.servicesRendered.length; i++) {
      const sr = state.servicesRendered[i];
      if (state.servicesRendered[i]?.technicianUserId == loggedUser.id) {
        if (latestSr.id < sr.id) {
          latestSr = sr;
        }
      }
    }
    if (latestSr.id != 0) {
      return latestSr;
    } else {
      return undefined;
    }
  }, [loggedUser.id, state.servicesRendered]);

  let lastStatus = '';
  const lastSr = getLastStatusForLoggedUser();
  if (lastSr) {
    lastStatus = lastSr.status;
  }

  if (lastStatus.startsWith(SIGNED_AS)) {
    lastStatus = SIGNED_AS;
  }

  const createActivity = useCallback(
    (message: string) => {
      const activity = ActivityLog.create();
      activity.eventId = serviceCallId;
      activity.activityName = message;
      activity.activityDate = timestamp();
      activity.userId = loggedUser.id;
      return activity;
    },
    [loggedUser.id, serviceCallId],
  );

  const handleCreateStatusActivity = useCallback(
    async ({ lastStatus, newStatus }: { lastStatus: string; newStatus: string }) => {
      const message = `User ${loggedUser.firstname + ' ' + loggedUser.lastname} changed status from '${lastStatus}' to '${newStatus}'`;
      const activity = createActivity(message);
      await ActivityLogClientService.Create(activity);
    },
    [createActivity, loggedUser.firstname, loggedUser.lastname],
  );

  const handleChangeStatus = useCallback(
    (status: string) => async () => {
      const isSignature = status === SIGNED_AS;
      if (isSignature) {
        const errors: SingatureError[] = [];
        if (state.signatureForm.authorizedSignorName.length === 0) {
          errors.push({
            key: 'Authorized Signor Name: ',
            message: 'This field is required',
          });
        }
        if (state.signatureForm.authorizedSignorRole.length === 0) {
          errors.push({
            key: 'Authorized Signor Role: ',
            message: 'This field is required',
          });
        }
        if (state.signatureForm.signorNotes.length === 0) {
          errors.push({
            key: 'Signor Notes: ',
            message: 'This field is required',
          });
        }
        if (state.signatureForm.authorizedSignorName.length > 256) {
          errors.push({
            key: 'Authorized Signor Name: ',
            message: 'This field must be less than 256 characters',
          });
        }
        if (state.signatureForm.authorizedSignorRole.length > 128) {
          errors.push({
            key: 'Authorized Signor Role: ',
            message: 'This field must be less than 128 characters',
          });
        }
        if (state.signatureForm.signorNotes.length > 1024) {
          errors.push({
            key: 'Signor Notes: ',
            message: 'This field must be less than 1024 characters',
          });
        }
        if (errors.length > 0) {
          setSignatureFormErrors(errors);
          return;
        }
      }
      setSignatureFormErrors([]);

      dispatch({ type: ACTIONS.SET_CHANGING_STATUS, data: true });
      dispatch({ type: ACTIONS.SET_SAVING, data: true });
      const req = ServicesRendered.create();
      if (isSignature) {
        req.techNotes = state.signatureForm.signorNotes;
      }
      req.datetime = timestamp();
      req.timeStarted = timestamp();
      req.eventId = serviceCallId;
      const statusToSave = `${status}${
        isSignature
          ? ` ${state.signatureForm.authorizedSignorRole || state.paymentForm.authorizedSignorRole}`
          : ''
      }`;
      req.status = statusToSave;
      req.name = isSignature
        ? `${state.signatureForm.authorizedSignorName || state.paymentForm.authorizedSignorName}`
        : `${loggedUser.firstname} ${loggedUser.lastname}`;

      const fieldMask = ['EventId', 'Status', 'Name', 'Datetime'];
      req.fieldMask = fieldMask;

      /*
      if (state.paymentForm != PAYMENT_SIGNATURE_INITIAL) {
        paymentInfo.amountCollected = state.paymentForm.amountCollected;
        paymentInfo.paymentCollected = 1;
        paymentInfo.date = state.paymentForm.date;
        paymentInfo.paymentType = state.paymentForm.paymentType;
      }
      */
      if (state.serviceRenderedPayment !== SERVICES_RENDERED_PAYMENT_INITIAL) {
        //we should update the SR, and include payment if needed
        req.serviceRendered = state.serviceRenderedPayment.servicesRendered;
        req.techNotes = state.serviceRenderedPayment.technicianNotes;
        req.datetime = state.serviceRenderedPayment.dateProcessed;
      }
      req.technicianUserId = loggedUser.id;
      if (req.datetime == '') {
        req.datetime = timestamp();
      }
      const res = await ServicesRenderedClientService.Create(req);
      //now update the most recent one to have a completion date
      if (state.servicesRendered.length > 0) {
        const mostRecent = getLastStatusForLoggedUser();
        if (mostRecent) {
          const updateSr = ServicesRendered.create();
          updateSr.id = mostRecent.id;
          updateSr.timeFinished = timestamp();
          updateSr.fieldMask.push('TimeFinished');
          await ServicesRenderedClientService.Update(updateSr);
        }
      }
      const servicesRendered = state.servicesRendered;
      servicesRendered.push(res!);
      updateServicesRenderedProp(servicesRendered);
      dispatch({ type: ACTIONS.SET_SERVICES_RENDERED, data: servicesRendered });

      if (status == 'Completed' && currentStatus != 'Completed') {
        const updateEvent = Event.create();
        updateEvent.id = serviceCallId;
        if (
          loggedUser.isAdmin == 0 &&
          loggedUser.permissionGroups.find((el) => el.name == 'Manager') == undefined &&
          loggedUser.permissionGroups.find((el) => el.name == 'ServiceCallBillingAccess') ==
            undefined
        ) {
          updateEvent.logJobStatus = 'Admin Review';
          updateEvent.fieldMask.push('LogJobStatus');
        }
        updateEvent.logDateCompleted = res?.datetime || '';
        updateEvent.fieldMask.push('LogDateCompleted');
        await EventClientService.Update(updateEvent);
      }
      if (
        res!.id != 0 &&
        (state.pendingNewQuotable.length > 0 || state.pendingQuotable.length > 0)
      ) {
        await handleSavePendingQuotable(res!.id);
      }
      if (isSignature) {
        let tempSignatureData = state.signatureForm.signature;
        if (state.paymentForm.signature != '') {
          tempSignatureData = state.paymentForm.signature;
        }
        const fileReq = File.create();
        fileReq.mimeType = 'image/png';
        fileReq.bucket = bucket;
        const fileName = `signature/${res!.id}-${serviceCallId}-${format(
          new Date(),
          'hhmmss',
        )}.png`;
        try {
          await S3ClientService.uploadFileToS3Bucket(
            fileName,
            tempSignatureData,
            bucket,
            'signature',
          );
        } catch (err) {
          console.log('failed to upload to AWS', err);
        }
        fileReq.name = fileName;
        const fileRes = await FileClientService.Create(fileReq);
        res!.signatureId = fileRes!.id;
        res!.fieldMask = ['SignatureId'];
        ServicesRenderedClientService.Update(res!);
      }
      if (
        state.serviceRenderedPayment.paymentType != '-- Select --' &&
        state.serviceRenderedPayment.amountCollected > 0
      ) {
        const paymentReq = Payment.create();
        paymentReq.collected = state.serviceRenderedPayment.paymentCollected;
        paymentReq.amountCollected = state.serviceRenderedPayment.amountCollected;
        paymentReq.type = state.serviceRenderedPayment.paymentType;
        const paymentClientService = new PaymentClient(ENDPOINT);
        paymentReq.servicesRenderedId = res!.id;
        await paymentClientService.Create(paymentReq);
        await paidServicesFetch();
      }

      await handleCreateStatusActivity({
        lastStatus,
        newStatus: status,
      });
      //create png image, upload Name of signature is
      //"signature/#form.event_id#-#local.services_rendered_id#-#timeFormat(now(),'hhmmss')#.png"
      //After successful creation,Signature ID=file ID in table
      dispatch({
        type: ACTIONS.SET_PAYMENT_FORM,
        data: PAYMENT_SIGNATURE_INITIAL,
      });

      dispatch({
        type: ACTIONS.SET_SERVICE_RENDERED_PAYMENT,
        data: SERVICES_RENDERED_PAYMENT_INITIAL,
      });
      updateServiceRenderedPayment?.(SERVICES_RENDERED_PAYMENT_INITIAL);
      dispatch({ type: ACTIONS.SET_SAVING, data: false });
      if (onUpdateMaterials) {
        onUpdateMaterials();
      }
      updateStatus(res!.status);
      dispatch({ type: ACTIONS.SET_SIGNATURE_FORM, data: SIGNATURE_INITIAL });

      dispatch({ type: ACTIONS.SET_CHANGING_STATUS, data: false });
    },
    [
      serviceCallId,
      updateServiceRenderedPayment,
      state.signatureForm.authorizedSignorRole,
      state.signatureForm.authorizedSignorName,
      state.signatureForm.signorNotes,
      currentStatus,
      state.signatureForm.signature,
      state.paymentForm.authorizedSignorRole,
      state.paymentForm.authorizedSignorName,
      state.paymentForm.signature,
      state.serviceRenderedPayment,
      state.servicesRendered,
      state.pendingNewQuotable.length,
      state.pendingQuotable.length,
      loggedUser.firstname,
      loggedUser.lastname,
      loggedUser.id,
      loggedUser.isAdmin,
      loggedUser.permissionGroups,
      updateServicesRenderedProp,
      onUpdateMaterials,
      updateStatus,
      SIGNATURE_INITIAL,
      getLastStatusForLoggedUser,
      handleSavePendingQuotable,
      paidServicesFetch,
      lastStatus,
      handleCreateStatusActivity,
    ],
  );

  const handleChangeServiceRendered = useCallback(
    async (data: ServicesRenderedPaymentType) => {
      if (state.editing) {
        const paymentClientService = new PaymentClient(ENDPOINT);
        dispatch({ type: ACTIONS.SET_SAVING, data: true });
        const srReq = ServicesRendered.create();
        srReq.serviceRendered = data.servicesRendered;
        srReq.techNotes = data.technicianNotes;
        srReq.datetime = data.dateProcessed;
        srReq.id = data.servicesRenderedId;
        srReq.fieldMask = ['TechNotes', 'ServiceRendered', 'Datetime'];
        //update sr
        await ServicesRenderedClientService.Update(srReq);
        const servicesRendered = state.servicesRendered;
        const index = servicesRendered.findIndex((el) => el.id == srReq.id);

        const updateSr = ServicesRendered.create();
        updateSr.id = srReq.id;
        const result = await ServicesRenderedClientService.Get(updateSr);
        if (result) {
          servicesRendered[index] = result;
        }
        updateServicesRenderedProp(servicesRendered);
        dispatch({
          type: ACTIONS.SET_SERVICES_RENDERED,
          data: servicesRendered,
        });

        const paymentReq = Payment.create();
        paymentReq.id = data.paymentId;
        paymentReq.collected = data.paymentCollected;
        paymentReq.amountCollected = data.amountCollected;
        paymentReq.type = data.paymentType;

        //if payment ID, update, else create
        if (paymentReq.id == 0 && paymentReq.amountCollected != 0) {
          paymentReq.servicesRenderedId = srReq.id;
          if (data.amountCollected > 0) {
            const activity = createActivity(`User created payment`);
            await ActivityLogClientService.Create(activity);
            paymentClientService.Create(paymentReq);
          }
          await paidServicesFetch();
        } else {
          paymentReq.fieldMask = ['Collected', 'AmountCollected', 'Type'];
          const res = await paymentClientService.Update(paymentReq);
          await paidServicesFetch();
          if (res) {
            const activity = createActivity(
              createVerboseLogging(res, loggedUser.firstname, loggedUser.lastname),
            );
            await ActivityLogClientService.Create(activity);
          }
          const activity = createActivity(
            `User ${loggedUser.firstname + ' ' + loggedUser.lastname} changed the service rendered`,
          );
          await ActivityLogClientService.Create(activity);
        }

        dispatch({ type: ACTIONS.SET_SAVING, data: false });
        const init: ServicesRenderedPaymentType = {
          servicesRenderedId: 0,
          servicesRendered: '',
          technicianNotes: '',
          paymentType: OPTION_BLANK,
          amountCollected: 0,
          paymentCollected: 0,
          paymentId: 0,
          dateProcessed: '',
        };
        dispatch({ type: ACTIONS.SET_EDITING, data: init });
      }
    },
    [
      paidServicesFetch,
      createActivity,
      state.editing,
      state.servicesRendered,
      updateServicesRenderedProp,
      loggedUser.firstname,
      loggedUser.lastname,
    ],
  );
  const handleSetEditing = useCallback(
    (sr?: ServicesRendered) => async () => {
      const paymentReq = Payment.create();
      const paymentClientService = new PaymentClient(ENDPOINT);
      const temp: ServicesRenderedPaymentType = {
        servicesRenderedId: 0,
        servicesRendered: '',
        technicianNotes: '',
        paymentType: OPTION_BLANK,
        amountCollected: 0,
        paymentCollected: 0,
        paymentId: 0,
        dateProcessed: '',
      };
      if (sr) {
        temp.servicesRendered = sr.serviceRendered;
        temp.servicesRenderedId = sr.id;
        temp.technicianNotes = sr.techNotes;
        temp.dateProcessed = sr.datetime;

        try {
          paymentReq.servicesRenderedId = sr.id;
          const paymentResults = await paymentClientService.Get(paymentReq);
          if (paymentResults) {
            temp.amountCollected = paymentResults.amountCollected;
            temp.paymentCollected = paymentResults.collected;
            temp.paymentId = paymentResults.id;
            temp.paymentType = paymentResults.type;
          }
        } catch {
          console.log('no payment found, stick with default');
        }
      }
      setIsRenderedPaymentEditValid(true);
      dispatch({ type: ACTIONS.SET_EDITING, data: temp });
    },
    [],
  );

  const handleSetViewPreview = useCallback(
    (sr: ServicesRendered, status: string) => async () => {
      if (status === PAYMENT) {
        const paymentClientService = new PaymentClient(ENDPOINT);
        const paymentReq = Payment.create();
        paymentReq.servicesRenderedId = sr.id;
        const paymentResults = await paymentClientService.Get(paymentReq);
        if (paymentResults) {
          dispatch({
            type: ACTIONS.SET_VIEW_PAYMENT,
            data: {
              paymentType: paymentResults.type,
              amountCollected: paymentResults.amountCollected,
              paymentCollected: paymentResults.collected,
              date: format(new Date(), 'yyyy-MM-dd'),
            },
          });
        }
      } else {
        const fileReq = File.create();
        fileReq.id = sr.signatureId;
        const fileRes = await FileClientService.Get(fileReq);
        const s3Data = await S3ClientService.getFileS3BucketUrl(fileRes!.name, fileRes!.bucket);
        dispatch({
          type: ACTIONS.SET_VIEW_SIGNATURE,
          data: {
            signatureData: s3Data,
            signorNotes: sr.techNotes,
            authorizedSignorName: sr.name,
            authorizedSignorRole: sr.status,
          },
        });
      }
    },
    [],
  );
  const data: Data = loading
    ? makeFakeRows(4, 3)
    : state.servicesRendered.map((props) => {
        return [
          { value: formatDateTime(props?.timeStarted || '') },
          { value: props?.name },
          {
            value: (
              <span
                style={{
                  ...([COMPLETED, INCOMPLETE].includes(props?.status)
                    ? {
                        color: props?.status === COMPLETED ? 'green' : 'red',
                      }
                    : {}),
                }}
              >
                {props?.status}
              </span>
            ),
            actions: [
              ...([COMPLETED, INCOMPLETE].includes(props?.status)
                ? [
                    <Tooltip key="edit" title="Edit" placement="top">
                      <IconButton
                        key={1}
                        onClick={handleSetEditing(props)}
                        size="small"
                        disabled={!hasEditingAccess && props.technicianUserId !== loggedUser.id}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>,
                  ]
                : []),
              ...([SIGNED_AS].includes(props?.status)
                ? [
                    <Tooltip key="zoom" title="Zoom In" placement="top">
                      <IconButton
                        key={2}
                        onClick={handleSetViewPreview(props, SIGNED_AS)}
                        size="small"
                      >
                        <ZoomIn />
                      </IconButton>
                    </Tooltip>,
                  ]
                : []),
              ...(props?.status.includes(SIGNED_AS)
                ? [
                    <Tooltip key="sign" title="View Signature" placement="top">
                      <IconButton
                        key={3}
                        onClick={handleSetViewPreview(props, SIGNED_AS)}
                        size="small"
                      >
                        <HomeRepairServiceIcon />
                      </IconButton>
                    </Tooltip>,
                  ]
                : []),
              <Tooltip key="delete" title="Delete" placement="top">
                <IconButton
                  key={0}
                  onClick={handleDeleting(props)}
                  size="small"
                  disabled={archived}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>,
            ],
          },
        ];
      });

  const servicesRenderedData: Data = state.servicesRendered
    .filter((sr) => [COMPLETED, INCOMPLETE].includes(sr?.status))
    .map((sr) => [
      { value: formatDateTimeDay(sr.timeStarted) },
      { value: sr.name },
      { value: sr.serviceRendered },
      { value: '' },
      { value: sr.techNotes },
    ]);

  return (
    <>
      {servicesRenderedData.length > 0 && (
        <>
          <SectionBar
            key={state.servicesRendered.toString() + 'Header'}
            title="Services Rendered and Employee Notes"
          />

          <InfoTable
            columns={COLUMNS_SERVICES_RENDERED}
            data={servicesRenderedData}
            loading={state.saving}
          />
        </>
      )}
      <SectionBar
        title="Services Rendered History"
        key={state.servicesRendered.toString() + 'History'}
        fixedActions={true}
        actions={
          loading
            ? []
            : [
                ...([
                  NO_STATUS,
                  ENROUTE,
                  ON_CALL,
                  COMPLETED,
                  INCOMPLETE,
                  //PAYMENT,
                  ADMIN,
                  SIGNED_AS,
                ].includes(lastStatus)
                  ? [
                      {
                        label: ENROUTE,
                        onClick: handleChangeStatus(ENROUTE),
                        disabled:
                          [ENROUTE, ON_CALL, ADMIN].includes(lastStatus) ||
                          state.changingStatus ||
                          archived,
                      },
                    ]
                  : []),
                ...([ENROUTE, ON_CALL].includes(lastStatus)
                  ? [
                      {
                        label: ON_CALL,
                        onClick: handleChangeStatus(ON_CALL) || state.changingStatus,
                        disabled:
                          [ON_CALL].includes(lastStatus) || state.changingStatus || archived,
                      },
                    ]
                  : []),
                ...([
                  NO_STATUS,
                  COMPLETED,
                  INCOMPLETE,
                  // PAYMENT,
                  SIGNATURE,
                  SIGNED_AS,
                ].includes(lastStatus) &&
                (loggedUser.permissionGroups.find((el) => el.name === 'ServiceCallBillingAccess') ||
                  loggedUser.permissionGroups.find((el) => el.name === 'Manager') ||
                  loggedUser.permissionGroups.find((el) => el.name === 'ServiceCallAdmin'))
                  ? [
                      {
                        label: ADMIN,
                        onClick: handleChangeStatus(ADMIN),
                        disabled: state.changingStatus || archived,
                      },
                    ]
                  : []),
                ...([ON_CALL, ADMIN].includes(lastStatus)
                  ? [
                      {
                        label: COMPLETED,
                        onClick: () => {
                          const errors = handleCheckAmountValid(state.serviceRenderedPayment);
                          setIsRenderedPaymentValid(!errors);
                          !errors && handleChangeStatus(COMPLETED)();
                        },
                        disabled:
                          state.changingStatus ||
                          archived ||
                          (state.serviceRenderedPayment.amountCollected
                            ? state.serviceRenderedPayment.paymentType === '-- Select --'
                            : false),
                        status: 'success' as const,
                      },
                      {
                        label: INCOMPLETE,
                        onClick: () => {
                          const errors = handleCheckAmountValid(state.serviceRenderedPayment);
                          setIsRenderedPaymentValid(!errors);
                          !errors && handleChangeStatus(INCOMPLETE)();
                        },
                        disabled: state.changingStatus || archived,
                        status: 'failure' as const,
                      },
                    ]
                  : []),
                ...([NO_STATUS, ENROUTE, COMPLETED, INCOMPLETE, SIGNED_AS].includes(lastStatus)
                  ? [
                      /* {
label: PAYMENT,
onClick: handleChangeStatus(PAYMENT),
disabled: state.changingStatus,
},
*/
                      {
                        label: SIGNATURE,
                        onClick: handleChangeStatus(SIGNATURE),
                        disabled: state.changingStatus || archived,
                      },
                    ]
                  : []),
                ...([SIGNATURE /*PAYMENT*/].includes(lastStatus)
                  ? [
                      {
                        label: 'SAVE',
                        onClick: handleChangeStatus(SIGNED_AS),
                        disabled: state.changingStatus || archived,
                      },
                    ]
                  : []),
              ]
        }
      />

      {/*[PAYMENT].includes(lastStatus) && (
        <PlainForm
          schema={SCHEMA_PAYMENT_AND_SIGNATURE}
          data={state.paymentForm}
          onChange={data =>
            dispatch({ type: ACTIONS.SET_PAYMENT_FORM, data: data })
          }
        />
        )*/}
      {[SIGNATURE].includes(lastStatus) && (
        <PlainForm
          {...(signatureFormErrors.length > 0 && {
            error: (
              <ul>
                {signatureFormErrors.map((error, index) => (
                  <li key={index}>
                    <strong>{error.key}</strong>
                    {error.message}
                  </li>
                ))}
              </ul>
            ),
          })}
          schema={SCHEMA_SIGNATURE}
          className="flex flex-col gap-2"
          data={state.signatureForm}
          onChange={(data) => dispatch({ type: ACTIONS.SET_SIGNATURE_FORM, data: data })}
        />
      )}

      {[ON_CALL, ADMIN].includes(lastStatus) && (
        <div className="flex flex-col gap-2 px-4 py-2">
          <Tooltip title="Supplies / Services">
            <IconButton
              className="max-w-max"
              key={'addMaterials'}
              onClick={() => dispatch({ type: ACTIONS.SET_OPEN_MATERIALS, data: true })}
              size="large"
              disabled={state.saving || archived}
            >
              <HomeRepairServiceIcon className="!h-10 !w-10" />
            </IconButton>
          </Tooltip>

          <PlainForm
            className="ServicesOnCallForm !p-0"
            schema={SCHEMA_ON_CALL}
            data={state.serviceRenderedPayment}
            onChange={(data) => {
              dispatch({
                type: ACTIONS.SET_SERVICE_RENDERED_PAYMENT,
                data: data,
              });
              updateServiceRenderedPayment?.(data);
            }}
            disabled={state.saving || archived}
            validations={
              !isRenderedPaymentValid
                ? handleCheckAmountValid(state.serviceRenderedPayment)
                : undefined
            }
            compact
          />
          <Modal
            open={state.openMaterials}
            onClose={() => dispatch({ type: ACTIONS.SET_OPEN_MATERIALS, data: false })}
          >
            <QuoteSelector
              onUpdate={onUpdateMaterials}
              archived={archived}
              onClose={() => dispatch({ type: ACTIONS.SET_OPEN_MATERIALS, data: false })}
              pendingNewQuotableProp={state.pendingNewQuotable}
              pendingQuotableProp={state.pendingQuotable}
              setPendingNewQuotableProp={(data) =>
                dispatch({ type: ACTIONS.SET_PENDING_NEW_QUOTABLE, data: data })
              }
              setPendingQuotableProp={(data) =>
                dispatch({ type: ACTIONS.SET_PENDING_QUOTABLE, data: data })
              }
            />
          </Modal>
        </div>
      )}
      <InfoTable
        columns={COLUMNS_SERVICES_RENDERED_HISTORY}
        key={state.servicesRendered.toString()}
        data={data}
        loading={loading}
      />
      {state.deleting && (
        <ConfirmDelete
          open
          onClose={handleDeleting()}
          kind="Service Rendered Item"
          name={`Employee: ${state.deleting.name}, Status: ${state.deleting.status}`}
          onConfirm={handleDelete}
        />
      )}
      {state.editing.servicesRenderedId != 0 && (
        <Modal open onClose={handleSetEditing()}>
          <div className={'ServicesEditing edit ' + state.editing.paymentCollected}>
            <Form<ServicesRenderedPaymentType>
              title="Services Rendered Edit"
              schema={SCHEMA_ON_CALL}
              data={state.editing}
              onChange={(data) => {
                setIsRenderedPaymentEditValid(!handleCheckAmountValid(data));
                dispatch({ type: ACTIONS.SET_EDITING, data: data });
              }}
              onClose={handleSetEditing()}
              validationsProp={
                !isRenderedPaymentEditValid ? handleCheckAmountValid(state.editing) : undefined
              }
              submitLabel={'Save Services Rendered'}
              submitDisabled={!isRenderedPaymentEditValid}
              onSave={handleChangeServiceRendered}
              disabled={state.saving || archived}
            ></Form>
            <QuoteSelector
              archived={archived}
              servicesRenderedId={state.editing.servicesRenderedId}
              onUpdate={onUpdateMaterials}
            />
          </div>
        </Modal>
      )}
      {state.viewPayment != undefined && (
        <Modal open onClose={() => dispatch({ type: ACTIONS.SET_VIEW_PAYMENT, data: undefined })}>
          <div className="ServicesEditing">
            <PlainForm<PaymentType>
              schema={SCHEMA_PAYMENT}
              onChange={console.log}
              data={state.viewPayment}
              disabled={state.saving || archived}
            ></PlainForm>
          </div>
        </Modal>
      )}
      {state.viewSignature != undefined && (
        <Modal open onClose={() => dispatch({ type: ACTIONS.SET_VIEW_SIGNATURE, data: undefined })}>
          <div className="ServicesEditing with-img">
            <PlainForm<SavedSignatureType>
              schema={SCHEMA_SIGNATURE_SAVED}
              onChange={console.log}
              data={state.viewSignature}
              disabled={state.saving || archived}
            />
            <img alt="signature" src={state.viewSignature.signatureData}></img>
          </div>
        </Modal>
      )}
    </>
  );
};
