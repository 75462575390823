import { QuoteLine, TimesheetDepartment, User } from '@kalos/kalos-rpc';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import { default as IconButton } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import { type FC, useCallback, useEffect, useReducer } from 'react';

import { useAuth } from '../../../context/AuthContext';
import {
  makeFakeRows,
  QuoteLineClientService,
  TimesheetDepartmentClientService,
  usd,
  UserClientService,
} from '../../../tools/helpers';
import { PageWrapper } from '../../PageWrapper/main';
import { InfoTable } from '../InfoTable';
import { PlainForm, type Schema } from '../PlainForm';
import { SectionBar } from '../SectionBar';

export interface Props {
  onClose?: () => void;
}

interface FormData {
  description: string;
  cost: string;
  department: number[];
}

interface FormError {
  label: string;
  message: string;
}

interface State {
  user: User;
  canEditFlatRates: boolean;
  canEditDepartment: boolean;
  department: number;
  departmentList: TimesheetDepartment[];
  selectedRowId: number;
  flatRates: QuoteLine[];
  filteredFlatRates: QuoteLine[];
  edit: boolean;
  createNew: boolean;
  loaded: boolean;
  formData: FormData;
  pendingSave: FormData;
  searchParams: FormData;
  reload: boolean;
  saving: boolean;
  remove: boolean;
  formErrors: FormError[];
  resethFormKey: number;
}

type Action =
  | { type: 'setFlatRates'; data: QuoteLine[] }
  | { type: 'setLoaded'; data: boolean }
  | {
      type: 'setData';
      data: {
        flatRates: QuoteLine[];
        filteredFlatRates: QuoteLine[];
        loaded: boolean;
      };
    }
  | {
      type: 'setEdit';
      data: {
        selectedRowId: number;
        edit: boolean;
        formData: FormData;
      };
    }
  | { type: 'setCreateNew'; data: boolean }
  | { type: 'setFormData'; data: FormData }
  | { type: 'setPendingSave'; data: FormData }
  | { type: 'cancel'; data: FormData }
  | { type: 'setDepartmentList'; data: TimesheetDepartment[] }
  | { type: 'setSearchParams'; data: FormData }
  | { type: 'setFilteredData'; data: QuoteLine[] }
  | { type: 'setSaving'; data: boolean }
  | { type: 'setFormErrors'; data: FormError[] }
  | { type: 'setResethFormKey'; data: number }
  | {
      type: 'setUser';
      data: {
        user: User;
        canEditFlatRate: boolean;
        canEditDepartment: boolean;
      };
    }
  | {
      type: 'setPendingDelete';
      data: {
        selectedRowId: number;
        remove: boolean;
      };
    };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setFlatRates':
      return {
        ...state,
        flatRates: action.data,
      };
    case 'setLoaded':
      return {
        ...state,
        loaded: action.data,
      };
    case 'setData':
      return {
        ...state,
        flatRates: action.data.flatRates,
        filteredFlatRates: action.data.filteredFlatRates,
        loaded: action.data.loaded,
        reload: false,
      };
    case 'setEdit':
      return {
        ...state,
        selectedRowId: action.data.selectedRowId,
        edit: action.data.edit,
        formData: action.data.formData,
        pendingSave: action.data.formData,
      };
    case 'setCreateNew':
      return {
        ...state,
        createNew: action.data,
      };
    case 'setFormData':
      return {
        ...state,
        formData: action.data,
      };
    case 'setPendingSave':
      return {
        ...state,
        pendingSave: action.data,
      };
    case 'cancel':
      return {
        ...state,
        edit: false,
        pendingSave: action.data,
        selectedRowId: 0,
      };
    case 'setDepartmentList':
      return {
        ...state,
        departmentList: action.data,
      };
    case 'setSearchParams':
      return {
        ...state,
        searchParams: action.data,
        reload: true,
      };
    case 'setFilteredData':
      return {
        ...state,
        filteredFlatRates: action.data,
      };
    case 'setSaving':
      return {
        ...state,
        saving: action.data,
      };
    case 'setUser':
      return {
        ...state,
        user: action.data.user,
        canEditFlatRates: action.data.canEditFlatRate,
        canEditDepartment: action.data.canEditDepartment,
      };
    case 'setPendingDelete':
      return {
        ...state,
        selectedRowId: action.data.selectedRowId,
        remove: action.data.remove,
      };
    case 'setFormErrors':
      return {
        ...state,
        formErrors: action.data,
      };
    case 'setResethFormKey':
      return {
        ...state,
        resethFormKey: state.resethFormKey + 1,
      };
    default:
      return state;
  }
};

const initialFormData: FormData = {
  description: '',
  cost: '',
  department: [],
};

const initialState: State = {
  user: User.create(),
  canEditFlatRates: false,
  canEditDepartment: false,
  department: 0,
  departmentList: [],
  selectedRowId: 0,
  flatRates: [],
  filteredFlatRates: [],
  edit: false,
  loaded: false,
  createNew: false,
  formData: initialFormData,
  pendingSave: initialFormData,
  searchParams: initialFormData,
  reload: false,
  saving: false,
  remove: false,
  formErrors: [],
  resethFormKey: 0,
};

export const FlatRateSheet: FC<Props> = function FlatRateSheet({ onClose }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const auth = useAuth();
  const loggedUserId = auth.user.id;

  const handleToggleEdit = (type: string, selectedRowId = 0, data: FormData) => {
    if (type === 'edit') {
      dispatch({
        type: 'setEdit',
        data: { selectedRowId: selectedRowId, edit: true, formData: data },
      });
    } else if (type === 'cancel') {
      dispatch({
        type: 'cancel',
        data: { description: '', cost: '', department: [] },
      });
    }
  };

  const handleTogglePendingDelete = (selectedRow: number, remove: boolean) => {
    dispatch({
      type: 'setPendingDelete',
      data: {
        selectedRowId: selectedRow,
        remove: remove,
      },
    });
  };

  const handleToggleAdd = () => {
    if (state.createNew) {
      dispatch({ type: 'setFormErrors', data: [] });
    }
    dispatch({ type: 'setCreateNew', data: !state.createNew });
  };

  const getUserData = useCallback(async () => {
    const userObj = User.create();
    userObj.id = loggedUserId || 0;
    const user = await UserClientService.Get(userObj);
    const canEditFlatRate = user.permissionGroups.find((p) => p.name === 'EditFlatRates');
    const canEditDepartments = user.permissionGroups.find(
      (p) => p.name === 'EditFlatRateDepartment',
    );
    dispatch({
      type: 'setUser',
      data: {
        user: user,
        canEditFlatRate: canEditFlatRate !== undefined,
        canEditDepartment: canEditDepartments !== undefined,
      },
    });
  }, [loggedUserId]);

  const handleSave = async (id: number, data?: any) => {
    const flatRate = QuoteLine.create();
    if (id === 0) {
      if (data && data['Department'][0] === '') {
        data['Department'][0] = '0';
      }
      const description = data['Description'] ? data['Description'] : '';
      const cost = data['Cost'] ? data['Cost'] : '';
      const department = data['Department'] ? data['Department'].toString() : '0';
      flatRate.description = description;
      flatRate.adjustment = cost;
      flatRate.departments = department;
      flatRate.isActive = 1;
      flatRate.isFlatrate = '1';
      try {
        await QuoteLineClientService.Create(flatRate);
      } catch (err) {
        console.error('Failed to Create Flat Rate', err);
      }
      handleToggleAdd();
    } else {
      flatRate.id = id;
      flatRate.description = state.pendingSave.description;
      flatRate.adjustment = state.pendingSave.cost.replace('$', '').replace(' ', '');
      flatRate.departments = state.pendingSave.department.toString();
      try {
        flatRate.fieldMask = ['Description', 'Adjustment', 'Departments'];
        await QuoteLineClientService.Update(flatRate);
      } catch (err) {
        console.error('Failed to Save Flat Rate', err);
      }
      handleToggleEdit('cancel', 0, initialFormData);
    }
    dispatch({ type: 'setLoaded', data: false });
    dispatch({ type: 'setSaving', data: false });
  };

  const handleDelete = async (id: number) => {
    const quoteReq = QuoteLine.create();
    quoteReq.id = id;
    try {
      await QuoteLineClientService.Delete(quoteReq);
    } catch (err) {
      console.error('Failed to Update Flat Rate', err);
    }
    dispatch({ type: 'setLoaded', data: false });
  };

  const updateSearchParams = async (formData: FormData) => {
    const updatedForm: FormData = {
      description: formData.description,
      cost: formData.cost,
      department: formData.department,
    };
    dispatch({ type: 'setSearchParams', data: updatedForm });
  };

  const getFlatRates = useCallback(
    async (refresh: boolean) => {
      let qlResults: QuoteLine[] = [];
      let filteredQLResults: QuoteLine[] = [];
      const startingPage = 0;
      if (refresh) {
        const quoteReq = QuoteLine.create();
        quoteReq.isActive = 1;
        quoteReq.isFlatrate = '1';
        quoteReq.pageNumber = startingPage;
        quoteReq.withoutLimit = true;
        try {
          qlResults = (await QuoteLineClientService.BatchGet(quoteReq))!.results;
        } catch (e) {
          console.error('could not fetch results for flat rate', e);
        }
        qlResults = qlResults.sort((a, b) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return 0;
        });

        filteredQLResults = filterFlatRates(qlResults, state.searchParams);

        dispatch({
          type: 'setData',
          data: {
            flatRates: qlResults,
            filteredFlatRates: filteredQLResults,
            loaded: true,
          },
        });
      } else {
        filteredQLResults = filterFlatRates(state.flatRates, state.searchParams);
        dispatch({ type: 'setFilteredData', data: filteredQLResults });
      }
    },
    [state.searchParams, state.flatRates],
  );

  const filterFlatRates = (rates: QuoteLine[], searchParams: FormData) => {
    return rates.filter((rate) => {
      if (searchParams.description) {
        const searchTerm = searchParams.description.toLowerCase();
        const description = rate.description.toLowerCase();
        if (!description.includes(searchTerm)) {
          return false;
        }
      }

      if (searchParams.cost) {
        const searchCost = searchParams.cost.toString().replace('$', '').trim();
        const rateCost = rate.adjustment.toString();
        if (!rateCost.includes(searchCost)) {
          return false;
        }
      }

      if (searchParams.department && searchParams.department.length > 0) {
        const rateDepartments = rate.departments.split(',');
        const hasMatchingDepartment = searchParams.department.some((dep) =>
          rateDepartments.includes(dep.toString()),
        );
        if (!hasMatchingDepartment) {
          return false;
        }
      }

      return true;
    });
  };

  const getDepartments = useCallback(async () => {
    const dep = TimesheetDepartment.create();
    dep.isActive = 1;
    try {
      const departmentList = await TimesheetDepartmentClientService.BatchGet(dep);
      dispatch({
        type: 'setDepartmentList',
        data: departmentList!.results,
      });
    } catch (err) {
      console.error('Unable to Retrieve Department List', err);
    }
  }, []);

  useEffect(() => {
    if (!state.loaded) {
      if (state.user.id === 0) {
        getUserData();
      } else {
        getFlatRates(true);
        getDepartments();
      }
    }
    if (state.reload) {
      getFlatRates(false);
    }
  }, [getFlatRates, state.loaded, getDepartments, state.reload, getUserData, state.user]);

  const DESCRIPTION_SCHEMA: Schema<FormData> = [
    [
      {
        name: 'description',
        label: 'Description',
        type: 'text',
      },
    ],
  ];

  const COST_SCHEMA: Schema<FormData> = [
    [
      {
        name: 'cost',
        label: 'Cost',
        type: 'text',
      },
    ],
  ];

  const DEPARTMENT_SCHEMA: Schema<FormData> = [
    [
      {
        name: 'department',
        label: 'Department(s)',
        type: 'multiselect',
        options: state.departmentList.map((dl) => ({
          key: dl.id + dl.description,
          label: `${dl.value} - ${dl.description}`,
          value: dl.id,
        })),
      },
    ],
  ];

  const SEARCH_PARAMS: Schema<FormData> = [
    [
      {
        name: 'description',
        label: 'Description',
        type: 'text',
      },
      {
        name: 'cost',
        label: 'Cost',
        startAdornment: '$ ',
        type: 'number',
      },
      {
        name: 'department',
        label: 'Department(s)',
        type: 'multiselect',
        options: state.departmentList.map((dl) => ({
          key: dl.id + dl.description,
          label: `${dl.value} - ${dl.description}`,
          value: dl.id,
        })),
      },
    ],
  ];

  return (
    <PageWrapper>
      <SectionBar
        title="Flat Rates"
        actions={
          onClose
            ? [
                {
                  label: state.createNew ? 'Cancel Add' : 'Add New Flat Rate',
                  onClick: () => handleToggleAdd(),
                  style: { display: state.canEditFlatRates ? '' : 'none' },
                },
                {
                  label: 'Close',
                  onClick: onClose,
                },
              ]
            : [
                {
                  label: state.createNew ? 'Cancel Add' : 'Add New Flat Rate',
                  onClick: () => handleToggleAdd(),
                  style: { display: state.canEditFlatRates ? '' : 'none' },
                },
              ]
        }
        uncollapsable
      />
      {/* @ts-ignore*/}
      <Box
        sx={{
          width: '100%',
          position: 'sticky',
          top: { xs: '35px', md: '48px' },
          zIndex: 3,
          backgroundColor: 'white',
          borderTop: '0px',
          borderLeft: '0px',
          borderRight: '0px',
          border: 'solid',
          borderColor: 'black',
        }}
      >
        <div>
          <PlainForm
            schema={SEARCH_PARAMS}
            data={state.searchParams}
            onChange={debounce(updateSearchParams, 1000)}
          />
        </div>
      </Box>
      {!state.loaded && <InfoTable loading data={makeFakeRows(3, 7)} />}
      {state.loaded && (
        <>
          {state.formErrors?.length && state.createNew ? (
            <ul
              className="m-4 flex flex-col gap-2 bg-[#C62828] py-4 pl-8 pr-4"
              style={{ listStyle: 'inside' }}
            >
              <h3>Please correct the following validation errors and try again.</h3>
              {state.formErrors.map((item, idx) => (
                <li key={idx}>
                  <strong className="text-sm">{item.label}</strong>
                  <span className="text-sm-">{item.message}</span>
                </li>
              ))}
            </ul>
          ) : null}
          <InfoTable
            className="Form"
            compact={!state.canEditFlatRates}
            onSaveRowButton={debounce((result) => {
              const errors: FormError[] = [];
              if (!result || Array.isArray(result?.Cost) || !result?.Cost) {
                errors.push({
                  label: 'Cost: ',
                  message: 'This field is required',
                });
              }
              if (!result || result?.Description?.trim().replaceAll(' ', '').length <= 0) {
                errors.push({
                  label: 'Description: ',
                  message: 'This field is required',
                });
              } else if (result?.Description?.trim().replaceAll(' ', '').length > 250) {
                errors.push({
                  label: 'Description: ',
                  message: "This field can't contain more than 250 characters",
                });
              }
              dispatch({
                type: 'setFormErrors',
                data: errors,
              });
              if (!errors.length) {
                dispatch({ type: 'setSaving', data: true });
                if (result) handleSave(0, result);
                dispatch({ type: 'setResethFormKey', data: state.resethFormKey + 1 });
              }
            }, 200)}
            rowButton={{
              type: QuoteLine.create(),
              columnDefinition: {
                columnsToIgnore: ['Actions'],
                columnTypeOverrides: [
                  { columnName: 'Description', columnType: 'text', required: true },
                  { columnName: 'Cost', columnType: 'number', required: true },
                  {
                    columnName: 'Department',
                    columnType: 'multiselect',
                    options: state.departmentList.map((dl) => ({
                      label: `${dl.value} - ${dl.description}`,
                      value: dl.id,
                    })),
                  },
                ],
              },
              externalButton: true,
              externalButtonClicked: state.createNew,
              disable: state.saving,
              resetData: state.resethFormKey,
            }}
            ignoreImage
            showCreateOnly
            columns={[
              { name: 'Description', width: window.innerWidth * 0.5 },
              { name: 'Cost', width: window.innerWidth * 0.1 },
              { name: 'Department', width: window.innerWidth * 0.3 },
              {
                name: 'Actions',
                width: window.innerWidth * 0.1,
                invisible: !state.canEditFlatRates,
              },
            ]}
            data={state.filteredFlatRates.map((value) => {
              return [
                {
                  value:
                    state.remove && state.selectedRowId === value.id ? (
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bolder',
                          fontSize: '20px',
                        }}
                      >
                        Are you sure you want to delete this record?
                      </Typography>
                    ) : state.edit && state.selectedRowId === value.id ? (
                      <PlainForm
                        onChange={debounce((result) => {
                          const pendingSave = state.pendingSave;
                          pendingSave.description = result.description;
                          dispatch({ type: 'setPendingSave', data: pendingSave });
                        }, 500)}
                        schema={DESCRIPTION_SCHEMA}
                        data={state.formData}
                      />
                    ) : (
                      value.description
                    ),
                },
                {
                  value:
                    state.remove && state.selectedRowId === value.id ? (
                      <div></div>
                    ) : state.edit && state.selectedRowId === value.id ? (
                      <PlainForm
                        onChange={debounce((result) => {
                          const pendingSave = state.pendingSave;
                          pendingSave.cost = result.cost;
                          dispatch({ type: 'setPendingSave', data: pendingSave });
                        }, 500)}
                        schema={COST_SCHEMA}
                        data={state.formData}
                      />
                    ) : (
                      usd(parseFloat(value.adjustment))
                    ),
                },
                {
                  value:
                    state.remove && state.selectedRowId === value.id ? (
                      <div></div>
                    ) : state.edit &&
                      state.selectedRowId === value.id &&
                      state.canEditDepartment ? (
                      <PlainForm
                        onChange={(result) => {
                          const pendingSave = state.pendingSave;
                          pendingSave.department = result.department;
                          dispatch({ type: 'setPendingSave', data: pendingSave });
                        }}
                        schema={DEPARTMENT_SCHEMA}
                        data={state.formData}
                      />
                    ) : value.departments === '0' ? (
                      'All Departments'
                    ) : (
                      state.departmentList
                        .filter((dep) => value.departments.split(',').includes(String(dep.id)))
                        .map((dep) => dep.value)
                        .toString()
                    ),
                },
                {
                  value: (
                    <div>
                      {state.selectedRowId !== value.id && state.canEditFlatRates && (
                        <div>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() =>
                                handleToggleEdit('edit', value.id, {
                                  description: value.description,
                                  cost: usd(parseFloat(value.adjustment)),
                                  department: value.departments
                                    .split(',')
                                    .map((dep) => Number(dep)),
                                })
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton onClick={() => handleTogglePendingDelete(value.id, true)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                      {state.edit && state.selectedRowId === value.id && state.canEditFlatRates && (
                        <div>
                          <Tooltip title="Save">
                            <IconButton onClick={() => handleSave(value.id)}>
                              <SaveIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Cancel">
                            <IconButton
                              onClick={() =>
                                handleToggleEdit('cancel', 0, {
                                  description: '',
                                  cost: '',
                                  department: [],
                                })
                              }
                            >
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                      {state.remove &&
                        state.selectedRowId === value.id &&
                        state.canEditFlatRates && (
                          <div>
                            <Tooltip title="Confirm">
                              <IconButton onClick={() => handleDelete(value.id)}>
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel">
                              <IconButton onClick={() => handleTogglePendingDelete(0, false)}>
                                <CancelIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                    </div>
                  ),
                },
              ];
            })}
          />
        </>
      )}
    </PageWrapper>
  );
};
