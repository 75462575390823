import { BaseClient } from '../BaseClient';
import { type Empty } from '../compiled-protos/common';
import {
  InternalLink,
  InternalLinkList,
  PayChangeRequest,
  PayChangeRequestList,
  PerformanceReview,
  PerformanceReviewList,
  ReviewForm,
  ReviewFormList,
} from '../compiled-protos/performance_review';
import { PerformanceReviewServiceClient } from '../compiled-protos/performance_review.client';
class PerformanceReviewClient extends BaseClient {
  self: PerformanceReviewServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new PerformanceReviewServiceClient(this.transport);
  }

  public async Create(req: PerformanceReview) {
    let res = PerformanceReview.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: PerformanceReview) {
    let res = PerformanceReview.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: PerformanceReview) {
    let res = PerformanceReview.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: PerformanceReview) {
    let res = PerformanceReview.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: PerformanceReview) {
    const res = await this.self.batchGet(req, this.getMetaData()).response;
    return res;
  }

  public async CreateReviewForm(req: ReviewForm) {
    let res = ReviewForm.create();
    try {
      res = await this.self.createReviewForm(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetReviewForm(req: ReviewForm) {
    let res = ReviewForm.create();
    try {
      res = await this.self.getReviewForm(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdateReviewForm(req: ReviewForm) {
    let res = ReviewForm.create();
    try {
      res = await this.self.updateReviewForm(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DeleteReviewForm(req: ReviewForm) {
    let res = ReviewForm.create();
    try {
      res = await this.self.deleteReviewForm(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetReviewForm(req: ReviewForm) {
    let res = ReviewFormList.create();
    try {
      res = await this.self.batchGetReviewForm(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async CreatePayChange(req: PayChangeRequest) {
    let res = PayChangeRequest.create();
    try {
      res = await this.self.createPayChange(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdatePayChange(req: PayChangeRequest) {
    let res = PayChangeRequest.create();
    try {
      res = await this.self.updatePayChange(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetPayChange(req: PayChangeRequest) {
    let res = PayChangeRequest.create();
    try {
      res = await this.self.getPayChange(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetPayChange(req: PayChangeRequest) {
    let res = PayChangeRequestList.create();
    try {
      res = await this.self.batchGetPayChange(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetAllPRStatuses(req: Empty) {
    const res = await this.self.getAllPRStatuses(req, this.getMetaData()).response;
    return res
  }

  public async deletePerformanceReview(id: number) {
    const req = PerformanceReview.create();
    req.id = id;
    await this.Delete(req);
  }

  public upsertPerformanceReview = async (data: PerformanceReview) => {
    const id = data.id;
    if (id !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    return await this[id != 0 ? 'Update' : 'Create'](data);
  };

  public BatchGetInternalLink = async (req: InternalLink) => {
    let res = InternalLinkList.create();
    try {
      res = await this.self.batchGetInternalLink(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public GetInternalLink = async (req: InternalLink) => {
    let res = InternalLink.create();
    try {
      res = await this.self.getInternalLink(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public CreateInternalLink = async (req: InternalLink) => {
    let res = InternalLink.create();
    try {
      res = await this.self.createInternalLink(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public DeleteInternalLink = async (req: InternalLink) => {
    let res = InternalLink.create();
    try {
      res = await this.self.deleteInternalLink(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public UpdateInternalLink = async (req: InternalLink) => {
    let res = InternalLink.create();
    try {
      res = await this.self.updateInternalLink(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}



export {
  PerformanceReview,
  PerformanceReviewList,
  ReviewForm,
  ReviewFormList,
  PayChangeRequest,
  PayChangeRequestList,
  PerformanceReviewClient,
  InternalLink,
  InternalLinkList,
};
