import {
  TimesheetDepartment,
  User,
  UserAttributes,
  type UsersFilter,
  type UsersSort,
} from '@kalos/kalos-rpc';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  cn,
  DataTable,
  DataTableColumnHeader,
  Dialog,
  DialogContent,
  Form as FormContext,
  Skeleton,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { Alert } from '@mui/material';
import {
  CardStackPlusIcon,
  ClockIcon,
  LayersIcon,
  MagnifyingGlassIcon,
  Pencil1Icon,
  PersonIcon,
  RulerSquareIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  type OnChangeFn,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { omit, pick } from 'lodash';
import { type ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME, ROWS_PER_PAGE } from '../../../../constants';
import { useAuth } from '../../../../context/AuthContext';
import { queryKeys } from '../../../../hooks/react-query/constants';
import { getEmployeeImageQueryConfig } from '../../../../hooks/react-query/useEmployeesQuery';
import { usePTOQuery } from '../../../../hooks/react-query/usePTOQuery';
import { useTimesheetDepartmentListQuery } from '../../../../hooks/react-query/useTimesheetDepartmentQuery';
import {
  useBatchUserQuery,
  useUserAttributesGetQuery,
  useUserQuery,
  useUserUpdateMutation,
} from '../../../../hooks/react-query/useUserQuery';
import {
  debounce,
  formatDate,
  MapClientService,
  S3ClientService,
  TimesheetDepartmentClientService,
  timestamp,
  UserClientService,
} from '../../../../tools/helpers';
import { EmployeeItemsTable } from '../../../EmployeeItems/EmployeeItemsTable';
import { ReimbursementComponent } from '../../../ReimbursementLog/components/Reimbursement';
import { EmployeeToolLog } from '../../../ToolFundDashboard/components/EmployeeToolLog';
import { ToolFundProfile } from '../../../ToolFundDashboard/components/ToolFundProfile';
import { ConfirmDelete } from '../../ConfirmDelete';
import { getFieldMaskFromDirtyField } from '../../EmployeeDepartments/utils';
import { EmployeePermissions } from '../../EmployeePermissions';
import { ExportJSON, type Status } from '../../ExportJSON';
import { Modal } from '../../Modal';
import { renderUser, techniciansUserFilter } from '../../Pickers/newPickers/utils';
import { PlainForm, type Schema } from '../../PlainForm';
import { PrintHeaderSubtitleItem } from '../../PrintHeader';
import { PrintPage } from '../../PrintPage';
import { PrintTable } from '../../PrintTable';
import { SectionBar } from '../../SectionBar';
import { type AdvancedSearchProps } from '..';
import { useAdvancedSearchPaginationStore } from '../paginationStore';
import EmployeeSearchForm from './EmployeesSearchForm';
import { EmployeeAvatar } from './subcomponents/EmployeeAvatar';
import { EmployeeForm } from './subcomponents/EmployeeEditForm';
import { type EmployeeSearchScheme, useEmployeeSearchForm } from './utils';
type EmployeeSearchProps = Pick<AdvancedSearchProps, 'printableEmployees'>;

type EmployeeHandle = (entry: User) => void;

const EmployeeTableActions = ({
  entry,
  setPendingEditPermissions,
  handlePendingEmployeeViewingToggle,
  handlePendingEmployeeEditingToggle,
  handlePendingEmployeeDeletingToggle,
  handlePendingEmployeeReimbToggle,
  handleToolLogViewingToggle,
}: {
  entry: User;
  setPendingEditPermissions: EmployeeHandle;
  handlePendingEmployeeViewingToggle: EmployeeHandle;
  handlePendingEmployeeEditingToggle: EmployeeHandle;
  handlePendingEmployeeDeletingToggle: EmployeeHandle;
  handlePendingEmployeeReimbToggle: EmployeeHandle;
  handleToolLogViewingToggle: EmployeeHandle;
}) => {
  const loggedUserId = useAuth().user.id;
  const userQuery = useUserQuery({
    filters: {
      id: loggedUserId || 0,
    },
    enabled: typeof loggedUserId === 'number',
    select(data) {
      return {
        ...data,
        isAdmin:
          !!data.isAdmin ||
          !!data.permissionGroups.find((el) => el.name === 'Manager' || el.name === 'Payroll'),
        limitedAccess: !!data.permissionGroups.find(
          (el) => el.name === 'LimitedEmployeeDirectoryAccess',
        ),
        isManager: !!data.permissionGroups.find((el) => el.name === 'Manager'),
        isPayroll: !!data.permissionGroups.find((el) => el.name === 'Payroll'),
        isHR: !!data.permissionGroups.find((el) => el.name === 'HR' || el.name === 'Payroll'),
        isToolAdmin: !!data.permissionGroups.find((el) => el.name == 'ToolFundAdmin'),
        isPermissionManager:
          data.permissionGroups.findIndex((p) => p.name == 'PermissionManager') != -1,
      };
    },
  });

  const isAdmin = userQuery.data?.isAdmin || userQuery.data?.isManager;
  const canDelete = isAdmin || userQuery.data?.isPayroll;
  const permissionManager = userQuery.data?.isPermissionManager;
  const limitedAccess = userQuery.data?.limitedAccess;
  const id = entry.id;

  const isToolAdmin = userQuery.data?.isToolAdmin;
  const isOwnToolLog = entry.id === loggedUserId;

  const toolLogAction =
    isToolAdmin || isOwnToolLog ? (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
              handleToolLogViewingToggle(entry);
            }}
          >
            <RulerSquareIcon />
          </Button>
        </TooltipTrigger>
        <TooltipContent className="p-1 text-xs">View Tool Log</TooltipContent>
      </Tooltip>
    ) : null;
  const spiffLogAction = (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          onClick={(e) => {
            e.stopPropagation();
          }}
          variant="outline"
          asChild
        >
          <Link to={`/spiffLog/${id}`}>
            <CardStackPlusIcon />
          </Link>
        </Button>
      </TooltipTrigger>
      <TooltipContent className="p-1 text-xs">View Spiff Log</TooltipContent>
    </Tooltip>
  );
  const timesheetAction = (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          onClick={(e) => {
            e.stopPropagation();
          }}
          variant="outline"
          asChild
        >
          <Link to={`/timeSheet/${id}`}>
            <ClockIcon />
          </Link>
        </Button>
      </TooltipTrigger>
      <TooltipContent className="p-1 text-xs">View Timesheet</TooltipContent>
    </Tooltip>
  );
  const permissionAction = (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            setPendingEditPermissions(entry);
          }}
        >
          <PersonIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent className="p-1 text-xs">View/Edit Permissions</TooltipContent>
    </Tooltip>
  );

  const viewAction = (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            handlePendingEmployeeViewingToggle(entry);
          }}
        >
          <MagnifyingGlassIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent className="p-1 text-xs">View Employee</TooltipContent>
    </Tooltip>
  );

  const reimbursementsAction = (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            handlePendingEmployeeReimbToggle(entry);
          }}
        >
          <LayersIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent className="p-1 text-xs">View Reimbursements</TooltipContent>
    </Tooltip>
  );

  const editAction = (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            handlePendingEmployeeEditingToggle(entry);
          }}
        >
          <Pencil1Icon />
        </Button>
      </TooltipTrigger>
      <TooltipContent className="p-1 text-xs">Edit Employee</TooltipContent>
    </Tooltip>
  );

  const deleteAction = (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            handlePendingEmployeeDeletingToggle(entry);
          }}
        >
          <TrashIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent className="p-1 text-xs">Delete Employee</TooltipContent>
    </Tooltip>
  );

  return (
    <TooltipProvider delayDuration={150}>
      {viewAction}
      {(isAdmin || limitedAccess || entry.id === userQuery.data?.id) && editAction}
      {isAdmin && spiffLogAction}
      {isAdmin && toolLogAction}
      {isAdmin && timesheetAction}
      {canDelete && deleteAction}
      {permissionManager && permissionAction}
      {permissionManager && reimbursementsAction}
    </TooltipProvider>
  );
};

const loadingUsers = Array.from({ length: 5 }, (_) => User.create());

export const EmployeesSearch = ({ printableEmployees }: EmployeeSearchProps) => {
  const loggedUserId = useAuth().user.id;

  const employeeSearchForm = useEmployeeSearchForm();

  const [employeesFilter, setEmployeesFilter] = useState<EmployeeSearchScheme>(
    employeeSearchForm.getValues(),
  );

  const page = useAdvancedSearchPaginationStore((state) => state.page);
  const setPage = useAdvancedSearchPaginationStore((state) => state.setPage);
  const setTotalEntriesCount = useAdvancedSearchPaginationStore(
    (state) => state.setTotalEntriesCount,
  );
  const totalEntriesCount = useAdvancedSearchPaginationStore((state) => state.totalEntries);

  useEffect(() => {
    const debouncedUpdate = debounce((data: EmployeeSearchScheme) => {
      setEmployeesFilter({
        ...data,
        id: Number(data.id),
      });
      setPage(0);
    }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME);
    const updateSubscription = employeeSearchForm.watch((data) => {
      debouncedUpdate(data);
      setIsPrintDataQueryEnabled(false);
    });
    return () => updateSubscription.unsubscribe();
  }, [employeeSearchForm, setPage]);

  const userQuery = useUserQuery({
    filters: {
      id: loggedUserId || 0,
    },
    enabled: typeof loggedUserId === 'number',
    select(data) {
      return {
        ...data,
        isAdmin:
          data.isAdmin ||
          !!data.permissionGroups.find((el) => el.name === 'Manager' || el.name === 'Payroll'),
        limitedAccess: !!data.permissionGroups.find(
          (el) => el.name === 'LimitedEmployeeDirectoryAccess',
        ),
        isManager: !!data.permissionGroups.find((el) => el.name === 'Manager'),
        isPayroll: !!data.permissionGroups.find((el) => el.name === 'Payroll'),
        isHR: !!data.permissionGroups.find((el) => el.name === 'HR' || el.name === 'Payroll'),
        canUploadPhotos: !!data.permissionGroups.find(
          (el) => el.name === 'UploadPhotosForDirectory',
        ),
        isPermissionManager:
          data.permissionGroups.findIndex((p) => p.name == 'PermissionManager') != -1,
      };
    },
  });
  const isHR = !!userQuery.data?.isHR;

  const [employeesSort, setEmployeesSort] = useState<UsersSort>({
    orderByField: 'lastname',
    orderBy: 'user_lastname',
    orderDir: 'ASC',
  });

  const employeeQueryFilterNew = useMemo<UsersFilter>(() => {
    return {
      isEmployee: 1,
      isActive: 1,
      orderBy: employeesSort.orderBy,
      orderDir: employeesSort.orderDir,
      pageNumber: page,
      cellphone: employeesFilter.cellphone ? `%${employeesFilter.cellphone}%` : undefined,
      email: employeesFilter.email ? `%${employeesFilter.email}%` : undefined,
      empTitle: employeesFilter.empTitle ? `%${employeesFilter.empTitle}%` : undefined,
      employeeDepartmentID: employeesFilter.employeeDepartmentID,
      firstname: employeesFilter.firstname ? `%${employeesFilter.firstname}%` : undefined,
      lastname: employeesFilter.lastname ? `%${employeesFilter.lastname}%` : undefined,
      employeeItemName: employeesFilter.employeeItemName
        ? `%${employeesFilter.employeeItemName}%`
        : undefined,
      id: employeesFilter.id,
    };
  }, [employeesFilter, employeesSort, page]);

  const employeesQuery = useBatchUserQuery({
    filters: employeeQueryFilterNew,
  });

  const techniciansQuery = useBatchUserQuery({
    filters: techniciansUserFilter,
  });

  const [isPrintDataQueryEnabled, setIsPrintDataQueryEnabled] = useState<boolean>(false);
  const [isJsonDataQueryEnabled, setIsJsonDataQueryEnabled] = useState<boolean>(false);

  const employeesToPrintQuery = useBatchUserQuery({
    filters: { ...employeeQueryFilterNew, overrideLimit: true, pageNumber: 0 },
    enabled: isPrintDataQueryEnabled || isJsonDataQueryEnabled,
  });

  useEffect(() => {
    if (employeesQuery.isSuccess) {
      setTotalEntriesCount(employeesQuery.data.totalCount);
    }
  }, [employeesQuery.data, employeesQuery.isSuccess, setTotalEntriesCount]);

  const ptoQuery = usePTOQuery();

  const employeesTableColumnsDefs = useMemo<ColumnDef<User>[]>(() => {
    const columns: ColumnDef<User>[] = [
      {
        id: 'avatar',
        header: ({ column }) => (
          <DataTableColumnHeader
            className="text-xs"
            hideVisibilityToggle
            title="Avatar"
            column={column}
          />
        ),
        cell({ row }) {
          return (
            <EmployeeAvatar
              image={row.original.image}
              fallback={`${row.original.firstname.at(0)?.toUpperCase() || ''}${
                row.original.lastname.at(0)?.toUpperCase() || ''
              }`}
            />
          );
        },
        sortingFn: () => 0,
      },
      {
        meta: {
          className: 'w-16 text-sm mx-auto',
        },
        accessorKey: 'firstname',
        id: 'firstname',
        header: ({ column }) => (
          <DataTableColumnHeader
            className="mx-auto flex w-32 items-center justify-center text-center"
            hideVisibilityToggle
            title="First Name"
            column={column}
          />
        ),
        cell({ row, column }) {
          const value = row.getValue('firstname');
          return <div className={column.columnDef.meta?.className}>{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        meta: {
          className: 'w-16 text-sm mx-auto',
        },
        accessorKey: 'lastname',
        id: 'lastname',
        header: ({ column }) => (
          <DataTableColumnHeader
            className="mx-auto flex w-32 items-center justify-center text-center"
            hideVisibilityToggle
            title="Last Name"
            column={column}
          />
        ),
        cell({ row, column }) {
          const value = row.getValue('lastname');
          return <div className={column.columnDef.meta?.className}>{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'empTitle',
        header: ({ column }) => (
          <DataTableColumnHeader
            className="mx-auto flex w-32 items-center justify-center text-center"
            hideVisibilityToggle
            title="Title"
            column={column}
          />
        ),
        cell({ row }) {
          return <div className="mx-auto w-32">{row.original.empTitle}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'email',
        header: ({ column }) => (
          <DataTableColumnHeader
            className="mx-auto flex w-32 items-center justify-center text-center"
            hideVisibilityToggle
            title="Email"
            column={column}
          />
        ),
        cell({ row }) {
          const value = row.getValue('email');
          return <div className="mx-auto w-32 break-all">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'phone',
        header: ({ column }) => (
          <DataTableColumnHeader
            className="mx-auto flex w-32 items-center justify-center text-center"
            hideVisibilityToggle
            title="Office, ext."
            column={column}
          />
        ),
        cell({ row }) {
          const value = row.getValue('phone');
          return <div className="mx-auto w-32 break-all">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'cellphone',
        header: ({ column }) => (
          <DataTableColumnHeader
            className="mx-auto flex w-32 items-center justify-center text-center"
            hideVisibilityToggle
            title="Cell"
            column={column}
          />
        ),
        cell({ row }) {
          const value = row.getValue('cellphone');
          return <div className="mx-auto w-32 break-all">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'id',
        header: ({ column }) => (
          <DataTableColumnHeader
            className="mx-auto flex w-32 items-center justify-center text-center"
            hideVisibilityToggle
            title="Badge Number"
            column={column}
          />
        ),
        cell({ row }) {
          const value = row.getValue('id');
          return <div className="mx-auto w-32 break-all">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        id: 'actions',
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            className="text-center"
            hideVisibilityToggle
            title="Actions"
          />
        ),
        cell({ row }) {
          const entry = row.original;
          return (
            <div className="mx-auto flex w-[16rem] flex-wrap items-center justify-center gap-2">
              <EmployeeTableActions
                entry={entry}
                handlePendingEmployeeDeletingToggle={setPendingEmployeeDeleting}
                handlePendingEmployeeEditingToggle={setPendingEmployeeEditing}
                handlePendingEmployeeViewingToggle={setPendingEmployeeViewing}
                setPendingEditPermissions={setPendingEditPermissions}
                handlePendingEmployeeReimbToggle={setReimbursementViewing}
                handleToolLogViewingToggle={setToolLogViewing}
              />
            </div>
          );
        },
      },
    ];
    return columns;
  }, []);

  const skeletonColumns = useMemo<ColumnDef<User>[]>(() => {
    return employeesTableColumnsDefs.map((column, idx, arr) => ({
      ...column,
      cell: () => (
        <Skeleton
          className={cn(
            'bg-foreground/30',
            idx === 0
              ? 'h-10 w-10 rounded-full'
              : idx === arr.length - 1
                ? 'mx-auto h-12 w-[16rem]'
                : 'h-4 w-32',
          )}
        />
      ),
    }));
  }, [employeesTableColumnsDefs]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const onSortChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      setSorting((previousState) => {
        const newState = typeof updater === 'function' ? updater(previousState) : updater;
        const field = newState[0].id;
        const orderDir = newState[0].desc ? 'DESC' : 'ASC';
        let orderBy: string;
        if (field === 'lastname') {
          orderBy = 'user_lastname';
        } else if (field === 'firstname') {
          orderBy = 'user_firstname';
        } else if (field === 'empTitle') {
          orderBy = 'emp_title';
        } else {
          orderBy = `user_${field}`;
        }
        setEmployeesSort({
          orderByField: field as keyof User,
          orderDir,
          orderBy,
        });
        setPage(0);
        setIsPrintDataQueryEnabled(false);
        return newState;
      });
    },
    [setPage],
  );

  const [reimbursementViewing, setReimbursementViewing] = useState<User>();
  //ReimbursementComponent

  const employeesTable = useReactTable({
    data: employeesQuery.isFetching ? loadingUsers : employeesQuery.data?.results ?? [],
    columns: employeesQuery.isFetching ? skeletonColumns : employeesTableColumnsDefs,
    state: {
      sorting,
      pagination: {
        pageIndex: page,
        pageSize: ROWS_PER_PAGE,
      },
    },
    onSortingChange: onSortChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const departmentsQuery = useTimesheetDepartmentListQuery({
    filter: {
      isActive: 1,
    },
  });
  const queryClient = useQueryClient();

  const [pendingEmployeeViewing, setPendingEmployeeViewing] = useState<User | null>(null);
  const handlePendingEmployeeViewingToggle = useCallback(
    (pendingEmployeeViewing: User | null) => () =>
      setPendingEmployeeViewing(pendingEmployeeViewing),
    [setPendingEmployeeViewing],
  );

  const [saving, setSaving] = useState<boolean>(false);
  const [pendingEditPermissions, setPendingEditPermissions] = useState<User | null>(null);

  const [pendingEmployeeEditing, setPendingEmployeeEditing] = useState<User>();
  const handlePendingEmployeeEditingToggle = useCallback(
    (pendingEmployeeEditing?: User) => async () => {
      if (pendingEmployeeEditing) {
        pendingEmployeeEditing.userAttributes = await UserClientService.GetUserAttributes(
          UserAttributes.create({
            userId: pendingEmployeeEditing.id,
            fieldMask: ['UserId'],
          }),
        );
      }
      setPendingEmployeeEditing(pendingEmployeeEditing);
    },
    [setPendingEmployeeEditing],
  );

  const pendingEmployeeEditingUserAttributesQuery = useUserAttributesGetQuery({
    filter: {
      userId: pendingEmployeeEditing?.id,
    },
    enabled: !!pendingEmployeeEditing?.id,
  });

  const updateUserMutation = useUserUpdateMutation();

  const onSaveEmployee = useCallback<ComponentProps<typeof EmployeeForm>['onSave']>(
    async (data, dirtyFields, hireDate, file) => {
      try {
        setSaving(true);
        if (pendingEmployeeEditing) {
          const userAttributesFields: Array<keyof typeof data> = [
            'overrideMonth',
            'pantSize',
            'shirtSize',
          ];
          const userAttributesFieldMask = getFieldMaskFromDirtyField(
            pick(dirtyFields, userAttributesFields),
          );
          const userFieldMask = getFieldMaskFromDirtyField(omit(dirtyFields, userAttributesFields));
          const req = User.create({
            id: pendingEmployeeEditing?.id,
            fieldMask: getFieldMaskFromDirtyField(dirtyFields),
            ...data,
            ...(hireDate && { hireDate: timestamp(true, hireDate) }),
          });
          if (userFieldMask.length > 0) {
            if (file) {
              await S3ClientService.uploadFileToS3Bucket(data.image, file, 'kalos-employee-images');
              req.fieldMask.push('image');
            }

            const newData = req;
            const address = newData.address;
            const city = newData.city;
            const addressState = newData.state;
            const zip = newData.zip;

            const geo = await MapClientService.loadGeoLocationByAddress(
              `${address}, ${city}, ${addressState} ${zip}`,
            );

            if (geo) {
              newData.geolocationLat = geo.geolocationLat;
              newData.geolocationLng = geo.geolocationLng;
            }

            if (newData.fieldMask.length > 0) {
              await updateUserMutation.mutateAsync(newData);
            }
          }

          if (
            userAttributesFieldMask.length > 0 &&
            pendingEmployeeEditingUserAttributesQuery.isSuccess
          ) {
            try {
              const currentUserAttributes = pendingEmployeeEditingUserAttributesQuery.data;
              const userAttributesData = pick(data, userAttributesFields);

              if (!currentUserAttributes.id) {
                await UserClientService.CreateUserAttributes(
                  UserAttributes.create({
                    userId: data.id,
                    ...userAttributesData,
                  }),
                );
              } else {
                await UserClientService.UpdateUserAttributes(
                  UserAttributes.create({
                    ...userAttributesData,
                    id: currentUserAttributes.id,
                    fieldMask: userAttributesFieldMask,
                  }),
                );
              }
            } catch (err) {
              console.error(err);
            }
          }

          setPendingEmployeeEditing(undefined);
          setSaving(false);
          await queryClient.invalidateQueries({ queryKey: [queryKeys.user.root] });
        }
      } catch (err) {
        console.log('error updating user');
        console.error(err);
      }
    },
    [
      pendingEmployeeEditing,
      pendingEmployeeEditingUserAttributesQuery.data,
      pendingEmployeeEditingUserAttributesQuery.isSuccess,
      queryClient,
      updateUserMutation,
    ],
  );

  const [pendingEmployeeDeleting, setPendingEmployeeDeleting] = useState<User>();
  const handleDeleteEmployee = useCallback(async () => {
    if (pendingEmployeeDeleting) {
      const id = pendingEmployeeDeleting.id;
      setPendingEmployeeDeleting(undefined);
      await UserClientService.deleteUserById(id);
      // TODO - use mutation and cache modification
      queryClient.refetchQueries({ queryKey: [queryKeys.user.root, queryKeys.user.list] });
    }
  }, [pendingEmployeeDeleting, queryClient]);

  const handlePendingEmployeeDeletingToggle = useCallback(
    (pendingEmployeeDeleting?: User) => () => setPendingEmployeeDeleting(pendingEmployeeDeleting),
    [],
  );

  const SCHEMA_EMPLOYEES_VIEW: Schema<User> = useMemo(
    () => [
      [
        {
          name: 'email',
          label: 'Email',
          disabled: true,
        },
      ],
      [
        {
          name: 'firstname',
          label: 'First Name',
          disabled: true,
        },
      ],
      [
        {
          name: 'lastname',
          label: 'Last Name',
          disabled: true,
        },
      ],
      [
        {
          name: 'phone',
          label: 'Phone',
          disabled: true,
        },
      ],
      [
        {
          name: 'ext',
          label: 'Phone ext.',
          disabled: true,
        },
      ],
      [
        {
          name: 'empTitle',
          label: 'Title',
          disabled: true,
        },
      ],
      [
        {
          name: 'employeeDepartmentID',
          label: 'Department',
          options:
            departmentsQuery.data?.results.map((d) => ({
              label: `${d.value} - ${d.description}`,
              value: d.id,
            })) ?? [],
          disabled: true,
        },
      ],
    ],
    [departmentsQuery.data],
  );

  const printableData = useMemo(() => {
    if (!employeesToPrintQuery.isSuccess) {
      return [];
    }
    return employeesToPrintQuery.data?.results.map((entry) => {
      const email = entry.email;
      const cellphone = entry.cellphone;
      const empTitle = entry.empTitle;
      return [
        {
          value: UserClientService.getCustomerName(entry),
        },
        {
          value: empTitle,
        },
        {
          value: email,
        },
        {
          value: UserClientService.getCustomerPhoneWithExt(entry),
        },
        {
          value: cellphone,
        },
        {
          value: entry.id,
        },
      ];
    });
  }, [employeesToPrintQuery.data?.results, employeesToPrintQuery.isSuccess]);

  const printHeaderSubtitle = useMemo(() => {
    const { firstname, lastname, empTitle, email, phone, cellphone, employeeDepartmentID } =
      employeesFilter;
    return (
      <>
        {employeeDepartmentID! > 0 && (
          <PrintHeaderSubtitleItem
            label="Department"
            value={TimesheetDepartmentClientService.getDepartmentName(
              departmentsQuery.data?.results.find(
                (u) => u.id === employeesFilter.employeeDepartmentID,
              ) || TimesheetDepartment.create(),
            )}
          />
        )}
        {!!firstname && <PrintHeaderSubtitleItem label="Firstname with" value={firstname} />}
        {!!lastname && <PrintHeaderSubtitleItem label="Lastname with" value={lastname} />}
        {!!empTitle && <PrintHeaderSubtitleItem label="Title with" value={empTitle} />}
        {!!email && <PrintHeaderSubtitleItem label="Email with" value={email} />}
        {!!phone && <PrintHeaderSubtitleItem label="Phone with" value={phone} />}
        {!!cellphone && <PrintHeaderSubtitleItem label="Cell with" value={cellphone} />}
      </>
    );
  }, [employeesFilter, departmentsQuery.data]);

  const pendingEmployeeViewImageQuery = useQuery({
    ...getEmployeeImageQueryConfig({ imageUrl: pendingEmployeeViewing?.image || '' }),
    enabled: !!pendingEmployeeViewing,
  });

  const onTableRowClick = useCallback((user: User) => {
    if (user.id) {
      setPendingEmployeeViewing(user);
    }
  }, []);

  const handleExport = useCallback(async () => {
    setIsJsonDataQueryEnabled(true);
  }, []);
  const handleExported = useCallback(() => setIsJsonDataQueryEnabled(false), []);

  const jsonExportData = useMemo(() => {
    if (
      !employeesToPrintQuery.isSuccess ||
      !techniciansQuery.isSuccess ||
      !departmentsQuery.isSuccess
    )
      return [];

    return employeesToPrintQuery.data?.results.map((user) => {
      const department = departmentsQuery.data?.results.find(
        (d) => d.id === user.employeeDepartmentID,
      );
      const deparmentName = department ? `${department.value} - ${department.description}` : '';
      const userAddress = `${user.address}, ${user.city}, ${user.state} ${user.zip}`;
      const manager = techniciansQuery.data?.results.find((t) => t.id === user.managedBy);
      return {
        firstname: user.firstname,
        lastname: user.lastname,
        title: user.empTitle,
        department: deparmentName,
        supervisor: manager ? renderUser(manager) : 'N/A',
        hireDate: formatDate(user.hireDate),
        address: userAddress,
      };
    });
  }, [
    departmentsQuery.data?.results,
    departmentsQuery.isSuccess,
    employeesToPrintQuery.data?.results,
    employeesToPrintQuery.isSuccess,
    techniciansQuery.data?.results,
    techniciansQuery.isSuccess,
  ]);

  const exportJSonStatus: Status = isJsonDataQueryEnabled
    ? employeesToPrintQuery.isSuccess && techniciansQuery.isSuccess && departmentsQuery.isSuccess
      ? 'loaded'
      : 'loading'
    : 'idle';
  const exportPrintDataStatus: Status = isPrintDataQueryEnabled
    ? employeesToPrintQuery.isSuccess
      ? 'loaded'
      : 'loading'
    : 'idle';

  const [toolLogViewing, setToolLogViewing] = useState<User>();

  return (
    <>
      <FormContext {...employeeSearchForm}>
        <div className="p-4">
          <EmployeeSearchForm showOwnedItemField={!!userQuery.data?.isAdmin} />
          <div className="flex max-w-max gap-2 py-4">
            <ExportJSON
              filename="employees"
              onExport={handleExport}
              onExported={handleExported}
              status={exportJSonStatus}
              json={jsonExportData}
            />
            {printableEmployees ? (
              <>
                <Button
                  className="w-40"
                  size="sm"
                  isLoading={isPrintDataQueryEnabled && employeesToPrintQuery.isPending}
                  disabled={totalEntriesCount === 0}
                  onClick={() => {
                    setIsPrintDataQueryEnabled(true);
                  }}
                >
                  Prepare for print
                </Button>
                <PrintPage
                  headerProps={{
                    title: 'Employees',
                    subtitle: printHeaderSubtitle,
                  }}
                  onPrinted={() => setIsPrintDataQueryEnabled(false)}
                  status={exportPrintDataStatus}
                  buttonProps={{
                    className: '!hidden',
                    label: '',
                    // loading: isPrintDataQueryEnabled && employeesToPrintQuery.isPending,
                  }}
                  className="m-2 flex items-center"
                >
                  <PrintTable
                    columns={[
                      'Name',
                      'Title',
                      'Email',
                      'Office, ext.',
                      { title: 'Cell', align: 'right' },
                      'Badge ID',
                    ]}
                    data={printableData.map((rows) => rows.map((row) => row.value))}
                    noEntriesText="No employees matching criteria"
                  />
                </PrintPage>
              </>
            ) : null}
          </div>
        </div>
      </FormContext>

      <div className="max-w-[100vw] overflow-auto">
        <DataTable table={employeesTable} onRowClick={onTableRowClick} />
      </div>
      {reimbursementViewing && (
        <Dialog
          open={!!reimbursementViewing}
          onOpenChange={(open) => {
            if (!open) setReimbursementViewing(undefined);
          }}
        >
          <DialogContent className="max-h-screen max-w-screen-2xl overflow-auto px-0 md:max-h-[90vh]">
            <ReimbursementComponent reimbursementOwnerId={reimbursementViewing?.id} />
          </DialogContent>
        </Dialog>
      )}

      {pendingEmployeeViewing && (
        <Modal
          className="Modal-Remove-Scroll"
          open
          onClose={handlePendingEmployeeViewingToggle(null)}
        >
          <SectionBar title="View Employee" fixedActions />
          <Avatar className="mx-auto h-40 w-40 p-4">
            <AvatarImage className="object-cover" src={pendingEmployeeViewImageQuery.data} />
            <AvatarFallback>{`${pendingEmployeeViewing.firstname.at(0)?.toUpperCase() || ''} ${
              pendingEmployeeViewing.lastname.at(0)?.toUpperCase() || ''
            }`}</AvatarFallback>
          </Avatar>
          <PlainForm
            schema={SCHEMA_EMPLOYEES_VIEW}
            data={pendingEmployeeViewing}
            onChange={() => {}}
          />
        </Modal>
      )}

      {pendingEditPermissions && (
        <Modal
          className="Modal-Remove-Scroll"
          fullScreen={true}
          open={!!pendingEditPermissions}
          onClose={() => setPendingEditPermissions(null)}
        >
          <EmployeePermissions
            loggedUserId={loggedUserId || 0}
            userId={pendingEditPermissions.id}
            onClose={() => setPendingEditPermissions(null)}
          />
        </Modal>
      )}

      <Dialog
        open={!!pendingEmployeeEditing}
        onOpenChange={(open) => {
          if (!open) handlePendingEmployeeEditingToggle(undefined)();
        }}
      >
        <DialogContent className="flex h-svh max-w-screen-2xl flex-col gap-1 overflow-auto px-0 pb-0 xl:h-[90vh]">
          {pendingEmployeeEditing && (
            <Tabs defaultValue="employee">
              <div className="flex">
                {!!userQuery.data?.isAdmin && (
                  <TabsList className="mx-auto">
                    <TabsTrigger value="employee">Employee</TabsTrigger>
                    <TabsTrigger value="items">Items</TabsTrigger>
                    <TabsTrigger value="toolFundSettings">Tool Fund Settings</TabsTrigger>
                  </TabsList>
                )}
              </div>

              <TabsContent className="px-4 pb-10" value="employee">
                {!isHR && pendingEmployeeEditing.id != 0 && (
                  <Alert severity="warning">
                    {' If you would like to change Department, please contact HR '}
                  </Alert>
                )}

                {pendingEmployeeEditingUserAttributesQuery.isPending && (
                  <Skeleton className="h-[40vh] w-full" />
                )}
                {pendingEmployeeEditingUserAttributesQuery.isSuccess && (
                  <EmployeeForm
                    isAdmin={!!userQuery.data?.isAdmin}
                    disableProfileImageField={
                      !(userQuery.data?.isHR || userQuery.data?.canUploadPhotos)
                    }
                    isHR={isHR}
                    user={pendingEmployeeEditing}
                    userAttributes={pendingEmployeeEditingUserAttributesQuery.data}
                    departmentsQuery={departmentsQuery}
                    saving={saving}
                    onSave={onSaveEmployee}
                    limitedAccess={userQuery.data?.limitedAccess}
                    associatedPto={ptoQuery.data?.results.find(
                      (el) => el.id === pendingEmployeeEditing.id,
                    )}
                  />
                )}
              </TabsContent>

              {!!userQuery.data?.isAdmin && (
                <TabsContent value="items">
                  <EmployeeItemsTable userId={pendingEmployeeEditing.id} />
                </TabsContent>
              )}
              {!!userQuery.data?.isAdmin && (
                <TabsContent value="toolFundSettings">
                  <ToolFundProfile userId={pendingEmployeeEditing.id} />
                </TabsContent>
              )}
            </Tabs>
          )}
        </DialogContent>
      </Dialog>

      {pendingEmployeeDeleting && (
        <ConfirmDelete
          open
          onClose={handlePendingEmployeeDeletingToggle(undefined)}
          onConfirm={handleDeleteEmployee}
          kind="Employee"
          name={UserClientService.getCustomerNameAndBusinessName(pendingEmployeeDeleting)}
        />
      )}

      {toolLogViewing && (
        <Dialog
          open={!!toolLogViewing}
          onOpenChange={(open) => {
            if (!open) setToolLogViewing(undefined);
          }}
        >
          <DialogContent className="max-h-screen max-w-screen-2xl overflow-auto px-0 md:max-h-[90vh]">
            <EmployeeToolLog userId={toolLogViewing.id} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
