import { ToolFundTransaction } from '@kalos/kalos-rpc';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MoneyInput,
  SimpleSelect,
  Textarea,
  toast,
} from '@kalos/ui';
import { format } from 'date-fns';
import { type ComponentProps, useCallback, useMemo, useState } from 'react';

import { useAuth } from '../../../context/AuthContext';
import {
  useBatchGetToolFundTransactionQuery,
  useBatchGetToolFundTransactionTypeQuery,
  useToolFundTransactionCreateMutation,
  useToolFundTransactionUpdateMutation,
} from '../../../hooks/react-query/useToolFundTransaction';
import {
  defaultTransactionValues,
  type TransactionFormData,
  useTransactionForm,
} from './ToolFundTransactions.hooks';

interface TransactionFormProps {
  userId: number;
  isAdmin: boolean;
  defaultData?: Partial<TransactionFormData>;
  onSuccess?: () => void;
}

const TransactionForm = ({ userId, isAdmin, defaultData, onSuccess }: TransactionFormProps) => {
  const { user: loggedUser } = useAuth();
  const { mutate: createTransaction, isPending: isCreating } =
    useToolFundTransactionCreateMutation();
  const { mutate: updateTransaction, isPending: isUpdating } =
    useToolFundTransactionUpdateMutation();
  const { data: transactionTypes = [] } = useBatchGetToolFundTransactionTypeQuery({
    select(data) {
      return data.results.map((type) => ({
        value: type.id.toString(),
        label: `${type.name} - ${type.description}`,
      }));
    },
  });

  const form = useTransactionForm({
    defaultValues: defaultData,
    userId,
    createdBy: loggedUser.id,
  });

  const onSubmit = useCallback(
    (data: TransactionFormData) => {
      const isNewRecord = !data.id;
      const dirtyFields = form.formState.dirtyFields;

      if (isNewRecord) {
        const transaction = ToolFundTransaction.create({
          ...data,
          userId,
          createdBy: loggedUser.id,
          dateCreated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        });

        createTransaction(transaction, {
          onSuccess: () => {
            toast({
              variant: 'success',
              title: 'Transaction created successfully',
            });
            form.reset(defaultTransactionValues);
            onSuccess?.();
          },
          onError: (error) => {
            toast({
              variant: 'destructive',
              title: 'Failed to create transaction',
              description: error.message,
            });
          },
        });
      } else {
        // For updates, include the field mask
        const fieldMask = Object.keys(dirtyFields).map((field) =>
          field.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`),
        );

        console.log('Dirty fields:', dirtyFields);
        console.log('Field mask:', fieldMask);

        const transaction = ToolFundTransaction.create({
          ...data,
          userId,
          createdBy: loggedUser.id,
          dateCreated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          fieldMask,
        });

        updateTransaction(transaction, {
          onSuccess: () => {
            toast({
              variant: 'success',
              title: 'Transaction updated successfully',
            });
            onSuccess?.();
          },
          onError: (error) => {
            toast({
              variant: 'destructive',
              title: 'Failed to update transaction',
              description: error.message,
            });
          },
        });
      }
    },
    [userId, loggedUser.id, createTransaction, updateTransaction, form, onSuccess],
  );

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="rounded-lg border bg-white p-4 shadow">
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="transactionTypeId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Transaction Type</FormLabel>
                  <SimpleSelect
                    values={transactionTypes}
                    onChange={field.onChange}
                    selectedValue={field.value.toString()}
                    disabled={!isAdmin || field.disabled}
                    placeholder="Select Type"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Amount</FormLabel>
                  <FormControl>
                    <MoneyInput
                      type="number"
                      step="0.01"
                      {...field}
                      disabled={!isAdmin}
                      onChange={(e) => field.onChange(Number(e.target.value))}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Notes</FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled={!isAdmin} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {isAdmin && (
            <div className="mt-4 flex justify-end">
              <Button
                type="submit"
                disabled={isCreating || isUpdating}
                isLoading={isCreating || isUpdating}
              >
                {defaultData?.id ? 'Save' : 'Create'}
              </Button>
            </div>
          )}
        </div>
      </form>
    </Form>
  );
};

interface ToolFundTransactionsProps {
  userId: number;
  isAdmin: boolean;
}

export const ToolFundTransactions: React.FC<ToolFundTransactionsProps> = ({ userId, isAdmin }) => {
  const [showNewForm, setShowNewForm] = useState(false);
  const { data: transactions, refetch } = useBatchGetToolFundTransactionQuery({
    filters: { userId },
    enabled: !!userId,
  });

  const handleSuccess = useCallback(() => {
    setShowNewForm(false);
    refetch();
  }, [refetch]);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-semibold tracking-wide">Transactions</h3>
        {isAdmin && (
          <Button
            onClick={() => setShowNewForm((prev) => !prev)}
            variant={showNewForm ? 'destructive' : 'default'}
          >
            {showNewForm ? 'Cancel' : 'Add Transaction'}
          </Button>
        )}
      </div>

      <div className="space-y-4">
        {showNewForm && (
          <div className="border-b pb-4">
            <h4 className="mb-2 text-lg font-medium">New Transaction</h4>
            <TransactionForm userId={userId} isAdmin={isAdmin} onSuccess={handleSuccess} />
          </div>
        )}

        {transactions?.results.map((transaction) => (
          <TransactionListItem
            key={transaction.id}
            transaction={transaction}
            userId={userId}
            isAdmin={isAdmin}
            onSuccess={handleSuccess}
          />
        ))}
      </div>
    </div>
  );
};

const TransactionListItem = ({
  transaction,
  userId,
  isAdmin,
  onSuccess,
}: {
  transaction: ToolFundTransaction;
  userId: number;
  isAdmin: boolean;
  onSuccess: () => void;
}) => {
  const defaultValues = useMemo<ComponentProps<typeof TransactionForm>['defaultData']>(
    () => ({
      createdBy: transaction.createdBy,
      amount: transaction.amount,
      transactionTypeId: transaction.transactionTypeId,
      notes: transaction.notes,
      dateCreated: transaction.dateCreated,
      id: transaction.id,
      isActive: transaction.isActive,
      userId,
    }),
    [
      transaction.amount,
      transaction.createdBy,
      transaction.dateCreated,
      transaction.id,
      transaction.isActive,
      transaction.notes,
      transaction.transactionTypeId,
      userId,
    ],
  );

  return (
    <div key={transaction.id}>
      <h4 className="mb-2 text-lg font-medium">
        Transaction #{transaction.id} - {format(new Date(transaction.dateCreated), 'PPp')}
      </h4>
      <TransactionForm
        userId={userId}
        isAdmin={isAdmin}
        defaultData={defaultValues}
        onSuccess={onSuccess}
      />
    </div>
  );
};
