import { ToolFundSettings, TradeType, User } from '@kalos/kalos-rpc';
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  toast,
} from '@kalos/ui';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { type SubmitHandler } from 'react-hook-form';

import { useAuth } from '../../../context/AuthContext';
import {
  useGetToolFundSettings,
  useToolFundCreateMutation,
  useToolFundSettingsUpdateMutation,
  useUserToolFundBalanceQuery,
} from '../../../hooks/react-query/useToolFundSettingsQuery';
import { useBatchGetTradeTypes } from '../../../hooks/react-query/useTradeTypeQuery';
import { getFieldMaskFromDirtyField } from '../../ComponentsLibrary/EmployeeDepartments/utils';
import { TechniciansComboboxPicker } from '../../ComponentsLibrary/Pickers/newPickers/TechnicianComboboxPicker';
import {
  type ToolFundSettingsFormType,
  useToolFundSettingsForm,
} from './ToolFundSettingsForm.hooks';

interface ToolFundSettingsFormProps {
  userId: number;
  isAdmin: boolean;
}

export const ToolFundSettingsForm: React.FC<ToolFundSettingsFormProps> = ({ userId, isAdmin }) => {
  const { user: loggedUser } = useAuth();
  const { data: settings, isLoading } = useGetToolFundSettings(ToolFundSettings.create({ userId }));
  const { data: trades } = useBatchGetTradeTypes(TradeType.create());
  const { mutateAsync: updateSettings, isPending: isSaving } = useToolFundSettingsUpdateMutation();
  const { mutate: createSettings, isPending: isCreating } = useToolFundCreateMutation();
  const { data: balance } = useUserToolFundBalanceQuery(User.create({ id: userId }));

  const isNewRecord = useMemo(() => !settings || settings.id === 0, [settings]);

  const defaultFormValues = useMemo(() => {
    if (!isNewRecord && settings) {
      return {
        ...settings,
      };
    }
    return {
      id: 0,
      userId,
      isEligible: false,
      monthlyAllowance: 0,
      lastUpdated: new Date().toISOString(),
      updatedBy: 0,
      tradeId: undefined,
    };
  }, [isNewRecord, settings, userId]);

  const form = useToolFundSettingsForm({
    defaultValues: defaultFormValues,
    disabled: isSaving,
  });

  // Add useEffect to update form when settings loads
  useEffect(() => {
    if (settings && !isNewRecord) {
      form.reset(defaultFormValues);
    }
  }, [settings, isNewRecord, form, defaultFormValues]);

  const isSubmitting = isSaving || isCreating;
  const isDirty = form.formState.isDirty;
  const isValid = form.formState.isValid;

  const canSubmit = isDirty && isValid && !isSubmitting;

  const onSaveCreate: SubmitHandler<ToolFundSettingsFormType> = useCallback(
    async (data) => {
      const selectedTrade = trades?.find((trade) => trade.id === data.tradeTypeId);
      const isDefaultAmount = selectedTrade?.defaultAllotment === data.monthlyAllowance;

      const settingsToCreate = ToolFundSettings.create({
        ...data,
        userId,
        updatedBy: loggedUser.id,
        lastUpdated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        monthlyAllowance: isDefaultAmount ? 0 : data.monthlyAllowance,
      });

      try {
        await createSettings(settingsToCreate);
        toast({
          title: 'Success',
          description: 'Tool fund settings created successfully',
          variant: 'success',
        });
      } catch {
        toast({
          title: 'Error',
          description: 'Failed to create tool fund settings',
          variant: 'destructive',
        });
      }
    },
    [createSettings, userId, trades, loggedUser.id],
  );

  const onSaveUpdate: SubmitHandler<ToolFundSettingsFormType> = useCallback(
    async (data) => {
      const dirtyFields = form.formState.dirtyFields;
      const fieldMask = getFieldMaskFromDirtyField(dirtyFields);

      if (fieldMask.length === 0) {
        toast({
          title: 'No changes',
          description: 'No changes to save',
          variant: 'default',
        });
        return;
      }

      const selectedTrade = trades?.find((trade) => trade.id === data.tradeTypeId);
      const isDefaultAmount = selectedTrade?.defaultAllotment === data.monthlyAllowance;

      fieldMask.push('UpdatedBy', 'LastUpdated');

      const settingsToUpdate = ToolFundSettings.create({
        ...data,
        userId,
        updatedBy: loggedUser.id,
        lastUpdated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        monthlyAllowance: isDefaultAmount ? 0 : data.monthlyAllowance,
        fieldMask,
      });

      try {
        await updateSettings(settingsToUpdate);
        toast({
          title: 'Success',
          description: 'Tool fund settings updated successfully',
          variant: 'success',
        });
      } catch {
        toast({
          title: 'Error',
          description: 'Failed to update tool fund settings',
          variant: 'destructive',
        });
      }
    },
    [updateSettings, userId, trades, form.formState.dirtyFields, loggedUser.id],
  );

  const onSubmit = isNewRecord ? onSaveCreate : onSaveUpdate;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {isAdmin && (
          <div className="flex justify-end space-x-4 border-b pb-4">
            <Button type="submit" disabled={!canSubmit} isLoading={isSubmitting}>
              Save
            </Button>
          </div>
        )}

        <div>
          <h3 className="text-2xl font-semibold tracking-wide">Tool Fund Settings</h3>
          <div className="rounded-lg border bg-white p-6 shadow">
            <div className="mb-6">
              <FormItem>
                <FormLabel>Total Tool Fund Available</FormLabel>
                <FormControl>
                  <Input value={`$${balance?.currentBalance.toFixed(2) || '0.00'}`} disabled />
                </FormControl>
              </FormItem>
            </div>

            <div className="flex items-center gap-6">
              <FormField
                control={form.control}
                name="isEligible"
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-2">
                    <FormLabel className="mb-0">Eligible for Tool Fund</FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        disabled={!isAdmin || isSubmitting}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="monthlyAllowance"
                render={({ field: allowanceField }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Monthly Allowance</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...allowanceField}
                        disabled={!isAdmin || !form.getValues('isEligible') || isSubmitting}
                        onChange={(e) => allowanceField.onChange(Number(e.target.value))}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tradeTypeId"
                render={({ field: tradeField }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Trade</FormLabel>
                    <FormControl>
                      <Select
                        value={tradeField.value?.toString()}
                        defaultValue={tradeField.value?.toString()}
                        onValueChange={(value) => {
                          const numValue = Number(value);
                          tradeField.onChange(numValue);
                          const selectedTrade = trades?.find((trade) => trade.id === numValue);
                          if (selectedTrade?.defaultAllotment) {
                            form.setValue('monthlyAllowance', selectedTrade.defaultAllotment, {
                              shouldDirty: true,
                              shouldTouch: true,
                            });
                          }
                        }}
                        disabled={!isAdmin || !form.getValues('isEligible') || isSubmitting}
                      >
                        <SelectTrigger
                          className={!tradeField.value ? 'text-muted-foreground' : undefined}
                        >
                          {trades?.find((trade) => trade.id === tradeField.value)?.name ||
                            'Select Trade'}
                        </SelectTrigger>
                        <SelectContent>
                          {trades?.map((trade) => (
                            <SelectItem key={trade.id} value={trade.id.toString()}>
                              {trade.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {!isNewRecord && (
              <>
                <FormField
                  control={form.control}
                  name="updatedBy"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last Updated By</FormLabel>
                      <TechniciansComboboxPicker
                        placeholder="Select"
                        disabled={true}
                        onSelect={() => {}} // No-op since it's disabled
                        selected={field.value.toString()}
                      />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="lastUpdated"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last Updated</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          disabled={true}
                          value={new Date(field.value).toLocaleString()}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};
