import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const transactionSchema = z.object({
  id: z.number(),
  userId: z.number(),
  amount: z.number().min(0.01, 'Amount must be greater than 0'),
  notes: z.string().min(1, 'Notes are required'),
  dateCreated: z.string(),
  isActive: z.boolean(),
  createdBy: z.number(),
  transactionTypeId: z.coerce.number().min(1, 'Transaction type is required'),
});

export type TransactionFormData = z.infer<typeof transactionSchema>;

export const defaultTransactionValues: TransactionFormData = {
  id: 0,
  userId: 0,
  amount: 0,
  notes: '',
  dateCreated: new Date().toISOString(),
  isActive: true,
  createdBy: 0,
  transactionTypeId: 0,
};

export const useTransactionForm = ({
  defaultValues,
  userId,
  createdBy,
}: {
  defaultValues?: Partial<TransactionFormData>;
  userId: number;
  createdBy: number;
}) => {
  return useForm<TransactionFormData>({
    resolver: zodResolver(transactionSchema),
    defaultValues: {
      ...defaultTransactionValues,
      ...defaultValues,
      userId,
      createdBy,
    },
  });
};
