import { type User } from '@kalos/kalos-rpc';

export const lodgingAdminCheck = (user: User) => {
  return !!user.permissionGroups.find((el) => el.name === 'LodgingAdmin');
};
export const permitAdminCheck = (user: User) => {
  return !!user.permissionGroups.find((el) => el.name === 'PermitAdmin');
};

export const creditCardsPagePermissionCheck = (user: User) => {
  return !!user.permissionGroups.find((item) => item.name === 'AccountingAdmin');
};

export const isTransactionPreValidatePermissionCheck = (user: User) => {
  return !!user.permissionGroups.find((item) => item.name === 'TransactionValidator');
};
