import { AssetInstance } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { InventoryClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type AssetInstanceFilter = EntityFilter<AssetInstance>;

export const getAssetInstanceQueryKeys = (filter: AssetInstanceFilter) => [
  queryKeys.assetInstance.root,
  queryKeys.assetInstance.getHash(filter),
];

// Get single asset instance
export const useAssetInstanceQuery = ({
  filters = {},
  enabled = true,
}: {
  filters?: AssetInstanceFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: getAssetInstanceQueryKeys(filters),
    queryFn: () => {
      const req = AssetInstance.create(filters);
      return InventoryClientService.GetAssetInstance(req);
    },
    enabled,
  });
};

// Batch get asset instances
export const useBatchGetAssetInstanceQuery = ({
  filters = {},
  enabled = true,
}: {
  filters?: AssetInstanceFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.assetInstance.root,
      queryKeys.assetInstance.list,
      queryKeys.assetInstance.getHash(filters),
    ],
    queryFn: async () => {
      const req = AssetInstance.create(filters);
      return await InventoryClientService.BatchGetAssetInstance(req);
    },
    enabled,
  });
};

// Create asset instance mutation
export const useAssetInstanceCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assetInstance: AssetInstance) => {
      return await InventoryClientService.CreateAssetInstance(assetInstance);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.assetInstance.root] });
    },
  });
};

// Update asset instance mutation
export const useAssetInstanceUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assetInstance: AssetInstance) => {
      return await InventoryClientService.UpdateAssetInstance(assetInstance);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.assetInstance.root] });
    },
  });
};

// Delete asset instance mutation
export const useAssetInstanceDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assetInstance: AssetInstance) => {
      return await InventoryClientService.DeleteAssetInstance(assetInstance);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.assetInstance.root] });
    },
  });
};
