// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "timesheet_line.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "timesheet_line.proto" (syntax proto3)
// tslint:disable
import { String$ } from "./common";
import { Int32 } from "./common";
import { Int32List } from "./common";
import { Empty } from "./common";
import { DateRange } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "./user";
import { TimesheetClassCode } from "./timesheet_department";
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetLine$Type extends MessageType {
    constructor() {
        super("TimesheetLine", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "services_rendered_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "task_event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "class_code_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "department_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "brief_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "reference_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "admin_approval_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "admin_approval_datetime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "user_approval_datetime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "time_finished", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "technician_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "class_code", kind: "message", T: () => TimesheetClassCode },
            { no: 17, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "event_property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "event_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "technician_user_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "department_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "week_start", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "week_end", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "payroll_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 33, name: "admin_approval_user_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "hours_worked", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 35, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 36, name: "search_user", kind: "message", T: () => User },
            { no: 37, name: "date_processed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "duration", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, servicesRenderedId: 0, taskEventId: 0, classCodeId: 0, departmentCode: 0, briefDescription: "", referenceNumber: "", notes: "", adminApprovalUserId: 0, adminApprovalDatetime: "", userApprovalDatetime: "", timeStarted: "", timeFinished: "", technicianUserId: 0, isActive: 0, fieldMask: [], pageNumber: 0, dateRange: [], dateTarget: [], eventPropertyId: 0, eventUserId: 0, technicianUserName: "", departmentName: "", notEquals: [], orderBy: "", orderDir: "", groupBy: "", weekStart: "", weekEnd: "", payrollProcessed: false, adminApprovalUserName: "", hoursWorked: 0, withoutLimit: false, dateProcessed: "", duration: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 services_rendered_id */ 3:
                    message.servicesRenderedId = reader.int32();
                    break;
                case /* int32 task_event_id */ 4:
                    message.taskEventId = reader.int32();
                    break;
                case /* int32 class_code_id */ 5:
                    message.classCodeId = reader.int32();
                    break;
                case /* int32 department_code */ 6:
                    message.departmentCode = reader.int32();
                    break;
                case /* string brief_description */ 7:
                    message.briefDescription = reader.string();
                    break;
                case /* string reference_number */ 8:
                    message.referenceNumber = reader.string();
                    break;
                case /* string notes */ 9:
                    message.notes = reader.string();
                    break;
                case /* int32 admin_approval_user_id */ 10:
                    message.adminApprovalUserId = reader.int32();
                    break;
                case /* string admin_approval_datetime */ 11:
                    message.adminApprovalDatetime = reader.string();
                    break;
                case /* string user_approval_datetime */ 12:
                    message.userApprovalDatetime = reader.string();
                    break;
                case /* string time_started */ 13:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_finished */ 14:
                    message.timeFinished = reader.string();
                    break;
                case /* int32 technician_user_id */ 15:
                    message.technicianUserId = reader.int32();
                    break;
                case /* int32 is_active */ 16:
                    message.isActive = reader.int32();
                    break;
                case /* TimesheetClassCode class_code */ 19:
                    message.classCode = TimesheetClassCode.internalBinaryRead(reader, reader.uint32(), options, message.classCode);
                    break;
                case /* repeated string field_mask */ 17:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 18:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string date_range */ 20:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 21:
                    message.dateTarget.push(reader.string());
                    break;
                case /* int32 event_property_id */ 22:
                    message.eventPropertyId = reader.int32();
                    break;
                case /* int32 event_user_id */ 23:
                    message.eventUserId = reader.int32();
                    break;
                case /* string technician_user_name */ 24:
                    message.technicianUserName = reader.string();
                    break;
                case /* string department_name */ 25:
                    message.departmentName = reader.string();
                    break;
                case /* repeated string not_equals */ 26:
                    message.notEquals.push(reader.string());
                    break;
                case /* string order_by */ 27:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 28:
                    message.orderDir = reader.string();
                    break;
                case /* string group_by */ 29:
                    message.groupBy = reader.string();
                    break;
                case /* string week_start */ 30:
                    message.weekStart = reader.string();
                    break;
                case /* string week_end */ 31:
                    message.weekEnd = reader.string();
                    break;
                case /* bool payroll_processed */ 32:
                    message.payrollProcessed = reader.bool();
                    break;
                case /* string admin_approval_user_name */ 33:
                    message.adminApprovalUserName = reader.string();
                    break;
                case /* double hours_worked */ 34:
                    message.hoursWorked = reader.double();
                    break;
                case /* bool without_limit */ 35:
                    message.withoutLimit = reader.bool();
                    break;
                case /* User search_user */ 36:
                    message.searchUser = User.internalBinaryRead(reader, reader.uint32(), options, message.searchUser);
                    break;
                case /* string date_processed */ 37:
                    message.dateProcessed = reader.string();
                    break;
                case /* double duration */ 38:
                    message.duration = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 services_rendered_id = 3; */
        if (message.servicesRenderedId !== 0)
            writer.tag(3, WireType.Varint).int32(message.servicesRenderedId);
        /* int32 task_event_id = 4; */
        if (message.taskEventId !== 0)
            writer.tag(4, WireType.Varint).int32(message.taskEventId);
        /* int32 class_code_id = 5; */
        if (message.classCodeId !== 0)
            writer.tag(5, WireType.Varint).int32(message.classCodeId);
        /* int32 department_code = 6; */
        if (message.departmentCode !== 0)
            writer.tag(6, WireType.Varint).int32(message.departmentCode);
        /* string brief_description = 7; */
        if (message.briefDescription !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.briefDescription);
        /* string reference_number = 8; */
        if (message.referenceNumber !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.referenceNumber);
        /* string notes = 9; */
        if (message.notes !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.notes);
        /* int32 admin_approval_user_id = 10; */
        if (message.adminApprovalUserId !== 0)
            writer.tag(10, WireType.Varint).int32(message.adminApprovalUserId);
        /* string admin_approval_datetime = 11; */
        if (message.adminApprovalDatetime !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.adminApprovalDatetime);
        /* string user_approval_datetime = 12; */
        if (message.userApprovalDatetime !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.userApprovalDatetime);
        /* string time_started = 13; */
        if (message.timeStarted !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_finished = 14; */
        if (message.timeFinished !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.timeFinished);
        /* int32 technician_user_id = 15; */
        if (message.technicianUserId !== 0)
            writer.tag(15, WireType.Varint).int32(message.technicianUserId);
        /* int32 is_active = 16; */
        if (message.isActive !== 0)
            writer.tag(16, WireType.Varint).int32(message.isActive);
        /* TimesheetClassCode class_code = 19; */
        if (message.classCode)
            TimesheetClassCode.internalBinaryWrite(message.classCode, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 17; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(17, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 18; */
        if (message.pageNumber !== 0)
            writer.tag(18, WireType.Varint).int32(message.pageNumber);
        /* repeated string date_range = 20; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 21; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* int32 event_property_id = 22; */
        if (message.eventPropertyId !== 0)
            writer.tag(22, WireType.Varint).int32(message.eventPropertyId);
        /* int32 event_user_id = 23; */
        if (message.eventUserId !== 0)
            writer.tag(23, WireType.Varint).int32(message.eventUserId);
        /* string technician_user_name = 24; */
        if (message.technicianUserName !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.technicianUserName);
        /* string department_name = 25; */
        if (message.departmentName !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.departmentName);
        /* repeated string not_equals = 26; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(26, WireType.LengthDelimited).string(message.notEquals[i]);
        /* string order_by = 27; */
        if (message.orderBy !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 28; */
        if (message.orderDir !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.orderDir);
        /* string group_by = 29; */
        if (message.groupBy !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.groupBy);
        /* string week_start = 30; */
        if (message.weekStart !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.weekStart);
        /* string week_end = 31; */
        if (message.weekEnd !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.weekEnd);
        /* bool payroll_processed = 32; */
        if (message.payrollProcessed !== false)
            writer.tag(32, WireType.Varint).bool(message.payrollProcessed);
        /* string admin_approval_user_name = 33; */
        if (message.adminApprovalUserName !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.adminApprovalUserName);
        /* double hours_worked = 34; */
        if (message.hoursWorked !== 0)
            writer.tag(34, WireType.Bit64).double(message.hoursWorked);
        /* bool without_limit = 35; */
        if (message.withoutLimit !== false)
            writer.tag(35, WireType.Varint).bool(message.withoutLimit);
        /* User search_user = 36; */
        if (message.searchUser)
            User.internalBinaryWrite(message.searchUser, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        /* string date_processed = 37; */
        if (message.dateProcessed !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.dateProcessed);
        /* double duration = 38; */
        if (message.duration !== 0)
            writer.tag(38, WireType.Bit64).double(message.duration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetLine
 */
export const TimesheetLine = new TimesheetLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetLinePaylocity$Type extends MessageType {
    constructor() {
        super("TimesheetLinePaylocity", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "services_rendered_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "task_event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "class_code_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "department_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "brief_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "reference_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "admin_approval_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "admin_approval_datetime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "user_approval_datetime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "time_finished", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "technician_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "class_code", kind: "message", T: () => TimesheetClassCode },
            { no: 17, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "event_property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "event_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "technician_user_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "department_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "week_start", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "week_end", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "payroll_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 33, name: "hours_worked", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 34, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 35, name: "date_processed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "wc_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 37, name: "cost_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "billable", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 39, name: "week_occurred", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 40, name: "paylocity_employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, servicesRenderedId: 0, taskEventId: 0, classCodeId: "", departmentCode: 0, briefDescription: "", referenceNumber: "", notes: "", adminApprovalUserId: 0, adminApprovalDatetime: "", userApprovalDatetime: "", timeStarted: "", timeFinished: "", technicianUserId: 0, isActive: 0, fieldMask: [], pageNumber: 0, dateRange: [], dateTarget: [], eventPropertyId: 0, eventUserId: 0, technicianUserName: "", departmentName: "", notEquals: [], orderBy: "", orderDir: "", groupBy: "", weekStart: "", weekEnd: "", payrollProcessed: false, hoursWorked: 0, withoutLimit: false, dateProcessed: "", wcCode: 0, costCode: "", billable: 0, weekOccurred: 0, paylocityEmployeeId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 services_rendered_id */ 3:
                    message.servicesRenderedId = reader.int32();
                    break;
                case /* int32 task_event_id */ 4:
                    message.taskEventId = reader.int32();
                    break;
                case /* string class_code_id */ 5:
                    message.classCodeId = reader.string();
                    break;
                case /* int32 department_code */ 6:
                    message.departmentCode = reader.int32();
                    break;
                case /* string brief_description */ 7:
                    message.briefDescription = reader.string();
                    break;
                case /* string reference_number */ 8:
                    message.referenceNumber = reader.string();
                    break;
                case /* string notes */ 9:
                    message.notes = reader.string();
                    break;
                case /* int32 admin_approval_user_id */ 10:
                    message.adminApprovalUserId = reader.int32();
                    break;
                case /* string admin_approval_datetime */ 11:
                    message.adminApprovalDatetime = reader.string();
                    break;
                case /* string user_approval_datetime */ 12:
                    message.userApprovalDatetime = reader.string();
                    break;
                case /* string time_started */ 13:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_finished */ 14:
                    message.timeFinished = reader.string();
                    break;
                case /* int32 technician_user_id */ 15:
                    message.technicianUserId = reader.int32();
                    break;
                case /* int32 is_active */ 16:
                    message.isActive = reader.int32();
                    break;
                case /* TimesheetClassCode class_code */ 19:
                    message.classCode = TimesheetClassCode.internalBinaryRead(reader, reader.uint32(), options, message.classCode);
                    break;
                case /* repeated string field_mask */ 17:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 18:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string date_range */ 20:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 21:
                    message.dateTarget.push(reader.string());
                    break;
                case /* int32 event_property_id */ 22:
                    message.eventPropertyId = reader.int32();
                    break;
                case /* int32 event_user_id */ 23:
                    message.eventUserId = reader.int32();
                    break;
                case /* string technician_user_name */ 24:
                    message.technicianUserName = reader.string();
                    break;
                case /* string department_name */ 25:
                    message.departmentName = reader.string();
                    break;
                case /* repeated string not_equals */ 26:
                    message.notEquals.push(reader.string());
                    break;
                case /* string order_by */ 27:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 28:
                    message.orderDir = reader.string();
                    break;
                case /* string group_by */ 29:
                    message.groupBy = reader.string();
                    break;
                case /* string week_start */ 30:
                    message.weekStart = reader.string();
                    break;
                case /* string week_end */ 31:
                    message.weekEnd = reader.string();
                    break;
                case /* bool payroll_processed */ 32:
                    message.payrollProcessed = reader.bool();
                    break;
                case /* double hours_worked */ 33:
                    message.hoursWorked = reader.double();
                    break;
                case /* bool without_limit */ 34:
                    message.withoutLimit = reader.bool();
                    break;
                case /* string date_processed */ 35:
                    message.dateProcessed = reader.string();
                    break;
                case /* int32 wc_code */ 36:
                    message.wcCode = reader.int32();
                    break;
                case /* string cost_code */ 37:
                    message.costCode = reader.string();
                    break;
                case /* int32 billable */ 38:
                    message.billable = reader.int32();
                    break;
                case /* int32 week_occurred */ 39:
                    message.weekOccurred = reader.int32();
                    break;
                case /* int32 paylocity_employee_id */ 40:
                    message.paylocityEmployeeId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 services_rendered_id = 3; */
        if (message.servicesRenderedId !== 0)
            writer.tag(3, WireType.Varint).int32(message.servicesRenderedId);
        /* int32 task_event_id = 4; */
        if (message.taskEventId !== 0)
            writer.tag(4, WireType.Varint).int32(message.taskEventId);
        /* string class_code_id = 5; */
        if (message.classCodeId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.classCodeId);
        /* int32 department_code = 6; */
        if (message.departmentCode !== 0)
            writer.tag(6, WireType.Varint).int32(message.departmentCode);
        /* string brief_description = 7; */
        if (message.briefDescription !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.briefDescription);
        /* string reference_number = 8; */
        if (message.referenceNumber !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.referenceNumber);
        /* string notes = 9; */
        if (message.notes !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.notes);
        /* int32 admin_approval_user_id = 10; */
        if (message.adminApprovalUserId !== 0)
            writer.tag(10, WireType.Varint).int32(message.adminApprovalUserId);
        /* string admin_approval_datetime = 11; */
        if (message.adminApprovalDatetime !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.adminApprovalDatetime);
        /* string user_approval_datetime = 12; */
        if (message.userApprovalDatetime !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.userApprovalDatetime);
        /* string time_started = 13; */
        if (message.timeStarted !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_finished = 14; */
        if (message.timeFinished !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.timeFinished);
        /* int32 technician_user_id = 15; */
        if (message.technicianUserId !== 0)
            writer.tag(15, WireType.Varint).int32(message.technicianUserId);
        /* int32 is_active = 16; */
        if (message.isActive !== 0)
            writer.tag(16, WireType.Varint).int32(message.isActive);
        /* TimesheetClassCode class_code = 19; */
        if (message.classCode)
            TimesheetClassCode.internalBinaryWrite(message.classCode, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 17; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(17, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 18; */
        if (message.pageNumber !== 0)
            writer.tag(18, WireType.Varint).int32(message.pageNumber);
        /* repeated string date_range = 20; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 21; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* int32 event_property_id = 22; */
        if (message.eventPropertyId !== 0)
            writer.tag(22, WireType.Varint).int32(message.eventPropertyId);
        /* int32 event_user_id = 23; */
        if (message.eventUserId !== 0)
            writer.tag(23, WireType.Varint).int32(message.eventUserId);
        /* string technician_user_name = 24; */
        if (message.technicianUserName !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.technicianUserName);
        /* string department_name = 25; */
        if (message.departmentName !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.departmentName);
        /* repeated string not_equals = 26; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(26, WireType.LengthDelimited).string(message.notEquals[i]);
        /* string order_by = 27; */
        if (message.orderBy !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 28; */
        if (message.orderDir !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.orderDir);
        /* string group_by = 29; */
        if (message.groupBy !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.groupBy);
        /* string week_start = 30; */
        if (message.weekStart !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.weekStart);
        /* string week_end = 31; */
        if (message.weekEnd !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.weekEnd);
        /* bool payroll_processed = 32; */
        if (message.payrollProcessed !== false)
            writer.tag(32, WireType.Varint).bool(message.payrollProcessed);
        /* double hours_worked = 33; */
        if (message.hoursWorked !== 0)
            writer.tag(33, WireType.Bit64).double(message.hoursWorked);
        /* bool without_limit = 34; */
        if (message.withoutLimit !== false)
            writer.tag(34, WireType.Varint).bool(message.withoutLimit);
        /* string date_processed = 35; */
        if (message.dateProcessed !== "")
            writer.tag(35, WireType.LengthDelimited).string(message.dateProcessed);
        /* int32 wc_code = 36; */
        if (message.wcCode !== 0)
            writer.tag(36, WireType.Varint).int32(message.wcCode);
        /* string cost_code = 37; */
        if (message.costCode !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.costCode);
        /* int32 billable = 38; */
        if (message.billable !== 0)
            writer.tag(38, WireType.Varint).int32(message.billable);
        /* int32 week_occurred = 39; */
        if (message.weekOccurred !== 0)
            writer.tag(39, WireType.Varint).int32(message.weekOccurred);
        /* int32 paylocity_employee_id = 40; */
        if (message.paylocityEmployeeId !== 0)
            writer.tag(40, WireType.Varint).int32(message.paylocityEmployeeId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetLinePaylocity
 */
export const TimesheetLinePaylocity = new TimesheetLinePaylocity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetLineExpress$Type extends MessageType {
    constructor() {
        super("TimesheetLineExpress", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "service_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "class_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "department", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "billable", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "explanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "duration", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "occurred", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, eventId: 0, serviceId: 0, classCode: 0, department: 0, billable: 0, explanation: "", duration: 0, occurred: "", category: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 service_id */ 3:
                    message.serviceId = reader.int32();
                    break;
                case /* int32 class_code */ 4:
                    message.classCode = reader.int32();
                    break;
                case /* int32 department */ 5:
                    message.department = reader.int32();
                    break;
                case /* int32 billable */ 6:
                    message.billable = reader.int32();
                    break;
                case /* string explanation */ 7:
                    message.explanation = reader.string();
                    break;
                case /* double duration */ 8:
                    message.duration = reader.double();
                    break;
                case /* string occurred */ 9:
                    message.occurred = reader.string();
                    break;
                case /* string category */ 10:
                    message.category = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 service_id = 3; */
        if (message.serviceId !== 0)
            writer.tag(3, WireType.Varint).int32(message.serviceId);
        /* int32 class_code = 4; */
        if (message.classCode !== 0)
            writer.tag(4, WireType.Varint).int32(message.classCode);
        /* int32 department = 5; */
        if (message.department !== 0)
            writer.tag(5, WireType.Varint).int32(message.department);
        /* int32 billable = 6; */
        if (message.billable !== 0)
            writer.tag(6, WireType.Varint).int32(message.billable);
        /* string explanation = 7; */
        if (message.explanation !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.explanation);
        /* double duration = 8; */
        if (message.duration !== 0)
            writer.tag(8, WireType.Bit64).double(message.duration);
        /* string occurred = 9; */
        if (message.occurred !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.occurred);
        /* string category = 10; */
        if (message.category !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.category);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetLineExpress
 */
export const TimesheetLineExpress = new TimesheetLineExpress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaylocityImportLine$Type extends MessageType {
    constructor() {
        super("PaylocityImportLine", [
            { no: 1, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "hours", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "service_item", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "payroll_item", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "wc_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "class", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "type_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "det_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "paylocity_employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { employeeId: 0, date: "", hours: 0, serviceItem: "", payrollItem: "", wcCode: 0, jobNumber: 0, class: "", typeCode: "", detCode: "", amount: "", paylocityEmployeeId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 employee_id */ 1:
                    message.employeeId = reader.int32();
                    break;
                case /* string date */ 2:
                    message.date = reader.string();
                    break;
                case /* double hours */ 3:
                    message.hours = reader.double();
                    break;
                case /* string service_item */ 4:
                    message.serviceItem = reader.string();
                    break;
                case /* string payroll_item */ 5:
                    message.payrollItem = reader.string();
                    break;
                case /* int32 wc_code */ 6:
                    message.wcCode = reader.int32();
                    break;
                case /* int32 job_number */ 7:
                    message.jobNumber = reader.int32();
                    break;
                case /* string class */ 8:
                    message.class = reader.string();
                    break;
                case /* string type_code */ 9:
                    message.typeCode = reader.string();
                    break;
                case /* string det_code */ 10:
                    message.detCode = reader.string();
                    break;
                case /* string amount */ 11:
                    message.amount = reader.string();
                    break;
                case /* int32 paylocity_employee_id */ 12:
                    message.paylocityEmployeeId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 employee_id = 1; */
        if (message.employeeId !== 0)
            writer.tag(1, WireType.Varint).int32(message.employeeId);
        /* string date = 2; */
        if (message.date !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.date);
        /* double hours = 3; */
        if (message.hours !== 0)
            writer.tag(3, WireType.Bit64).double(message.hours);
        /* string service_item = 4; */
        if (message.serviceItem !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.serviceItem);
        /* string payroll_item = 5; */
        if (message.payrollItem !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.payrollItem);
        /* int32 wc_code = 6; */
        if (message.wcCode !== 0)
            writer.tag(6, WireType.Varint).int32(message.wcCode);
        /* int32 job_number = 7; */
        if (message.jobNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.jobNumber);
        /* string class = 8; */
        if (message.class !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.class);
        /* string type_code = 9; */
        if (message.typeCode !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.typeCode);
        /* string det_code = 10; */
        if (message.detCode !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.detCode);
        /* string amount = 11; */
        if (message.amount !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.amount);
        /* int32 paylocity_employee_id = 12; */
        if (message.paylocityEmployeeId !== 0)
            writer.tag(12, WireType.Varint).int32(message.paylocityEmployeeId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaylocityImportLine
 */
export const PaylocityImportLine = new PaylocityImportLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeClockEntry$Type extends MessageType {
    constructor() {
        super("TimeClockEntry", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "clock_in", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "clock_out", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, clockIn: "", clockOut: "", isActive: 0, fieldMask: [], notes: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* string clock_in */ 3:
                    message.clockIn = reader.string();
                    break;
                case /* string clock_out */ 4:
                    message.clockOut = reader.string();
                    break;
                case /* int32 is_active */ 5:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string notes */ 7:
                    message.notes = reader.string();
                    break;
                case /* string order_dir */ 8:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* string clock_in = 3; */
        if (message.clockIn !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.clockIn);
        /* string clock_out = 4; */
        if (message.clockOut !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.clockOut);
        /* int32 is_active = 5; */
        if (message.isActive !== 0)
            writer.tag(5, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string notes = 7; */
        if (message.notes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.notes);
        /* string order_dir = 8; */
        if (message.orderDir !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeClockEntry
 */
export const TimeClockEntry = new TimeClockEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeClockEntryList$Type extends MessageType {
    constructor() {
        super("TimeClockEntryList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimeClockEntry },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimeClockEntry results */ 1:
                    message.results.push(TimeClockEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimeClockEntry results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TimeClockEntry.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeClockEntryList
 */
export const TimeClockEntryList = new TimeClockEntryList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaylocityImportLineList$Type extends MessageType {
    constructor() {
        super("PaylocityImportLineList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PaylocityImportLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PaylocityImportLine results */ 1:
                    message.results.push(PaylocityImportLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PaylocityImportLine results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PaylocityImportLine.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaylocityImportLineList
 */
export const PaylocityImportLineList = new PaylocityImportLineList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetLineList$Type extends MessageType {
    constructor() {
        super("TimesheetLineList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimesheetLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimesheetLine results */ 1:
                    message.results.push(TimesheetLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimesheetLine results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TimesheetLine.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetLineList
 */
export const TimesheetLineList = new TimesheetLineList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetLinePaylocityList$Type extends MessageType {
    constructor() {
        super("TimesheetLinePaylocityList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimesheetLinePaylocity },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimesheetLinePaylocity results */ 1:
                    message.results.push(TimesheetLinePaylocity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimesheetLinePaylocity results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TimesheetLinePaylocity.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetLinePaylocityList
 */
export const TimesheetLinePaylocityList = new TimesheetLinePaylocityList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitApproveReq$Type extends MessageType {
    constructor() {
        super("SubmitApproveReq", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { ids: [], userId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int32());
                    else
                        message.ids.push(reader.int32());
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated int32 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int32(message.ids[i]);
            writer.join();
        }
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubmitApproveReq
 */
export const SubmitApproveReq = new SubmitApproveReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetDay$Type extends MessageType {
    constructor() {
        super("TimesheetDay", [
            { no: 1, name: "services_rendered", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimesheetServicesRendered },
            { no: 2, name: "timesheet_line", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimesheetLine }
        ]);
    }
    create(value) {
        const message = { servicesRendered: [], timesheetLine: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimesheetServicesRendered services_rendered */ 1:
                    message.servicesRendered.push(TimesheetServicesRendered.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated TimesheetLine timesheet_line */ 2:
                    message.timesheetLine.push(TimesheetLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimesheetServicesRendered services_rendered = 1; */
        for (let i = 0; i < message.servicesRendered.length; i++)
            TimesheetServicesRendered.internalBinaryWrite(message.servicesRendered[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated TimesheetLine timesheet_line = 2; */
        for (let i = 0; i < message.timesheetLine.length; i++)
            TimesheetLine.internalBinaryWrite(message.timesheetLine[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetDay
 */
export const TimesheetDay = new TimesheetDay$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Timesheet$Type extends MessageType {
    constructor() {
        super("Timesheet", [
            { no: 1, name: "dates", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => TimesheetDay } }
        ]);
    }
    create(value) {
        const message = { dates: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, TimesheetDay> dates */ 1:
                    this.binaryReadMap1(message.dates, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    binaryReadMap1(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = TimesheetDay.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Timesheet.dates");
            }
        }
        map[key ?? ""] = val ?? TimesheetDay.create();
    }
    internalBinaryWrite(message, writer, options) {
        /* map<string, TimesheetDay> dates = 1; */
        for (let k of Object.keys(message.dates)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            TimesheetDay.internalBinaryWrite(message.dates[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Timesheet
 */
export const Timesheet = new Timesheet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserTimesheet$Type extends MessageType {
    constructor() {
        super("UserTimesheet", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, startDate: "", endDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string start_date */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string end_date */ 3:
                    message.endDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string start_date = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string end_date = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserTimesheet
 */
export const UserTimesheet = new UserTimesheet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetReq$Type extends MessageType {
    constructor() {
        super("TimesheetReq", [
            { no: 1, name: "timesheet_line", kind: "message", T: () => TimesheetLine },
            { no: 2, name: "services_rendered", kind: "message", T: () => TimesheetServicesRendered }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* TimesheetLine timesheet_line */ 1:
                    message.timesheetLine = TimesheetLine.internalBinaryRead(reader, reader.uint32(), options, message.timesheetLine);
                    break;
                case /* TimesheetServicesRendered services_rendered */ 2:
                    message.servicesRendered = TimesheetServicesRendered.internalBinaryRead(reader, reader.uint32(), options, message.servicesRendered);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* TimesheetLine timesheet_line = 1; */
        if (message.timesheetLine)
            TimesheetLine.internalBinaryWrite(message.timesheetLine, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* TimesheetServicesRendered services_rendered = 2; */
        if (message.servicesRendered)
            TimesheetServicesRendered.internalBinaryWrite(message.servicesRendered, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetReq
 */
export const TimesheetReq = new TimesheetReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaidTimeOff$Type extends MessageType {
    constructor() {
        super("PaidTimeOff", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "hours", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "usage_start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "usage_end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_rollover", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "is_bonus", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, hours: 0, usageStartDate: "", usageEndDate: "", isRollover: 0, isBonus: 0, dateTarget: [], dateRange: [], fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 hours */ 3:
                    message.hours = reader.int32();
                    break;
                case /* string usage_start_date */ 4:
                    message.usageStartDate = reader.string();
                    break;
                case /* string usage_end_date */ 5:
                    message.usageEndDate = reader.string();
                    break;
                case /* int32 is_rollover */ 6:
                    message.isRollover = reader.int32();
                    break;
                case /* int32 is_bonus */ 7:
                    message.isBonus = reader.int32();
                    break;
                case /* repeated string date_target */ 8:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string date_range */ 9:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 11:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 12:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 13:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 hours = 3; */
        if (message.hours !== 0)
            writer.tag(3, WireType.Varint).int32(message.hours);
        /* string usage_start_date = 4; */
        if (message.usageStartDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.usageStartDate);
        /* string usage_end_date = 5; */
        if (message.usageEndDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.usageEndDate);
        /* int32 is_rollover = 6; */
        if (message.isRollover !== 0)
            writer.tag(6, WireType.Varint).int32(message.isRollover);
        /* int32 is_bonus = 7; */
        if (message.isBonus !== 0)
            writer.tag(7, WireType.Varint).int32(message.isBonus);
        /* repeated string date_target = 8; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string date_range = 9; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 11; */
        if (message.pageNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 12; */
        if (message.orderBy !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 13; */
        if (message.orderDir !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaidTimeOff
 */
export const PaidTimeOff = new PaidTimeOff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaidTimeOffList$Type extends MessageType {
    constructor() {
        super("PaidTimeOffList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PaidTimeOff },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PaidTimeOff data */ 1:
                    message.data.push(PaidTimeOff.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PaidTimeOff data = 1; */
        for (let i = 0; i < message.data.length; i++)
            PaidTimeOff.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaidTimeOffList
 */
export const PaidTimeOffList = new PaidTimeOffList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoffRequestTypeList$Type extends MessageType {
    constructor() {
        super("TimeoffRequestTypeList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimeoffRequestType }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimeoffRequestType data */ 1:
                    message.data.push(TimeoffRequestType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimeoffRequestType data = 1; */
        for (let i = 0; i < message.data.length; i++)
            TimeoffRequestType.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeoffRequestTypeList
 */
export const TimeoffRequestTypeList = new TimeoffRequestTypeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoffRequestType$Type extends MessageType {
    constructor() {
        super("TimeoffRequestType", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "request_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "request_class", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, requestType: "", requestClass: "", isActive: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string request_type */ 2:
                    message.requestType = reader.string();
                    break;
                case /* string request_class */ 3:
                    message.requestClass = reader.string();
                    break;
                case /* int32 is_active */ 4:
                    message.isActive = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string request_type = 2; */
        if (message.requestType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.requestType);
        /* string request_class = 3; */
        if (message.requestClass !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.requestClass);
        /* int32 is_active = 4; */
        if (message.isActive !== 0)
            writer.tag(4, WireType.Varint).int32(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeoffRequestType
 */
export const TimeoffRequestType = new TimeoffRequestType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoffRequestReadOnly$Type extends MessageType {
    constructor() {
        super("TimeoffRequestReadOnly", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "allowance_hours_pto", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "date_hired", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "renew_next", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "rewnew_prior", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "base_prior", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "current_year", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "prior_year", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, allowanceHoursPto: 0, dateHired: "", renewNext: "", rewnewPrior: "", basePrior: "", currentYear: 0, priorYear: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 allowance_hours_pto */ 2:
                    message.allowanceHoursPto = reader.int32();
                    break;
                case /* string date_hired */ 3:
                    message.dateHired = reader.string();
                    break;
                case /* string renew_next */ 4:
                    message.renewNext = reader.string();
                    break;
                case /* string rewnew_prior */ 5:
                    message.rewnewPrior = reader.string();
                    break;
                case /* string base_prior */ 6:
                    message.basePrior = reader.string();
                    break;
                case /* double current_year */ 7:
                    message.currentYear = reader.double();
                    break;
                case /* double prior_year */ 8:
                    message.priorYear = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 allowance_hours_pto = 2; */
        if (message.allowanceHoursPto !== 0)
            writer.tag(2, WireType.Varint).int32(message.allowanceHoursPto);
        /* string date_hired = 3; */
        if (message.dateHired !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dateHired);
        /* string renew_next = 4; */
        if (message.renewNext !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.renewNext);
        /* string rewnew_prior = 5; */
        if (message.rewnewPrior !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.rewnewPrior);
        /* string base_prior = 6; */
        if (message.basePrior !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.basePrior);
        /* double current_year = 7; */
        if (message.currentYear !== 0)
            writer.tag(7, WireType.Bit64).double(message.currentYear);
        /* double prior_year = 8; */
        if (message.priorYear !== 0)
            writer.tag(8, WireType.Bit64).double(message.priorYear);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeoffRequestReadOnly
 */
export const TimeoffRequestReadOnly = new TimeoffRequestReadOnly$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoffRequest$Type extends MessageType {
    constructor() {
        super("TimeoffRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "services_rendered_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "task_event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 33, name: "request_type_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "request_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 36, name: "department_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "department_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "brief_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "reference_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "reviewed_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "admin_comments", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "admin_approval_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "admin_approval_datetime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "user_approval_datetime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "time_finished", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "request_status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "all_day_off", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "is_roo_request", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "user_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "admin_approval_user_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "request_class", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "department_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "user", kind: "message", T: () => User },
            { no: 32, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "payroll_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 35, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 37, name: "duration", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 38, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, servicesRenderedId: 0, taskEventId: 0, requestTypeList: "", requestType: 0, departmentIdList: "", departmentCode: 0, briefDescription: "", referenceNumber: "", notes: "", reviewedBy: "", adminComments: "", adminApprovalUserId: 0, adminApprovalDatetime: "", userApprovalDatetime: "", timeStarted: "", timeFinished: "", userId: 0, requestStatus: 0, allDayOff: 0, isRooRequest: 0, userName: "", isActive: 0, fieldMask: [], pageNumber: 0, adminApprovalUserName: "", dateRange: [], dateTarget: [], requestClass: "", notEquals: [], departmentName: "", orderBy: "", payrollProcessed: false, orderDir: "", duration: 0, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 services_rendered_id */ 3:
                    message.servicesRenderedId = reader.int32();
                    break;
                case /* int32 task_event_id */ 4:
                    message.taskEventId = reader.int32();
                    break;
                case /* string request_type_list */ 33:
                    message.requestTypeList = reader.string();
                    break;
                case /* int32 request_type */ 5:
                    message.requestType = reader.int32();
                    break;
                case /* string department_id_list */ 36:
                    message.departmentIdList = reader.string();
                    break;
                case /* int32 department_code */ 6:
                    message.departmentCode = reader.int32();
                    break;
                case /* string brief_description */ 7:
                    message.briefDescription = reader.string();
                    break;
                case /* string reference_number */ 8:
                    message.referenceNumber = reader.string();
                    break;
                case /* string notes */ 9:
                    message.notes = reader.string();
                    break;
                case /* string reviewed_by */ 10:
                    message.reviewedBy = reader.string();
                    break;
                case /* string admin_comments */ 11:
                    message.adminComments = reader.string();
                    break;
                case /* int32 admin_approval_user_id */ 12:
                    message.adminApprovalUserId = reader.int32();
                    break;
                case /* string admin_approval_datetime */ 13:
                    message.adminApprovalDatetime = reader.string();
                    break;
                case /* string user_approval_datetime */ 14:
                    message.userApprovalDatetime = reader.string();
                    break;
                case /* string time_started */ 15:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_finished */ 16:
                    message.timeFinished = reader.string();
                    break;
                case /* int32 user_id */ 17:
                    message.userId = reader.int32();
                    break;
                case /* int32 request_status */ 18:
                    message.requestStatus = reader.int32();
                    break;
                case /* int32 all_day_off */ 19:
                    message.allDayOff = reader.int32();
                    break;
                case /* int32 is_roo_request */ 20:
                    message.isRooRequest = reader.int32();
                    break;
                case /* string user_name */ 24:
                    message.userName = reader.string();
                    break;
                case /* int32 is_active */ 21:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 22:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 23:
                    message.pageNumber = reader.int32();
                    break;
                case /* string admin_approval_user_name */ 25:
                    message.adminApprovalUserName = reader.string();
                    break;
                case /* repeated string date_range */ 26:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 27:
                    message.dateTarget.push(reader.string());
                    break;
                case /* string request_class */ 28:
                    message.requestClass = reader.string();
                    break;
                case /* repeated string not_equals */ 29:
                    message.notEquals.push(reader.string());
                    break;
                case /* string department_name */ 30:
                    message.departmentName = reader.string();
                    break;
                case /* User user */ 31:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* string order_by */ 32:
                    message.orderBy = reader.string();
                    break;
                case /* bool payroll_processed */ 34:
                    message.payrollProcessed = reader.bool();
                    break;
                case /* string order_dir */ 35:
                    message.orderDir = reader.string();
                    break;
                case /* double duration */ 37:
                    message.duration = reader.double();
                    break;
                case /* bool without_limit */ 38:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 services_rendered_id = 3; */
        if (message.servicesRenderedId !== 0)
            writer.tag(3, WireType.Varint).int32(message.servicesRenderedId);
        /* int32 task_event_id = 4; */
        if (message.taskEventId !== 0)
            writer.tag(4, WireType.Varint).int32(message.taskEventId);
        /* string request_type_list = 33; */
        if (message.requestTypeList !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.requestTypeList);
        /* int32 request_type = 5; */
        if (message.requestType !== 0)
            writer.tag(5, WireType.Varint).int32(message.requestType);
        /* string department_id_list = 36; */
        if (message.departmentIdList !== "")
            writer.tag(36, WireType.LengthDelimited).string(message.departmentIdList);
        /* int32 department_code = 6; */
        if (message.departmentCode !== 0)
            writer.tag(6, WireType.Varint).int32(message.departmentCode);
        /* string brief_description = 7; */
        if (message.briefDescription !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.briefDescription);
        /* string reference_number = 8; */
        if (message.referenceNumber !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.referenceNumber);
        /* string notes = 9; */
        if (message.notes !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.notes);
        /* string reviewed_by = 10; */
        if (message.reviewedBy !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.reviewedBy);
        /* string admin_comments = 11; */
        if (message.adminComments !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.adminComments);
        /* int32 admin_approval_user_id = 12; */
        if (message.adminApprovalUserId !== 0)
            writer.tag(12, WireType.Varint).int32(message.adminApprovalUserId);
        /* string admin_approval_datetime = 13; */
        if (message.adminApprovalDatetime !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.adminApprovalDatetime);
        /* string user_approval_datetime = 14; */
        if (message.userApprovalDatetime !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.userApprovalDatetime);
        /* string time_started = 15; */
        if (message.timeStarted !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_finished = 16; */
        if (message.timeFinished !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.timeFinished);
        /* int32 user_id = 17; */
        if (message.userId !== 0)
            writer.tag(17, WireType.Varint).int32(message.userId);
        /* int32 request_status = 18; */
        if (message.requestStatus !== 0)
            writer.tag(18, WireType.Varint).int32(message.requestStatus);
        /* int32 all_day_off = 19; */
        if (message.allDayOff !== 0)
            writer.tag(19, WireType.Varint).int32(message.allDayOff);
        /* int32 is_roo_request = 20; */
        if (message.isRooRequest !== 0)
            writer.tag(20, WireType.Varint).int32(message.isRooRequest);
        /* string user_name = 24; */
        if (message.userName !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.userName);
        /* int32 is_active = 21; */
        if (message.isActive !== 0)
            writer.tag(21, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 22; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(22, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 23; */
        if (message.pageNumber !== 0)
            writer.tag(23, WireType.Varint).int32(message.pageNumber);
        /* string admin_approval_user_name = 25; */
        if (message.adminApprovalUserName !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.adminApprovalUserName);
        /* repeated string date_range = 26; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(26, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 27; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(27, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* string request_class = 28; */
        if (message.requestClass !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.requestClass);
        /* repeated string not_equals = 29; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(29, WireType.LengthDelimited).string(message.notEquals[i]);
        /* string department_name = 30; */
        if (message.departmentName !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.departmentName);
        /* User user = 31; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* string order_by = 32; */
        if (message.orderBy !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.orderBy);
        /* bool payroll_processed = 34; */
        if (message.payrollProcessed !== false)
            writer.tag(34, WireType.Varint).bool(message.payrollProcessed);
        /* string order_dir = 35; */
        if (message.orderDir !== "")
            writer.tag(35, WireType.LengthDelimited).string(message.orderDir);
        /* double duration = 37; */
        if (message.duration !== 0)
            writer.tag(37, WireType.Bit64).double(message.duration);
        /* bool without_limit = 38; */
        if (message.withoutLimit !== false)
            writer.tag(38, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeoffRequest
 */
export const TimeoffRequest = new TimeoffRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoffRequestList$Type extends MessageType {
    constructor() {
        super("TimeoffRequestList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimeoffRequest },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimeoffRequest results */ 1:
                    message.results.push(TimeoffRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimeoffRequest results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TimeoffRequest.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeoffRequestList
 */
export const TimeoffRequestList = new TimeoffRequestList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PTO$Type extends MessageType {
    constructor() {
        super("PTO", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "years_worked", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "days_available", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "hours_available", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "total_hours", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, yearsWorked: 0, daysAvailable: 0, hoursAvailable: 0, totalHours: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double years_worked */ 2:
                    message.yearsWorked = reader.double();
                    break;
                case /* int32 days_available */ 3:
                    message.daysAvailable = reader.int32();
                    break;
                case /* int32 hours_available */ 4:
                    message.hoursAvailable = reader.int32();
                    break;
                case /* int32 total_hours */ 5:
                    message.totalHours = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double years_worked = 2; */
        if (message.yearsWorked !== 0)
            writer.tag(2, WireType.Bit64).double(message.yearsWorked);
        /* int32 days_available = 3; */
        if (message.daysAvailable !== 0)
            writer.tag(3, WireType.Varint).int32(message.daysAvailable);
        /* int32 hours_available = 4; */
        if (message.hoursAvailable !== 0)
            writer.tag(4, WireType.Varint).int32(message.hoursAvailable);
        /* int32 total_hours = 5; */
        if (message.totalHours !== 0)
            writer.tag(5, WireType.Varint).int32(message.totalHours);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PTO
 */
export const PTO = new PTO$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PTOList$Type extends MessageType {
    constructor() {
        super("PTOList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PTO },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PTO results */ 1:
                    message.results.push(PTO.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PTO results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PTO.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PTOList
 */
export const PTOList = new PTOList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetServicesRendered$Type extends MessageType {
    constructor() {
        super("TimesheetServicesRendered", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "technician_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "materials_used", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "service_rendered", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "tech_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "datetime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "time_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "time_finished", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "hide_from_timesheet", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "signature_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "signature_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, technicianUserId: 0, name: "", materialsUsed: "", serviceRendered: "", techNotes: "", status: "", datetime: "", timeStarted: "", timeFinished: "", isActive: 0, hideFromTimesheet: 0, signatureId: 0, signatureData: "", fieldMask: [], pageNumber: 0, dateRange: [], dateTarget: [], groupBy: "", withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 technician_user_id */ 3:
                    message.technicianUserId = reader.int32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string materials_used */ 5:
                    message.materialsUsed = reader.string();
                    break;
                case /* string service_rendered */ 6:
                    message.serviceRendered = reader.string();
                    break;
                case /* string tech_notes */ 7:
                    message.techNotes = reader.string();
                    break;
                case /* string status */ 8:
                    message.status = reader.string();
                    break;
                case /* string datetime */ 9:
                    message.datetime = reader.string();
                    break;
                case /* string time_started */ 10:
                    message.timeStarted = reader.string();
                    break;
                case /* string time_finished */ 11:
                    message.timeFinished = reader.string();
                    break;
                case /* int32 is_active */ 12:
                    message.isActive = reader.int32();
                    break;
                case /* int32 hide_from_timesheet */ 13:
                    message.hideFromTimesheet = reader.int32();
                    break;
                case /* int32 signature_id */ 14:
                    message.signatureId = reader.int32();
                    break;
                case /* string signature_data */ 15:
                    message.signatureData = reader.string();
                    break;
                case /* repeated string field_mask */ 16:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 17:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string date_range */ 18:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 19:
                    message.dateTarget.push(reader.string());
                    break;
                case /* string group_by */ 20:
                    message.groupBy = reader.string();
                    break;
                case /* bool without_limit */ 21:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 technician_user_id = 3; */
        if (message.technicianUserId !== 0)
            writer.tag(3, WireType.Varint).int32(message.technicianUserId);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string materials_used = 5; */
        if (message.materialsUsed !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.materialsUsed);
        /* string service_rendered = 6; */
        if (message.serviceRendered !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.serviceRendered);
        /* string tech_notes = 7; */
        if (message.techNotes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.techNotes);
        /* string status = 8; */
        if (message.status !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.status);
        /* string datetime = 9; */
        if (message.datetime !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.datetime);
        /* string time_started = 10; */
        if (message.timeStarted !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.timeStarted);
        /* string time_finished = 11; */
        if (message.timeFinished !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.timeFinished);
        /* int32 is_active = 12; */
        if (message.isActive !== 0)
            writer.tag(12, WireType.Varint).int32(message.isActive);
        /* int32 hide_from_timesheet = 13; */
        if (message.hideFromTimesheet !== 0)
            writer.tag(13, WireType.Varint).int32(message.hideFromTimesheet);
        /* int32 signature_id = 14; */
        if (message.signatureId !== 0)
            writer.tag(14, WireType.Varint).int32(message.signatureId);
        /* string signature_data = 15; */
        if (message.signatureData !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.signatureData);
        /* repeated string field_mask = 16; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 17; */
        if (message.pageNumber !== 0)
            writer.tag(17, WireType.Varint).int32(message.pageNumber);
        /* repeated string date_range = 18; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(18, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 19; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* string group_by = 20; */
        if (message.groupBy !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.groupBy);
        /* bool without_limit = 21; */
        if (message.withoutLimit !== false)
            writer.tag(21, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetServicesRendered
 */
export const TimesheetServicesRendered = new TimesheetServicesRendered$Type();
/**
 * @generated ServiceType for protobuf service TimesheetLineService
 */
export const TimesheetLineService = new ServiceType("TimesheetLineService", [
    { name: "Create", options: {}, I: TimesheetLine, O: TimesheetLine },
    { name: "Get", options: {}, I: TimesheetLine, O: TimesheetLine },
    { name: "GetTimesheet", options: {}, I: TimesheetReq, O: Timesheet },
    { name: "BatchGet", options: {}, I: TimesheetLine, O: TimesheetLineList },
    { name: "BatchGetPaylocityImport", options: {}, I: DateRange, O: PaylocityImportLineList },
    { name: "BatchGetPaylocity", options: {}, I: TimesheetLinePaylocity, O: TimesheetLinePaylocityList },
    { name: "BatchGetPayroll", options: {}, I: TimesheetLine, O: TimesheetLineList },
    { name: "BatchGetManager", options: {}, I: TimesheetLine, O: TimesheetLineList },
    { name: "Update", options: {}, I: TimesheetLine, O: TimesheetLine },
    { name: "Delete", options: {}, I: TimesheetLine, O: TimesheetLine },
    { name: "Submit", options: {}, I: SubmitApproveReq, O: Empty },
    { name: "Approve", options: {}, I: SubmitApproveReq, O: Empty },
    { name: "Process", options: {}, I: SubmitApproveReq, O: Empty },
    { name: "Reject", options: {}, I: SubmitApproveReq, O: Empty },
    { name: "Deny", options: {}, I: SubmitApproveReq, O: Empty },
    { name: "GetIDsForPayroll", options: {}, I: DateRange, O: Int32List },
    { name: "GetReferenceURL", options: {}, I: Int32, O: String$ },
    { name: "CreatePaidTimeOff", options: {}, I: PaidTimeOff, O: PaidTimeOff },
    { name: "GetPaidTimeOff", options: {}, I: PaidTimeOff, O: PaidTimeOff },
    { name: "BatchGetPaidTimeOff", options: {}, I: PaidTimeOff, O: PaidTimeOffList },
    { name: "UpdatePaidTimeOff", options: {}, I: PaidTimeOff, O: PaidTimeOff },
    { name: "DeletePaidTimeOff", options: {}, I: PaidTimeOff, O: PaidTimeOff },
    { name: "CreateTimeoffRequest", options: {}, I: TimeoffRequest, O: TimeoffRequest },
    { name: "GetTimeoffRequest", options: {}, I: TimeoffRequest, O: TimeoffRequest },
    { name: "BatchGetTimeoffRequest", options: {}, I: TimeoffRequest, O: TimeoffRequestList },
    { name: "UpdateTimeoffRequest", options: {}, I: TimeoffRequest, O: TimeoffRequest },
    { name: "DeleteTimeoffRequest", options: {}, I: TimeoffRequest, O: TimeoffRequest },
    { name: "PTOInquiry", options: {}, I: PTO, O: PTO },
    { name: "BatchGetPTOInquiry", options: {}, I: Empty, O: PTOList },
    { name: "GetTimeoffRequestTypes", options: {}, I: TimeoffRequestType, O: TimeoffRequestTypeList },
    { name: "CreateTimeClockEntry", options: {}, I: TimeClockEntry, O: TimeClockEntry },
    { name: "GetTimeClockEntry", options: {}, I: TimeClockEntry, O: TimeClockEntry },
    { name: "BatchGetTimeClockEntry", options: {}, I: TimeClockEntry, O: TimeClockEntryList },
    { name: "UpdateTimeClockEntry", options: {}, I: TimeClockEntry, O: TimeClockEntry },
    { name: "DeleteTimeClockEntry", options: {}, I: TimeClockEntry, O: TimeClockEntry }
]);
