import { useEffect, useState } from 'react';

import { ApiKeyClientService } from '../tools/helpers';

interface ScriptState {
  isLoaded: boolean;
  error: Error | null;
}

export const useGoogleMapsScript = () => {
  const [state, setState] = useState<ScriptState>({
    isLoaded: false,
    error: null,
  });

  useEffect(() => {
    if (window.google?.maps?.places) {
      setState({ isLoaded: true, error: null });
      return;
    }

    const loadScript = async () => {
      try {
        const keyData = await ApiKeyClientService.getKeyByKeyName('google_maps');
        if (!keyData?.apiKey) {
          throw new Error('Google Maps API key is undefined');
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${keyData.apiKey}&libraries=places`;

        script.addEventListener('load', () => {
          setState({ isLoaded: true, error: null });
        });

        script.addEventListener('error', (e) => {
          setState({ isLoaded: false, error: new Error('Failed to load Google Maps script') });
        });

        document.head.appendChild(script);
      } catch (error) {
        setState({ isLoaded: false, error: error as Error });
      }
    };

    loadScript();
  }, []);

  return state;
};
