// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "inventory.proto" (syntax proto3)
// tslint:disable
import { InventoryService } from "./inventory";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service InventoryService
 */
export class InventoryServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = InventoryService.typeName;
        this.methods = InventoryService.methods;
        this.options = InventoryService.options;
    }
    /**
     * Catalog Management
     *
     * @generated from protobuf rpc: CreateCatalogItem(CatalogItem) returns (CatalogItem);
     */
    createCatalogItem(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCatalogItem(CatalogItem) returns (CatalogItem);
     */
    getCatalogItem(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetCatalogItem(CatalogItem) returns (CatalogItemList);
     */
    batchGetCatalogItem(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCatalogItem(CatalogItem) returns (CatalogItem);
     */
    updateCatalogItem(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteCatalogItem(CatalogItem) returns (Empty);
     */
    deleteCatalogItem(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Stock Management
     *
     * @generated from protobuf rpc: CreateStock(Stock) returns (Stock);
     */
    createStock(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStock(Stock) returns (Stock);
     */
    getStock(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetStock(Stock) returns (StockList);
     */
    batchGetStock(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateStock(Stock) returns (Stock);
     */
    updateStock(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteStock(Stock) returns (Empty);
     */
    deleteStock(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Requisition Management
     *
     * @generated from protobuf rpc: CreateRequisition(Requisition) returns (Requisition);
     */
    createRequisition(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRequisition(Requisition) returns (Requisition);
     */
    getRequisition(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetRequisition(Requisition) returns (RequisitionList);
     */
    batchGetRequisition(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRequisition(Requisition) returns (Requisition);
     */
    updateRequisition(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteRequisition(Requisition) returns (Empty);
     */
    deleteRequisition(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPendingRequisitionCount(Empty) returns (Int32);
     */
    getPendingRequisitionCount(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetRequisitionItem(RequisitionItem) returns (RequisitionItemList);
     */
    batchGetRequisitionItem(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Tool Fund Specific Operations
     *
     * @generated from protobuf rpc: GetUserToolFundBalance(User) returns (ToolFundBalance);
     */
    getUserToolFundBalance(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserToolAssets(User) returns (AssetInstanceList);
     */
    getUserToolAssets(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateToolFundRequisition(ToolFundRequisitionRequest) returns (Requisition);
     */
    createToolFundRequisition(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetToolFundHistory(ToolFundHistoryRequest) returns (ToolFundHistoryResponse);
     */
    getToolFundHistory(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: TransferToolAsset(TransferAssetRequest) returns (AssetInstance);
     */
    transferToolAsset(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UploadApprovedTools(UploadApprovedToolsRequest) returns (UploadApprovedToolsResponse);
     */
    uploadApprovedTools(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetImportStatus(GetImportStatusRequest) returns (GetImportStatusResponse);
     */
    getImportStatus(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Tool Fund Transaction Management
     *
     * @generated from protobuf rpc: CreateToolFundTransaction(ToolFundTransaction) returns (ToolFundTransaction);
     */
    createToolFundTransaction(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetToolFundTransaction(ToolFundTransaction) returns (ToolFundTransaction);
     */
    getToolFundTransaction(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetToolFundTransaction(ToolFundTransaction) returns (ToolFundTransactionList);
     */
    batchGetToolFundTransaction(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateToolFundTransaction(ToolFundTransaction) returns (ToolFundTransaction);
     */
    updateToolFundTransaction(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteToolFundTransaction(ToolFundTransaction) returns (Empty);
     */
    deleteToolFundTransaction(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Transaction Type Management
     *
     * @generated from protobuf rpc: CreateTransactionType(ToolFundTransactionType) returns (ToolFundTransactionType);
     */
    createTransactionType(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransactionType(ToolFundTransactionType) returns (ToolFundTransactionType);
     */
    getTransactionType(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTransactionType(ToolFundTransactionType) returns (ToolFundTransactionTypeList);
     */
    batchGetTransactionType(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTransactionType(ToolFundTransactionType) returns (ToolFundTransactionType);
     */
    updateTransactionType(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTransactionType(ToolFundTransactionType) returns (Empty);
     */
    deleteTransactionType(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Trade Type Management
     *
     * @generated from protobuf rpc: CreateTradeType(TradeType) returns (TradeType);
     */
    createTradeType(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTradeType(TradeType) returns (TradeType);
     */
    getTradeType(input, options) {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTradeType(TradeType) returns (TradeTypeList);
     */
    batchGetTradeType(input, options) {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTradeType(TradeType) returns (TradeType);
     */
    updateTradeType(input, options) {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTradeType(TradeType) returns (Empty);
     */
    deleteTradeType(input, options) {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Tool Fund Dashboard
     *
     * @generated from protobuf rpc: GetToolFundDashboardMetrics(Empty) returns (ToolFundDashboardMetrics);
     */
    getToolFundDashboardMetrics(input, options) {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Tool Fund Settings
     *
     * @generated from protobuf rpc: GetToolFundSettings(ToolFundSettings) returns (ToolFundSettings);
     */
    getToolFundSettings(input, options) {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateToolFundSettings(ToolFundSettings) returns (ToolFundSettings);
     */
    updateToolFundSettings(input, options) {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateToolFundSettings(ToolFundSettings) returns (ToolFundSettings);
     */
    createToolFundSettings(input, options) {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Catalog Item Category Management
     *
     * @generated from protobuf rpc: GetCatalogItemCategory(CatalogItemCategory) returns (CatalogItemCategory);
     */
    getCatalogItemCategory(input, options) {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetCatalogItemCategory(CatalogItemCategory) returns (CatalogItemCategoryList);
     */
    batchGetCatalogItemCategory(input, options) {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Asset Instance Management
     *
     * @generated from protobuf rpc: CreateAssetInstance(AssetInstance) returns (AssetInstance);
     */
    createAssetInstance(input, options) {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAssetInstance(AssetInstance) returns (AssetInstance);
     */
    getAssetInstance(input, options) {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetAssetInstance(AssetInstance) returns (AssetInstanceList);
     */
    batchGetAssetInstance(input, options) {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateAssetInstance(AssetInstance) returns (AssetInstance);
     */
    updateAssetInstance(input, options) {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteAssetInstance(AssetInstance) returns (Empty);
     */
    deleteAssetInstance(input, options) {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateRequisitionActivity(RequisitionActivity) returns (RequisitionActivity);
     */
    createRequisitionActivity(input, options) {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetRequisitionActivity(RequisitionActivity) returns (RequisitionActivityList);
     */
    batchGetRequisitionActivity(input, options) {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRequisitionActivity(RequisitionActivity) returns (RequisitionActivity);
     */
    updateRequisitionActivity(input, options) {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Requisition Fulfillment Item Management
     *
     * @generated from protobuf rpc: CreateRequisitionFulfillmentItem(RequisitionFulfillmentItem) returns (RequisitionFulfillmentItem);
     */
    createRequisitionFulfillmentItem(input, options) {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRequisitionFulfillmentItem(RequisitionFulfillmentItem) returns (RequisitionFulfillmentItem);
     */
    getRequisitionFulfillmentItem(input, options) {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetRequisitionFulfillmentItem(RequisitionFulfillmentItem) returns (RequisitionFulfillmentItemList);
     */
    batchGetRequisitionFulfillmentItem(input, options) {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRequisitionFulfillmentItem(RequisitionFulfillmentItem) returns (RequisitionFulfillmentItem);
     */
    updateRequisitionFulfillmentItem(input, options) {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteRequisitionFulfillmentItem(RequisitionFulfillmentItem) returns (Empty);
     */
    deleteRequisitionFulfillmentItem(input, options) {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
