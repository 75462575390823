import './AltGallery.module.less';

import { TransactionDocumentClient, TransactionDocumentList } from '@kalos/kalos-rpc';
import {
  Button,
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  DataTablePagination,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@kalos/ui';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageSearchTwoTone from '@mui/icons-material/ImageSearchTwoTone';
import { default as IconButton } from '@mui/material/IconButton';
import { DownloadIcon, RotateCounterClockwiseIcon, TrashIcon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { ENDPOINT, WaiverTypes } from '../../constants';
import { queryKeys } from '../../hooks/react-query/constants';
import { useFilesBatchQuery } from '../../hooks/react-query/useFileQuery';
import {
  useDeleteTransactionDocumentByIDMutation,
  useTransactionDocumentDataByIdQuery,
  useTransactionDocumentsBatchQuery,
} from '../../hooks/react-query/useTransactionDocumentQuery';
import { getMimeType } from '../../tools/helpers';
import { Button as ButtonLib } from '../ComponentsLibrary/Button';
import { ConfirmDelete } from '../ComponentsLibrary/ConfirmDelete';
import { Modal } from '../ComponentsLibrary/Modal';
import { type Deg } from '../ComponentsLibrary/RotatedImage';
import { SectionBar } from '../ComponentsLibrary/SectionBar';
import { Tooltip } from '../ComponentsLibrary/Tooltip';
import { Loader } from '../Loader/main';

export interface GalleryData {
  key: string;
  bucket: string;
  description: string;
  typeId: number;
}

interface Props {
  fileList: GalleryData[];
  title: string;
  text: string;
  subtitle?: string;
  iconButton?: boolean;
  disabled?: boolean;
  canDelete?: boolean;
  transactionID: number;
}

interface DocData {
  reference: string;
  id: number;
  data?: Uint8Array;
}

const DocClientInstance = new TransactionDocumentClient(ENDPOINT);

/**
 * @deprecated use TransactionFilesGallery instead
 */
export const AltGallery: React.FC<Props> = ({
  fileList,
  title,
  text,
  subtitle,
  iconButton,
  disabled,
  canDelete,
  transactionID,
}) => {
  const [activeImage, setActiveImage] = useState(0);
  const [currentURL, setCurrentURL] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileListData, setFileListData] = useState<GalleryData[]>(fileList);
  const [documentList, setDocumentList] = useState<DocData[]>([]);
  const [deleting, setDeleting] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const fetchDocData = useCallback(
    async (d: DocData) => {
      try {
        const dataResult = await DocClientInstance.download(transactionID, d.reference);
        const data = dataResult.data;

        setDocumentList((prevState) =>
          prevState.map((doc) => {
            if (doc.reference === d.reference) {
              doc.data = data;
            }
            return doc;
          }),
        );

        return data;
      } catch {
        alert('Error fetching document data');
        return undefined;
      }
    },
    [transactionID],
  );

  const deleteFile = async () => {
    setIsLoading(true);
    setDeleting(false);

    const data = fileListData[activeImage];
    try {
      await DocClientInstance.deleteByName(data.key, data.bucket);
      setFileListData((prevState) => prevState.filter((f) => f.key !== data.key));
      setDocumentList((prevState) => {
        const updatedList = prevState.filter((d) => `${transactionID}-${d.reference}` !== data.key);
        if (updatedList.length === 0) {
          setIsOpen(false);
          return updatedList;
        }
        changeImage(0, updatedList);
        return updatedList;
      });

      setIsLoading(false);
    } catch {
      alert('File could not be deleted');
      setIsLoading(false);
    }
  };

  const downloadFile = () => {
    const image = documentList[activeImage];
    const el = document.createElement('a');
    el.download = image.reference;
    const blob = new Blob([image.data!], {
      type: getMimeType(image.reference) || '.png',
    });
    el.href = URL.createObjectURL(blob);
    el.click();
    el.remove();
  };

  const fetchImage = useCallback(
    async (doc: DocData) => {
      setIsLoading(true);
      try {
        const data = await fetchDocData(doc);
        if (data) {
          const blob = new Blob([data], {
            type: getMimeType(doc.reference) || '.png',
          });
          const currentURL = URL.createObjectURL(blob);
          setCurrentURL(currentURL);
        } else throw new Error('no doc found');
      } catch {
        alert('No documents were found');
        toggleOpen();
      } finally {
        setIsLoading(false);
      }
    },
    [fetchDocData],
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const docs = await DocClientInstance.byTransactionID(transactionID);

      const reverseDoc = docs?.reverse();
      const galleryData = reverseDoc?.map((d) => ({
        key: `${transactionID}-${d.reference}`,
        description: d.description,
        bucket: 'kalos-transactions',
        typeId: d.typeId,
      }));
      const documentList = reverseDoc?.map((d) => ({
        reference: d.reference,
        id: transactionID,
      }));
      setFileListData(galleryData || []);
      setDocumentList(documentList || []);
      if (documentList && documentList.length > 0) {
        fetchImage(documentList[0]);
      }
    } catch {
      alert('No documents were found');
      toggleOpen();
    }
    setIsLoading(false);
  }, [transactionID, fetchImage]);

  const changeImage = (newImageIndex: number, newDocumentList?: DocData[]) => {
    setActiveImage(newImageIndex);

    const newCurrentDocument = newDocumentList?.at(newImageIndex) || documentList.at(newImageIndex);
    if (newCurrentDocument) {
      fetchImage(newCurrentDocument);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
    if (!isOpen) {
      //We shall reset just to have an even flow
      setFileListData([]);
      setDocumentList([]);
      setActiveImage(0);
    }
  }, [isOpen, fetchData]);

  const button = iconButton ? (
    <Tooltip content={text}>
      <span>
        <IconButton size="small" onClick={toggleOpen} disabled={disabled}>
          <ImageSearchTwoTone />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <ButtonLib onClick={toggleOpen} disabled={disabled} label={text} />
  );

  const mimeType = getMimeType(fileListData[activeImage]?.key || '');

  const sectionTitle = useMemo(() => {
    if (subtitle) return subtitle;
    const label = WaiverTypes.find((a) => a.value === fileListData[activeImage]?.typeId)?.label;
    const description = fileListData[activeImage]?.description;
    const transactionDocumentType = description ? `Transaction Document Type: ${description}` : '';
    const waiverType = label ? `Waiver Status: ${label}` : '';

    return `${transactionDocumentType}  ${waiverType}`;
  }, [subtitle, fileListData, activeImage]);

  return (
    <>
      {button}

      <Modal
        open={isOpen}
        onClose={toggleOpen}
        fullScreen
        className="AltGalleryModal Modal-Remove-Scroll relative flex h-svh flex-col justify-between"
      >
        <SectionBar
          title={title}
          subtitle={sectionTitle}
          subtitleMinimized
          actions={[
            ...(canDelete
              ? [
                  {
                    label: '',
                    onClick: () => setDeleting(true),
                    startIcon: <DeleteIcon />,
                    disabled: !fileListData.length,
                  },
                ]
              : []),

            {
              label: '',
              onClick: downloadFile,
              disabled: !fileListData.length,
              startIcon: <CloudDownloadIcon />,
            },

            { label: 'Close', onClick: toggleOpen },
          ]}
          fixedActions
          className="AltGallerySectionBar"
        />

        <div className="flex flex-auto items-center justify-center">
          {isLoading ? (
            <Loader className="AltGalleryModal-Loader" />
          ) : fileListData.length ? (
            mimeType === 'application/pdf' ? (
              <iframe title="preview" src={currentURL} className="AltGalleryIframe" />
            ) : (
              <RotateZoomImage url={currentURL} />
            )
          ) : (
            <div className="absolute flex h-full w-full items-center justify-center">
              <span className="text-xl">No files found.</span>
            </div>
          )}
        </div>

        <SectionBar
          pagination={{
            count: fileListData.length,
            page: activeImage,
            onPageChange: changeImage,
          }}
          sticky={false}
          className="fixed bottom-0 left-0 right-0"
        />
      </Modal>

      {deleting && (
        <ConfirmDelete
          open
          onConfirm={deleteFile}
          onClose={() => setDeleting(false)}
          kind="this file"
          name=""
        />
      )}
    </>
  );
};

// TODO new AltGallery component using new UI and to be only content of the modal.
export const TransactionFilesGallery = ({
  title,
  subTitle,
  canDelete,
  transactionId,
  subtitle,
  waiverDisplay,
  onDelete,
}: {
  title: string;
  subTitle?: React.ReactNode;
  canDelete?: boolean;
  transactionId: number;
  subtitle?: string;
  waiverDisplay?: boolean;
  onDelete?: () => void;
}) => {
  const [activeImageIdx, setActiveImageIdx] = useState(0);

  const filesMetaQuery = useTransactionDocumentsBatchQuery({
    filter: {
      transactionId,
    },
    select(data) {
      return data.results.toReversed() ?? [];
    },
  });

  const fileIds = useMemo(
    () => filesMetaQuery.data?.map((doc) => doc.fileId) ?? [],
    [filesMetaQuery.data],
  );

  const filesQuery = useFilesBatchQuery({
    fileIds,
    enabled: fileIds.length > 0,
  });

  const currentFileData = useMemo(() => {
    if (!filesMetaQuery.data || !filesQuery.data) return null;

    const transactionDoc = filesMetaQuery.data[activeImageIdx];
    const fileData = filesQuery.data.find((f) => f?.id === transactionDoc?.fileId);

    return {
      transactionDoc,
      fileData,
    };
  }, [filesMetaQuery.data, filesQuery.data, activeImageIdx]);

  const currentFileQuery = useTransactionDocumentDataByIdQuery({
    fileName: currentFileData?.fileData?.name || '',
    enabled: !!currentFileData?.fileData?.name,
    select(data) {
      if (data) {
        return {
          file: data,
          urlObject: URL.createObjectURL(
            new Blob([data.data], {
              type: getMimeType(currentFileData?.fileData?.name || '') || '.png',
            }),
          ),
        };
      }
    },
  });

  const downloadFile = () => {
    if (!currentFileQuery.data || !currentFileData?.fileData) {
      console.error('No file data found', { fileQuery: currentFileQuery.data, currentFileData });
      return;
    }

    const el = document.createElement('a');
    el.download = currentFileData.fileData.name;
    const blob = new Blob([currentFileQuery.data.file.data], {
      type: getMimeType(currentFileData.fileData.name) || '.png',
    });
    el.href = URL.createObjectURL(blob);
    el.click();
    el.remove();
  };

  const { mimeType, description } = useMemo(
    () => ({
      mimeType: getMimeType(currentFileData?.fileData?.name ?? ''),
      description: currentFileData?.transactionDoc?.description ?? '',
    }),
    [currentFileData],
  );

  const deleteFileMutation = useDeleteTransactionDocumentByIDMutation();
  const queryClient = useQueryClient();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const deleteFile = async () => {
    const data = currentFileData?.transactionDoc;
    if (!data) {
      alert('No file found');
      return;
    }

    try {
      await deleteFileMutation.mutateAsync(
        { fileId: data.fileId, transactionFileId: data.id },
        {
          onSuccess: () => {
            queryClient.setQueriesData<TransactionDocumentList>(
              {
                queryKey: [queryKeys.transactionDocument.root, queryKeys.transactionDocument.list],
              },
              (cache) => {
                if (cache && cache.results.at(0)?.transactionId === transactionId) {
                  const modifiedCache = cache.results.filter(
                    (cachedFile) => cachedFile.fileId !== data.fileId,
                  );
                  return TransactionDocumentList.create({
                    results: modifiedCache,
                    totalCount: modifiedCache.length,
                  });
                }
              },
            );
          },
        },
      );
      if (onDelete) onDelete();
      setActiveImageIdx(0);
    } catch {
      alert('File could not be deleted');
    } finally {
      setIsDeleteDialogOpen(false);
    }
  };
  const sectionTitle = useMemo(() => {
    if (subtitle) return subtitle;

    const label = WaiverTypes.find(
      (a) => a.value === filesMetaQuery.data?.at(activeImageIdx)?.typeId,
    )?.label;
    const transactionDocumentType = description ? `Transaction Document Type: ${description}` : '';
    const waiverType = label ? `Waiver Status: ${label}` : '';
    if (waiverDisplay) {
      return `${transactionDocumentType}  ${waiverType}`;
    } else {
      return `${transactionDocumentType}`;
    }
  }, [subtitle, waiverDisplay, filesMetaQuery.data, description, activeImageIdx]);

  return (
    <Card className="relative flex h-full w-full flex-1 flex-col overflow-hidden">
      <CardHeader>
        <div>
          <CardTitle>{title}</CardTitle>
          {sectionTitle && <CardDescription>{sectionTitle}</CardDescription>}
          {subtitle && <CardDescription>Subtitle: {subtitle}</CardDescription>}
        </div>
        {subTitle}
        <div className="flex gap-2">
          {canDelete && (
            <Dialog onOpenChange={setIsDeleteDialogOpen} open={isDeleteDialogOpen}>
              <DialogTrigger asChild>
                <Button className="flex gap-1 text-xs sm:text-sm">
                  <TrashIcon />
                  <span>Delete File</span>
                </Button>
              </DialogTrigger>

              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Confirm deletion of this file</DialogTitle>
                  <DialogDescription>Are you sure you want to delete this file</DialogDescription>
                </DialogHeader>
                <DialogFooter className="flex flex-row items-center justify-center gap-2">
                  <DialogClose asChild>
                    <Button disabled={deleteFileMutation.isPending} className="flex-1">
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button
                    isLoading={deleteFileMutation.isPending}
                    disabled={deleteFileMutation.isPending}
                    className="flex-1"
                    onClick={deleteFile}
                    variant="destructive"
                  >
                    Confirm
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}

          <Button className="flex gap-1 text-xs sm:text-sm" onClick={downloadFile}>
            <DownloadIcon />
            <span>Download File</span>
          </Button>
        </div>
      </CardHeader>

      <div className="AltGalleryModal flex h-[calc(100%_-138px)]  w-full items-center justify-center">
        {filesMetaQuery.isPending || filesQuery.isPending || currentFileQuery.isPending ? (
          <Loader className="AltGalleryModal-Loader" />
        ) : filesMetaQuery.data?.length ? (
          mimeType === 'application/pdf' ? (
            <iframe
              title="preview"
              src={currentFileQuery.data?.urlObject}
              className="h-full w-full"
            />
          ) : (
            <RotateZoomImage url={currentFileQuery.data?.urlObject ?? ''} />
          )
        ) : (
          <div className="absolute flex h-full w-full items-center justify-center">
            <span className="text-xl">No files found.</span>
          </div>
        )}
      </div>

      <CardFooter className="flex justify-center p-2">
        <DataTablePagination
          currentPage={activeImageIdx}
          pageCount={filesMetaQuery.data?.length ?? 0}
          setPage={setActiveImageIdx}
          disabled={filesMetaQuery.isPending || filesQuery.isPending || currentFileQuery.isPending}
        />
      </CardFooter>
    </Card>
  );
};

export const RotateZoomImage = ({ url, data }: { url: string; data?: string }) => {
  const [rotation, setRotation] = useState<Deg>(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, []);

  const imageSrc = data ? data : url;

  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut, resetTransform }) => (
        <div
          ref={containerRef}
          className="flex h-full w-full  flex-col items-center gap-1 sm:gap-2"
        >
          <div className="flex h-[calc(100%_-_96px)] w-full justify-center py-0 sm:p-2">
            <div className="relative grid h-full place-items-center">
              <TransformComponent>
                <img
                  src={imageSrc}
                  style={{
                    transform: `rotate(${rotation}deg)`,
                    transformOrigin: 'center',
                    width: dimensions.width,
                    height: dimensions.height - 84,
                  }}
                  className="object-contain"
                  alt="test"
                />
              </TransformComponent>
            </div>
          </div>
          <div className="mt-auto space-y-4 p-2">
            <div className="flex flex-wrap items-center justify-center gap-2">
              <Button
                className="text-xs sm:text-sm"
                onClick={() =>
                  setRotation((prev) => {
                    if (prev === 270) return 0;
                    return (prev + 90) as Deg;
                  })
                }
              >
                <RotateCounterClockwiseIcon className="-scale-x-100" /> Rotate Right
              </Button>
              <Button
                className="text-xs sm:text-sm"
                onClick={() =>
                  setRotation((prev) => {
                    if (prev === 0) return 270;
                    return (prev - 90) as Deg;
                  })
                }
              >
                <RotateCounterClockwiseIcon /> Rotate Left
              </Button>
              <Button className="text-xs sm:text-sm" onClick={() => zoomIn()}>
                Zoom In
              </Button>
              <Button className="text-xs sm:text-sm" onClick={() => zoomOut()}>
                Zoom Out
              </Button>
              <Button
                className="text-xs sm:text-sm"
                onClick={() => {
                  resetTransform();
                  setRotation(0);
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
      )}
    </TransformWrapper>
  );
};
