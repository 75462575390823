import { InternalLink, InternalLinkList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { PerformanceReviewClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type InternalLinkFilter = EntityFilter<InternalLink>;

export const useCreateInternalLinkMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: InternalLink) => PerformanceReviewClientService.CreateInternalLink(req),
    async onSuccess(data) {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.internalLink.root],
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.internalResource.root],
        }),
      ]);
    },
  });
};

export const useDeleteInternalLinkMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: InternalLink) => PerformanceReviewClientService.DeleteInternalLink(req),
    async onSuccess(data, deletedLink, context) {
      queryClient.setQueriesData<InternalLinkList>(
        { queryKey: [queryKeys.internalLink.root, queryKeys.internalLink.list] },
        (oldData) => {
          if (oldData) {
            const includesDeleted = oldData?.results?.find((link) => link.id === deletedLink.id);

            if (includesDeleted) {
              return InternalLinkList.create({
                totalCount: oldData.totalCount - 1,
                results: oldData.results.filter((link) => link.id !== deletedLink.id),
              });
            }
            return oldData;
          }
        },
      );
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.internalLink.root],
      });
    },
  });
};

export const useUpdateInternalLinkMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: InternalLink) => PerformanceReviewClientService.UpdateInternalLink(req),
    async onSuccess(data) {
      if (data) {
        queryClient.setQueriesData<InternalLinkList>(
          { queryKey: [queryKeys.creditCards.root, queryKeys.creditCards.list] },
          (oldData) => {
            if (oldData) {
              const includesUpdated = oldData?.results?.find((link) => link.id === data.id);
              if (includesUpdated) {
                return InternalLinkList.create({
                  totalCount: oldData.totalCount,
                  results: oldData.results.map((link) => (link.id === data.id ? data : link)),
                });
              }
              return oldData;
            }
          },
        );

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [queryKeys.internalLink.root],
          }),
          queryClient.invalidateQueries({
            queryKey: [queryKeys.internalResource.root],
          }),
        ]);
      }
    },
  });
};

export const useBatchGetInternalLinksQuery = ({
  filter,
  enabled,
}: {
  filter: InternalLinkFilter;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: [
      queryKeys.internalLink.root,
      queryKeys.internalLink.list,
      queryKeys.internalLink.getHash(filter),
    ],
    queryFn: () => {
      const req = InternalLink.create(filter);
      return PerformanceReviewClientService.BatchGetInternalLink(req);
    },
    enabled,
  });

export const useGetInternalLinkQuery = ({ id, enabled }: { id: number; enabled?: boolean }) =>
  useQuery({
    queryKey: [queryKeys.internalLink.root, id],
    queryFn: () => {
      const req = InternalLink.create({ id });
      return PerformanceReviewClientService.GetInternalLink(req);
    },
    enabled,
  });
