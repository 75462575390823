import { BaseClient } from '../BaseClient';
import { Empty, type Int32 } from '../compiled-protos/common';
import {
  type AssetInstance,
  type AssetInstanceList,
  type CatalogItem,
  type CatalogItemCategory,
  type CatalogItemCategoryList,
  type CatalogItemList,
  type GetImportStatusRequest,
  type GetImportStatusResponse,
  type Requisition,
  type RequisitionFulfillmentItem,
  type RequisitionList,
  ToolFundBalance,
  type ToolFundDashboardMetrics,
  type ToolFundHistoryRequest,
  type ToolFundHistoryResponse,
  type ToolFundRequisitionRequest,
   ToolFundSettings,
  type ToolFundTransaction,
  type ToolFundTransactionList,
  type ToolFundTransactionType,
  type ToolFundTransactionTypeList,
  type TradeType,
  type TradeTypeList,
  type TransferAssetRequest,
  type UploadApprovedToolsRequest,
  type UploadApprovedToolsResponse
} from '../compiled-protos/inventory';
import { InventoryServiceClient } from '../compiled-protos/inventory.client';
import { type User } from '../compiled-protos/user';

class InventoryClient extends BaseClient {
  self: InventoryServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new InventoryServiceClient(this.transport);
  }

  // Tool Fund Request Management
  async GetToolFundRequests(req: ToolFundHistoryRequest): Promise<ToolFundHistoryResponse> {
    const response = await this.self.getToolFundHistory(req, this.getMetaData()).response;
    return response;
  }

  async CreateToolFundRequest(req: ToolFundRequisitionRequest): Promise<Requisition> {
    const response = await this.self.createToolFundRequisition(req, this.getMetaData()).response;
    return response;
  }

  async GetUserToolFundBalance(user: User): Promise<ToolFundBalance> {
    let res = ToolFundBalance.create();
    try {
      res = await this.self.getUserToolFundBalance(user, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  async TransferToolAsset(req: TransferAssetRequest): Promise<AssetInstance> {
    const response = await this.self.transferToolAsset(req, this.getMetaData()).response;
    return response;
  }

  async CreateToolFundTransaction(req: ToolFundTransaction): Promise<ToolFundTransaction> {
    const response = await this.self.createToolFundTransaction(req, this.getMetaData()).response;
    return response;
  }

  async GetToolFundTransaction(req: ToolFundTransaction): Promise<ToolFundTransaction> {
    const response = await this.self.getToolFundTransaction(req, this.getMetaData()).response;
    return response;
  }

  async BatchGetToolFundTransaction(req: ToolFundTransaction): Promise<ToolFundTransactionList> {
    const response = await this.self.batchGetToolFundTransaction(req, this.getMetaDataWithoutCache()).response;
    return response;
  }

  async UpdateToolFundTransaction(req: ToolFundTransaction): Promise<ToolFundTransaction> {
    const response = await this.self.updateToolFundTransaction(req, this.getMetaData()).response;
    return response;
  }

  async DeleteToolFundTransaction(req: ToolFundTransaction) {
    const response = await this.self.deleteToolFundTransaction(req, this.getMetaData()).response;
    return response;
  }

  async CreateRequisition(req: Requisition): Promise<Requisition> {
    const response = await this.self.createRequisition(req, this.getMetaData()).response;
    return response;
  }

  async BatchGetRequisition(req: Requisition): Promise<RequisitionList> {
    const response = await this.self.batchGetRequisition(req, this.getMetaDataWithoutCache()).response;
    return response;
  }

  async UpdateRequisition(req: Requisition): Promise<Requisition> {
    const response = await this.self.updateRequisition(req, this.getMetaData()).response;
    return response;
  }

  async BatchGetToolFundTrade(req: TradeType): Promise<TradeTypeList> {
    const response = await this.self.batchGetTradeType(req, this.getMetaDataWithoutCache()).response;
    return response;
  }

  async UpdateTradeType(req: TradeType): Promise<TradeType> {
    const response = await this.self.updateTradeType(req, this.getMetaData()).response;
    return response;
  }

  async UploadApprovedToolList(req: UploadApprovedToolsRequest): Promise<UploadApprovedToolsResponse> {
    const response = await this.self.uploadApprovedTools(req, this.getMetaData()).response;
    return response;
  }

  async FetchDashboardMetrics(): Promise<ToolFundDashboardMetrics> {
    const response = await this.self.getToolFundDashboardMetrics(Empty.create(), this.getMetaData());
    return response.response;
  }

  async GetToolFundSettings(req: ToolFundSettings): Promise<ToolFundSettings> {
    let res = ToolFundSettings.create();
    try {
      res = await this.self.getToolFundSettings(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  async UpdateToolFundSettings(req: ToolFundSettings): Promise<ToolFundSettings> {
    const response = await this.self.updateToolFundSettings(req, this.getMetaData());
    return response.response;
  }

  async CreateToolFundSettings(req: ToolFundSettings): Promise<ToolFundSettings> {
    const response = await this.self.createToolFundSettings(req, this.getMetaData());
    return response.response;
  }

  async BatchGetToolFundTransactionType(req: ToolFundTransactionType): Promise<ToolFundTransactionTypeList> {
    const response = await this.self.batchGetTransactionType(req, this.getMetaDataWithoutCache());
    return response.response;
  }

  async BatchGetCatalogItem(req: CatalogItem): Promise<CatalogItemList> {
    const response = await this.self.batchGetCatalogItem(req, this.getMetaDataWithoutCache());
    return response.response;
  }
  async GetCatalogItem(req: CatalogItem): Promise<CatalogItem> {
    const response = await this.self.getCatalogItem(req, this.getMetaDataWithoutCache());
    return response.response;
  }
  async GetPendingRequistionRequests(): Promise<Int32> {
    const response = await this.self.getPendingRequisitionCount(Empty.create(), this.getMetaData());
    return response.response;
  }

  async BatchGetCatalogItemCategory(req: CatalogItemCategory): Promise<CatalogItemCategoryList> {
    const response = await this.self.batchGetCatalogItemCategory(req, this.getMetaDataWithoutCache());
    return response.response;
  }

  async BatchGetAssetInstance(req: AssetInstance): Promise<AssetInstanceList> {
    const response = await this.self.batchGetAssetInstance(req, this.getMetaDataWithoutCache());
    return response.response;
  }

  async GetAssetInstance(req: AssetInstance): Promise<AssetInstance> {
    const response = await this.self.getAssetInstance(req, this.getMetaData());
    return response.response;
  }

  async CreateAssetInstance(req: AssetInstance): Promise<AssetInstance> {
    const response = await this.self.createAssetInstance(req, this.getMetaData());
    return response.response;
  }

  async UpdateAssetInstance(req: AssetInstance): Promise<AssetInstance> {
    const response = await this.self.updateAssetInstance(req, this.getMetaData());
    return response.response;
  }

  async DeleteAssetInstance(req: AssetInstance): Promise<AssetInstance> {
    await this.self.deleteAssetInstance(req, this.getMetaData());
    return req;
  }

  async GetRequisition(req: Requisition): Promise<Requisition> {
    const response = await this.self.getRequisition(req, this.getMetaData());
    return response.response;
  }

  async DeleteRequisition(req: Requisition): Promise<Requisition> {
    await this.self.deleteRequisition(req, this.getMetaData());
    return req;
  }
  async UpdateRequistionFulfillmentItem(req: RequisitionFulfillmentItem): Promise<RequisitionFulfillmentItem> {
    await this.self.updateRequisitionFulfillmentItem(req, this.getMetaData());
    return req;
  }
  async GetUploadToolCatalogStatus(req: GetImportStatusRequest): Promise<GetImportStatusResponse> {
    const response = await this.self.getImportStatus(req, this.getMetaData());
    return response.response;
  }

}
export { InventoryClient };

// Re-export types
export type {
  TransferAssetRequest,
  AssetInstance,
  AssetInstanceList,
  ToolFundTransaction,
  ToolFundTransactionList,
  ToolFundSettings,
  ToolFundBalance,
  ToolFundHistoryRequest,
  ToolFundHistoryResponse,
  ToolFundRequisitionRequest,
  RequisitionFulfillmentItem,
  Requisition,
  RequisitionList,
  TradeType,
  TradeTypeList
};
