import { ToolFundSettings, type User } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { InventoryClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ToolFundSettingsFilter = EntityFilter<ToolFundSettings>;

export const useGetToolFundSettings = (filter: ToolFundSettingsFilter) => {
  return useQuery({
    queryKey: [
      queryKeys.toolFundSettings.root,
      queryKeys.toolFundSettings.list,
      queryKeys.toolFundSettings.getHash(filter),
    ],
    queryFn: async () => {
      return await InventoryClientService.GetToolFundSettings(ToolFundSettings.create(filter));
    },
  });
};

export const useToolFundSettingsUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (settings: ToolFundSettings) => {
      return await InventoryClientService.UpdateToolFundSettings(settings);
    },
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.toolFundSettings.root, queryKeys.toolFundSettings.list],
      });
    },
  });
};

export const useToolFundCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (settings: ToolFundSettings) => {
      return await InventoryClientService.CreateToolFundSettings(settings);
    },
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.toolFundSettings.root],
      });
    },
  });
};

export const useUserToolFundBalanceQuery = (user: User) => {
  return useQuery({
    queryKey: [
      queryKeys.toolFundBalance.root,
      queryKeys.toolFundBalance.list,
      queryKeys.toolFundBalance.getHash(user),
    ],
    queryFn: async () => {
      return await InventoryClientService.GetUserToolFundBalance(user);
    },
  });
};
