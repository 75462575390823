import { CatalogItemCategory, type CatalogItemCategoryList } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { InventoryClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type CatalogItemCategoryFilter = EntityFilter<CatalogItemCategory>;

export const useToolFundCatalogItemCategoryQuery = <TSelectData = CatalogItemCategoryList,>({
  filter = {},
  enabled = true,
  select,
}: {
  filter?: CatalogItemCategoryFilter;
  enabled?: boolean;
  select?: (data: CatalogItemCategoryList) => TSelectData;
} = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.catalogItemCategory.root,
      queryKeys.catalogItemCategory.list,
      queryKeys.catalogItemCategory.getHash(filter),
    ],
    queryFn: async () => {
      return await InventoryClientService.BatchGetCatalogItemCategory(
        CatalogItemCategory.create(filter),
      );
    },
    enabled,
    select,
  });
};
