// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "perdiem.proto" (syntax proto3)
// tslint:disable
import { PerDiemService } from "./perdiem";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service PerDiemService
 */
export class PerDiemServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = PerDiemService.typeName;
        this.methods = PerDiemService.methods;
        this.options = PerDiemService.options;
    }
    /**
     * @generated from protobuf rpc: Create(PerDiem) returns (PerDiem);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(PerDiem) returns (PerDiem);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(PerDiem) returns (PerDiemList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(PerDiem) returns (PerDiem);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(PerDiem) returns (PerDiem);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateRow(PerDiemRow) returns (PerDiemRow);
     */
    createRow(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateRow(PerDiemRow) returns (PerDiemRow);
     */
    updateRow(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRow(PerDiemRow) returns (PerDiemRow);
     */
    getRow(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteRow(PerDiemRow) returns (Empty);
     */
    deleteRow(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPerDiemReport(PerDiemReportRequest) returns (PerDiemList);
     */
    getPerDiemReport(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTrip(Trip) returns (Trip);
     */
    createTrip(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTrip(Trip) returns (Trip);
     */
    updateTrip(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTrip(Trip) returns (Trip);
     */
    getTrip(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTrip(Trip) returns (Empty);
     */
    deleteTrip(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTrips(Trip) returns (TripList);
     */
    batchGetTrips(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTotalRowTripDistance(Int32) returns (Double);
     */
    getTotalRowTripDistance(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchDeleteTrips(Trip) returns (Trip);
     */
    batchDeleteTrips(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPerDiemsByIds(IntArray) returns (PerDiemList);
     */
    batchGetPerDiemsByIds(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDebugSQLOutput(SQLRequest) returns (String);
     */
    getDebugSQLOutput(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FetchGovPerDiemByZipCode(Int32) returns (Rate);
     */
    fetchGovPerDiemByZipCode(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPerDiemZipCodeValues(PerDiemZipCodeValue) returns (PerDiemZipCodeValue);
     */
    getPerDiemZipCodeValues(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPerDiemZipCodeValues(PerDiemZipCodeRequest) returns (PerDiemZipCodeValueList);
     */
    batchGetPerDiemZipCodeValues(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
