// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "inventory.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "inventory.proto" (syntax proto3)
// tslint:disable
import { User } from "./user";
import { Int32 } from "./common";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class CatalogItem$Type extends MessageType {
    constructor() {
        super("CatalogItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "category_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "cost", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "requires_asset_tag", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "is_tool_fund_eligible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "external_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "has_duplicates", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "updated_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "trade_type_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "trade_type_name_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "serial_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", description: "", categoryId: 0, cost: 0, requiresAssetTag: false, isToolFundEligible: false, externalLink: "", quantity: 0, hasDuplicates: false, createdAt: "", updatedAt: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", tradeTypeIdList: "", tradeTypeNameList: "", brand: "", model: "", serialNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* int32 category_id */ 4:
                    message.categoryId = reader.int32();
                    break;
                case /* double cost */ 5:
                    message.cost = reader.double();
                    break;
                case /* bool requires_asset_tag */ 6:
                    message.requiresAssetTag = reader.bool();
                    break;
                case /* bool is_tool_fund_eligible */ 7:
                    message.isToolFundEligible = reader.bool();
                    break;
                case /* string external_link */ 8:
                    message.externalLink = reader.string();
                    break;
                case /* int32 quantity */ 9:
                    message.quantity = reader.int32();
                    break;
                case /* bool has_duplicates */ 10:
                    message.hasDuplicates = reader.bool();
                    break;
                case /* string created_at */ 11:
                    message.createdAt = reader.string();
                    break;
                case /* string updated_at */ 12:
                    message.updatedAt = reader.string();
                    break;
                case /* repeated string field_mask */ 13:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 14:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 15:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 16:
                    message.orderDir = reader.string();
                    break;
                case /* string trade_type_id_list */ 17:
                    message.tradeTypeIdList = reader.string();
                    break;
                case /* string trade_type_name_list */ 18:
                    message.tradeTypeNameList = reader.string();
                    break;
                case /* string brand */ 19:
                    message.brand = reader.string();
                    break;
                case /* string model */ 20:
                    message.model = reader.string();
                    break;
                case /* string serial_number */ 21:
                    message.serialNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* int32 category_id = 4; */
        if (message.categoryId !== 0)
            writer.tag(4, WireType.Varint).int32(message.categoryId);
        /* double cost = 5; */
        if (message.cost !== 0)
            writer.tag(5, WireType.Bit64).double(message.cost);
        /* bool requires_asset_tag = 6; */
        if (message.requiresAssetTag !== false)
            writer.tag(6, WireType.Varint).bool(message.requiresAssetTag);
        /* bool is_tool_fund_eligible = 7; */
        if (message.isToolFundEligible !== false)
            writer.tag(7, WireType.Varint).bool(message.isToolFundEligible);
        /* string external_link = 8; */
        if (message.externalLink !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.externalLink);
        /* int32 quantity = 9; */
        if (message.quantity !== 0)
            writer.tag(9, WireType.Varint).int32(message.quantity);
        /* bool has_duplicates = 10; */
        if (message.hasDuplicates !== false)
            writer.tag(10, WireType.Varint).bool(message.hasDuplicates);
        /* string created_at = 11; */
        if (message.createdAt !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.createdAt);
        /* string updated_at = 12; */
        if (message.updatedAt !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.updatedAt);
        /* repeated string field_mask = 13; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 14; */
        if (message.pageNumber !== 0)
            writer.tag(14, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 15; */
        if (message.orderBy !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 16; */
        if (message.orderDir !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.orderDir);
        /* string trade_type_id_list = 17; */
        if (message.tradeTypeIdList !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.tradeTypeIdList);
        /* string trade_type_name_list = 18; */
        if (message.tradeTypeNameList !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.tradeTypeNameList);
        /* string brand = 19; */
        if (message.brand !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.brand);
        /* string model = 20; */
        if (message.model !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.model);
        /* string serial_number = 21; */
        if (message.serialNumber !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.serialNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CatalogItem
 */
export const CatalogItem = new CatalogItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CatalogItemList$Type extends MessageType {
    constructor() {
        super("CatalogItemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CatalogItem },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CatalogItem results */ 1:
                    message.results.push(CatalogItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CatalogItem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CatalogItem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CatalogItemList
 */
export const CatalogItemList = new CatalogItemList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TradeType$Type extends MessageType {
    constructor() {
        super("TradeType", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "default_allotment", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "updated_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", description: "", defaultAllotment: 0, departmentId: 0, createdAt: "", updatedAt: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* double default_allotment */ 4:
                    message.defaultAllotment = reader.double();
                    break;
                case /* int32 department_id */ 5:
                    message.departmentId = reader.int32();
                    break;
                case /* string created_at */ 6:
                    message.createdAt = reader.string();
                    break;
                case /* string updated_at */ 7:
                    message.updatedAt = reader.string();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 10:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 11:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* double default_allotment = 4; */
        if (message.defaultAllotment !== 0)
            writer.tag(4, WireType.Bit64).double(message.defaultAllotment);
        /* int32 department_id = 5; */
        if (message.departmentId !== 0)
            writer.tag(5, WireType.Varint).int32(message.departmentId);
        /* string created_at = 6; */
        if (message.createdAt !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.createdAt);
        /* string updated_at = 7; */
        if (message.updatedAt !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.updatedAt);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 10; */
        if (message.orderBy !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 11; */
        if (message.orderDir !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TradeType
 */
export const TradeType = new TradeType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TradeTypeList$Type extends MessageType {
    constructor() {
        super("TradeTypeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TradeType },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TradeType results */ 1:
                    message.results.push(TradeType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TradeType results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TradeType.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TradeTypeList
 */
export const TradeTypeList = new TradeTypeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolTradeMapping$Type extends MessageType {
    constructor() {
        super("ToolTradeMapping", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "catalog_item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "trade_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "updated_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, catalogItemId: 0, tradeTypeId: 0, createdAt: "", updatedAt: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 catalog_item_id */ 2:
                    message.catalogItemId = reader.int32();
                    break;
                case /* int32 trade_type_id */ 3:
                    message.tradeTypeId = reader.int32();
                    break;
                case /* string created_at */ 4:
                    message.createdAt = reader.string();
                    break;
                case /* string updated_at */ 5:
                    message.updatedAt = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 catalog_item_id = 2; */
        if (message.catalogItemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.catalogItemId);
        /* int32 trade_type_id = 3; */
        if (message.tradeTypeId !== 0)
            writer.tag(3, WireType.Varint).int32(message.tradeTypeId);
        /* string created_at = 4; */
        if (message.createdAt !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.createdAt);
        /* string updated_at = 5; */
        if (message.updatedAt !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.updatedAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolTradeMapping
 */
export const ToolTradeMapping = new ToolTradeMapping$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExternalToolImport$Type extends MessageType {
    constructor() {
        super("ExternalToolImport", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "catalog_item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "external_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "source_system", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "last_sync", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "updated_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, catalogItemId: 0, externalId: "", sourceSystem: "", lastSync: "", createdAt: "", updatedAt: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 catalog_item_id */ 2:
                    message.catalogItemId = reader.int32();
                    break;
                case /* string external_id */ 3:
                    message.externalId = reader.string();
                    break;
                case /* string source_system */ 4:
                    message.sourceSystem = reader.string();
                    break;
                case /* string last_sync */ 5:
                    message.lastSync = reader.string();
                    break;
                case /* string created_at */ 6:
                    message.createdAt = reader.string();
                    break;
                case /* string updated_at */ 7:
                    message.updatedAt = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 catalog_item_id = 2; */
        if (message.catalogItemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.catalogItemId);
        /* string external_id = 3; */
        if (message.externalId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.externalId);
        /* string source_system = 4; */
        if (message.sourceSystem !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.sourceSystem);
        /* string last_sync = 5; */
        if (message.lastSync !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastSync);
        /* string created_at = 6; */
        if (message.createdAt !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.createdAt);
        /* string updated_at = 7; */
        if (message.updatedAt !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.updatedAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExternalToolImport
 */
export const ExternalToolImport = new ExternalToolImport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Stock$Type extends MessageType {
    constructor() {
        super("Stock", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, itemId: 0, locationId: 0, quantity: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 item_id */ 2:
                    message.itemId = reader.int32();
                    break;
                case /* int32 location_id */ 3:
                    message.locationId = reader.int32();
                    break;
                case /* int32 quantity */ 4:
                    message.quantity = reader.int32();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 7:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 8:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 item_id = 2; */
        if (message.itemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.itemId);
        /* int32 location_id = 3; */
        if (message.locationId !== 0)
            writer.tag(3, WireType.Varint).int32(message.locationId);
        /* int32 quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantity);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 7; */
        if (message.orderBy !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 8; */
        if (message.orderDir !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Stock
 */
export const Stock = new Stock$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StockList$Type extends MessageType {
    constructor() {
        super("StockList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Stock },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Stock results */ 1:
                    message.results.push(Stock.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Stock results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Stock.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StockList
 */
export const StockList = new StockList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Requisition$Type extends MessageType {
    constructor() {
        super("Requisition", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "request_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "requisition_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RequisitionItem },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "with_items", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "with_status", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "category_names", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "category_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "catalog_item_names", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "catalog_item_brands", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "total_estimated_cost", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 17, name: "updated_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "with_fulfillments", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "fulfillments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RequisitionFulfillmentItem },
            { no: 21, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "purchase_order", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, eventId: 0, requestDate: "", statusId: 0, requisitionTypeId: 0, items: [], fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", withItems: false, withStatus: false, categoryNames: "", categoryList: "", catalogItemNames: "", catalogItemBrands: "", totalEstimatedCost: 0, updatedAt: "", createdAt: "", withFulfillments: false, fulfillments: [], fileId: 0, purchaseOrder: "", isActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 event_id */ 3:
                    message.eventId = reader.int32();
                    break;
                case /* string request_date */ 4:
                    message.requestDate = reader.string();
                    break;
                case /* int32 status_id */ 5:
                    message.statusId = reader.int32();
                    break;
                case /* int32 requisition_type_id */ 6:
                    message.requisitionTypeId = reader.int32();
                    break;
                case /* repeated RequisitionItem items */ 7:
                    message.items.push(RequisitionItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 10:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 11:
                    message.orderDir = reader.string();
                    break;
                case /* bool with_items */ 12:
                    message.withItems = reader.bool();
                    break;
                case /* bool with_status */ 13:
                    message.withStatus = reader.bool();
                    break;
                case /* string category_names */ 14:
                    message.categoryNames = reader.string();
                    break;
                case /* string category_list */ 15:
                    message.categoryList = reader.string();
                    break;
                case /* string catalog_item_names */ 23:
                    message.catalogItemNames = reader.string();
                    break;
                case /* string catalog_item_brands */ 24:
                    message.catalogItemBrands = reader.string();
                    break;
                case /* double total_estimated_cost */ 16:
                    message.totalEstimatedCost = reader.double();
                    break;
                case /* string updated_at */ 17:
                    message.updatedAt = reader.string();
                    break;
                case /* string created_at */ 18:
                    message.createdAt = reader.string();
                    break;
                case /* bool with_fulfillments */ 19:
                    message.withFulfillments = reader.bool();
                    break;
                case /* repeated RequisitionFulfillmentItem fulfillments */ 20:
                    message.fulfillments.push(RequisitionFulfillmentItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 file_id */ 21:
                    message.fileId = reader.int32();
                    break;
                case /* string purchase_order */ 22:
                    message.purchaseOrder = reader.string();
                    break;
                case /* bool is_active */ 25:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 event_id = 3; */
        if (message.eventId !== 0)
            writer.tag(3, WireType.Varint).int32(message.eventId);
        /* string request_date = 4; */
        if (message.requestDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.requestDate);
        /* int32 status_id = 5; */
        if (message.statusId !== 0)
            writer.tag(5, WireType.Varint).int32(message.statusId);
        /* int32 requisition_type_id = 6; */
        if (message.requisitionTypeId !== 0)
            writer.tag(6, WireType.Varint).int32(message.requisitionTypeId);
        /* repeated RequisitionItem items = 7; */
        for (let i = 0; i < message.items.length; i++)
            RequisitionItem.internalBinaryWrite(message.items[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 10; */
        if (message.orderBy !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 11; */
        if (message.orderDir !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderDir);
        /* bool with_items = 12; */
        if (message.withItems !== false)
            writer.tag(12, WireType.Varint).bool(message.withItems);
        /* bool with_status = 13; */
        if (message.withStatus !== false)
            writer.tag(13, WireType.Varint).bool(message.withStatus);
        /* string category_names = 14; */
        if (message.categoryNames !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.categoryNames);
        /* string category_list = 15; */
        if (message.categoryList !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.categoryList);
        /* string catalog_item_names = 23; */
        if (message.catalogItemNames !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.catalogItemNames);
        /* string catalog_item_brands = 24; */
        if (message.catalogItemBrands !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.catalogItemBrands);
        /* double total_estimated_cost = 16; */
        if (message.totalEstimatedCost !== 0)
            writer.tag(16, WireType.Bit64).double(message.totalEstimatedCost);
        /* string updated_at = 17; */
        if (message.updatedAt !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.updatedAt);
        /* string created_at = 18; */
        if (message.createdAt !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.createdAt);
        /* bool with_fulfillments = 19; */
        if (message.withFulfillments !== false)
            writer.tag(19, WireType.Varint).bool(message.withFulfillments);
        /* repeated RequisitionFulfillmentItem fulfillments = 20; */
        for (let i = 0; i < message.fulfillments.length; i++)
            RequisitionFulfillmentItem.internalBinaryWrite(message.fulfillments[i], writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* int32 file_id = 21; */
        if (message.fileId !== 0)
            writer.tag(21, WireType.Varint).int32(message.fileId);
        /* string purchase_order = 22; */
        if (message.purchaseOrder !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.purchaseOrder);
        /* bool is_active = 25; */
        if (message.isActive !== false)
            writer.tag(25, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Requisition
 */
export const Requisition = new Requisition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequisitionItem$Type extends MessageType {
    constructor() {
        super("RequisitionItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "requisition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "catalog_item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "custom_item_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "custom_item_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "quantity_requested", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "estimated_cost", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "requisition_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "requested_delivery_location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "category_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, requisitionId: 0, catalogItemId: 0, customItemName: "", customItemDescription: "", quantityRequested: 0, estimatedCost: 0, fieldMask: [], requisitionIdList: "", requestedDeliveryLocation: "", categoryId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 requisition_id */ 2:
                    message.requisitionId = reader.int32();
                    break;
                case /* int32 catalog_item_id */ 3:
                    message.catalogItemId = reader.int32();
                    break;
                case /* string custom_item_name */ 4:
                    message.customItemName = reader.string();
                    break;
                case /* string custom_item_description */ 5:
                    message.customItemDescription = reader.string();
                    break;
                case /* int32 quantity_requested */ 6:
                    message.quantityRequested = reader.int32();
                    break;
                case /* double estimated_cost */ 7:
                    message.estimatedCost = reader.double();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string requisition_id_list */ 9:
                    message.requisitionIdList = reader.string();
                    break;
                case /* string requested_delivery_location */ 10:
                    message.requestedDeliveryLocation = reader.string();
                    break;
                case /* int32 category_id */ 11:
                    message.categoryId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 requisition_id = 2; */
        if (message.requisitionId !== 0)
            writer.tag(2, WireType.Varint).int32(message.requisitionId);
        /* int32 catalog_item_id = 3; */
        if (message.catalogItemId !== 0)
            writer.tag(3, WireType.Varint).int32(message.catalogItemId);
        /* string custom_item_name = 4; */
        if (message.customItemName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customItemName);
        /* string custom_item_description = 5; */
        if (message.customItemDescription !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customItemDescription);
        /* int32 quantity_requested = 6; */
        if (message.quantityRequested !== 0)
            writer.tag(6, WireType.Varint).int32(message.quantityRequested);
        /* double estimated_cost = 7; */
        if (message.estimatedCost !== 0)
            writer.tag(7, WireType.Bit64).double(message.estimatedCost);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string requisition_id_list = 9; */
        if (message.requisitionIdList !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.requisitionIdList);
        /* string requested_delivery_location = 10; */
        if (message.requestedDeliveryLocation !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.requestedDeliveryLocation);
        /* int32 category_id = 11; */
        if (message.categoryId !== 0)
            writer.tag(11, WireType.Varint).int32(message.categoryId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RequisitionItem
 */
export const RequisitionItem = new RequisitionItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequisitionList$Type extends MessageType {
    constructor() {
        super("RequisitionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Requisition },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Requisition results */ 1:
                    message.results.push(Requisition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Requisition results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Requisition.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RequisitionList
 */
export const RequisitionList = new RequisitionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequisitionItemList$Type extends MessageType {
    constructor() {
        super("RequisitionItemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RequisitionItem },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated RequisitionItem results */ 1:
                    message.results.push(RequisitionItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated RequisitionItem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            RequisitionItem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RequisitionItemList
 */
export const RequisitionItemList = new RequisitionItemList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetInstance$Type extends MessageType {
    constructor() {
        super("AssetInstance", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "fulfillment_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "asset_tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "current_location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "current_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "acquisition_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "vesting_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "amount_paid", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "with_catalog_item", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "with_status", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "with_location", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "with_user", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "include_associated_fulfillment", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "fulfillment_item", kind: "message", T: () => RequisitionFulfillmentItem }
        ]);
    }
    create(value) {
        const message = { id: 0, itemId: 0, fulfillmentId: 0, assetTag: "", currentLocationId: 0, currentUserId: 0, statusId: 0, acquisitionDate: "", vestingDate: "", amountPaid: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", withCatalogItem: false, withStatus: false, withLocation: false, withUser: false, createdAt: "", includeAssociatedFulfillment: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 item_id */ 2:
                    message.itemId = reader.int32();
                    break;
                case /* int32 fulfillment_id */ 3:
                    message.fulfillmentId = reader.int32();
                    break;
                case /* string asset_tag */ 4:
                    message.assetTag = reader.string();
                    break;
                case /* int32 current_location_id */ 5:
                    message.currentLocationId = reader.int32();
                    break;
                case /* int32 current_user_id */ 6:
                    message.currentUserId = reader.int32();
                    break;
                case /* int32 status_id */ 7:
                    message.statusId = reader.int32();
                    break;
                case /* string acquisition_date */ 8:
                    message.acquisitionDate = reader.string();
                    break;
                case /* string vesting_date */ 9:
                    message.vestingDate = reader.string();
                    break;
                case /* double amount_paid */ 18:
                    message.amountPaid = reader.double();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 15:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 16:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 17:
                    message.orderDir = reader.string();
                    break;
                case /* bool with_catalog_item */ 11:
                    message.withCatalogItem = reader.bool();
                    break;
                case /* bool with_status */ 12:
                    message.withStatus = reader.bool();
                    break;
                case /* bool with_location */ 13:
                    message.withLocation = reader.bool();
                    break;
                case /* bool with_user */ 14:
                    message.withUser = reader.bool();
                    break;
                case /* string created_at */ 19:
                    message.createdAt = reader.string();
                    break;
                case /* bool include_associated_fulfillment */ 20:
                    message.includeAssociatedFulfillment = reader.bool();
                    break;
                case /* RequisitionFulfillmentItem fulfillment_item */ 21:
                    message.fulfillmentItem = RequisitionFulfillmentItem.internalBinaryRead(reader, reader.uint32(), options, message.fulfillmentItem);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 item_id = 2; */
        if (message.itemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.itemId);
        /* int32 fulfillment_id = 3; */
        if (message.fulfillmentId !== 0)
            writer.tag(3, WireType.Varint).int32(message.fulfillmentId);
        /* string asset_tag = 4; */
        if (message.assetTag !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.assetTag);
        /* int32 current_location_id = 5; */
        if (message.currentLocationId !== 0)
            writer.tag(5, WireType.Varint).int32(message.currentLocationId);
        /* int32 current_user_id = 6; */
        if (message.currentUserId !== 0)
            writer.tag(6, WireType.Varint).int32(message.currentUserId);
        /* int32 status_id = 7; */
        if (message.statusId !== 0)
            writer.tag(7, WireType.Varint).int32(message.statusId);
        /* string acquisition_date = 8; */
        if (message.acquisitionDate !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.acquisitionDate);
        /* string vesting_date = 9; */
        if (message.vestingDate !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.vestingDate);
        /* double amount_paid = 18; */
        if (message.amountPaid !== 0)
            writer.tag(18, WireType.Bit64).double(message.amountPaid);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 15; */
        if (message.pageNumber !== 0)
            writer.tag(15, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 16; */
        if (message.orderBy !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 17; */
        if (message.orderDir !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.orderDir);
        /* bool with_catalog_item = 11; */
        if (message.withCatalogItem !== false)
            writer.tag(11, WireType.Varint).bool(message.withCatalogItem);
        /* bool with_status = 12; */
        if (message.withStatus !== false)
            writer.tag(12, WireType.Varint).bool(message.withStatus);
        /* bool with_location = 13; */
        if (message.withLocation !== false)
            writer.tag(13, WireType.Varint).bool(message.withLocation);
        /* bool with_user = 14; */
        if (message.withUser !== false)
            writer.tag(14, WireType.Varint).bool(message.withUser);
        /* string created_at = 19; */
        if (message.createdAt !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.createdAt);
        /* bool include_associated_fulfillment = 20; */
        if (message.includeAssociatedFulfillment !== false)
            writer.tag(20, WireType.Varint).bool(message.includeAssociatedFulfillment);
        /* RequisitionFulfillmentItem fulfillment_item = 21; */
        if (message.fulfillmentItem)
            RequisitionFulfillmentItem.internalBinaryWrite(message.fulfillmentItem, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AssetInstance
 */
export const AssetInstance = new AssetInstance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetInstanceList$Type extends MessageType {
    constructor() {
        super("AssetInstanceList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetInstance },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated AssetInstance results */ 1:
                    message.results.push(AssetInstance.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated AssetInstance results = 1; */
        for (let i = 0; i < message.results.length; i++)
            AssetInstance.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AssetInstanceList
 */
export const AssetInstanceList = new AssetInstanceList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundSettings$Type extends MessageType {
    constructor() {
        super("ToolFundSettings", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "is_eligible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "monthly_allowance", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "last_updated", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "updated_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "trade_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "is_vesting_eligible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, isEligible: false, monthlyAllowance: 0, lastUpdated: "", updatedBy: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", tradeTypeId: 0, isVestingEligible: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* bool is_eligible */ 3:
                    message.isEligible = reader.bool();
                    break;
                case /* double monthly_allowance */ 4:
                    message.monthlyAllowance = reader.double();
                    break;
                case /* string last_updated */ 5:
                    message.lastUpdated = reader.string();
                    break;
                case /* int32 updated_by */ 6:
                    message.updatedBy = reader.int32();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 8:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 9:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 10:
                    message.orderDir = reader.string();
                    break;
                case /* int32 trade_type_id */ 11:
                    message.tradeTypeId = reader.int32();
                    break;
                case /* bool is_vesting_eligible */ 12:
                    message.isVestingEligible = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* bool is_eligible = 3; */
        if (message.isEligible !== false)
            writer.tag(3, WireType.Varint).bool(message.isEligible);
        /* double monthly_allowance = 4; */
        if (message.monthlyAllowance !== 0)
            writer.tag(4, WireType.Bit64).double(message.monthlyAllowance);
        /* string last_updated = 5; */
        if (message.lastUpdated !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastUpdated);
        /* int32 updated_by = 6; */
        if (message.updatedBy !== 0)
            writer.tag(6, WireType.Varint).int32(message.updatedBy);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 8; */
        if (message.pageNumber !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 9; */
        if (message.orderBy !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 10; */
        if (message.orderDir !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderDir);
        /* int32 trade_type_id = 11; */
        if (message.tradeTypeId !== 0)
            writer.tag(11, WireType.Varint).int32(message.tradeTypeId);
        /* bool is_vesting_eligible = 12; */
        if (message.isVestingEligible !== false)
            writer.tag(12, WireType.Varint).bool(message.isVestingEligible);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundSettings
 */
export const ToolFundSettings = new ToolFundSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundBalance$Type extends MessageType {
    constructor() {
        super("ToolFundBalance", [
            { no: 1, name: "current_balance", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "monthly_allowance", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "last_updated", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { currentBalance: 0, monthlyAllowance: 0, lastUpdated: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double current_balance */ 1:
                    message.currentBalance = reader.double();
                    break;
                case /* double monthly_allowance */ 2:
                    message.monthlyAllowance = reader.double();
                    break;
                case /* string last_updated */ 3:
                    message.lastUpdated = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* double current_balance = 1; */
        if (message.currentBalance !== 0)
            writer.tag(1, WireType.Bit64).double(message.currentBalance);
        /* double monthly_allowance = 2; */
        if (message.monthlyAllowance !== 0)
            writer.tag(2, WireType.Bit64).double(message.monthlyAllowance);
        /* string last_updated = 3; */
        if (message.lastUpdated !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastUpdated);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundBalance
 */
export const ToolFundBalance = new ToolFundBalance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundRequisitionRequest$Type extends MessageType {
    constructor() {
        super("ToolFundRequisitionRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "catalog_item_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "quantities", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, catalogItemIds: [], quantities: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* repeated int32 catalog_item_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.catalogItemIds.push(reader.int32());
                    else
                        message.catalogItemIds.push(reader.int32());
                    break;
                case /* repeated int32 quantities */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.quantities.push(reader.int32());
                    else
                        message.quantities.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* repeated int32 catalog_item_ids = 2; */
        if (message.catalogItemIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.catalogItemIds.length; i++)
                writer.int32(message.catalogItemIds[i]);
            writer.join();
        }
        /* repeated int32 quantities = 3; */
        if (message.quantities.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.quantities.length; i++)
                writer.int32(message.quantities[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundRequisitionRequest
 */
export const ToolFundRequisitionRequest = new ToolFundRequisitionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundHistoryRequest$Type extends MessageType {
    constructor() {
        super("ToolFundHistoryRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, startDate: "", endDate: "", pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string start_date */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string end_date */ 3:
                    message.endDate = reader.string();
                    break;
                case /* int32 page_number */ 4:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string start_date = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string end_date = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* int32 page_number = 4; */
        if (message.pageNumber !== 0)
            writer.tag(4, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundHistoryRequest
 */
export const ToolFundHistoryRequest = new ToolFundHistoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundHistoryResponse$Type extends MessageType {
    constructor() {
        super("ToolFundHistoryResponse", [
            { no: 1, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetInstance },
            { no: 2, name: "requisitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Requisition },
            { no: 3, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { assets: [], requisitions: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated AssetInstance assets */ 1:
                    message.assets.push(AssetInstance.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Requisition requisitions */ 2:
                    message.requisitions.push(Requisition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 3:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated AssetInstance assets = 1; */
        for (let i = 0; i < message.assets.length; i++)
            AssetInstance.internalBinaryWrite(message.assets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated Requisition requisitions = 2; */
        for (let i = 0; i < message.requisitions.length; i++)
            Requisition.internalBinaryWrite(message.requisitions[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 3; */
        if (message.totalCount !== 0)
            writer.tag(3, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundHistoryResponse
 */
export const ToolFundHistoryResponse = new ToolFundHistoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransferAssetRequest$Type extends MessageType {
    constructor() {
        super("TransferAssetRequest", [
            { no: 1, name: "asset_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "new_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { assetId: 0, newUserId: 0, notes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 asset_id */ 1:
                    message.assetId = reader.int32();
                    break;
                case /* int32 new_user_id */ 2:
                    message.newUserId = reader.int32();
                    break;
                case /* string notes */ 3:
                    message.notes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 asset_id = 1; */
        if (message.assetId !== 0)
            writer.tag(1, WireType.Varint).int32(message.assetId);
        /* int32 new_user_id = 2; */
        if (message.newUserId !== 0)
            writer.tag(2, WireType.Varint).int32(message.newUserId);
        /* string notes = 3; */
        if (message.notes !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransferAssetRequest
 */
export const TransferAssetRequest = new TransferAssetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadApprovedToolsRequest$Type extends MessageType {
    constructor() {
        super("UploadApprovedToolsRequest", [
            { no: 1, name: "file_content", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value) {
        const message = { fileContent: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes file_content */ 1:
                    message.fileContent = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bytes file_content = 1; */
        if (message.fileContent.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.fileContent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UploadApprovedToolsRequest
 */
export const UploadApprovedToolsRequest = new UploadApprovedToolsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadApprovedToolsResponse$Type extends MessageType {
    constructor() {
        super("UploadApprovedToolsResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rows_processed", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { success: false, message: "", rowsProcessed: 0, jobId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                case /* int32 rows_processed */ 3:
                    message.rowsProcessed = reader.int32();
                    break;
                case /* string job_id */ 4:
                    message.jobId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        /* int32 rows_processed = 3; */
        if (message.rowsProcessed !== 0)
            writer.tag(3, WireType.Varint).int32(message.rowsProcessed);
        /* string job_id = 4; */
        if (message.jobId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.jobId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UploadApprovedToolsResponse
 */
export const UploadApprovedToolsResponse = new UploadApprovedToolsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetImportStatusRequest$Type extends MessageType {
    constructor() {
        super("GetImportStatusRequest", [
            { no: 1, name: "job_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { jobId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string job_id */ 1:
                    message.jobId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string job_id = 1; */
        if (message.jobId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.jobId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetImportStatusRequest
 */
export const GetImportStatusRequest = new GetImportStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetImportStatusResponse$Type extends MessageType {
    constructor() {
        super("GetImportStatusResponse", [
            { no: 1, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "progress", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "rows_processed", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "total_rows", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "warnings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "errors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ValidationError }
        ]);
    }
    create(value) {
        const message = { status: "", progress: 0, message: "", rowsProcessed: 0, totalRows: 0, warnings: [], errors: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string status */ 1:
                    message.status = reader.string();
                    break;
                case /* float progress */ 2:
                    message.progress = reader.float();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                case /* int32 rows_processed */ 4:
                    message.rowsProcessed = reader.int32();
                    break;
                case /* int32 total_rows */ 5:
                    message.totalRows = reader.int32();
                    break;
                case /* repeated string warnings */ 6:
                    message.warnings.push(reader.string());
                    break;
                case /* repeated ValidationError errors */ 7:
                    message.errors.push(ValidationError.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string status = 1; */
        if (message.status !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.status);
        /* float progress = 2; */
        if (message.progress !== 0)
            writer.tag(2, WireType.Bit32).float(message.progress);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        /* int32 rows_processed = 4; */
        if (message.rowsProcessed !== 0)
            writer.tag(4, WireType.Varint).int32(message.rowsProcessed);
        /* int32 total_rows = 5; */
        if (message.totalRows !== 0)
            writer.tag(5, WireType.Varint).int32(message.totalRows);
        /* repeated string warnings = 6; */
        for (let i = 0; i < message.warnings.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.warnings[i]);
        /* repeated ValidationError errors = 7; */
        for (let i = 0; i < message.errors.length; i++)
            ValidationError.internalBinaryWrite(message.errors[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetImportStatusResponse
 */
export const GetImportStatusResponse = new GetImportStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidationError$Type extends MessageType {
    constructor() {
        super("ValidationError", [
            { no: 1, name: "row", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "field", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { row: 0, field: "", value: "", message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 row */ 1:
                    message.row = reader.int32();
                    break;
                case /* string field */ 2:
                    message.field = reader.string();
                    break;
                case /* string value */ 3:
                    message.value = reader.string();
                    break;
                case /* string message */ 4:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 row = 1; */
        if (message.row !== 0)
            writer.tag(1, WireType.Varint).int32(message.row);
        /* string field = 2; */
        if (message.field !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.field);
        /* string value = 3; */
        if (message.value !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.value);
        /* string message = 4; */
        if (message.message !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ValidationError
 */
export const ValidationError = new ValidationError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApprovedTool$Type extends MessageType {
    constructor() {
        super("ApprovedTool", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tool_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "brand_model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "has_duplicates", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "purchased_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "is_electrical", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "is_bas", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "is_construction", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "is_carpentry", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "is_vas", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "is_plumbing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "is_residential", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "is_com_light", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "is_com_heavy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "is_refrigeration", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "updated_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 25, name: "hyperlink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, category: "", toolName: "", brandModel: "", hasDuplicates: false, quantity: 0, price: 0, purchasedBy: "", isElectrical: false, isBas: false, isConstruction: false, isCarpentry: false, isVas: false, isPlumbing: false, isResidential: false, isComLight: false, isComHeavy: false, isRefrigeration: false, notes: "", createdAt: "", updatedAt: "", fieldMask: [], pageNumber: 0, withoutLimit: false, hyperlink: "", orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string category */ 2:
                    message.category = reader.string();
                    break;
                case /* string tool_name */ 3:
                    message.toolName = reader.string();
                    break;
                case /* string brand_model */ 4:
                    message.brandModel = reader.string();
                    break;
                case /* bool has_duplicates */ 5:
                    message.hasDuplicates = reader.bool();
                    break;
                case /* int32 quantity */ 6:
                    message.quantity = reader.int32();
                    break;
                case /* double price */ 7:
                    message.price = reader.double();
                    break;
                case /* string purchased_by */ 8:
                    message.purchasedBy = reader.string();
                    break;
                case /* bool is_electrical */ 9:
                    message.isElectrical = reader.bool();
                    break;
                case /* bool is_bas */ 10:
                    message.isBas = reader.bool();
                    break;
                case /* bool is_construction */ 11:
                    message.isConstruction = reader.bool();
                    break;
                case /* bool is_carpentry */ 12:
                    message.isCarpentry = reader.bool();
                    break;
                case /* bool is_vas */ 13:
                    message.isVas = reader.bool();
                    break;
                case /* bool is_plumbing */ 14:
                    message.isPlumbing = reader.bool();
                    break;
                case /* bool is_residential */ 15:
                    message.isResidential = reader.bool();
                    break;
                case /* bool is_com_light */ 16:
                    message.isComLight = reader.bool();
                    break;
                case /* bool is_com_heavy */ 17:
                    message.isComHeavy = reader.bool();
                    break;
                case /* bool is_refrigeration */ 18:
                    message.isRefrigeration = reader.bool();
                    break;
                case /* string notes */ 19:
                    message.notes = reader.string();
                    break;
                case /* string created_at */ 20:
                    message.createdAt = reader.string();
                    break;
                case /* string updated_at */ 21:
                    message.updatedAt = reader.string();
                    break;
                case /* repeated string field_mask */ 22:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 23:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 24:
                    message.withoutLimit = reader.bool();
                    break;
                case /* string hyperlink */ 25:
                    message.hyperlink = reader.string();
                    break;
                case /* string order_by */ 26:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 27:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string category = 2; */
        if (message.category !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.category);
        /* string tool_name = 3; */
        if (message.toolName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.toolName);
        /* string brand_model = 4; */
        if (message.brandModel !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brandModel);
        /* bool has_duplicates = 5; */
        if (message.hasDuplicates !== false)
            writer.tag(5, WireType.Varint).bool(message.hasDuplicates);
        /* int32 quantity = 6; */
        if (message.quantity !== 0)
            writer.tag(6, WireType.Varint).int32(message.quantity);
        /* double price = 7; */
        if (message.price !== 0)
            writer.tag(7, WireType.Bit64).double(message.price);
        /* string purchased_by = 8; */
        if (message.purchasedBy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.purchasedBy);
        /* bool is_electrical = 9; */
        if (message.isElectrical !== false)
            writer.tag(9, WireType.Varint).bool(message.isElectrical);
        /* bool is_bas = 10; */
        if (message.isBas !== false)
            writer.tag(10, WireType.Varint).bool(message.isBas);
        /* bool is_construction = 11; */
        if (message.isConstruction !== false)
            writer.tag(11, WireType.Varint).bool(message.isConstruction);
        /* bool is_carpentry = 12; */
        if (message.isCarpentry !== false)
            writer.tag(12, WireType.Varint).bool(message.isCarpentry);
        /* bool is_vas = 13; */
        if (message.isVas !== false)
            writer.tag(13, WireType.Varint).bool(message.isVas);
        /* bool is_plumbing = 14; */
        if (message.isPlumbing !== false)
            writer.tag(14, WireType.Varint).bool(message.isPlumbing);
        /* bool is_residential = 15; */
        if (message.isResidential !== false)
            writer.tag(15, WireType.Varint).bool(message.isResidential);
        /* bool is_com_light = 16; */
        if (message.isComLight !== false)
            writer.tag(16, WireType.Varint).bool(message.isComLight);
        /* bool is_com_heavy = 17; */
        if (message.isComHeavy !== false)
            writer.tag(17, WireType.Varint).bool(message.isComHeavy);
        /* bool is_refrigeration = 18; */
        if (message.isRefrigeration !== false)
            writer.tag(18, WireType.Varint).bool(message.isRefrigeration);
        /* string notes = 19; */
        if (message.notes !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.notes);
        /* string created_at = 20; */
        if (message.createdAt !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.createdAt);
        /* string updated_at = 21; */
        if (message.updatedAt !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.updatedAt);
        /* repeated string field_mask = 22; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(22, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 23; */
        if (message.pageNumber !== 0)
            writer.tag(23, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 24; */
        if (message.withoutLimit !== false)
            writer.tag(24, WireType.Varint).bool(message.withoutLimit);
        /* string hyperlink = 25; */
        if (message.hyperlink !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.hyperlink);
        /* string order_by = 26; */
        if (message.orderBy !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 27; */
        if (message.orderDir !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ApprovedTool
 */
export const ApprovedTool = new ApprovedTool$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundTransaction$Type extends MessageType {
    constructor() {
        super("ToolFundTransaction", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "asset_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "transaction_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "created_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "include_user", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "include_asset", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "include_creator", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "transaction_type_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "transaction_type_is_deduction", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "user_trade_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, assetId: 0, amount: 0, dateCreated: "", isActive: false, transactionTypeId: 0, notes: "", createdBy: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", includeUser: false, includeAsset: false, includeCreator: false, transactionTypeName: "", transactionTypeIsDeduction: false, userTradeTypeId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 asset_id */ 3:
                    message.assetId = reader.int32();
                    break;
                case /* double amount */ 4:
                    message.amount = reader.double();
                    break;
                case /* string date_created */ 5:
                    message.dateCreated = reader.string();
                    break;
                case /* bool is_active */ 6:
                    message.isActive = reader.bool();
                    break;
                case /* int32 transaction_type_id */ 7:
                    message.transactionTypeId = reader.int32();
                    break;
                case /* string notes */ 8:
                    message.notes = reader.string();
                    break;
                case /* int32 created_by */ 9:
                    message.createdBy = reader.int32();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 11:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 12:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 13:
                    message.orderDir = reader.string();
                    break;
                case /* bool include_user */ 14:
                    message.includeUser = reader.bool();
                    break;
                case /* bool include_asset */ 15:
                    message.includeAsset = reader.bool();
                    break;
                case /* bool include_creator */ 16:
                    message.includeCreator = reader.bool();
                    break;
                case /* string transaction_type_name */ 17:
                    message.transactionTypeName = reader.string();
                    break;
                case /* bool transaction_type_is_deduction */ 18:
                    message.transactionTypeIsDeduction = reader.bool();
                    break;
                case /* int32 user_trade_type_id */ 19:
                    message.userTradeTypeId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 asset_id = 3; */
        if (message.assetId !== 0)
            writer.tag(3, WireType.Varint).int32(message.assetId);
        /* double amount = 4; */
        if (message.amount !== 0)
            writer.tag(4, WireType.Bit64).double(message.amount);
        /* string date_created = 5; */
        if (message.dateCreated !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dateCreated);
        /* bool is_active = 6; */
        if (message.isActive !== false)
            writer.tag(6, WireType.Varint).bool(message.isActive);
        /* int32 transaction_type_id = 7; */
        if (message.transactionTypeId !== 0)
            writer.tag(7, WireType.Varint).int32(message.transactionTypeId);
        /* string notes = 8; */
        if (message.notes !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.notes);
        /* int32 created_by = 9; */
        if (message.createdBy !== 0)
            writer.tag(9, WireType.Varint).int32(message.createdBy);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 11; */
        if (message.pageNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 12; */
        if (message.orderBy !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 13; */
        if (message.orderDir !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.orderDir);
        /* bool include_user = 14; */
        if (message.includeUser !== false)
            writer.tag(14, WireType.Varint).bool(message.includeUser);
        /* bool include_asset = 15; */
        if (message.includeAsset !== false)
            writer.tag(15, WireType.Varint).bool(message.includeAsset);
        /* bool include_creator = 16; */
        if (message.includeCreator !== false)
            writer.tag(16, WireType.Varint).bool(message.includeCreator);
        /* string transaction_type_name = 17; */
        if (message.transactionTypeName !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.transactionTypeName);
        /* bool transaction_type_is_deduction = 18; */
        if (message.transactionTypeIsDeduction !== false)
            writer.tag(18, WireType.Varint).bool(message.transactionTypeIsDeduction);
        /* int32 user_trade_type_id = 19; */
        if (message.userTradeTypeId !== 0)
            writer.tag(19, WireType.Varint).int32(message.userTradeTypeId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundTransaction
 */
export const ToolFundTransaction = new ToolFundTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundTransactionList$Type extends MessageType {
    constructor() {
        super("ToolFundTransactionList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ToolFundTransaction },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ToolFundTransaction results */ 1:
                    message.results.push(ToolFundTransaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ToolFundTransaction results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ToolFundTransaction.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundTransactionList
 */
export const ToolFundTransactionList = new ToolFundTransactionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundTransactionType$Type extends MessageType {
    constructor() {
        super("ToolFundTransactionType", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "is_deduction", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", description: "", isActive: false, isDeduction: false, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* bool is_active */ 4:
                    message.isActive = reader.bool();
                    break;
                case /* bool is_deduction */ 5:
                    message.isDeduction = reader.bool();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 8:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 9:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* bool is_active = 4; */
        if (message.isActive !== false)
            writer.tag(4, WireType.Varint).bool(message.isActive);
        /* bool is_deduction = 5; */
        if (message.isDeduction !== false)
            writer.tag(5, WireType.Varint).bool(message.isDeduction);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 8; */
        if (message.orderBy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 9; */
        if (message.orderDir !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundTransactionType
 */
export const ToolFundTransactionType = new ToolFundTransactionType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundTransactionTypeList$Type extends MessageType {
    constructor() {
        super("ToolFundTransactionTypeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ToolFundTransactionType },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ToolFundTransactionType results */ 1:
                    message.results.push(ToolFundTransactionType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ToolFundTransactionType results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ToolFundTransactionType.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundTransactionTypeList
 */
export const ToolFundTransactionTypeList = new ToolFundTransactionTypeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TradeTypeSpending$Type extends MessageType {
    constructor() {
        super("TradeTypeSpending", [
            { no: 1, name: "trade_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "trade_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount_spent", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value) {
        const message = { tradeTypeId: 0, tradeName: "", amountSpent: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 trade_type_id */ 1:
                    message.tradeTypeId = reader.int32();
                    break;
                case /* string trade_name */ 2:
                    message.tradeName = reader.string();
                    break;
                case /* double amount_spent */ 3:
                    message.amountSpent = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 trade_type_id = 1; */
        if (message.tradeTypeId !== 0)
            writer.tag(1, WireType.Varint).int32(message.tradeTypeId);
        /* string trade_name = 2; */
        if (message.tradeName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tradeName);
        /* double amount_spent = 3; */
        if (message.amountSpent !== 0)
            writer.tag(3, WireType.Bit64).double(message.amountSpent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TradeTypeSpending
 */
export const TradeTypeSpending = new TradeTypeSpending$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolFundDashboardMetrics$Type extends MessageType {
    constructor() {
        super("ToolFundDashboardMetrics", [
            { no: 1, name: "total_funding", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "total_spent", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "remaining_balance", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "trade_type_spending", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TradeTypeSpending }
        ]);
    }
    create(value) {
        const message = { totalFunding: 0, totalSpent: 0, remainingBalance: 0, tradeTypeSpending: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double total_funding */ 1:
                    message.totalFunding = reader.double();
                    break;
                case /* double total_spent */ 2:
                    message.totalSpent = reader.double();
                    break;
                case /* double remaining_balance */ 3:
                    message.remainingBalance = reader.double();
                    break;
                case /* repeated TradeTypeSpending trade_type_spending */ 4:
                    message.tradeTypeSpending.push(TradeTypeSpending.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* double total_funding = 1; */
        if (message.totalFunding !== 0)
            writer.tag(1, WireType.Bit64).double(message.totalFunding);
        /* double total_spent = 2; */
        if (message.totalSpent !== 0)
            writer.tag(2, WireType.Bit64).double(message.totalSpent);
        /* double remaining_balance = 3; */
        if (message.remainingBalance !== 0)
            writer.tag(3, WireType.Bit64).double(message.remainingBalance);
        /* repeated TradeTypeSpending trade_type_spending = 4; */
        for (let i = 0; i < message.tradeTypeSpending.length; i++)
            TradeTypeSpending.internalBinaryWrite(message.tradeTypeSpending[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolFundDashboardMetrics
 */
export const ToolFundDashboardMetrics = new ToolFundDashboardMetrics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PendingRequisitionCount$Type extends MessageType {
    constructor() {
        super("PendingRequisitionCount", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { count: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 count */ 1:
                    message.count = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int32(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PendingRequisitionCount
 */
export const PendingRequisitionCount = new PendingRequisitionCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CatalogItemCategory$Type extends MessageType {
    constructor() {
        super("CatalogItemCategory", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", description: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 6:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 7:
                    message.orderDir = reader.string();
                    break;
                case /* bool without_limit */ 8:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 6; */
        if (message.orderBy !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 7; */
        if (message.orderDir !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.orderDir);
        /* bool without_limit = 8; */
        if (message.withoutLimit !== false)
            writer.tag(8, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CatalogItemCategory
 */
export const CatalogItemCategory = new CatalogItemCategory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CatalogItemCategoryList$Type extends MessageType {
    constructor() {
        super("CatalogItemCategoryList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CatalogItemCategory },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CatalogItemCategory results */ 1:
                    message.results.push(CatalogItemCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CatalogItemCategory results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CatalogItemCategory.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CatalogItemCategoryList
 */
export const CatalogItemCategoryList = new CatalogItemCategoryList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequisitionActivity$Type extends MessageType {
    constructor() {
        super("RequisitionActivity", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "requisition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, requisitionId: 0, userId: 0, statusId: 0, description: "", timestamp: "", isActive: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 requisition_id */ 2:
                    message.requisitionId = reader.int32();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* int32 status_id */ 4:
                    message.statusId = reader.int32();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* string timestamp */ 6:
                    message.timestamp = reader.string();
                    break;
                case /* int32 is_active */ 7:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 requisition_id = 2; */
        if (message.requisitionId !== 0)
            writer.tag(2, WireType.Varint).int32(message.requisitionId);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* int32 status_id = 4; */
        if (message.statusId !== 0)
            writer.tag(4, WireType.Varint).int32(message.statusId);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* string timestamp = 6; */
        if (message.timestamp !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timestamp);
        /* int32 is_active = 7; */
        if (message.isActive !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RequisitionActivity
 */
export const RequisitionActivity = new RequisitionActivity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequisitionActivityList$Type extends MessageType {
    constructor() {
        super("RequisitionActivityList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RequisitionActivity },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated RequisitionActivity results */ 1:
                    message.results.push(RequisitionActivity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated RequisitionActivity results = 1; */
        for (let i = 0; i < message.results.length; i++)
            RequisitionActivity.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RequisitionActivityList
 */
export const RequisitionActivityList = new RequisitionActivityList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequisitionFulfillmentItem$Type extends MessageType {
    constructor() {
        super("RequisitionFulfillmentItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "requisition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "requisition_item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "fulfilled_by_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "catalog_item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "actual_item_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "actual_item_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "quantity_fulfilled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "actual_cost", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "fulfillment_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "supplier_info", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "updated_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "actual_delivery_location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "asset_tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, requisitionId: 0, requisitionItemId: 0, fulfilledByUserId: 0, catalogItemId: 0, actualItemName: "", actualItemDescription: "", quantityFulfilled: 0, actualCost: 0, fulfillmentDate: "", supplierInfo: "", notes: "", createdAt: "", updatedAt: "", isActive: false, fileId: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", actualDeliveryLocation: "", assetTag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 requisition_id */ 2:
                    message.requisitionId = reader.int32();
                    break;
                case /* int32 requisition_item_id */ 3:
                    message.requisitionItemId = reader.int32();
                    break;
                case /* int32 fulfilled_by_user_id */ 4:
                    message.fulfilledByUserId = reader.int32();
                    break;
                case /* int32 catalog_item_id */ 5:
                    message.catalogItemId = reader.int32();
                    break;
                case /* string actual_item_name */ 6:
                    message.actualItemName = reader.string();
                    break;
                case /* string actual_item_description */ 7:
                    message.actualItemDescription = reader.string();
                    break;
                case /* int32 quantity_fulfilled */ 8:
                    message.quantityFulfilled = reader.int32();
                    break;
                case /* double actual_cost */ 9:
                    message.actualCost = reader.double();
                    break;
                case /* string fulfillment_date */ 10:
                    message.fulfillmentDate = reader.string();
                    break;
                case /* string supplier_info */ 11:
                    message.supplierInfo = reader.string();
                    break;
                case /* string notes */ 12:
                    message.notes = reader.string();
                    break;
                case /* string created_at */ 13:
                    message.createdAt = reader.string();
                    break;
                case /* string updated_at */ 14:
                    message.updatedAt = reader.string();
                    break;
                case /* bool is_active */ 15:
                    message.isActive = reader.bool();
                    break;
                case /* int32 file_id */ 20:
                    message.fileId = reader.int32();
                    break;
                case /* repeated string field_mask */ 21:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 22:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 23:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 24:
                    message.orderDir = reader.string();
                    break;
                case /* string actual_delivery_location */ 25:
                    message.actualDeliveryLocation = reader.string();
                    break;
                case /* string asset_tag */ 26:
                    message.assetTag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 requisition_id = 2; */
        if (message.requisitionId !== 0)
            writer.tag(2, WireType.Varint).int32(message.requisitionId);
        /* int32 requisition_item_id = 3; */
        if (message.requisitionItemId !== 0)
            writer.tag(3, WireType.Varint).int32(message.requisitionItemId);
        /* int32 fulfilled_by_user_id = 4; */
        if (message.fulfilledByUserId !== 0)
            writer.tag(4, WireType.Varint).int32(message.fulfilledByUserId);
        /* int32 catalog_item_id = 5; */
        if (message.catalogItemId !== 0)
            writer.tag(5, WireType.Varint).int32(message.catalogItemId);
        /* string actual_item_name = 6; */
        if (message.actualItemName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.actualItemName);
        /* string actual_item_description = 7; */
        if (message.actualItemDescription !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.actualItemDescription);
        /* int32 quantity_fulfilled = 8; */
        if (message.quantityFulfilled !== 0)
            writer.tag(8, WireType.Varint).int32(message.quantityFulfilled);
        /* double actual_cost = 9; */
        if (message.actualCost !== 0)
            writer.tag(9, WireType.Bit64).double(message.actualCost);
        /* string fulfillment_date = 10; */
        if (message.fulfillmentDate !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.fulfillmentDate);
        /* string supplier_info = 11; */
        if (message.supplierInfo !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.supplierInfo);
        /* string notes = 12; */
        if (message.notes !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.notes);
        /* string created_at = 13; */
        if (message.createdAt !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.createdAt);
        /* string updated_at = 14; */
        if (message.updatedAt !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.updatedAt);
        /* bool is_active = 15; */
        if (message.isActive !== false)
            writer.tag(15, WireType.Varint).bool(message.isActive);
        /* int32 file_id = 20; */
        if (message.fileId !== 0)
            writer.tag(20, WireType.Varint).int32(message.fileId);
        /* repeated string field_mask = 21; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 22; */
        if (message.pageNumber !== 0)
            writer.tag(22, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 23; */
        if (message.orderBy !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 24; */
        if (message.orderDir !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.orderDir);
        /* string actual_delivery_location = 25; */
        if (message.actualDeliveryLocation !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.actualDeliveryLocation);
        /* string asset_tag = 26; */
        if (message.assetTag !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.assetTag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RequisitionFulfillmentItem
 */
export const RequisitionFulfillmentItem = new RequisitionFulfillmentItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequisitionFulfillmentItemList$Type extends MessageType {
    constructor() {
        super("RequisitionFulfillmentItemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RequisitionFulfillmentItem },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated RequisitionFulfillmentItem results */ 1:
                    message.results.push(RequisitionFulfillmentItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated RequisitionFulfillmentItem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            RequisitionFulfillmentItem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RequisitionFulfillmentItemList
 */
export const RequisitionFulfillmentItemList = new RequisitionFulfillmentItemList$Type();
/**
 * @generated ServiceType for protobuf service InventoryService
 */
export const InventoryService = new ServiceType("InventoryService", [
    { name: "CreateCatalogItem", options: {}, I: CatalogItem, O: CatalogItem },
    { name: "GetCatalogItem", options: {}, I: CatalogItem, O: CatalogItem },
    { name: "BatchGetCatalogItem", options: {}, I: CatalogItem, O: CatalogItemList },
    { name: "UpdateCatalogItem", options: {}, I: CatalogItem, O: CatalogItem },
    { name: "DeleteCatalogItem", options: {}, I: CatalogItem, O: Empty },
    { name: "CreateStock", options: {}, I: Stock, O: Stock },
    { name: "GetStock", options: {}, I: Stock, O: Stock },
    { name: "BatchGetStock", options: {}, I: Stock, O: StockList },
    { name: "UpdateStock", options: {}, I: Stock, O: Stock },
    { name: "DeleteStock", options: {}, I: Stock, O: Empty },
    { name: "CreateRequisition", options: {}, I: Requisition, O: Requisition },
    { name: "GetRequisition", options: {}, I: Requisition, O: Requisition },
    { name: "BatchGetRequisition", options: {}, I: Requisition, O: RequisitionList },
    { name: "UpdateRequisition", options: {}, I: Requisition, O: Requisition },
    { name: "DeleteRequisition", options: {}, I: Requisition, O: Empty },
    { name: "GetPendingRequisitionCount", options: {}, I: Empty, O: Int32 },
    { name: "BatchGetRequisitionItem", options: {}, I: RequisitionItem, O: RequisitionItemList },
    { name: "GetUserToolFundBalance", options: {}, I: User, O: ToolFundBalance },
    { name: "GetUserToolAssets", options: {}, I: User, O: AssetInstanceList },
    { name: "CreateToolFundRequisition", options: {}, I: ToolFundRequisitionRequest, O: Requisition },
    { name: "GetToolFundHistory", options: {}, I: ToolFundHistoryRequest, O: ToolFundHistoryResponse },
    { name: "TransferToolAsset", options: {}, I: TransferAssetRequest, O: AssetInstance },
    { name: "UploadApprovedTools", options: {}, I: UploadApprovedToolsRequest, O: UploadApprovedToolsResponse },
    { name: "GetImportStatus", options: {}, I: GetImportStatusRequest, O: GetImportStatusResponse },
    { name: "CreateToolFundTransaction", options: {}, I: ToolFundTransaction, O: ToolFundTransaction },
    { name: "GetToolFundTransaction", options: {}, I: ToolFundTransaction, O: ToolFundTransaction },
    { name: "BatchGetToolFundTransaction", options: {}, I: ToolFundTransaction, O: ToolFundTransactionList },
    { name: "UpdateToolFundTransaction", options: {}, I: ToolFundTransaction, O: ToolFundTransaction },
    { name: "DeleteToolFundTransaction", options: {}, I: ToolFundTransaction, O: Empty },
    { name: "CreateTransactionType", options: {}, I: ToolFundTransactionType, O: ToolFundTransactionType },
    { name: "GetTransactionType", options: {}, I: ToolFundTransactionType, O: ToolFundTransactionType },
    { name: "BatchGetTransactionType", options: {}, I: ToolFundTransactionType, O: ToolFundTransactionTypeList },
    { name: "UpdateTransactionType", options: {}, I: ToolFundTransactionType, O: ToolFundTransactionType },
    { name: "DeleteTransactionType", options: {}, I: ToolFundTransactionType, O: Empty },
    { name: "CreateTradeType", options: {}, I: TradeType, O: TradeType },
    { name: "GetTradeType", options: {}, I: TradeType, O: TradeType },
    { name: "BatchGetTradeType", options: {}, I: TradeType, O: TradeTypeList },
    { name: "UpdateTradeType", options: {}, I: TradeType, O: TradeType },
    { name: "DeleteTradeType", options: {}, I: TradeType, O: Empty },
    { name: "GetToolFundDashboardMetrics", options: {}, I: Empty, O: ToolFundDashboardMetrics },
    { name: "GetToolFundSettings", options: {}, I: ToolFundSettings, O: ToolFundSettings },
    { name: "UpdateToolFundSettings", options: {}, I: ToolFundSettings, O: ToolFundSettings },
    { name: "CreateToolFundSettings", options: {}, I: ToolFundSettings, O: ToolFundSettings },
    { name: "GetCatalogItemCategory", options: {}, I: CatalogItemCategory, O: CatalogItemCategory },
    { name: "BatchGetCatalogItemCategory", options: {}, I: CatalogItemCategory, O: CatalogItemCategoryList },
    { name: "CreateAssetInstance", options: {}, I: AssetInstance, O: AssetInstance },
    { name: "GetAssetInstance", options: {}, I: AssetInstance, O: AssetInstance },
    { name: "BatchGetAssetInstance", options: {}, I: AssetInstance, O: AssetInstanceList },
    { name: "UpdateAssetInstance", options: {}, I: AssetInstance, O: AssetInstance },
    { name: "DeleteAssetInstance", options: {}, I: AssetInstance, O: Empty },
    { name: "CreateRequisitionActivity", options: {}, I: RequisitionActivity, O: RequisitionActivity },
    { name: "BatchGetRequisitionActivity", options: {}, I: RequisitionActivity, O: RequisitionActivityList },
    { name: "UpdateRequisitionActivity", options: {}, I: RequisitionActivity, O: RequisitionActivity },
    { name: "CreateRequisitionFulfillmentItem", options: {}, I: RequisitionFulfillmentItem, O: RequisitionFulfillmentItem },
    { name: "GetRequisitionFulfillmentItem", options: {}, I: RequisitionFulfillmentItem, O: RequisitionFulfillmentItem },
    { name: "BatchGetRequisitionFulfillmentItem", options: {}, I: RequisitionFulfillmentItem, O: RequisitionFulfillmentItemList },
    { name: "UpdateRequisitionFulfillmentItem", options: {}, I: RequisitionFulfillmentItem, O: RequisitionFulfillmentItem },
    { name: "DeleteRequisitionFulfillmentItem", options: {}, I: RequisitionFulfillmentItem, O: Empty }
]);
