import { type Address } from './PlaceAutoCompleteNew';

export const getAddressFromAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[],
) =>
  addressComponents.reduce<Address>(
    (acc, component) => {
      const type = component.types[0];
      switch (type) {
        case 'street_number':
        case 'route':
          acc.streetAddress += ` ${component.long_name}`;
          break;
        case 'locality':
          acc.city = component.long_name;
          break;
        case 'administrative_area_level_1':
          acc.state = component.short_name;
          break;
        case 'country':
          acc.country = component.long_name;
          break;
        case 'postal_code':
          acc.postalCode = component.long_name;
          break;
      }
      return acc;
    },
    { streetAddress: '', city: '', state: '', country: '', postalCode: '' },
  );
