import { zodResolver } from '@hookform/resolvers/zod';
import {
  CatalogItem,
  type CatalogItemCategory,
  Requisition,
  RequisitionItem,
  User,
} from '@kalos/kalos-rpc';
import { ToolFundSettings } from '@kalos/kalos-rpc';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  cn,
  Combobox,
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@kalos/ui';
import { addWeeks, format } from 'date-fns';
import { X } from 'lucide-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { BusinessAutocompleteInput } from '../../../components/BusinessAutocompleteInput';
import { useAuth } from '../../../context/AuthContext';
import { useToolFundCatalogItemCategoryQuery } from '../../../hooks/react-query/useToolFundCatalogItemCategoryQuery';
import { useToolFundCatalogQuery } from '../../../hooks/react-query/useToolFundCatalogQuery';
import { useBatchGetRequisitionQuery } from '../../../hooks/react-query/useToolFundQuery';
import {
  useGetToolFundSettings,
  useUserToolFundBalanceQuery,
} from '../../../hooks/react-query/useToolFundSettingsQuery';

const requisitionFormSchema = z
  .object({
    categoryId: z.number({
      required_error: 'Please select a category',
    }),
    catalogItemId: z.number({
      required_error: 'Please select a tool from the catalog',
    }),
    notes: z.string().optional(),
    deliveryMethod: z.string({
      required_error: 'Please select a delivery method',
    }),
    confirmedDeliveryAddress: z
      .object({
        placeId: z.string(),
        name: z.string(),
        address: z.string(),
      })
      .optional(),
    requestDate: z.date({
      required_error: 'Please select when you need the tool',
    }),
  })
  .refine(
    (data) => {
      if (data.deliveryMethod === 'other') {
        return !!data.confirmedDeliveryAddress?.placeId;
      }
      return true;
    },
    {
      message: 'Please select a valid delivery address from the dropdown',
      path: ['confirmedDeliveryAddress'],
    },
  );

type RequisitionFormData = z.infer<typeof requisitionFormSchema>;

interface RequisitionFormProps {
  userId: number;
  onSubmit: (requisition: Requisition) => void;
  ignoreTradeRestriction?: boolean;
  isSubmitting?: boolean;
}

export const RequisitionForm = ({
  userId,
  onSubmit,
  ignoreTradeRestriction = false,
  isSubmitting = false,
}: RequisitionFormProps) => {
  const { user } = useAuth();

  const { data: userSettings } = useGetToolFundSettings(ToolFundSettings.create({ userId }));

  const form = useForm<RequisitionFormData>({
    resolver: zodResolver(requisitionFormSchema),
    defaultValues: {
      notes: '',
      deliveryMethod: 'warehouse',
      requestDate: addWeeks(new Date(), 2),
    },
  });

  const { data: categories } = useToolFundCatalogItemCategoryQuery();

  const selectedCategoryId = form.watch('categoryId');
  const isCatalogItemsQueryEnabled = !!selectedCategoryId;
  const catalogItemsQuery = useToolFundCatalogQuery({
    filter: CatalogItem.create({
      orderBy: 'description',
      orderDir: 'ASC',
      pageNumber: -1,
      categoryId: selectedCategoryId,
      tradeTypeIdList: !ignoreTradeRestriction ? userSettings?.tradeTypeId.toString() : undefined,
    }),
    enabled: isCatalogItemsQueryEnabled,
    select(data) {
      return data.results.map((item) => ({
        label: `${item.name}-${item.brand} ($${item.cost.toFixed(2)})`,
        value: item.id.toString(),
        original: item,
      }));
    },
  });

  const isCatalogItemsQueryPending = catalogItemsQuery.isPending && isCatalogItemsQueryEnabled;

  const { data: balance } = useUserToolFundBalanceQuery(User.create({ id: userId }));

  const { data: allPendingRequests } = useBatchGetRequisitionQuery({
    filters: {
      userId,
      statusId: 1,
      pageNumber: -1,
    },
  });

  const pendingCost = useMemo(() => {
    return (
      allPendingRequests?.results?.reduce(
        (total, req) => total + (req.totalEstimatedCost || 0),
        0,
      ) ?? 0
    );
  }, [allPendingRequests]);

  const netBalance = useMemo(() => {
    return (balance?.currentBalance ?? 0) - pendingCost;
  }, [balance?.currentBalance, pendingCost]);

  const categoryOptions = useMemo(() => {
    return (
      categories?.results?.map((category: CatalogItemCategory) => ({
        label: category.name,
        value: category.id.toString(),
      })) ?? []
    );
  }, [categories]);

  const selectedItemId = form.watch('catalogItemId');
  const selectedItem = useMemo(
    () => catalogItemsQuery.data?.find((item) => Number(item.value) === selectedItemId)?.original,
    [catalogItemsQuery.data, selectedItemId],
  );

  const totalCost = useMemo(() => (selectedItem ? selectedItem.cost : 0), [selectedItem]);

  const exceedsBalance = useMemo(() => netBalance < totalCost, [netBalance, totalCost]);

  const deliveryMethod = form.watch('deliveryMethod');
  const confirmedDeliveryAddress = form.watch('confirmedDeliveryAddress');

  const isSubmitDisabled = useMemo(() => {
    return (
      !selectedCategoryId ||
      !selectedItemId ||
      exceedsBalance ||
      (deliveryMethod === 'other' && !confirmedDeliveryAddress?.placeId)
    );
  }, [
    selectedCategoryId,
    selectedItemId,
    exceedsBalance,
    deliveryMethod,
    confirmedDeliveryAddress,
  ]);

  const formatDeliveryAddress = useCallback(
    (method: string, customAddress?: { name: string; address: string }) => {
      let homeAddress: string;

      switch (method) {
        case 'other':
          if (!customAddress) return '';
          return `(${customAddress.name}), ${customAddress.address}`;

        case 'home':
          homeAddress = `${user.address}, ${user.city}, ${user.state} ${user.zip}`;
          return `(Home Address), ${homeAddress}`;

        case 'warehouse':
          return '(Warehouse), 236 E Taylor Road, Kaufman, TX 75142';

        default:
          return '';
      }
    },
    [user],
  );

  const handleSubmit = useCallback(
    (data: RequisitionFormData) => {
      if (exceedsBalance) {
        return;
      }

      const deliveryAddress = formatDeliveryAddress(
        data.deliveryMethod,
        data.confirmedDeliveryAddress
          ? {
              name: data.confirmedDeliveryAddress.name,
              address: data.confirmedDeliveryAddress.address,
            }
          : undefined,
      );

      const requisitionItem = RequisitionItem.create({
        catalogItemId: data.catalogItemId,
        quantityRequested: 1,
        estimatedCost: selectedItem?.cost || 0,
        customItemName: selectedItem?.name || '',
        requestedDeliveryLocation: deliveryAddress,
      });

      const requisition = Requisition.create({
        userId,
        statusId: 1,
        requisitionTypeId: 1,
        items: [requisitionItem],
        requestDate: format(data.requestDate, 'yyyy-MM-dd HH:mm:ss'),
      });

      onSubmit(requisition);
    },
    [exceedsBalance, onSubmit, userId, selectedItem, formatDeliveryAddress],
  );

  const newBalance = useMemo(() => {
    return netBalance - totalCost;
  }, [netBalance, totalCost]);

  useEffect(() => {
    // Fix Radix Dialog pointer events issue
    setTimeout(() => (document.body.style.pointerEvents = ''), 0);
  }, []);

  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      setTimeout(() => {
        const element = event.target as HTMLElement;
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    };

    const inputs = document.querySelectorAll('input');
    inputs.forEach((input) => {
      input.addEventListener('focus', handleFocus);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('focus', handleFocus);
      });
    };
  }, []);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="max-h-[calc(100vh-2rem)] space-y-6 overflow-y-auto"
      >
        <Card>
          <CardHeader>
            <CardTitle>Select Tool</CardTitle>
          </CardHeader>
          <CardContent className="grid grid-cols-1 items-center gap-4 md:grid-cols-2">
            <FormField
              control={form.control}
              name="categoryId"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Select Category</FormLabel>
                  <Combobox
                    value={field.value?.toString()}
                    onChange={(value) => field.onChange(Number(value))}
                    values={categoryOptions}
                    placeholder="Select a category..."
                    disabled={isSubmitting}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="catalogItemId"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Select Tool</FormLabel>
                  <Combobox
                    value={field.value?.toString()}
                    onChange={(value) => field.onChange(Number(value))}
                    isLoading={isCatalogItemsQueryPending}
                    values={catalogItemsQuery.data ?? []}
                    placeholder={
                      catalogItemsQuery.data?.length === 0 &&
                      !ignoreTradeRestriction &&
                      userSettings?.tradeTypeId
                        ? 'No tools available for your trade'
                        : 'Search for a tool...'
                    }
                    disabled={
                      !selectedCategoryId ||
                      isSubmitting ||
                      isCatalogItemsQueryPending ||
                      field.disabled
                    }
                  />
                </FormItem>
              )}
            />
          </CardContent>
        </Card>

        {/* Section 2: Review Tool Fund Balance */}

        {/* Section 3: Delivery Method */}
        <Card>
          <CardHeader>
            <CardTitle>Select Method of Delivery</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <FormField
              control={form.control}
              name="deliveryMethod"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Delivery Method</FormLabel>
                  <FormControl>
                    <Combobox
                      value={field.value}
                      onChange={field.onChange}
                      values={[
                        { label: 'Warehouse Pickup', value: 'warehouse' },
                        { label: 'Home Delivery', value: 'home' },
                        { label: 'Other Location', value: 'other' },
                      ]}
                      placeholder="Select delivery method..."
                      disabled={isSubmitting}
                    />
                  </FormControl>
                  {field.value === 'home' && (
                    <div className="bg-muted mt-2 rounded-md border p-3">
                      <p className="font-medium">Home Address</p>
                      <p className="text-muted-foreground text-sm">
                        {`${user.address}, ${user.city}, ${user.state} ${user.zip}`}
                      </p>
                    </div>
                  )}
                  {field.value === 'warehouse' && (
                    <div className="bg-muted mt-2 rounded-md border p-3">
                      <p className="font-medium">Kalos Office</p>
                      <p className="text-muted-foreground text-sm">
                        236 Hatteras Ave, Clermont Fl, 34711
                      </p>
                    </div>
                  )}
                </FormItem>
              )}
            />

            {form.watch('deliveryMethod') === 'other' && (
              <FormField
                control={form.control}
                name="confirmedDeliveryAddress"
                render={({ field }) => (
                  <FormItem className="static-on-mobile relative">
                    <FormLabel>Delivery Address</FormLabel>
                    <FormControl>
                      <div className="space-y-2">
                        <div className="flex gap-2">
                          <div className="flex-1">
                            <BusinessAutocompleteInput
                              onChange={(place) => {
                                field.onChange(
                                  place
                                    ? {
                                        placeId: place.placeId,
                                        name: place.name,
                                        address: place.address,
                                      }
                                    : undefined,
                                );
                              }}
                              value={field.value || null}
                              disabled={isSubmitting}
                            />
                          </div>
                          {field.value && (
                            <Button
                              variant="ghost"
                              size="icon"
                              type="button"
                              onClick={() => field.onChange(undefined)}
                            >
                              <X className="h-4 w-4" />
                            </Button>
                          )}
                        </div>
                        {field.value && (
                          <div className="bg-muted rounded-md border p-3">
                            <p className="font-medium">{field.value.name}</p>
                            <p className="text-muted-foreground text-sm">{field.value.address}</p>
                          </div>
                        )}
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>When do you need it?</CardTitle>
          </CardHeader>
          <CardContent>
            <FormField
              control={form.control}
              name="requestDate"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel>Needed Date</FormLabel>
                  <FormControl>
                    <DateInput
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      disabled={isSubmitting}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Review Tool Fund Balance</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div
              className={cn(
                'grid items-center',
                selectedItem
                  ? 'grid-cols-1 gap-1 md:grid-cols-5'
                  : 'grid-cols-1 gap-4 md:grid-cols-3',
              )}
            >
              <div>
                <p className="text-muted-foreground text-sm font-medium">Current Balance</p>
                <p className="text-base font-bold md:text-2xl">${netBalance.toFixed(2)}</p>
              </div>
              {selectedItem && (
                <>
                  <div className="text-lg font-bold md:text-center">-</div>
                  <div>
                    <p className="text-muted-foreground text-sm font-medium">Tool Cost</p>
                    <p className="text-base font-bold text-amber-500 md:text-2xl">
                      ${totalCost.toFixed(2)}
                    </p>
                  </div>
                  <div className="text-lg font-bold md:text-center">=</div>
                  <div>
                    <p className="text-muted-foreground text-sm font-medium">New Balance</p>
                    <p
                      className={cn(
                        'text-base font-bold md:text-2xl',
                        exceedsBalance ? 'text-red-500' : 'text-green-500',
                      )}
                    >
                      ${newBalance.toFixed(2)}
                    </p>
                  </div>
                </>
              )}
            </div>

            {exceedsBalance && (
              <Alert variant="destructive">
                <AlertTitle>Insufficient Balance</AlertTitle>
                <AlertDescription>
                  Error: The cost of this tool exceeds your current balance. Please wait for your
                  next allotment or contact your manager if this tool is critical to completing a
                  current job.
                </AlertDescription>
              </Alert>
            )}
          </CardContent>
        </Card>
        <div className="flex justify-end space-x-4">
          <Button
            type="submit"
            disabled={isSubmitDisabled || isSubmitting}
            isLoading={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Request'}
          </Button>
        </div>
      </form>
    </Form>
  );
};
