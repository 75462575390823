import { BaseClient } from '../BaseClient';
import { type DateRange, type Int32, String$ } from '../compiled-protos/common';
import {
  BillingAudit,
  BillingAuditReport,
  CostItem,
  CostItemReport,
  DailyJobReport,
  EmployeeKpiRequest,
  InvoiceJournalEntry,
  InvoiceJournalReport,
  JobHoursEmployee,
  JobHoursEmployeeReport,
  ProcessedReport,
  ProcessedReportLine,
  ProcessedReportRequest,
  PromptPaymentDetailsResponse,
  type PromptPaymentListFinalized
  ,
  PromptPaymentListFinalizedResponse,
  PromptPaymentReport,
  PromptPaymentReportLine,
  PromptPaymentSummaryRequest,
  PromptPaymentSummaryResponse,
  ReceiptJournalReport,
  ReceiptJournalReportLine,
  Report,
  ReportFile,
  ReportFileList,
  SalesJournalReport,
  SalesJournalReportLine,
  SpiffReport,
  SpiffReportLine,
  TimeOffMetric,
  TimeOffMetricRequest,
  TimeoffReport,
  TimeoffReportLine,
  TimeoffReportRequest,
  TimesheetAuditReport,
  TimesheetAuditReportCollection,
  TransactionDumpReport,
  TransactionReportLine,
  WeeklyEmployeeReportRequest,
} from '../compiled-protos/reports';
import { ReportServiceClient } from '../compiled-protos/reports.client';
class ReportClient extends BaseClient {
  self: ReportServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ReportServiceClient(this.transport);
  }

  public async GetPromptPaymentData(req: PromptPaymentReportLine) {
    let res = PromptPaymentReport.create();
    try {
      res = await this.self.getPromptPaymentData(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async GetSpiffReportData(req: SpiffReportLine) {
    let res = SpiffReport.create();
    try {
      res = await this.self.getSpiffReportData(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async GetTransactionDumpData(req: TransactionReportLine) {
    let res = TransactionDumpReport.create();
    try {
      res = await this.self.getTransactionDumpData(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async GetTimeOffReport(req: TimeoffReportRequest) {
    let res = TimeoffReport.create();
    try {
      res = await this.self.getTimeoffReportData(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }
  public async GetProcessedReport(req: ProcessedReportRequest) {
    let res = ProcessedReport.create();
    try {
      res = await this.self.getProcessedReport(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;

    }
    return res;
  }
  public async GetReceiptJournalReport(req: ReceiptJournalReportLine) {
    let res = ReceiptJournalReport.create();
    try {
      res = await this.self.getReceiptJournalReport(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;

    }
    return res;
  }

  public async GetSalesJournalReport(req: SalesJournalReportLine) {
    let res = SalesJournalReport.create();
    try {
      res = await this.self.getSalesJournalReport(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;

    }
    return res;
  }
  public async GetCostItemReport(req: DateRange) {
    let res = CostItemReport.create();
    try {
      res = await this.self.getCostItemReport(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetBillingAuditReport(req: DateRange) {
    let res = BillingAuditReport.create();
    try {
      res = await this.self.getBillingAuditReport(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDailyJobReport(req: Int32) {
    let res = DailyJobReport.create();
    try {
      res = await this.self.getDailyJobReport(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetJobHoursEmployeeReport(req: DateRange) {
    let res = JobHoursEmployeeReport.create();
    try {
      res = await this.self.getJobHoursEmployeeReport(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetInvoiceJournalReport(req: DateRange) {
    let res = InvoiceJournalReport.create();
    try {
      res = await this.self.getInvoiceJournalReport(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetPromptPaymentSummary(req: PromptPaymentSummaryRequest) {
    let res = PromptPaymentSummaryResponse.create();
    try {
      res = await this.self.getPromptPaymentSummary(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetPromptPaymentDetails(req: PromptPaymentSummaryRequest) {
    let res = PromptPaymentDetailsResponse.create();
    try {
      res = await this.self.getPromptPaymentDetails(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;

    }
    return res;
  }
  public async CreateEmailsForPromptPayment(req: PromptPaymentSummaryRequest) {
    let res = String$.create({ value: 'Failure' });
    try {
      res = await this.self.createEmailsForPromptPayment(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;

    }
    return res;
  }
  public async ParseTokenForRequest(token: string) {
    let res = PromptPaymentSummaryRequest.create();
    try {
      res = await this.self.parseTokenForRequest(
        String$.create({ value: token }),
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;

    }
    return res;
  }
  public async GetPromptPaymentListFinalized(req: PromptPaymentListFinalized) {
    let res = PromptPaymentListFinalizedResponse.create();
    try {
      res = await this.self.getPromptPaymentListFinalized(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;

    }
    return res;
  }
  public async Get(req: ReportFile) {
    let res = ReportFile.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async BatchGet(req: ReportFile) {
    let res = ReportFileList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async Create(req: Report) {
    let res = Report.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async Update(req: Report) {
    let res = ReportFile.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async GetApprovedPendingReport(req: DateRange) {
    let res = CostItemReport.create();
    try {
      res = await this.self.getAllReadyToBeProcessedItems(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async ProcessAllApprovedPendingItems(req: DateRange) {
    let res = CostItemReport.create();
    try {
      res = await this.self.processAllApproved(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async GetWeeklyEmployeeReport(req: DateRange) {
    const tempReq = WeeklyEmployeeReportRequest.create({ dateRange: req })
    let res = TimesheetAuditReportCollection.create();
    try {
      res = await this.self.getWeeklyEmployeeReport(tempReq, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async getKPIReport(req: EmployeeKpiRequest) {
    const res = await this.self.getEmployeeKPI(req, this.getMetaDataWithoutCache()).response
    return res
  }

  public async getTimeoffMetric(req: TimeOffMetricRequest) {
    const res = await this.self.getTimeOffMetric(req, this.getMetaData()).response
    return res
  }
}

export {
  SpiffReport,
  SpiffReportLine,
  PromptPaymentReport,
  PromptPaymentReportLine,
  TransactionDumpReport,
  TimeoffReport,
  TimeoffReportRequest,
  TimeoffReportLine,
  TransactionReportLine,
  EmployeeKpiRequest,
  ProcessedReport,
  ProcessedReportRequest,
  ProcessedReportLine,
  TimesheetAuditReportCollection,
  TimesheetAuditReport,
  ReportClient,
  ReceiptJournalReport,
  ReceiptJournalReportLine,
  SalesJournalReport,
  SalesJournalReportLine,
  CostItemReport,
  BillingAuditReport,
  BillingAudit,
  TimeOffMetricRequest,
  TimeOffMetric,
  InvoiceJournalEntry,
  InvoiceJournalReport,
  JobHoursEmployee,
  JobHoursEmployeeReport,
  CostItem,
  Report,
  ReportFile,
  ReportFileList,
};
