import { zodResolver } from '@hookform/resolvers/zod';
import { type MaintenanceQuestion, type Reading } from '@kalos/kalos-rpc';
import { useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';

export type ReadingFields = Pick<
  Reading,
  | 'refrigerantType'
  | 'tstatBrand'
  | 'blowerCapacitor'
  | 'blowerAmps'
  | 'returnDb'
  | 'supplyTemperature'
  | 'poolSupplyTemp'
  | 'poolReturnTemp'
  | 'ambientAirTemp'
  | 'coilStaticDrop'
  | 'returnWb'
  | 'evapTd'
  | 'tesp'
  | 'compressorAmps'
  | 'compressorCapacitor'
  | 'condensingFanAmps'
  | 'condenserFanCapacitor'
  | 'suctionPressure'
  | 'headPressure'
  | 'dischargeTemperature'
  | 'subcool'
  | 'superheat'
  | 'gasPressureIn'
  | 'gasPressureOut'
  | 'llTempDrop'
  | 'slTempDrop'
  | 'notes'
>;

export type MaintenanceQuestionFields = Pick<
  MaintenanceQuestion,
  | 'conditionRating1'
  | 'conditionNotes1'
  | 'conditionRating2'
  | 'conditionNotes2'
  | 'conditionRating3'
  | 'conditionNotes3'
  | 'thermostat'
  | 'plateform'
  | 'floatSwitch'
  | 'evaporatorCoil'
  | 'condenserCoil'
  | 'hurricanePad'
  | 'lineset'
  | 'drainLine'
  | 'gasType'
  | 'burner'
  | 'heatExchanger'
>;

export const maintenanceQuestionScheme = z.object({
  conditionRating1: z.string(),
  conditionNotes1: z.string(),
  conditionRating2: z.string(),
  conditionNotes2: z.string(),
  conditionRating3: z.string(),
  conditionNotes3: z.string(),
  thermostat: z.number(),
  plateform: z.number(),
  floatSwitch: z.number(),
  evaporatorCoil: z.number(),
  condenserCoil: z.number(),
  hurricanePad: z.number(),
  lineset: z.number(),
  drainLine: z.number(),
  gasType: z.number(),
  burner: z.number(),
  heatExchanger: z.number(),
}) satisfies ZodObjectWithModelKeys<MaintenanceQuestionFields>;

export const readingScheme = z.object({
  refrigerantType: z.string(),
  tstatBrand: z.string(),
  blowerCapacitor: z.string(),
  blowerAmps: z.string(),
  returnDb: z.string(),
  supplyTemperature: z.string(),
  poolSupplyTemp: z.string(),
  poolReturnTemp: z.string(),
  ambientAirTemp: z.string(),
  coilStaticDrop: z.string(),
  returnWb: z.string(),
  evapTd: z.string(),
  tesp: z.string(),
  compressorAmps: z.string(),
  compressorCapacitor: z.string(),
  condensingFanAmps: z.string(),
  condenserFanCapacitor: z.string(),
  suctionPressure: z.string(),
  headPressure: z.string(),
  dischargeTemperature: z.string(),
  subcool: z.string(),
  superheat: z.string(),
  gasPressureIn: z.string(),
  gasPressureOut: z.string(),
  llTempDrop: z.string(),
  slTempDrop: z.string(),
  notes: z.string(),
  images: z.array(z.instanceof(File)),
}) satisfies ZodObjectWithModelKeys<ReadingFields>;

export type ReadingSchemeType = z.infer<typeof readingScheme>;

export type MaintenanceQuestionSchemeType = z.infer<typeof maintenanceQuestionScheme>;

export const readingFormDefaultValues: () => ReadingSchemeType = () => ({
  refrigerantType: '',
  tstatBrand: '',
  blowerCapacitor: '',
  blowerAmps: '',
  returnDb: '',
  supplyTemperature: '',
  poolSupplyTemp: '',
  poolReturnTemp: '',
  ambientAirTemp: '',
  coilStaticDrop: '',
  returnWb: '',
  evapTd: '',
  tesp: '',
  compressorAmps: '',
  compressorCapacitor: '',
  condensingFanAmps: '',
  condenserFanCapacitor: '',
  suctionPressure: '',
  headPressure: '',
  dischargeTemperature: '',
  subcool: '',
  superheat: '',
  gasPressureIn: '',
  gasPressureOut: '',
  llTempDrop: '',
  slTempDrop: '',
  notes: '',
  images: [],
});

export const maintenanceQuestionFormDefaultValues: () => MaintenanceQuestionSchemeType = () => ({
  conditionRating1: '0',
  conditionNotes1: '',
  conditionRating2: '',
  conditionNotes2: '',
  conditionRating3: '',
  conditionNotes3: '',
  thermostat: 0,
  plateform: 0,
  floatSwitch: 0,
  evaporatorCoil: 0,
  condenserCoil: 0,
  hurricanePad: 0,
  lineset: 0,
  drainLine: 0,
  gasType: 0,
  burner: 0,
  heatExchanger: 0,
});

export const getReadingDefaultFields = ({
  defaultValues,
}: {
  defaultValues?: Partial<ReadingSchemeType>;
}): ReadingSchemeType => {
  return {
    ...readingFormDefaultValues(),
    ...(defaultValues || readingFormDefaultValues),
  };
};

export const getMaintenanceQuestionFormDefaultValues = ({
  defaultValues,
}: {
  defaultValues?: Partial<MaintenanceQuestionSchemeType>;
} = {}): MaintenanceQuestionSchemeType => {
  return {
    ...maintenanceQuestionFormDefaultValues(),
    ...(defaultValues || maintenanceQuestionFormDefaultValues),
  };
};

export const useReadingForm = ({
  defaultValues,
  disabled,
}: {
  defaultValues: Partial<ReadingSchemeType>;
  disabled?: boolean;
}) => {
  return useForm<ReadingSchemeType>({
    resolver: zodResolver(readingScheme),
    mode: 'onSubmit',
    defaultValues: getReadingDefaultFields({ defaultValues }),
    disabled,
  });
};

export const useMaintenanceQuestionForm = ({
  defaultValues,
  disabled,
}: {
  defaultValues: Partial<MaintenanceQuestionSchemeType>;
  disabled?: boolean;
}) => {
  return useForm<MaintenanceQuestionSchemeType>({
    resolver: zodResolver(maintenanceQuestionScheme),
    mode: 'onSubmit',
    defaultValues: getMaintenanceQuestionFormDefaultValues({ defaultValues }),
    disabled,
  });
};

export const useReadingFormContext = () => {
  return useFormContext<ReadingSchemeType>();
};
