import { File } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { FileClientService } from '../../tools/helpers';
import { queryKeys } from './constants';

export const getFileQueryConfig = ({ filter }: { filter: { id?: number } }) => ({
  queryFn: async () => {
    if (!filter.id) return null;
    const req = File.create({ id: filter.id });
    const response = await FileClientService.Get(req);
    return response;
  },
  queryKey: [queryKeys.files.root, queryKeys.files.getHash(filter)],
});

export const useFileQuery = ({
  filter,
  enabled = true,
}: {
  filter: { id?: number };
  enabled?: boolean;
}) => {
  return useQuery({
    ...getFileQueryConfig({ filter }),
    enabled: enabled && !!filter.id,
  });
};

export const useFilesBatchQuery = ({
  fileIds,
  enabled = true,
}: {
  fileIds: number[];
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [queryKeys.files.root, queryKeys.files.list, fileIds],
    queryFn: async () => {
      const files = await Promise.all(
        fileIds.map(async (id) => {
          const req = File.create({ id });
          return await FileClientService.Get(req);
        }),
      );
      return files;
    },
    enabled: enabled && fileIds.length > 0,
  });
};
