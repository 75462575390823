import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const toolFundSettingsSchema = z.object({
  id: z.number(),
  userId: z.number(),
  isEligible: z.boolean(),
  monthlyAllowance: z.number(),
  lastUpdated: z.string(),
  updatedBy: z.number(),
  tradeTypeId: z.number({
    required_error: 'Trade is required',
    invalid_type_error: 'Trade must be selected',
  }),
});

export type ToolFundSettingsFormType = z.infer<typeof toolFundSettingsSchema>;

export const useToolFundSettingsForm = ({
  defaultValues,
  disabled = false,
}: {
  defaultValues?: Partial<ToolFundSettingsFormType>;
  disabled?: boolean;
}) => {
  return useForm<ToolFundSettingsFormType>({
    resolver: zodResolver(toolFundSettingsSchema),
    mode: 'onSubmit',
    defaultValues,
    disabled,
  });
};
