import { CatalogItem, type CatalogItemList } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { InventoryClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type CatalogItemFilter = EntityFilter<CatalogItem>;

export const useToolFundCatalogQuery = <TSelectData = CatalogItemList,>({
  filter: request,
  enabled = true,
  select,
}: {
  filter: CatalogItemFilter;
  enabled?: boolean;
  select?: (data: CatalogItemList) => TSelectData;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.catalogItems.root,
      queryKeys.catalogItems.list,
      queryKeys.catalogItems.getHash(request),
    ],
    queryFn: async () => {
      return InventoryClientService.BatchGetCatalogItem(CatalogItem.create(request));
    },
    enabled,
    select,
  });
};
export const useToolFundCatalogGetQuery = ({
  filter: request,
  enabled = true,
}: {
  filter: CatalogItemFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.catalogItems.root,
      queryKeys.catalogItems.list,
      queryKeys.catalogItems.getHash(request),
    ],
    queryFn: async () => {
      return InventoryClientService.GetCatalogItem(CatalogItem.create(request));
    },
    enabled,
  });
};
