import { Document, File } from '@kalos/kalos-rpc';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useState } from 'react';

import {
  DocumentClientService,
  FileClientService,
  PDFClientService,
  timestamp,
} from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { Alert } from '../Alert';
import { Button } from '../Button';

type Area = {
  l: number;
  w: number;
  h: number;
};
type StringDimension = {
  value1: string;
  value2: string;
};
export interface Props {
  propertyId: number;
  jobNumber: number;
  onCreateDoc?: () => void;
}
type SellSheetType = {
  systemDescription: string;
  systemType: string;
  heatStrip: boolean;
  heatStripSize: string;
  condensorTonnage: string;
  AHLocation: string;
  AHDimensions: Area[];
  selectedItems: {
    thermostat: boolean;
    copperLineset: boolean;
    pad: boolean;
    returnBox: boolean;
    filterBackReturnGrill: boolean;
    outdoorHighVoltageDisconnect: boolean;
    comboSmokeDetectorCOAlarm: boolean;
    lowLevelCOMonitor: boolean;
    noneOfTheAbove: boolean;
  };
  filterLocation: string;
  filterSize: string;
  breakerType: string;
  breakerDimensions: StringDimension[];
  staticReadings: StringDimension[];
  ductType: string;
  ductSize: StringDimension[];
  zoning: string;
  zones: string;
};

type Errors = {
  systemDescription?: string;
  systemType?: string;
  selectedItems?: string;
};

const checkFormIsValid = (form: SellSheetType): Errors => {
  const errors: Errors = {};
  if (!form.systemDescription.length) errors.systemDescription = 'This field is required';
  if (form.systemType === 'Choose One') errors.systemType = 'This field is required';
  if (!Object.values(form.selectedItems).some((item) => item))
    errors.selectedItems = 'Please select any needs';

  return errors;
};

export const SellSheetResidential: React.FC<Props> = ({ propertyId, jobNumber, onCreateDoc }) => {
  const [form, setForm] = useState<SellSheetType>({
    systemDescription: '',
    systemType: 'Choose One',
    heatStrip: false,
    heatStripSize: '',
    AHLocation: 'Choose One',
    condensorTonnage: '',
    AHDimensions: [{ l: 0, w: 0, h: 0 }],
    selectedItems: {
      thermostat: false,
      copperLineset: false,
      pad: false,
      returnBox: false,
      filterBackReturnGrill: false,
      outdoorHighVoltageDisconnect: false,
      comboSmokeDetectorCOAlarm: false,
      lowLevelCOMonitor: false,
      noneOfTheAbove: false,
    },
    filterLocation: 'Choose One',
    filterSize: '',
    breakerType: 'Choose One',
    breakerDimensions: [{ value1: '', value2: '' }],
    staticReadings: [{ value1: '', value2: '' }],
    ductType: 'Choose One',
    ductSize: [{ value1: '', value2: '' }],
    zoning: 'Choose One',
    zones: 'Choose One',
  });
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});

  const createMyDocument = async (formData: SellSheetType) => {
    const selectedItems = Object.entries(formData.selectedItems)
      .filter(([key, value]) => value)
      .map((key) => {
        switch (key[0]) {
          case 'thermostat':
            return 'Thermostat';
          case 'copperLineset':
            return 'Copper Lineset';
          case 'pad':
            return 'Pad';
          case 'returnBox':
            return 'Return Box';
          case 'filterBackReturnGrill':
            return 'Filter Back Return Grill';
          case 'outdoorHighVoltageDisconnect':
            return 'Outdoor High Voltage Disconnect';
          case 'comboSmokeDetectorCOAlarm':
            return 'Combo Smoke Detector/CO Alarm';
          case 'lowLevelCOMonitor':
            return 'Low Level CO Monitor';
          case 'noneOfTheAbove':
            return '(none of the above)';
          default:
            return '';
        }
      })
      .join(', ');

    const content = `
    <div style="font-family: Arial, sans-serif; margin: 0 auto; width: 600px;">
      <h1 style="text-align: center;">AC Quote</h1>
      <table style="width: 100%; border-collapse: collapse;">
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">System Description</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.systemDescription}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">System Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.systemType}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Heat Strip</td>
          <td style="border: 1px solid #000; padding: 10px;">${
            formData.heatStrip ? 'Yes' : 'No'
          }</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Heat Strip Size</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.heatStripSize}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Condensor Tonnage</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.condensorTonnage}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">AH Location</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.AHLocation}</td>
        </tr>
        ${formData.AHDimensions.map(
          (dim, index) => `
          <tr>
            <td colspan="2" style="border: 1px solid #000; padding: 10px;">
              <h2>AH Dimension ${index + 1}</h2>
              <p>Length: ${dim.l}</p>
              <p>Width: ${dim.w}</p>
              <p>Height: ${dim.h}</p>
            </td>
          </tr>
        `,
        ).join('')}
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Filter Location</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.filterLocation}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Filter Size</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.filterSize}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Breaker Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.breakerType}</td>
        </tr>
        ${formData.breakerDimensions
          .map(
            (dim, index) => `
          <tr>
            <td colspan="2" style="border: 1px solid #000; padding: 10px;">
              <h2>Breaker Dimension ${index + 1}</h2>
              <p>Value 1: ${dim.value1}</p>
              <p>Value 2: ${dim.value2}</p>
            </td>
          </tr>
        `,
          )
          .join('')}
        ${formData.staticReadings
          .map(
            (dim, index) => `
          <tr>
            <td colspan="2" style="border: 1px solid #000; padding: 10px;">
              <h2>Static Reading ${index + 1}</h2>
              <p>Value 1: ${dim.value1}</p>
              <p>Value 2: ${dim.value2}</p>
            </td>
          </tr>
        `,
          )
          .join('')}
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Duct Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.ductType}</td>
        </tr>
        ${formData.ductSize
          .map(
            (dim, index) => `
          <tr>
            <td colspan="2" style="border: 1px solid #000; padding: 10px;">
              <h2>Duct Size ${index + 1}</h2>
              <p>Value 1: ${dim.value1}</p>
              <p>Value 2: ${dim.value2}</p>
            </td>
          </tr>
        `,
          )
          .join('')}
        <tr>
        <td style="border: 1px solid #000; padding: 10px;">Zoning</td>
        <td style="border: 1px solid #000; padding: 10px;">${formData.zoning}</td>
      </tr>
      <tr>
        <td style="border: 1px solid #000; padding: 10px;">Zones</td>
        <td style="border: 1px solid #000; padding: 10px;">${formData.zones}</td>
      </tr>
      <tr>
        <td style="border: 1px solid #000; padding: 10px;">Needs To Be Replaced</td>
        <td style="border: 1px solid #000; padding: 10px;">${selectedItems}</td>
      </tr>
    </table>
  </div>
`;

    const html = `
<!DOCTYPE html>
<html>
  <head>
    <title>Sell Sheet</title>
  </head>
  <body>
    ${content}
  </body>
</html>
`;

    // Use the PDFClientService to convert the HTML to a PDF
    const url = await PDFClientService.getUploadedHTMLUrl(
      html,
      `SellSheet-${jobNumber}-${timestamp()}.pdf`,
      'kalosdocs-prod',
    );

    return url;
  };

  const uploadDocument = async () => {
    setLoading(true);
    const errors: Errors = checkFormIsValid(form);
    setErrors(errors);
    try {
      if (!Object.keys(errors).length) {
        const url = await createMyDocument(form);
        if (url) {
          //we have to fetch the file first
          const fileRes = await FileClientService.Get(File.create({ name: url.key }));
          if (fileRes) {
            // the file was created, so we can create the document record
            await DocumentClientService.Create(
              Document.create({
                fileId: fileRes.id,
                propertyId: propertyId,
                description: fileRes.name,
                filename: fileRes.name,
              }),
            );
          }
          onCreateDoc?.();
          setMessage(`Document Successfully Created`);
        }
      }
    } catch (error) {
      console.error('Failed to create or upload document:', error);
      setLoading(false);
      setMessage(`Error with Generating document: ${error}`);
      return;
    }
    setLoading(false);
  };
  const handleAddAHDimension = () => {
    setForm({
      ...form,
      AHDimensions: [...form.AHDimensions, { l: 0, w: 0, h: 0 }],
    });
  };
  const handleRemoveAHDimension = (index: number) => {
    setForm({
      ...form,
      AHDimensions: form.AHDimensions.filter((_, i) => i !== index),
    });
  };
  const handleAddBreakerDimension = () => {
    setForm({
      ...form,
      breakerDimensions: [...form.breakerDimensions, { value1: '', value2: '' }],
    });
  };
  const handleRemoveBreakerDimension = (index: number) => {
    setForm({
      ...form,
      breakerDimensions: form.breakerDimensions.filter((_, i) => i !== index),
    });
  };
  const handleAddStaticReading = () => {
    setForm({
      ...form,
      staticReadings: [...form.staticReadings, { value1: '', value2: '' }],
    });
  };
  const handleRemoveStaticReading = (index: number) => {
    setForm({
      ...form,
      staticReadings: form.staticReadings.filter((_, i) => i !== index),
    });
  };
  const handleAddDuctSize = () => {
    setForm({
      ...form,
      ductSize: [...form.ductSize, { value1: '', value2: '' }],
    });
  };
  const handleRemoveDuctSize = (index: number) => {
    setForm({
      ...form,
      ductSize: form.ductSize.filter((_, i) => i !== index),
    });
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name as string]: value as string,
    }));
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      selectedItems: {
        ...form.selectedItems,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const handleDimensionChange =
    (index: number, key: keyof Area) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDimensions = [...form.AHDimensions];
      newDimensions[index][key] = Number(event.target.value);
      setForm({
        ...form,
        AHDimensions: newDimensions,
      });
    };

  const handleBreakerChange =
    (index: number, key: keyof StringDimension) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newBreaker = [...form.breakerDimensions];
      newBreaker[index][key] = String(event.target.value);
      setForm({
        ...form,
        breakerDimensions: newBreaker,
      });
    };

  const handleStaticReadingChange =
    (index: number, key: keyof StringDimension) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const readings = [...form.staticReadings];
      readings[index][key] = String(event.target.value);
      setForm({
        ...form,
        staticReadings: readings,
      });
    };

  const handleDuctSizeChange =
    (index: number, key: keyof StringDimension) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const ductSize = [...form.ductSize];
      ductSize[index][key] = String(event.target.value);
      setForm({
        ...form,
        ductSize: ductSize,
      });
    };

  return (
    <div>
      <form className="pt-4">
        <Grid container direction="column" alignItems="flex-start" spacing={2}>
          <Grid item>
            <TextField
              className="mt-4"
              label="System Description"
              name="systemDescription"
              value={form.systemDescription}
              onChange={handleInputChange}
              error={!!errors.systemDescription}
            />
            <p className="pt-1 text-sm text-red-500">{errors.systemDescription}</p>
          </Grid>
          <Grid item className="flex items-end gap-2">
            <div className="flex flex-col">
              <InputLabel
                id="systemType-label"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                System Type
              </InputLabel>
              <div className="flex flex-wrap gap-x-2 gap-y-4">
                <Select
                  label="System Type"
                  name="systemType"
                  value={form.systemType}
                  onChange={handleSelectChange}
                  error={!!errors.systemType}
                >
                  <MenuItem value="Choose One">Choose One</MenuItem>
                  <MenuItem value="Split System AC">Split System AC</MenuItem>
                  <MenuItem value="Split System Heat Pump">Split System Heat Pump</MenuItem>
                  <MenuItem value="Split System Gas Furnace">Split System Gas Furnace</MenuItem>
                  <MenuItem value="Package Unit AC">Package Unit AC</MenuItem>
                  <MenuItem value="Package Unit Heat Pump">Package Unit Heat Pump</MenuItem>
                  <MenuItem value="Ductless">Ductless</MenuItem>
                </Select>
              </div>
            </div>
            <p className="pt-1 text-sm text-[red]">{errors.systemType}</p>
            {form.systemType === 'Split System Gas Furnace' && (
              <TextField
                label="Heat Strip Size"
                name="heatStripSize"
                value={form.heatStripSize}
                onChange={handleInputChange}
              />
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Condensor Tonnage"
              name="condensorTonnage"
              value={form.condensorTonnage}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="zoning-label">AH Location</InputLabel>
            <Select
              label="AH/Furnace Location"
              name="AHLocation"
              value={form.AHLocation}
              onChange={handleSelectChange}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>
              <MenuItem value="Garage">Garage</MenuItem>
              <MenuItem value="Closet">Closet</MenuItem>
              <MenuItem value="Attic">Attic</MenuItem>
            </Select>
          </Grid>

          <Grid item>
            <InputLabel
              id="ahd-label"
              style={{
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              A/H Area Max Dimensions
            </InputLabel>
            {form.AHDimensions.map((dim, index) => (
              <Grid container spacing={2} key={index} style={{ paddingBottom: '10px' }}>
                <Grid item xs={3}>
                  <TextField
                    label={
                      form.AHLocation == 'Attic'
                        ? `Opening dimensions? ${index + 1}`
                        : `Unit Dimension ${index + 1} Length`
                    }
                    value={dim.l}
                    onChange={handleDimensionChange(index, 'l')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label={
                      form.AHLocation == 'Attic'
                        ? `How far from opening is the AH? ${index + 1}`
                        : `Unit Dimension ${index + 1} Width`
                    }
                    value={dim.w}
                    onChange={handleDimensionChange(index, 'w')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label={
                      form.AHLocation == 'Attic'
                        ? `Attic open or tight?? ${index + 1}`
                        : `Unit Dimension ${index + 1} Height`
                    }
                    value={dim.h}
                    onChange={handleDimensionChange(index, 'h')}
                  />
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {form.AHDimensions.length > 1 && (
                    <IconButton onClick={() => handleRemoveAHDimension(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddAHDimension}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                error={!!errors.selectedItems}
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                Needs to Be Replaced (select all that apply)
              </FormLabel>
              <FormGroup>
                <p className="pt-1 text-sm text-[red]">{errors.selectedItems}</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.thermostat}
                      onChange={handleCheckboxChange}
                      name="thermostat"
                    />
                  }
                  label="Thermostat"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.copperLineset}
                      onChange={handleCheckboxChange}
                      name="copperLineset"
                    />
                  }
                  label="Copper Lineset"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.pad}
                      onChange={handleCheckboxChange}
                      name="pad"
                    />
                  }
                  label="Pad"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.returnBox}
                      onChange={handleCheckboxChange}
                      name="returnBox"
                    />
                  }
                  label="Return Box"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.filterBackReturnGrill}
                      onChange={handleCheckboxChange}
                      name="filterBackReturnGrill"
                    />
                  }
                  label="Filter Back Return Grill"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.outdoorHighVoltageDisconnect}
                      onChange={handleCheckboxChange}
                      name="outdoorHighVoltageDisconnect"
                    />
                  }
                  label="Outdoor High Voltage Disconnect"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.comboSmokeDetectorCOAlarm}
                      onChange={handleCheckboxChange}
                      name="comboSmokeDetectorCOAlarm"
                    />
                  }
                  label="Combo Smoke Detector/CO Alarm"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.lowLevelCOMonitor}
                      onChange={handleCheckboxChange}
                      name="lowLevelCOMonitor"
                    />
                  }
                  label="Low Level CO Monitor"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.noneOfTheAbove}
                      onChange={handleCheckboxChange}
                      name="noneOfTheAbove"
                    />
                  }
                  label="(none of the above)"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <InputLabel
              id="systemType-label"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Filter Location
            </InputLabel>

            <Select name="filterLocation" value={form.filterLocation} onChange={handleSelectChange}>
              <MenuItem value="Choose One">Choose One</MenuItem>

              <MenuItem value="Underneath AH">Underneath AH</MenuItem>
              <MenuItem value="Filter Back Return Grill">Filter Back Return Grill</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            {form.filterLocation === 'Filter Back Return Grill' && (
              <TextField
                label="Filter Size"
                name="filterSize"
                value={form.filterSize}
                onChange={handleInputChange}
              />
            )}
          </Grid>
          <Grid item>
            <InputLabel
              id="breakerType-label"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Breaker Type
            </InputLabel>

            <Select name="breakerType" value={form.breakerType} onChange={handleSelectChange}>
              <MenuItem value="Choose One">Choose One</MenuItem>

              <MenuItem value="Siemens">Siemens</MenuItem>
              <MenuItem value="Square D QO">Square D QO</MenuItem>
              <MenuItem value="Square D Homeline">Square D Homeline</MenuItem>
              <MenuItem value="GE Slimeline">GE Slimeline</MenuItem>
              <MenuItem value="GE regular">GE regular</MenuItem>
              <MenuItem value="Cutler Hammer Black">Cutler Hammer Black</MenuItem>
              <MenuItem value="Cutler Hammer Brown">Cutler Hammer Brown</MenuItem>
              <MenuItem value="WestingHouse">WestingHouse</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <InputLabel
              id="breakerSizes-label"
              style={{
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              Breaker Sizes
            </InputLabel>
            {form.breakerDimensions.map((dim, index) => (
              <Grid container spacing={4} key={index} style={{ paddingBottom: '10px' }}>
                <Grid item xs={4}>
                  <TextField
                    label={`AH/Furnace Breaker ${index + 1} `}
                    value={dim.value1}
                    onChange={handleBreakerChange(index, 'value1')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={`Condenser Breaker ${index + 1}`}
                    value={dim.value2}
                    onChange={handleBreakerChange(index, 'value2')}
                  />
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {form.breakerDimensions.length > 1 && (
                    <IconButton onClick={() => handleRemoveBreakerDimension(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddBreakerDimension}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <InputLabel
              id="staticPressureReadings-label"
              style={{
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              Static Pressure Readings
            </InputLabel>
            {form.staticReadings.map((dim, index) => (
              <Grid container spacing={2} key={index} style={{ paddingBottom: '10px' }}>
                <Grid item xs={4}>
                  <TextField
                    label={`Return Static ${index + 1} `}
                    value={dim.value1}
                    onChange={handleStaticReadingChange(index, 'value1')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={`Supply Static ${index + 1}`}
                    value={dim.value2}
                    onChange={handleStaticReadingChange(index, 'value2')}
                  />
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {form.staticReadings.length > 1 && (
                    <IconButton onClick={() => handleRemoveStaticReading(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddStaticReading}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item>
            <InputLabel id="ductType-label">Duct Type</InputLabel>

            <Select name="ductType" value={form.ductType} onChange={handleSelectChange}>
              <MenuItem value="Choose One">Choose One</MenuItem>
              <MenuItem value="Metal">Metal</MenuItem>

              <MenuItem value="Flex">Flex</MenuItem>
              <MenuItem value="Duct Board">Duct Board</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <InputLabel
              id="ductSizes-label"
              style={{
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              Duct Sizes
            </InputLabel>
            {form.ductSize.map((dim, index) => (
              <Grid container spacing={2} key={index} style={{ paddingBottom: '10px' }}>
                <Grid item xs={4}>
                  <TextField
                    label={`Return Duct Size ${index + 1} `}
                    value={dim.value1}
                    onChange={handleDuctSizeChange(index, 'value1')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={`Supply Duct Size ${index + 1}`}
                    value={dim.value2}
                    onChange={handleDuctSizeChange(index, 'value2')}
                  />
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {form.ductSize.length > 1 && (
                    <IconButton onClick={() => handleRemoveDuctSize(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddDuctSize}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <InputLabel id="zoning-label">Zoning Type</InputLabel>

            <Select name="zoning" value={form.zoning} onChange={handleSelectChange}>
              <MenuItem value="Choose One">Choose One</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>

              <MenuItem value="No">No</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            {form.zoning === 'Yes' && (
              <div>
                <InputLabel id="Zones-label">Zones</InputLabel>
                <Select
                  name="zones"
                  value={form.zones}
                  onChange={handleSelectChange}
                  className="mb-4"
                >
                  <MenuItem value="Choose One">Choose One</MenuItem>
                  <MenuItem value="One">One</MenuItem>
                  <MenuItem value="Two">Two</MenuItem>
                  <MenuItem value="Three">Three</MenuItem>
                  <MenuItem value="4 or More">4 or More</MenuItem>
                </Select>
              </div>
            )}
          </Grid>
        </Grid>
        <Button label="Create" onClick={() => uploadDocument()}></Button>
      </form>
      {message != '' && (
        <Alert
          className="CenteredModal"
          title="Sell Sheet/AC Quote"
          open
          onClose={() => setMessage('')}
        >
          {message}
        </Alert>
      )}
      {loading && <Loader className="SellSheet-Loader" />}
    </div>
  );
};
