import { zodResolver } from '@hookform/resolvers/zod';
import { CatalogItem } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@kalos/ui';
import { type ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import { useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import { useGetLoadingColumns } from '../../../components/utils';
import { ROWS_PER_PAGE } from '../../../constants';
import { useToolFundCatalogItemCategoryQuery } from '../../../hooks/react-query/useToolFundCatalogItemCategoryQuery';
import { useToolFundCatalogQuery } from '../../../hooks/react-query/useToolFundCatalogQuery';

const searchSchema = z.object({
  name: z.string().optional().default(''),
  brand: z.string().optional().default(''),
  categoryId: z.number().optional(),
});

type SearchSchema = z.infer<typeof searchSchema>;

const staticCatalogItems = Array.from({ length: 5 }, (_, idx) => CatalogItem.create({ id: idx }));

export const ToolFundCatalog = () => {
  const { catalogItemId } = useParams();
  const isDetailView = catalogItemId && Number(catalogItemId) > 0;
  const [page, setPage] = useState(0);
  const form = useForm<SearchSchema>({
    resolver: zodResolver(searchSchema),
    defaultValues: {
      name: '',
    },
    mode: 'onChange',
  });

  const searchValues = form.watch();

  const { data: categories } = useToolFundCatalogItemCategoryQuery({ filter: { pageNumber: -1 } });

  const categoryOptions = useMemo(() => {
    return (
      categories?.results?.map((category) => ({
        label: category.name,
        value: category.id.toString(),
      })) ?? []
    );
  }, [categories]);

  const request = useMemo(() => {
    const req = CatalogItem.create();
    req.pageNumber = page;

    if (catalogItemId && Number(catalogItemId) > 0) {
      req.id = Number(catalogItemId);
      return req;
    }

    const searchName = searchValues.name?.trim();
    const searchBrand = searchValues.brand?.trim();
    const categoryId = searchValues.categoryId;

    if (searchName) {
      req.name = `%${searchName}%`;
    }
    if (searchBrand) {
      req.brand = `%${searchBrand}%`;
    }
    if (categoryId) {
      req.categoryId = categoryId;
    }

    return req;
  }, [searchValues, page, catalogItemId]);

  const { data, isLoading } = useToolFundCatalogQuery({
    filter: request,
    enabled: true,
  });

  const pageCount = data?.totalCount ? Math.ceil(data.totalCount / ROWS_PER_PAGE) : 0;

  const columns: ColumnDef<CatalogItem>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => (
          <div
            className="max-w-[150px] truncate md:max-w-[200px] lg:max-w-[300px]"
            title={row.original.name}
          >
            {row.original.name}
          </div>
        ),
      },
      {
        accessorKey: 'brand',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Brand" />,
        cell: ({ row }) => (
          <div
            className="max-w-[150px] truncate md:max-w-[200px] lg:max-w-[250px]"
            title={row.original.brand}
          >
            {row.original.brand}
          </div>
        ),
      },
      {
        accessorKey: 'price',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Price" />,
        cell: ({ row }) => <div className="w-[60px]">${row.original.cost.toFixed(2)}</div>,
      },
      {
        accessorKey: 'description',
        header: ({ column }) => (
          <div className="@md:block hidden">
            <DataTableColumnHeader column={column} title="Description" />
          </div>
        ),
        cell: ({ row }) => (
          <div className="@md:block hidden max-w-[200px] truncate" title={row.original.description}>
            {row.original.description}
          </div>
        ),
      },
      {
        accessorKey: 'tradeTypeNameList',
        header: ({ column }) => (
          <div className="@md:block hidden">
            <DataTableColumnHeader column={column} title="Trades" />
          </div>
        ),
        cell: ({ row }) => (
          <div
            className="@md:block hidden max-w-[100px] truncate"
            title={row.original.tradeTypeNameList}
          >
            {row.original.tradeTypeNameList}
          </div>
        ),
      },
    ],
    [],
  );

  const loadingColumns = useGetLoadingColumns(columns);

  const table = useReactTable<CatalogItem>({
    data: isLoading ? staticCatalogItems : data?.results ?? [],
    columns: isLoading ? loadingColumns : columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
    enableSorting: false,
  });

  return (
    <FormProvider {...form}>
      <div className="space-y-4">
        {!isDetailView && (
          <form className="px-4">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Search by name..."
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setPage(0);
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="brand"
                render={({ field }) => (
                  <FormItem className="@md:block hidden">
                    <FormLabel>Brand</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Search by brand..."
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          setPage(0);
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="categoryId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Category</FormLabel>
                    <Select
                      value={field.value?.toString()}
                      onValueChange={(value) => {
                        field.onChange(value ? Number(value) : undefined);
                        setPage(0);
                      }}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Filter by category" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="0">All Categories</SelectItem>
                        {categoryOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
              <Button
                type="reset"
                className="mt-auto max-w-max"
                variant="outline"
                onClick={() => {
                  form.reset();
                  setPage(0);
                }}
              >
                Reset
              </Button>
            </div>
          </form>
        )}

        {pageCount > 1 && !isDetailView && (
          <div className="flex items-center justify-between px-4">
            <DataTablePagination currentPage={page} setPage={setPage} pageCount={pageCount} />
            {data?.totalCount && <p className="text-sm">Total Entries: {data.totalCount}</p>}
          </div>
        )}

        <div className="rounded-md border">
          <DataTable table={table} />
        </div>
      </div>
    </FormProvider>
  );
};

export default ToolFundCatalog;
