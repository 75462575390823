import { Input } from '@kalos/ui';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useGoogleMapsScript } from '../hooks/useGoogleMapsScript';
import { getAddressFromAddressComponents } from '../modules/ComponentsLibrary/PlaceAutocompleteAddressForm/utils';

export interface Business {
  placeId: string;
  name: string;
  address: string;
}

interface BusinessAutocompleteInputProps {
  onChange: (place: Business | null) => void;
  disabled?: boolean;
  value?: Business | null;
}

export const BusinessAutocompleteInput = ({
  onChange,
  disabled,
  value,
}: BusinessAutocompleteInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [inputValue, setInputValue] = useState('');

  const { isLoaded, error } = useGoogleMapsScript();

  useEffect(() => {
    if (value) {
      setInputValue(`${value.name} - ${value.address}`);
    } else {
      setInputValue('');
    }
  }, [value]);

  useEffect(() => {
    setTimeout(() => (document.body.style.pointerEvents = ''), 0);
  }, []);

  const handlePlaceSelect = useCallback(() => {
    if (!autocompleteRef.current) return;

    const place = autocompleteRef.current.getPlace();
    if (!place.geometry || !place.address_components) {
      onChange(null);
      return;
    }

    const address = getAddressFromAddressComponents(place.address_components);
    const business = {
      placeId: place.place_id || '',
      name: place.name || '',
      address: `${address.streetAddress}, ${address.city}, ${address.state} ${address.postalCode}`,
    };

    onChange(business);
    setInputValue(`${business.name} - ${business.address}`);
  }, [onChange]);

  useEffect(() => {
    if (!isLoaded || !inputRef.current) return;

    const inputElement = inputRef.current;

    const adjustDropdownPosition = () => {
      requestAnimationFrame(() => {
        const inputRect = inputElement.getBoundingClientRect();
        const pacContainers = document.querySelectorAll('.pac-container');
        pacContainers.forEach((el) => {
          const pacElement = el as HTMLElement;
          pacElement.style.position = 'fixed';
          pacElement.style.left = `${inputRect.left}px`;
          pacElement.style.top = `${inputRect.bottom}px`;
          pacElement.style.width = `${inputRect.width}px`;
          pacElement.style.zIndex = '99999';
        });
      });
    };

    autocompleteRef.current = new window.google.maps.places.Autocomplete(inputElement, {
      types: ['establishment'],
    });

    autocompleteRef.current.addListener('place_changed', handlePlaceSelect);

    inputElement.addEventListener('focus', adjustDropdownPosition);
    window.addEventListener('resize', adjustDropdownPosition);
    window.addEventListener('scroll', adjustDropdownPosition);

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') e.preventDefault();
    };

    inputElement.addEventListener('keydown', handleKeyDown);

    return () => {
      if (autocompleteRef.current) {
        google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
      inputElement.removeEventListener('keydown', handleKeyDown);
      inputElement.removeEventListener('focus', adjustDropdownPosition);
      window.removeEventListener('resize', adjustDropdownPosition);
      window.removeEventListener('scroll', adjustDropdownPosition);
    };
  }, [isLoaded, handlePlaceSelect]);

  if (error) {
    console.error('Error loading Google Maps:', error);
    return <Input value="Error loading address search" disabled />;
  }

  return (
    <div className="relative w-full">
      <Input
        ref={inputRef}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          if (!e.target.value) {
            onChange(null);
          }
        }}
        placeholder="Search for a business (e.g., Lowe's, Home Depot)..."
        disabled={disabled || !isLoaded}
      />
    </div>
  );
};
