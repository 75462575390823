// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "reports.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "reports.proto" (syntax proto3)
// tslint:disable
import { Event } from "./event";
import { String$ } from "./common";
import { Int32 } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TimeoffRequestList } from "./timesheet_line";
import { DateRange } from "./common";
// @generated message type with reflection information, may provide speed optimized methods
class SpiffReportLine$Type extends MessageType {
    constructor() {
        super("SpiffReportLine", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "contract_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "pay_year", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "pay_month", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "pay_week", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "is_paid_weekly", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "is_paid_monthly", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "employee_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "ext", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, employeeId: 0, eventId: 0, contractId: 0, typeId: 0, statusId: 0, category: "", description: "", amount: 0, timestamp: "", payYear: 0, payMonth: 0, payWeek: 0, isPaidWeekly: false, isPaidMonthly: false, isActive: false, employeeName: "", address: "", jobNumber: "", ext: "", orderBy: "", orderDir: "", dateTarget: [], dateRange: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 employee_id */ 2:
                    message.employeeId = reader.int32();
                    break;
                case /* int32 event_id */ 3:
                    message.eventId = reader.int32();
                    break;
                case /* int32 contract_id */ 4:
                    message.contractId = reader.int32();
                    break;
                case /* int32 type_id */ 5:
                    message.typeId = reader.int32();
                    break;
                case /* int32 status_id */ 6:
                    message.statusId = reader.int32();
                    break;
                case /* string category */ 7:
                    message.category = reader.string();
                    break;
                case /* string description */ 8:
                    message.description = reader.string();
                    break;
                case /* double amount */ 9:
                    message.amount = reader.double();
                    break;
                case /* string timestamp */ 10:
                    message.timestamp = reader.string();
                    break;
                case /* int32 pay_year */ 11:
                    message.payYear = reader.int32();
                    break;
                case /* int32 pay_month */ 12:
                    message.payMonth = reader.int32();
                    break;
                case /* int32 pay_week */ 13:
                    message.payWeek = reader.int32();
                    break;
                case /* bool is_paid_weekly */ 14:
                    message.isPaidWeekly = reader.bool();
                    break;
                case /* bool is_paid_monthly */ 15:
                    message.isPaidMonthly = reader.bool();
                    break;
                case /* bool is_active */ 16:
                    message.isActive = reader.bool();
                    break;
                case /* string employee_name */ 21:
                    message.employeeName = reader.string();
                    break;
                case /* string address */ 22:
                    message.address = reader.string();
                    break;
                case /* string job_number */ 23:
                    message.jobNumber = reader.string();
                    break;
                case /* string ext */ 24:
                    message.ext = reader.string();
                    break;
                case /* string order_by */ 17:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 18:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string date_target */ 20:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string date_range */ 19:
                    message.dateRange.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 employee_id = 2; */
        if (message.employeeId !== 0)
            writer.tag(2, WireType.Varint).int32(message.employeeId);
        /* int32 event_id = 3; */
        if (message.eventId !== 0)
            writer.tag(3, WireType.Varint).int32(message.eventId);
        /* int32 contract_id = 4; */
        if (message.contractId !== 0)
            writer.tag(4, WireType.Varint).int32(message.contractId);
        /* int32 type_id = 5; */
        if (message.typeId !== 0)
            writer.tag(5, WireType.Varint).int32(message.typeId);
        /* int32 status_id = 6; */
        if (message.statusId !== 0)
            writer.tag(6, WireType.Varint).int32(message.statusId);
        /* string category = 7; */
        if (message.category !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.category);
        /* string description = 8; */
        if (message.description !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.description);
        /* double amount = 9; */
        if (message.amount !== 0)
            writer.tag(9, WireType.Bit64).double(message.amount);
        /* string timestamp = 10; */
        if (message.timestamp !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.timestamp);
        /* int32 pay_year = 11; */
        if (message.payYear !== 0)
            writer.tag(11, WireType.Varint).int32(message.payYear);
        /* int32 pay_month = 12; */
        if (message.payMonth !== 0)
            writer.tag(12, WireType.Varint).int32(message.payMonth);
        /* int32 pay_week = 13; */
        if (message.payWeek !== 0)
            writer.tag(13, WireType.Varint).int32(message.payWeek);
        /* bool is_paid_weekly = 14; */
        if (message.isPaidWeekly !== false)
            writer.tag(14, WireType.Varint).bool(message.isPaidWeekly);
        /* bool is_paid_monthly = 15; */
        if (message.isPaidMonthly !== false)
            writer.tag(15, WireType.Varint).bool(message.isPaidMonthly);
        /* bool is_active = 16; */
        if (message.isActive !== false)
            writer.tag(16, WireType.Varint).bool(message.isActive);
        /* string employee_name = 21; */
        if (message.employeeName !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.employeeName);
        /* string address = 22; */
        if (message.address !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.address);
        /* string job_number = 23; */
        if (message.jobNumber !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.jobNumber);
        /* string ext = 24; */
        if (message.ext !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.ext);
        /* string order_by = 17; */
        if (message.orderBy !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 18; */
        if (message.orderDir !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string date_target = 20; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string date_range = 19; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.dateRange[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffReportLine
 */
export const SpiffReportLine = new SpiffReportLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpiffReport$Type extends MessageType {
    constructor() {
        super("SpiffReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SpiffReportLine }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SpiffReportLine data */ 1:
                    message.data.push(SpiffReportLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SpiffReportLine data = 1; */
        for (let i = 0; i < message.data.length; i++)
            SpiffReportLine.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SpiffReport
 */
export const SpiffReport = new SpiffReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentReportLine$Type extends MessageType {
    constructor() {
        super("PromptPaymentReportLine", [
            { no: 1, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "billingDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "due_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "payment_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "report_until", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "days_to_pay", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "user_business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "user_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "payable", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 12, name: "payed", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 13, name: "payment_terms", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "eligible_rate", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 15, name: "possible_award", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 16, name: "rebate_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 17, name: "rebate_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { eventId: 0, billingDate: "", dueDate: "", paymentDate: "", reportUntil: "", daysToPay: 0, jobNumber: "", userId: 0, userBusinessName: "", userEmail: "", payable: 0, payed: 0, paymentTerms: 0, eligibleRate: 0, possibleAward: 0, rebateAmount: 0, rebateDate: "", orderBy: "", orderDir: "", dateTarget: [], dateRange: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 event_id */ 1:
                    message.eventId = reader.int32();
                    break;
                case /* string billingDate */ 2:
                    message.billingDate = reader.string();
                    break;
                case /* string due_date */ 3:
                    message.dueDate = reader.string();
                    break;
                case /* string payment_date */ 4:
                    message.paymentDate = reader.string();
                    break;
                case /* string report_until */ 5:
                    message.reportUntil = reader.string();
                    break;
                case /* int32 days_to_pay */ 6:
                    message.daysToPay = reader.int32();
                    break;
                case /* string job_number */ 7:
                    message.jobNumber = reader.string();
                    break;
                case /* int32 user_id */ 8:
                    message.userId = reader.int32();
                    break;
                case /* string user_business_name */ 9:
                    message.userBusinessName = reader.string();
                    break;
                case /* string user_email */ 10:
                    message.userEmail = reader.string();
                    break;
                case /* double payable */ 11:
                    message.payable = reader.double();
                    break;
                case /* double payed */ 12:
                    message.payed = reader.double();
                    break;
                case /* int32 payment_terms */ 13:
                    message.paymentTerms = reader.int32();
                    break;
                case /* double eligible_rate */ 14:
                    message.eligibleRate = reader.double();
                    break;
                case /* double possible_award */ 15:
                    message.possibleAward = reader.double();
                    break;
                case /* double rebate_amount */ 16:
                    message.rebateAmount = reader.double();
                    break;
                case /* string rebate_date */ 17:
                    message.rebateDate = reader.string();
                    break;
                case /* string order_by */ 18:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 19:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string date_target */ 20:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string date_range */ 21:
                    message.dateRange.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 event_id = 1; */
        if (message.eventId !== 0)
            writer.tag(1, WireType.Varint).int32(message.eventId);
        /* string billingDate = 2; */
        if (message.billingDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.billingDate);
        /* string due_date = 3; */
        if (message.dueDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dueDate);
        /* string payment_date = 4; */
        if (message.paymentDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.paymentDate);
        /* string report_until = 5; */
        if (message.reportUntil !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.reportUntil);
        /* int32 days_to_pay = 6; */
        if (message.daysToPay !== 0)
            writer.tag(6, WireType.Varint).int32(message.daysToPay);
        /* string job_number = 7; */
        if (message.jobNumber !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.jobNumber);
        /* int32 user_id = 8; */
        if (message.userId !== 0)
            writer.tag(8, WireType.Varint).int32(message.userId);
        /* string user_business_name = 9; */
        if (message.userBusinessName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.userBusinessName);
        /* string user_email = 10; */
        if (message.userEmail !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.userEmail);
        /* double payable = 11; */
        if (message.payable !== 0)
            writer.tag(11, WireType.Bit64).double(message.payable);
        /* double payed = 12; */
        if (message.payed !== 0)
            writer.tag(12, WireType.Bit64).double(message.payed);
        /* int32 payment_terms = 13; */
        if (message.paymentTerms !== 0)
            writer.tag(13, WireType.Varint).int32(message.paymentTerms);
        /* double eligible_rate = 14; */
        if (message.eligibleRate !== 0)
            writer.tag(14, WireType.Bit64).double(message.eligibleRate);
        /* double possible_award = 15; */
        if (message.possibleAward !== 0)
            writer.tag(15, WireType.Bit64).double(message.possibleAward);
        /* double rebate_amount = 16; */
        if (message.rebateAmount !== 0)
            writer.tag(16, WireType.Bit64).double(message.rebateAmount);
        /* string rebate_date = 17; */
        if (message.rebateDate !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.rebateDate);
        /* string order_by = 18; */
        if (message.orderBy !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 19; */
        if (message.orderDir !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string date_target = 20; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string date_range = 21; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.dateRange[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentReportLine
 */
export const PromptPaymentReportLine = new PromptPaymentReportLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionReportLine$Type extends MessageType {
    constructor() {
        super("TransactionReportLine", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "artificial_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "zoning", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "job_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "sub_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "class_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "department", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "vendor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "holder_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "transaction_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "posted_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "year", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { transactionId: 0, artificialId: "", jobNumber: "", zoning: "", jobType: "", subType: "", classCode: "", department: "", category: "", vendor: "", holderName: "", transactionTimestamp: "", postedTimestamp: "", amount: "", notes: "", year: "", pageNumber: 0, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 transaction_id */ 1:
                    message.transactionId = reader.int32();
                    break;
                case /* string artificial_id */ 2:
                    message.artificialId = reader.string();
                    break;
                case /* string job_number */ 3:
                    message.jobNumber = reader.string();
                    break;
                case /* string zoning */ 4:
                    message.zoning = reader.string();
                    break;
                case /* string job_type */ 5:
                    message.jobType = reader.string();
                    break;
                case /* string sub_type */ 6:
                    message.subType = reader.string();
                    break;
                case /* string class_code */ 7:
                    message.classCode = reader.string();
                    break;
                case /* string department */ 8:
                    message.department = reader.string();
                    break;
                case /* string category */ 9:
                    message.category = reader.string();
                    break;
                case /* string vendor */ 10:
                    message.vendor = reader.string();
                    break;
                case /* string holder_name */ 11:
                    message.holderName = reader.string();
                    break;
                case /* string transaction_timestamp */ 12:
                    message.transactionTimestamp = reader.string();
                    break;
                case /* string posted_timestamp */ 13:
                    message.postedTimestamp = reader.string();
                    break;
                case /* string amount */ 14:
                    message.amount = reader.string();
                    break;
                case /* string notes */ 15:
                    message.notes = reader.string();
                    break;
                case /* string year */ 16:
                    message.year = reader.string();
                    break;
                case /* int32 page_number */ 17:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 18:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 transaction_id = 1; */
        if (message.transactionId !== 0)
            writer.tag(1, WireType.Varint).int32(message.transactionId);
        /* string artificial_id = 2; */
        if (message.artificialId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.artificialId);
        /* string job_number = 3; */
        if (message.jobNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.jobNumber);
        /* string zoning = 4; */
        if (message.zoning !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.zoning);
        /* string job_type = 5; */
        if (message.jobType !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.jobType);
        /* string sub_type = 6; */
        if (message.subType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.subType);
        /* string class_code = 7; */
        if (message.classCode !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.classCode);
        /* string department = 8; */
        if (message.department !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.department);
        /* string category = 9; */
        if (message.category !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.category);
        /* string vendor = 10; */
        if (message.vendor !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.vendor);
        /* string holder_name = 11; */
        if (message.holderName !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.holderName);
        /* string transaction_timestamp = 12; */
        if (message.transactionTimestamp !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.transactionTimestamp);
        /* string posted_timestamp = 13; */
        if (message.postedTimestamp !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.postedTimestamp);
        /* string amount = 14; */
        if (message.amount !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.amount);
        /* string notes = 15; */
        if (message.notes !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.notes);
        /* string year = 16; */
        if (message.year !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.year);
        /* int32 page_number = 17; */
        if (message.pageNumber !== 0)
            writer.tag(17, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 18; */
        if (message.withoutLimit !== false)
            writer.tag(18, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionReportLine
 */
export const TransactionReportLine = new TransactionReportLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoffReportRequest$Type extends MessageType {
    constructor() {
        super("TimeoffReportRequest", [
            { no: 1, name: "year_shift", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { yearShift: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 year_shift */ 1:
                    message.yearShift = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 year_shift = 1; */
        if (message.yearShift !== 0)
            writer.tag(1, WireType.Varint).int32(message.yearShift);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeoffReportRequest
 */
export const TimeoffReportRequest = new TimeoffReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoffReportLine$Type extends MessageType {
    constructor() {
        super("TimeoffReportLine", [
            { no: 1, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "hire_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "annual_hours_pto", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "hire_date_discretionary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "hire_date_mandatory", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "hire_date_pto", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "hire_date_paid_downtime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "annual_discretionary", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "annual_mandatory", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "annual_pto", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "annual_paid_downtime", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { employeeId: 0, userFirstname: "", userLastname: "", hireDate: "", annualHoursPto: "", hireDateDiscretionary: "", hireDateMandatory: "", hireDatePto: "", hireDatePaidDowntime: "", annualDiscretionary: "", annualMandatory: "", annualPto: "", annualPaidDowntime: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 employee_id */ 1:
                    message.employeeId = reader.int32();
                    break;
                case /* string user_firstname */ 2:
                    message.userFirstname = reader.string();
                    break;
                case /* string user_lastname */ 3:
                    message.userLastname = reader.string();
                    break;
                case /* string hire_date */ 4:
                    message.hireDate = reader.string();
                    break;
                case /* string annual_hours_pto */ 5:
                    message.annualHoursPto = reader.string();
                    break;
                case /* string hire_date_discretionary */ 6:
                    message.hireDateDiscretionary = reader.string();
                    break;
                case /* string hire_date_mandatory */ 7:
                    message.hireDateMandatory = reader.string();
                    break;
                case /* string hire_date_pto */ 8:
                    message.hireDatePto = reader.string();
                    break;
                case /* string hire_date_paid_downtime */ 9:
                    message.hireDatePaidDowntime = reader.string();
                    break;
                case /* string annual_discretionary */ 10:
                    message.annualDiscretionary = reader.string();
                    break;
                case /* string annual_mandatory */ 11:
                    message.annualMandatory = reader.string();
                    break;
                case /* string annual_pto */ 12:
                    message.annualPto = reader.string();
                    break;
                case /* string annual_paid_downtime */ 13:
                    message.annualPaidDowntime = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 employee_id = 1; */
        if (message.employeeId !== 0)
            writer.tag(1, WireType.Varint).int32(message.employeeId);
        /* string user_firstname = 2; */
        if (message.userFirstname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userFirstname);
        /* string user_lastname = 3; */
        if (message.userLastname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userLastname);
        /* string hire_date = 4; */
        if (message.hireDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.hireDate);
        /* string annual_hours_pto = 5; */
        if (message.annualHoursPto !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.annualHoursPto);
        /* string hire_date_discretionary = 6; */
        if (message.hireDateDiscretionary !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.hireDateDiscretionary);
        /* string hire_date_mandatory = 7; */
        if (message.hireDateMandatory !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.hireDateMandatory);
        /* string hire_date_pto = 8; */
        if (message.hireDatePto !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.hireDatePto);
        /* string hire_date_paid_downtime = 9; */
        if (message.hireDatePaidDowntime !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.hireDatePaidDowntime);
        /* string annual_discretionary = 10; */
        if (message.annualDiscretionary !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.annualDiscretionary);
        /* string annual_mandatory = 11; */
        if (message.annualMandatory !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.annualMandatory);
        /* string annual_pto = 12; */
        if (message.annualPto !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.annualPto);
        /* string annual_paid_downtime = 13; */
        if (message.annualPaidDowntime !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.annualPaidDowntime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeoffReportLine
 */
export const TimeoffReportLine = new TimeoffReportLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessedReportLine$Type extends MessageType {
    constructor() {
        super("ProcessedReportLine", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "total_lodging", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "total_meals", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "total_mileage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "total_spiffs", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "total_processed_hours", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "total_mileage_cost", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, userFirstname: "", userLastname: "", totalLodging: "", totalMeals: "", totalMileage: "", totalSpiffs: "", totalProcessedHours: "", totalMileageCost: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string user_firstname */ 2:
                    message.userFirstname = reader.string();
                    break;
                case /* string user_lastname */ 3:
                    message.userLastname = reader.string();
                    break;
                case /* string total_lodging */ 4:
                    message.totalLodging = reader.string();
                    break;
                case /* string total_meals */ 5:
                    message.totalMeals = reader.string();
                    break;
                case /* string total_mileage */ 6:
                    message.totalMileage = reader.string();
                    break;
                case /* string total_spiffs */ 7:
                    message.totalSpiffs = reader.string();
                    break;
                case /* string total_processed_hours */ 8:
                    message.totalProcessedHours = reader.string();
                    break;
                case /* string total_mileage_cost */ 9:
                    message.totalMileageCost = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string user_firstname = 2; */
        if (message.userFirstname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userFirstname);
        /* string user_lastname = 3; */
        if (message.userLastname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userLastname);
        /* string total_lodging = 4; */
        if (message.totalLodging !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.totalLodging);
        /* string total_meals = 5; */
        if (message.totalMeals !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.totalMeals);
        /* string total_mileage = 6; */
        if (message.totalMileage !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.totalMileage);
        /* string total_spiffs = 7; */
        if (message.totalSpiffs !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.totalSpiffs);
        /* string total_processed_hours = 8; */
        if (message.totalProcessedHours !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.totalProcessedHours);
        /* string total_mileage_cost = 9; */
        if (message.totalMileageCost !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.totalMileageCost);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProcessedReportLine
 */
export const ProcessedReportLine = new ProcessedReportLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReceiptJournalReportLine$Type extends MessageType {
    constructor() {
        super("ReceiptJournalReportLine", [
            { no: 1, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "payment_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount_collected", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "sr_datetime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "date_target", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { eventId: 0, paymentType: "", amountCollected: 0, srDatetime: "", departmentId: 0, withoutLimit: false, pageNumber: 0, orderBy: "", orderDir: "", dateRange: [], dateTarget: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 event_id */ 1:
                    message.eventId = reader.int32();
                    break;
                case /* string payment_type */ 2:
                    message.paymentType = reader.string();
                    break;
                case /* double amount_collected */ 3:
                    message.amountCollected = reader.double();
                    break;
                case /* string sr_datetime */ 4:
                    message.srDatetime = reader.string();
                    break;
                case /* int32 department_id */ 5:
                    message.departmentId = reader.int32();
                    break;
                case /* bool without_limit */ 6:
                    message.withoutLimit = reader.bool();
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 8:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 9:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string date_range */ 10:
                    message.dateRange.push(reader.string());
                    break;
                case /* string date_target */ 11:
                    message.dateTarget = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 event_id = 1; */
        if (message.eventId !== 0)
            writer.tag(1, WireType.Varint).int32(message.eventId);
        /* string payment_type = 2; */
        if (message.paymentType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.paymentType);
        /* double amount_collected = 3; */
        if (message.amountCollected !== 0)
            writer.tag(3, WireType.Bit64).double(message.amountCollected);
        /* string sr_datetime = 4; */
        if (message.srDatetime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.srDatetime);
        /* int32 department_id = 5; */
        if (message.departmentId !== 0)
            writer.tag(5, WireType.Varint).int32(message.departmentId);
        /* bool without_limit = 6; */
        if (message.withoutLimit !== false)
            writer.tag(6, WireType.Varint).bool(message.withoutLimit);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 8; */
        if (message.orderBy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 9; */
        if (message.orderDir !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string date_range = 10; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.dateRange[i]);
        /* string date_target = 11; */
        if (message.dateTarget !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.dateTarget);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReceiptJournalReportLine
 */
export const ReceiptJournalReportLine = new ReceiptJournalReportLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SalesJournalReportLine$Type extends MessageType {
    constructor() {
        super("SalesJournalReportLine", [
            { no: 1, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "service_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "job_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "billing_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "total_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { eventId: 0, serviceDate: "", customerId: 0, customerName: "", status: "", jobTypeId: 0, billingDate: "", departmentId: 0, totalAmount: 0, withoutLimit: false, pageNumber: 0, orderBy: "", orderDir: "", dateRange: [], dateTarget: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 event_id */ 1:
                    message.eventId = reader.int32();
                    break;
                case /* string service_date */ 2:
                    message.serviceDate = reader.string();
                    break;
                case /* int32 customer_id */ 3:
                    message.customerId = reader.int32();
                    break;
                case /* string customer_name */ 4:
                    message.customerName = reader.string();
                    break;
                case /* string status */ 5:
                    message.status = reader.string();
                    break;
                case /* int32 job_type_id */ 6:
                    message.jobTypeId = reader.int32();
                    break;
                case /* string billing_date */ 7:
                    message.billingDate = reader.string();
                    break;
                case /* int32 department_id */ 8:
                    message.departmentId = reader.int32();
                    break;
                case /* double total_amount */ 9:
                    message.totalAmount = reader.double();
                    break;
                case /* bool without_limit */ 10:
                    message.withoutLimit = reader.bool();
                    break;
                case /* int32 page_number */ 11:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 12:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 13:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string date_range */ 14:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 15:
                    message.dateTarget.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 event_id = 1; */
        if (message.eventId !== 0)
            writer.tag(1, WireType.Varint).int32(message.eventId);
        /* string service_date = 2; */
        if (message.serviceDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.serviceDate);
        /* int32 customer_id = 3; */
        if (message.customerId !== 0)
            writer.tag(3, WireType.Varint).int32(message.customerId);
        /* string customer_name = 4; */
        if (message.customerName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerName);
        /* string status = 5; */
        if (message.status !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.status);
        /* int32 job_type_id = 6; */
        if (message.jobTypeId !== 0)
            writer.tag(6, WireType.Varint).int32(message.jobTypeId);
        /* string billing_date = 7; */
        if (message.billingDate !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.billingDate);
        /* int32 department_id = 8; */
        if (message.departmentId !== 0)
            writer.tag(8, WireType.Varint).int32(message.departmentId);
        /* double total_amount = 9; */
        if (message.totalAmount !== 0)
            writer.tag(9, WireType.Bit64).double(message.totalAmount);
        /* bool without_limit = 10; */
        if (message.withoutLimit !== false)
            writer.tag(10, WireType.Varint).bool(message.withoutLimit);
        /* int32 page_number = 11; */
        if (message.pageNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 12; */
        if (message.orderBy !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 13; */
        if (message.orderDir !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string date_range = 14; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 15; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.dateTarget[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SalesJournalReportLine
 */
export const SalesJournalReportLine = new SalesJournalReportLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CostItem$Type extends MessageType {
    constructor() {
        super("CostItem", [
            { no: 1, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "employee_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "main_cost", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "sub_cost", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "entity_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "entity_joined_table_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { employeeId: 0, employeeName: "", departmentId: 0, date: "", jobNumber: "", mainCost: 0, subCost: 0, type: "", entityId: 0, entityJoinedTableId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 employee_id */ 1:
                    message.employeeId = reader.int32();
                    break;
                case /* string employee_name */ 2:
                    message.employeeName = reader.string();
                    break;
                case /* int32 department_id */ 3:
                    message.departmentId = reader.int32();
                    break;
                case /* string date */ 4:
                    message.date = reader.string();
                    break;
                case /* string job_number */ 5:
                    message.jobNumber = reader.string();
                    break;
                case /* float main_cost */ 6:
                    message.mainCost = reader.float();
                    break;
                case /* float sub_cost */ 7:
                    message.subCost = reader.float();
                    break;
                case /* string type */ 8:
                    message.type = reader.string();
                    break;
                case /* int32 entity_id */ 9:
                    message.entityId = reader.int32();
                    break;
                case /* int32 entity_joined_table_id */ 10:
                    message.entityJoinedTableId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 employee_id = 1; */
        if (message.employeeId !== 0)
            writer.tag(1, WireType.Varint).int32(message.employeeId);
        /* string employee_name = 2; */
        if (message.employeeName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.employeeName);
        /* int32 department_id = 3; */
        if (message.departmentId !== 0)
            writer.tag(3, WireType.Varint).int32(message.departmentId);
        /* string date = 4; */
        if (message.date !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.date);
        /* string job_number = 5; */
        if (message.jobNumber !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.jobNumber);
        /* float main_cost = 6; */
        if (message.mainCost !== 0)
            writer.tag(6, WireType.Bit32).float(message.mainCost);
        /* float sub_cost = 7; */
        if (message.subCost !== 0)
            writer.tag(7, WireType.Bit32).float(message.subCost);
        /* string type = 8; */
        if (message.type !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.type);
        /* int32 entity_id = 9; */
        if (message.entityId !== 0)
            writer.tag(9, WireType.Varint).int32(message.entityId);
        /* int32 entity_joined_table_id = 10; */
        if (message.entityJoinedTableId !== 0)
            writer.tag(10, WireType.Varint).int32(message.entityJoinedTableId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CostItem
 */
export const CostItem = new CostItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingAudit$Type extends MessageType {
    constructor() {
        super("BillingAudit", [
            { no: 1, name: "log_billingDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "invoice_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "document_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "userName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "businessName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "log_jobNumber", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "log_paymentStatus", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "payable", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 12, name: "payed", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 13, name: "income", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 14, name: "site_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "is_tracked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "log_po", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "department_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { logBillingDate: "", userId: 0, invoiceId: 0, eventId: 0, propertyId: 0, documentId: 0, userName: "", businessName: "", logJobNumber: "", logPaymentStatus: "", payable: 0, payed: 0, income: 0, siteAddress: "", isTracked: false, logPo: "", departmentName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string log_billingDate */ 1:
                    message.logBillingDate = reader.string();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 invoice_id */ 3:
                    message.invoiceId = reader.int32();
                    break;
                case /* int32 event_id */ 4:
                    message.eventId = reader.int32();
                    break;
                case /* int32 property_id */ 5:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 document_id */ 6:
                    message.documentId = reader.int32();
                    break;
                case /* string userName */ 7:
                    message.userName = reader.string();
                    break;
                case /* string businessName */ 8:
                    message.businessName = reader.string();
                    break;
                case /* string log_jobNumber */ 9:
                    message.logJobNumber = reader.string();
                    break;
                case /* string log_paymentStatus */ 10:
                    message.logPaymentStatus = reader.string();
                    break;
                case /* float payable */ 11:
                    message.payable = reader.float();
                    break;
                case /* float payed */ 12:
                    message.payed = reader.float();
                    break;
                case /* float income */ 13:
                    message.income = reader.float();
                    break;
                case /* string site_address */ 14:
                    message.siteAddress = reader.string();
                    break;
                case /* bool is_tracked */ 15:
                    message.isTracked = reader.bool();
                    break;
                case /* string log_po */ 16:
                    message.logPo = reader.string();
                    break;
                case /* string department_name */ 17:
                    message.departmentName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string log_billingDate = 1; */
        if (message.logBillingDate !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.logBillingDate);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 invoice_id = 3; */
        if (message.invoiceId !== 0)
            writer.tag(3, WireType.Varint).int32(message.invoiceId);
        /* int32 event_id = 4; */
        if (message.eventId !== 0)
            writer.tag(4, WireType.Varint).int32(message.eventId);
        /* int32 property_id = 5; */
        if (message.propertyId !== 0)
            writer.tag(5, WireType.Varint).int32(message.propertyId);
        /* int32 document_id = 6; */
        if (message.documentId !== 0)
            writer.tag(6, WireType.Varint).int32(message.documentId);
        /* string userName = 7; */
        if (message.userName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.userName);
        /* string businessName = 8; */
        if (message.businessName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.businessName);
        /* string log_jobNumber = 9; */
        if (message.logJobNumber !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.logJobNumber);
        /* string log_paymentStatus = 10; */
        if (message.logPaymentStatus !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.logPaymentStatus);
        /* float payable = 11; */
        if (message.payable !== 0)
            writer.tag(11, WireType.Bit32).float(message.payable);
        /* float payed = 12; */
        if (message.payed !== 0)
            writer.tag(12, WireType.Bit32).float(message.payed);
        /* float income = 13; */
        if (message.income !== 0)
            writer.tag(13, WireType.Bit32).float(message.income);
        /* string site_address = 14; */
        if (message.siteAddress !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.siteAddress);
        /* bool is_tracked = 15; */
        if (message.isTracked !== false)
            writer.tag(15, WireType.Varint).bool(message.isTracked);
        /* string log_po = 16; */
        if (message.logPo !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.logPo);
        /* string department_name = 17; */
        if (message.departmentName !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.departmentName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BillingAudit
 */
export const BillingAudit = new BillingAudit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobHoursEmployee$Type extends MessageType {
    constructor() {
        super("JobHoursEmployee", [
            { no: 1, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "employee_first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "employee_last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "hours", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { jobNumber: 0, employeeId: 0, employeeFirstName: "", employeeLastName: "", hours: 0, date: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 job_number */ 1:
                    message.jobNumber = reader.int32();
                    break;
                case /* int32 employee_id */ 2:
                    message.employeeId = reader.int32();
                    break;
                case /* string employee_first_name */ 3:
                    message.employeeFirstName = reader.string();
                    break;
                case /* string employee_last_name */ 4:
                    message.employeeLastName = reader.string();
                    break;
                case /* float hours */ 5:
                    message.hours = reader.float();
                    break;
                case /* string date */ 6:
                    message.date = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 job_number = 1; */
        if (message.jobNumber !== 0)
            writer.tag(1, WireType.Varint).int32(message.jobNumber);
        /* int32 employee_id = 2; */
        if (message.employeeId !== 0)
            writer.tag(2, WireType.Varint).int32(message.employeeId);
        /* string employee_first_name = 3; */
        if (message.employeeFirstName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.employeeFirstName);
        /* string employee_last_name = 4; */
        if (message.employeeLastName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.employeeLastName);
        /* float hours = 5; */
        if (message.hours !== 0)
            writer.tag(5, WireType.Bit32).float(message.hours);
        /* string date = 6; */
        if (message.date !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.date);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobHoursEmployee
 */
export const JobHoursEmployee = new JobHoursEmployee$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoiceJournalEntry$Type extends MessageType {
    constructor() {
        super("InvoiceJournalEntry", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "invoice_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "order_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "cost_center_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "vendor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "invoice_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "job_start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "invoice_upload_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "accepted_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "processed_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "rejected_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { transactionId: 0, invoiceNumber: "", orderNumber: "", jobNumber: 0, departmentId: 0, costCenterId: 0, amount: "", vendor: "", invoiceDate: "", jobStartDate: "", invoiceUploadDate: "", acceptedDate: "", processedDate: "", rejectedDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 transaction_id */ 1:
                    message.transactionId = reader.int32();
                    break;
                case /* string invoice_number */ 2:
                    message.invoiceNumber = reader.string();
                    break;
                case /* string order_number */ 3:
                    message.orderNumber = reader.string();
                    break;
                case /* int32 job_number */ 4:
                    message.jobNumber = reader.int32();
                    break;
                case /* int32 department_id */ 5:
                    message.departmentId = reader.int32();
                    break;
                case /* int32 cost_center_id */ 6:
                    message.costCenterId = reader.int32();
                    break;
                case /* string amount */ 7:
                    message.amount = reader.string();
                    break;
                case /* string vendor */ 8:
                    message.vendor = reader.string();
                    break;
                case /* string invoice_date */ 9:
                    message.invoiceDate = reader.string();
                    break;
                case /* string job_start_date */ 10:
                    message.jobStartDate = reader.string();
                    break;
                case /* string invoice_upload_date */ 11:
                    message.invoiceUploadDate = reader.string();
                    break;
                case /* string accepted_date */ 12:
                    message.acceptedDate = reader.string();
                    break;
                case /* string processed_date */ 13:
                    message.processedDate = reader.string();
                    break;
                case /* string rejected_date */ 14:
                    message.rejectedDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 transaction_id = 1; */
        if (message.transactionId !== 0)
            writer.tag(1, WireType.Varint).int32(message.transactionId);
        /* string invoice_number = 2; */
        if (message.invoiceNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.invoiceNumber);
        /* string order_number = 3; */
        if (message.orderNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.orderNumber);
        /* int32 job_number = 4; */
        if (message.jobNumber !== 0)
            writer.tag(4, WireType.Varint).int32(message.jobNumber);
        /* int32 department_id = 5; */
        if (message.departmentId !== 0)
            writer.tag(5, WireType.Varint).int32(message.departmentId);
        /* int32 cost_center_id = 6; */
        if (message.costCenterId !== 0)
            writer.tag(6, WireType.Varint).int32(message.costCenterId);
        /* string amount = 7; */
        if (message.amount !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.amount);
        /* string vendor = 8; */
        if (message.vendor !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.vendor);
        /* string invoice_date = 9; */
        if (message.invoiceDate !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.invoiceDate);
        /* string job_start_date = 10; */
        if (message.jobStartDate !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.jobStartDate);
        /* string invoice_upload_date = 11; */
        if (message.invoiceUploadDate !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.invoiceUploadDate);
        /* string accepted_date = 12; */
        if (message.acceptedDate !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.acceptedDate);
        /* string processed_date = 13; */
        if (message.processedDate !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.processedDate);
        /* string rejected_date = 14; */
        if (message.rejectedDate !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.rejectedDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoiceJournalEntry
 */
export const InvoiceJournalEntry = new InvoiceJournalEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DailyJobEntry$Type extends MessageType {
    constructor() {
        super("DailyJobEntry", [
            { no: 1, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "job_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "zip_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "division", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "job_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "job_sub_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "job_contract", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "job_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "is_tracked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "tracked_job_type", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { startDate: "", customerName: "", businessName: "", jobAddress: "", city: "", zipCode: "", division: "", phoneNumber: "", jobNumber: "", jobType: "", jobSubType: "", jobContract: "", jobLink: "", isTracked: false, trackedJobType: false, eventId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string start_date */ 1:
                    message.startDate = reader.string();
                    break;
                case /* string customer_name */ 2:
                    message.customerName = reader.string();
                    break;
                case /* string business_name */ 3:
                    message.businessName = reader.string();
                    break;
                case /* string job_address */ 4:
                    message.jobAddress = reader.string();
                    break;
                case /* string city */ 5:
                    message.city = reader.string();
                    break;
                case /* string zip_code */ 6:
                    message.zipCode = reader.string();
                    break;
                case /* string division */ 7:
                    message.division = reader.string();
                    break;
                case /* string phone_number */ 8:
                    message.phoneNumber = reader.string();
                    break;
                case /* string job_number */ 9:
                    message.jobNumber = reader.string();
                    break;
                case /* string job_type */ 10:
                    message.jobType = reader.string();
                    break;
                case /* string job_sub_type */ 11:
                    message.jobSubType = reader.string();
                    break;
                case /* string job_contract */ 12:
                    message.jobContract = reader.string();
                    break;
                case /* string job_link */ 13:
                    message.jobLink = reader.string();
                    break;
                case /* bool is_tracked */ 14:
                    message.isTracked = reader.bool();
                    break;
                case /* bool tracked_job_type */ 15:
                    message.trackedJobType = reader.bool();
                    break;
                case /* int32 event_id */ 16:
                    message.eventId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string start_date = 1; */
        if (message.startDate !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startDate);
        /* string customer_name = 2; */
        if (message.customerName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerName);
        /* string business_name = 3; */
        if (message.businessName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.businessName);
        /* string job_address = 4; */
        if (message.jobAddress !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.jobAddress);
        /* string city = 5; */
        if (message.city !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.city);
        /* string zip_code = 6; */
        if (message.zipCode !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.zipCode);
        /* string division = 7; */
        if (message.division !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.division);
        /* string phone_number = 8; */
        if (message.phoneNumber !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.phoneNumber);
        /* string job_number = 9; */
        if (message.jobNumber !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.jobNumber);
        /* string job_type = 10; */
        if (message.jobType !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.jobType);
        /* string job_sub_type = 11; */
        if (message.jobSubType !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.jobSubType);
        /* string job_contract = 12; */
        if (message.jobContract !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.jobContract);
        /* string job_link = 13; */
        if (message.jobLink !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.jobLink);
        /* bool is_tracked = 14; */
        if (message.isTracked !== false)
            writer.tag(14, WireType.Varint).bool(message.isTracked);
        /* bool tracked_job_type = 15; */
        if (message.trackedJobType !== false)
            writer.tag(15, WireType.Varint).bool(message.trackedJobType);
        /* int32 event_id = 16; */
        if (message.eventId !== 0)
            writer.tag(16, WireType.Varint).int32(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DailyJobEntry
 */
export const DailyJobEntry = new DailyJobEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DailyJobReport$Type extends MessageType {
    constructor() {
        super("DailyJobReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DailyJobEntry },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], count: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DailyJobEntry data */ 1:
                    message.data.push(DailyJobEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 count */ 2:
                    message.count = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated DailyJobEntry data = 1; */
        for (let i = 0; i < message.data.length; i++)
            DailyJobEntry.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).int32(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DailyJobReport
 */
export const DailyJobReport = new DailyJobReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoiceJournalReport$Type extends MessageType {
    constructor() {
        super("InvoiceJournalReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InvoiceJournalEntry },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], count: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated InvoiceJournalEntry data */ 1:
                    message.data.push(InvoiceJournalEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 count */ 2:
                    message.count = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated InvoiceJournalEntry data = 1; */
        for (let i = 0; i < message.data.length; i++)
            InvoiceJournalEntry.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).int32(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoiceJournalReport
 */
export const InvoiceJournalReport = new InvoiceJournalReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobHoursEmployeeReport$Type extends MessageType {
    constructor() {
        super("JobHoursEmployeeReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JobHoursEmployee },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], count: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated JobHoursEmployee data */ 1:
                    message.data.push(JobHoursEmployee.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 count */ 2:
                    message.count = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated JobHoursEmployee data = 1; */
        for (let i = 0; i < message.data.length; i++)
            JobHoursEmployee.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).int32(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobHoursEmployeeReport
 */
export const JobHoursEmployeeReport = new JobHoursEmployeeReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CostItemReport$Type extends MessageType {
    constructor() {
        super("CostItemReport", [
            { no: 1, name: "trip_data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CostItem },
            { no: 2, name: "per_diem_data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CostItem },
            { no: 3, name: "spiff_data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CostItem },
            { no: 4, name: "reimbursement_data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CostItem }
        ]);
    }
    create(value) {
        const message = { tripData: [], perDiemData: [], spiffData: [], reimbursementData: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CostItem trip_data */ 1:
                    message.tripData.push(CostItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated CostItem per_diem_data */ 2:
                    message.perDiemData.push(CostItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated CostItem spiff_data */ 3:
                    message.spiffData.push(CostItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated CostItem reimbursement_data */ 4:
                    message.reimbursementData.push(CostItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CostItem trip_data = 1; */
        for (let i = 0; i < message.tripData.length; i++)
            CostItem.internalBinaryWrite(message.tripData[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated CostItem per_diem_data = 2; */
        for (let i = 0; i < message.perDiemData.length; i++)
            CostItem.internalBinaryWrite(message.perDiemData[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated CostItem spiff_data = 3; */
        for (let i = 0; i < message.spiffData.length; i++)
            CostItem.internalBinaryWrite(message.spiffData[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated CostItem reimbursement_data = 4; */
        for (let i = 0; i < message.reimbursementData.length; i++)
            CostItem.internalBinaryWrite(message.reimbursementData[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CostItemReport
 */
export const CostItemReport = new CostItemReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentReport$Type extends MessageType {
    constructor() {
        super("PromptPaymentReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PromptPaymentReportLine }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PromptPaymentReportLine data */ 1:
                    message.data.push(PromptPaymentReportLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PromptPaymentReportLine data = 1; */
        for (let i = 0; i < message.data.length; i++)
            PromptPaymentReportLine.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentReport
 */
export const PromptPaymentReport = new PromptPaymentReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReceiptJournalReport$Type extends MessageType {
    constructor() {
        super("ReceiptJournalReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReceiptJournalReportLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ReceiptJournalReportLine data */ 1:
                    message.data.push(ReceiptJournalReportLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ReceiptJournalReportLine data = 1; */
        for (let i = 0; i < message.data.length; i++)
            ReceiptJournalReportLine.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReceiptJournalReport
 */
export const ReceiptJournalReport = new ReceiptJournalReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SalesJournalReport$Type extends MessageType {
    constructor() {
        super("SalesJournalReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SalesJournalReportLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SalesJournalReportLine data */ 1:
                    message.data.push(SalesJournalReportLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SalesJournalReportLine data = 1; */
        for (let i = 0; i < message.data.length; i++)
            SalesJournalReportLine.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SalesJournalReport
 */
export const SalesJournalReport = new SalesJournalReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionDumpReport$Type extends MessageType {
    constructor() {
        super("TransactionDumpReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionReportLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionReportLine data */ 1:
                    message.data.push(TransactionReportLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionReportLine data = 1; */
        for (let i = 0; i < message.data.length; i++)
            TransactionReportLine.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionDumpReport
 */
export const TransactionDumpReport = new TransactionDumpReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeoffReport$Type extends MessageType {
    constructor() {
        super("TimeoffReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimeoffReportLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimeoffReportLine data */ 1:
                    message.data.push(TimeoffReportLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimeoffReportLine data = 1; */
        for (let i = 0; i < message.data.length; i++)
            TimeoffReportLine.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeoffReport
 */
export const TimeoffReport = new TimeoffReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessedReportRequest$Type extends MessageType {
    constructor() {
        super("ProcessedReportRequest", [
            { no: 1, name: "processed_start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "processed_end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timesheet_start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timesheet_end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { processedStartDate: "", processedEndDate: "", timesheetStartDate: "", timesheetEndDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string processed_start_date */ 1:
                    message.processedStartDate = reader.string();
                    break;
                case /* string processed_end_date */ 2:
                    message.processedEndDate = reader.string();
                    break;
                case /* string timesheet_start_date */ 3:
                    message.timesheetStartDate = reader.string();
                    break;
                case /* string timesheet_end_date */ 4:
                    message.timesheetEndDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string processed_start_date = 1; */
        if (message.processedStartDate !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.processedStartDate);
        /* string processed_end_date = 2; */
        if (message.processedEndDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.processedEndDate);
        /* string timesheet_start_date = 3; */
        if (message.timesheetStartDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timesheetStartDate);
        /* string timesheet_end_date = 4; */
        if (message.timesheetEndDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.timesheetEndDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProcessedReportRequest
 */
export const ProcessedReportRequest = new ProcessedReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessedReport$Type extends MessageType {
    constructor() {
        super("ProcessedReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProcessedReportLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProcessedReportLine data */ 1:
                    message.data.push(ProcessedReportLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ProcessedReportLine data = 1; */
        for (let i = 0; i < message.data.length; i++)
            ProcessedReportLine.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProcessedReport
 */
export const ProcessedReport = new ProcessedReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingAuditReport$Type extends MessageType {
    constructor() {
        super("BillingAuditReport", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BillingAudit },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { data: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BillingAudit data */ 1:
                    message.data.push(BillingAudit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated BillingAudit data = 1; */
        for (let i = 0; i < message.data.length; i++)
            BillingAudit.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BillingAuditReport
 */
export const BillingAuditReport = new BillingAuditReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentSummary$Type extends MessageType {
    constructor() {
        super("PromptPaymentSummary", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "payable_award", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "forfeited_award", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "total_payable", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "payment_terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "average_days_to_pay", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "invoices_paid", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "invoices", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, businessName: "", userEmail: "", payableAward: 0, forfeitedAward: 0, totalPayable: 0, paymentTerms: "", averageDaysToPay: 0, invoicesPaid: 0, invoices: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string business_name */ 2:
                    message.businessName = reader.string();
                    break;
                case /* string user_email */ 3:
                    message.userEmail = reader.string();
                    break;
                case /* double payable_award */ 4:
                    message.payableAward = reader.double();
                    break;
                case /* double forfeited_award */ 5:
                    message.forfeitedAward = reader.double();
                    break;
                case /* double total_payable */ 6:
                    message.totalPayable = reader.double();
                    break;
                case /* string payment_terms */ 7:
                    message.paymentTerms = reader.string();
                    break;
                case /* double average_days_to_pay */ 8:
                    message.averageDaysToPay = reader.double();
                    break;
                case /* double invoices_paid */ 9:
                    message.invoicesPaid = reader.double();
                    break;
                case /* int32 invoices */ 10:
                    message.invoices = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string business_name = 2; */
        if (message.businessName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.businessName);
        /* string user_email = 3; */
        if (message.userEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userEmail);
        /* double payable_award = 4; */
        if (message.payableAward !== 0)
            writer.tag(4, WireType.Bit64).double(message.payableAward);
        /* double forfeited_award = 5; */
        if (message.forfeitedAward !== 0)
            writer.tag(5, WireType.Bit64).double(message.forfeitedAward);
        /* double total_payable = 6; */
        if (message.totalPayable !== 0)
            writer.tag(6, WireType.Bit64).double(message.totalPayable);
        /* string payment_terms = 7; */
        if (message.paymentTerms !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.paymentTerms);
        /* double average_days_to_pay = 8; */
        if (message.averageDaysToPay !== 0)
            writer.tag(8, WireType.Bit64).double(message.averageDaysToPay);
        /* double invoices_paid = 9; */
        if (message.invoicesPaid !== 0)
            writer.tag(9, WireType.Bit64).double(message.invoicesPaid);
        /* int32 invoices = 10; */
        if (message.invoices !== 0)
            writer.tag(10, WireType.Varint).int32(message.invoices);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentSummary
 */
export const PromptPaymentSummary = new PromptPaymentSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentSummaryResponse$Type extends MessageType {
    constructor() {
        super("PromptPaymentSummaryResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PromptPaymentSummary }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PromptPaymentSummary data */ 1:
                    message.data.push(PromptPaymentSummary.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PromptPaymentSummary data = 1; */
        for (let i = 0; i < message.data.length; i++)
            PromptPaymentSummary.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentSummaryResponse
 */
export const PromptPaymentSummaryResponse = new PromptPaymentSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentSummaryRequest$Type extends MessageType {
    constructor() {
        super("PromptPaymentSummaryRequest", [
            { no: 1, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { startDate: "", endDate: "", customerId: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string start_date */ 1:
                    message.startDate = reader.string();
                    break;
                case /* string end_date */ 2:
                    message.endDate = reader.string();
                    break;
                case /* repeated int32 customer_id */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.customerId.push(reader.int32());
                    else
                        message.customerId.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string start_date = 1; */
        if (message.startDate !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startDate);
        /* string end_date = 2; */
        if (message.endDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endDate);
        /* repeated int32 customer_id = 3; */
        if (message.customerId.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.customerId.length; i++)
                writer.int32(message.customerId[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentSummaryRequest
 */
export const PromptPaymentSummaryRequest = new PromptPaymentSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentDetails$Type extends MessageType {
    constructor() {
        super("PromptPaymentDetails", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "billing_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "due_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "payment_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "payable", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "payed", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "payment_terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "days_to_pay", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "possible_award", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 13, name: "payable_award", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 14, name: "forfeited_award", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 15, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "document_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "rebate_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 18, name: "rebate_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "report_until", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "override_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "pending_award", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, businessName: "", eventId: 0, billingDate: "", dueDate: "", paymentDate: "", jobNumber: "", payable: 0, payed: 0, paymentTerms: "", daysToPay: 0, possibleAward: 0, payableAward: 0, forfeitedAward: 0, propertyId: 0, documentId: 0, rebateAmount: 0, rebateDate: "", reportUntil: "", overrideReason: "", pendingAward: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string business_name */ 2:
                    message.businessName = reader.string();
                    break;
                case /* int32 event_id */ 3:
                    message.eventId = reader.int32();
                    break;
                case /* string billing_date */ 4:
                    message.billingDate = reader.string();
                    break;
                case /* string due_date */ 5:
                    message.dueDate = reader.string();
                    break;
                case /* string payment_date */ 6:
                    message.paymentDate = reader.string();
                    break;
                case /* string job_number */ 7:
                    message.jobNumber = reader.string();
                    break;
                case /* double payable */ 8:
                    message.payable = reader.double();
                    break;
                case /* double payed */ 9:
                    message.payed = reader.double();
                    break;
                case /* string payment_terms */ 10:
                    message.paymentTerms = reader.string();
                    break;
                case /* int32 days_to_pay */ 11:
                    message.daysToPay = reader.int32();
                    break;
                case /* double possible_award */ 12:
                    message.possibleAward = reader.double();
                    break;
                case /* double payable_award */ 13:
                    message.payableAward = reader.double();
                    break;
                case /* double forfeited_award */ 14:
                    message.forfeitedAward = reader.double();
                    break;
                case /* int32 property_id */ 15:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 document_id */ 16:
                    message.documentId = reader.int32();
                    break;
                case /* double rebate_amount */ 17:
                    message.rebateAmount = reader.double();
                    break;
                case /* string rebate_date */ 18:
                    message.rebateDate = reader.string();
                    break;
                case /* string report_until */ 19:
                    message.reportUntil = reader.string();
                    break;
                case /* string override_reason */ 20:
                    message.overrideReason = reader.string();
                    break;
                case /* double pending_award */ 21:
                    message.pendingAward = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string business_name = 2; */
        if (message.businessName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.businessName);
        /* int32 event_id = 3; */
        if (message.eventId !== 0)
            writer.tag(3, WireType.Varint).int32(message.eventId);
        /* string billing_date = 4; */
        if (message.billingDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.billingDate);
        /* string due_date = 5; */
        if (message.dueDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dueDate);
        /* string payment_date = 6; */
        if (message.paymentDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.paymentDate);
        /* string job_number = 7; */
        if (message.jobNumber !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.jobNumber);
        /* double payable = 8; */
        if (message.payable !== 0)
            writer.tag(8, WireType.Bit64).double(message.payable);
        /* double payed = 9; */
        if (message.payed !== 0)
            writer.tag(9, WireType.Bit64).double(message.payed);
        /* string payment_terms = 10; */
        if (message.paymentTerms !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.paymentTerms);
        /* int32 days_to_pay = 11; */
        if (message.daysToPay !== 0)
            writer.tag(11, WireType.Varint).int32(message.daysToPay);
        /* double possible_award = 12; */
        if (message.possibleAward !== 0)
            writer.tag(12, WireType.Bit64).double(message.possibleAward);
        /* double payable_award = 13; */
        if (message.payableAward !== 0)
            writer.tag(13, WireType.Bit64).double(message.payableAward);
        /* double forfeited_award = 14; */
        if (message.forfeitedAward !== 0)
            writer.tag(14, WireType.Bit64).double(message.forfeitedAward);
        /* int32 property_id = 15; */
        if (message.propertyId !== 0)
            writer.tag(15, WireType.Varint).int32(message.propertyId);
        /* int32 document_id = 16; */
        if (message.documentId !== 0)
            writer.tag(16, WireType.Varint).int32(message.documentId);
        /* double rebate_amount = 17; */
        if (message.rebateAmount !== 0)
            writer.tag(17, WireType.Bit64).double(message.rebateAmount);
        /* string rebate_date = 18; */
        if (message.rebateDate !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.rebateDate);
        /* string report_until = 19; */
        if (message.reportUntil !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.reportUntil);
        /* string override_reason = 20; */
        if (message.overrideReason !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.overrideReason);
        /* double pending_award = 21; */
        if (message.pendingAward !== 0)
            writer.tag(21, WireType.Bit64).double(message.pendingAward);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentDetails
 */
export const PromptPaymentDetails = new PromptPaymentDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentDetailsResponse$Type extends MessageType {
    constructor() {
        super("PromptPaymentDetailsResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PromptPaymentDetails }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PromptPaymentDetails data */ 1:
                    message.data.push(PromptPaymentDetails.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PromptPaymentDetails data = 1; */
        for (let i = 0; i < message.data.length; i++)
            PromptPaymentDetails.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentDetailsResponse
 */
export const PromptPaymentDetailsResponse = new PromptPaymentDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentListFinalized$Type extends MessageType {
    constructor() {
        super("PromptPaymentListFinalized", [
            { no: 1, name: "payment_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "payee_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "amount_collected", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "rebate_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { paymentId: 0, payeeUserId: 0, amountCollected: 0, rebateDate: "", dateRange: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 payment_id */ 1:
                    message.paymentId = reader.int32();
                    break;
                case /* int32 payee_user_id */ 2:
                    message.payeeUserId = reader.int32();
                    break;
                case /* double amount_collected */ 3:
                    message.amountCollected = reader.double();
                    break;
                case /* string rebate_date */ 4:
                    message.rebateDate = reader.string();
                    break;
                case /* repeated string date_range */ 5:
                    message.dateRange.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 payment_id = 1; */
        if (message.paymentId !== 0)
            writer.tag(1, WireType.Varint).int32(message.paymentId);
        /* int32 payee_user_id = 2; */
        if (message.payeeUserId !== 0)
            writer.tag(2, WireType.Varint).int32(message.payeeUserId);
        /* double amount_collected = 3; */
        if (message.amountCollected !== 0)
            writer.tag(3, WireType.Bit64).double(message.amountCollected);
        /* string rebate_date = 4; */
        if (message.rebateDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.rebateDate);
        /* repeated string date_range = 5; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.dateRange[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentListFinalized
 */
export const PromptPaymentListFinalized = new PromptPaymentListFinalized$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentListFinalizedResponse$Type extends MessageType {
    constructor() {
        super("PromptPaymentListFinalizedResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PromptPaymentListFinalized }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PromptPaymentListFinalized data */ 1:
                    message.data.push(PromptPaymentListFinalized.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PromptPaymentListFinalized data = 1; */
        for (let i = 0; i < message.data.length; i++)
            PromptPaymentListFinalized.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentListFinalizedResponse
 */
export const PromptPaymentListFinalizedResponse = new PromptPaymentListFinalizedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportFile$Type extends MessageType {
    constructor() {
        super("ReportFile", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "presented_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "creation_dt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "create_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "geolocation_lat", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 12, name: "geolocation_lng", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 13, name: "classification_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "classification_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, fileId: 0, presentedName: "", name: "", bucket: "", mimeType: "", creationDt: "", createTime: "", ownerId: 0, eventId: 0, geolocationLat: 0, geolocationLng: 0, classificationId: 0, classificationName: "", fieldMask: [], withoutLimit: false, pageNumber: 0, orderBy: "", orderDir: "", idList: "", dateTarget: [], dateRange: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 file_id */ 2:
                    message.fileId = reader.int32();
                    break;
                case /* string presented_name */ 3:
                    message.presentedName = reader.string();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string bucket */ 5:
                    message.bucket = reader.string();
                    break;
                case /* string mime_type */ 6:
                    message.mimeType = reader.string();
                    break;
                case /* string creation_dt */ 7:
                    message.creationDt = reader.string();
                    break;
                case /* string create_time */ 8:
                    message.createTime = reader.string();
                    break;
                case /* int32 owner_id */ 9:
                    message.ownerId = reader.int32();
                    break;
                case /* int32 event_id */ 10:
                    message.eventId = reader.int32();
                    break;
                case /* float geolocation_lat */ 11:
                    message.geolocationLat = reader.float();
                    break;
                case /* float geolocation_lng */ 12:
                    message.geolocationLng = reader.float();
                    break;
                case /* int32 classification_id */ 13:
                    message.classificationId = reader.int32();
                    break;
                case /* string classification_name */ 14:
                    message.classificationName = reader.string();
                    break;
                case /* repeated string field_mask */ 15:
                    message.fieldMask.push(reader.string());
                    break;
                case /* bool without_limit */ 16:
                    message.withoutLimit = reader.bool();
                    break;
                case /* int32 page_number */ 17:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 18:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 19:
                    message.orderDir = reader.string();
                    break;
                case /* string id_list */ 20:
                    message.idList = reader.string();
                    break;
                case /* repeated string date_target */ 21:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string date_range */ 22:
                    message.dateRange.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 file_id = 2; */
        if (message.fileId !== 0)
            writer.tag(2, WireType.Varint).int32(message.fileId);
        /* string presented_name = 3; */
        if (message.presentedName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.presentedName);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string bucket = 5; */
        if (message.bucket !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.bucket);
        /* string mime_type = 6; */
        if (message.mimeType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mimeType);
        /* string creation_dt = 7; */
        if (message.creationDt !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.creationDt);
        /* string create_time = 8; */
        if (message.createTime !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.createTime);
        /* int32 owner_id = 9; */
        if (message.ownerId !== 0)
            writer.tag(9, WireType.Varint).int32(message.ownerId);
        /* int32 event_id = 10; */
        if (message.eventId !== 0)
            writer.tag(10, WireType.Varint).int32(message.eventId);
        /* float geolocation_lat = 11; */
        if (message.geolocationLat !== 0)
            writer.tag(11, WireType.Bit32).float(message.geolocationLat);
        /* float geolocation_lng = 12; */
        if (message.geolocationLng !== 0)
            writer.tag(12, WireType.Bit32).float(message.geolocationLng);
        /* int32 classification_id = 13; */
        if (message.classificationId !== 0)
            writer.tag(13, WireType.Varint).int32(message.classificationId);
        /* string classification_name = 14; */
        if (message.classificationName !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.classificationName);
        /* repeated string field_mask = 15; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* bool without_limit = 16; */
        if (message.withoutLimit !== false)
            writer.tag(16, WireType.Varint).bool(message.withoutLimit);
        /* int32 page_number = 17; */
        if (message.pageNumber !== 0)
            writer.tag(17, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 18; */
        if (message.orderBy !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 19; */
        if (message.orderDir !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.orderDir);
        /* string id_list = 20; */
        if (message.idList !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.idList);
        /* repeated string date_target = 21; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string date_range = 22; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(22, WireType.LengthDelimited).string(message.dateRange[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReportFile
 */
export const ReportFile = new ReportFile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportFileList$Type extends MessageType {
    constructor() {
        super("ReportFileList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReportFile },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ReportFile results */ 1:
                    message.results.push(ReportFile.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ReportFile results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ReportFile.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReportFileList
 */
export const ReportFileList = new ReportFileList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Report$Type extends MessageType {
    constructor() {
        super("Report", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "presented_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "classification_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, fileId: 0, presentedName: "", classificationId: 0, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 file_id */ 2:
                    message.fileId = reader.int32();
                    break;
                case /* string presented_name */ 3:
                    message.presentedName = reader.string();
                    break;
                case /* int32 classification_id */ 4:
                    message.classificationId = reader.int32();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 file_id = 2; */
        if (message.fileId !== 0)
            writer.tag(2, WireType.Varint).int32(message.fileId);
        /* string presented_name = 3; */
        if (message.presentedName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.presentedName);
        /* int32 classification_id = 4; */
        if (message.classificationId !== 0)
            writer.tag(4, WireType.Varint).int32(message.classificationId);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Report
 */
export const Report = new Report$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetAudit$Type extends MessageType {
    constructor() {
        super("TimesheetAudit", [
            { no: 1, name: "day", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "time_in", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "time_out", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "total_hours", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "travel_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "mileage", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "perdiem", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "hotel_lodging", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 9, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { day: "", timeIn: "", timeOut: "", totalHours: 0, travelTime: 0, mileage: 0, perdiem: 0, hotelLodging: 0, status: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string day */ 1:
                    message.day = reader.string();
                    break;
                case /* string time_in */ 2:
                    message.timeIn = reader.string();
                    break;
                case /* string time_out */ 3:
                    message.timeOut = reader.string();
                    break;
                case /* float total_hours */ 4:
                    message.totalHours = reader.float();
                    break;
                case /* float travel_time */ 5:
                    message.travelTime = reader.float();
                    break;
                case /* float mileage */ 6:
                    message.mileage = reader.float();
                    break;
                case /* float perdiem */ 7:
                    message.perdiem = reader.float();
                    break;
                case /* float hotel_lodging */ 8:
                    message.hotelLodging = reader.float();
                    break;
                case /* string status */ 9:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string day = 1; */
        if (message.day !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.day);
        /* string time_in = 2; */
        if (message.timeIn !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.timeIn);
        /* string time_out = 3; */
        if (message.timeOut !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timeOut);
        /* float total_hours = 4; */
        if (message.totalHours !== 0)
            writer.tag(4, WireType.Bit32).float(message.totalHours);
        /* float travel_time = 5; */
        if (message.travelTime !== 0)
            writer.tag(5, WireType.Bit32).float(message.travelTime);
        /* float mileage = 6; */
        if (message.mileage !== 0)
            writer.tag(6, WireType.Bit32).float(message.mileage);
        /* float perdiem = 7; */
        if (message.perdiem !== 0)
            writer.tag(7, WireType.Bit32).float(message.perdiem);
        /* float hotel_lodging = 8; */
        if (message.hotelLodging !== 0)
            writer.tag(8, WireType.Bit32).float(message.hotelLodging);
        /* string status = 9; */
        if (message.status !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetAudit
 */
export const TimesheetAudit = new TimesheetAudit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetAuditReport$Type extends MessageType {
    constructor() {
        super("TimesheetAuditReport", [
            { no: 1, name: "audits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimesheetAudit },
            { no: 2, name: "total_hours", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "total_travel_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "total_mileage", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "total_per_diem", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { audits: [], totalHours: 0, totalTravelTime: 0, totalMileage: 0, totalPerDiem: 0, userId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimesheetAudit audits */ 1:
                    message.audits.push(TimesheetAudit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* float total_hours */ 2:
                    message.totalHours = reader.float();
                    break;
                case /* float total_travel_time */ 3:
                    message.totalTravelTime = reader.float();
                    break;
                case /* float total_mileage */ 4:
                    message.totalMileage = reader.float();
                    break;
                case /* float total_per_diem */ 5:
                    message.totalPerDiem = reader.float();
                    break;
                case /* int32 user_id */ 6:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimesheetAudit audits = 1; */
        for (let i = 0; i < message.audits.length; i++)
            TimesheetAudit.internalBinaryWrite(message.audits[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* float total_hours = 2; */
        if (message.totalHours !== 0)
            writer.tag(2, WireType.Bit32).float(message.totalHours);
        /* float total_travel_time = 3; */
        if (message.totalTravelTime !== 0)
            writer.tag(3, WireType.Bit32).float(message.totalTravelTime);
        /* float total_mileage = 4; */
        if (message.totalMileage !== 0)
            writer.tag(4, WireType.Bit32).float(message.totalMileage);
        /* float total_per_diem = 5; */
        if (message.totalPerDiem !== 0)
            writer.tag(5, WireType.Bit32).float(message.totalPerDiem);
        /* int32 user_id = 6; */
        if (message.userId !== 0)
            writer.tag(6, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetAuditReport
 */
export const TimesheetAuditReport = new TimesheetAuditReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetAuditReportCollection$Type extends MessageType {
    constructor() {
        super("TimesheetAuditReportCollection", [
            { no: 1, name: "reports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimesheetAuditReport },
            { no: 2, name: "total_employees", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "grand_total_hours", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "grand_total_travel_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "grand_total_mileage", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "grand_total_per_diem", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { reports: [], totalEmployees: 0, grandTotalHours: 0, grandTotalTravelTime: 0, grandTotalMileage: 0, grandTotalPerDiem: 0, startDate: "", endDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimesheetAuditReport reports */ 1:
                    message.reports.push(TimesheetAuditReport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_employees */ 2:
                    message.totalEmployees = reader.int32();
                    break;
                case /* float grand_total_hours */ 3:
                    message.grandTotalHours = reader.float();
                    break;
                case /* float grand_total_travel_time */ 4:
                    message.grandTotalTravelTime = reader.float();
                    break;
                case /* float grand_total_mileage */ 5:
                    message.grandTotalMileage = reader.float();
                    break;
                case /* float grand_total_per_diem */ 6:
                    message.grandTotalPerDiem = reader.float();
                    break;
                case /* string start_date */ 7:
                    message.startDate = reader.string();
                    break;
                case /* string end_date */ 8:
                    message.endDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimesheetAuditReport reports = 1; */
        for (let i = 0; i < message.reports.length; i++)
            TimesheetAuditReport.internalBinaryWrite(message.reports[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_employees = 2; */
        if (message.totalEmployees !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalEmployees);
        /* float grand_total_hours = 3; */
        if (message.grandTotalHours !== 0)
            writer.tag(3, WireType.Bit32).float(message.grandTotalHours);
        /* float grand_total_travel_time = 4; */
        if (message.grandTotalTravelTime !== 0)
            writer.tag(4, WireType.Bit32).float(message.grandTotalTravelTime);
        /* float grand_total_mileage = 5; */
        if (message.grandTotalMileage !== 0)
            writer.tag(5, WireType.Bit32).float(message.grandTotalMileage);
        /* float grand_total_per_diem = 6; */
        if (message.grandTotalPerDiem !== 0)
            writer.tag(6, WireType.Bit32).float(message.grandTotalPerDiem);
        /* string start_date = 7; */
        if (message.startDate !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.startDate);
        /* string end_date = 8; */
        if (message.endDate !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.endDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetAuditReportCollection
 */
export const TimesheetAuditReportCollection = new TimesheetAuditReportCollection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeKpiRequest$Type extends MessageType {
    constructor() {
        super("EmployeeKpiRequest", [
            { no: 1, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "date_range", kind: "message", T: () => DateRange }
        ]);
    }
    create(value) {
        const message = { employeeId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 employee_id */ 1:
                    message.employeeId = reader.int32();
                    break;
                case /* DateRange date_range */ 2:
                    message.dateRange = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.dateRange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 employee_id = 1; */
        if (message.employeeId !== 0)
            writer.tag(1, WireType.Varint).int32(message.employeeId);
        /* DateRange date_range = 2; */
        if (message.dateRange)
            DateRange.internalBinaryWrite(message.dateRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeKpiRequest
 */
export const EmployeeKpiRequest = new EmployeeKpiRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeKpi$Type extends MessageType {
    constructor() {
        super("EmployeeKpi", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "metric_average_ticket", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "metric_income", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "metric_average_billable", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "metric_average_travel_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "metric_average_job_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "jobs_details", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => JobDetails }
        ]);
    }
    create(value) {
        const message = { userId: 0, metricAverageTicket: 0, metricIncome: 0, metricAverageBillable: 0, metricAverageTravelTime: 0, metricAverageJobTime: 0, jobsDetails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* float metric_average_ticket */ 2:
                    message.metricAverageTicket = reader.float();
                    break;
                case /* float metric_income */ 3:
                    message.metricIncome = reader.float();
                    break;
                case /* float metric_average_billable */ 4:
                    message.metricAverageBillable = reader.float();
                    break;
                case /* float metric_average_travel_time */ 5:
                    message.metricAverageTravelTime = reader.float();
                    break;
                case /* float metric_average_job_time */ 6:
                    message.metricAverageJobTime = reader.float();
                    break;
                case /* repeated JobDetails jobs_details */ 7:
                    message.jobsDetails.push(JobDetails.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* float metric_average_ticket = 2; */
        if (message.metricAverageTicket !== 0)
            writer.tag(2, WireType.Bit32).float(message.metricAverageTicket);
        /* float metric_income = 3; */
        if (message.metricIncome !== 0)
            writer.tag(3, WireType.Bit32).float(message.metricIncome);
        /* float metric_average_billable = 4; */
        if (message.metricAverageBillable !== 0)
            writer.tag(4, WireType.Bit32).float(message.metricAverageBillable);
        /* float metric_average_travel_time = 5; */
        if (message.metricAverageTravelTime !== 0)
            writer.tag(5, WireType.Bit32).float(message.metricAverageTravelTime);
        /* float metric_average_job_time = 6; */
        if (message.metricAverageJobTime !== 0)
            writer.tag(6, WireType.Bit32).float(message.metricAverageJobTime);
        /* repeated JobDetails jobs_details = 7; */
        for (let i = 0; i < message.jobsDetails.length; i++)
            JobDetails.internalBinaryWrite(message.jobsDetails[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeKpi
 */
export const EmployeeKpi = new EmployeeKpi$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JobDetails$Type extends MessageType {
    constructor() {
        super("JobDetails", [
            { no: 1, name: "job_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "invoice_total_amount", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "user_billable_hours", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "user_non_billable_hours", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "user_job_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "user_travel_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "total_job_billable_hours", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 9, name: "total_job_non_billable_hours", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value) {
        const message = { jobName: "", jobNumber: 0, invoiceTotalAmount: 0, userBillableHours: 0, userNonBillableHours: 0, userJobTime: 0, userTravelTime: 0, totalJobBillableHours: 0, totalJobNonBillableHours: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string job_name */ 1:
                    message.jobName = reader.string();
                    break;
                case /* int32 job_number */ 2:
                    message.jobNumber = reader.int32();
                    break;
                case /* float invoice_total_amount */ 3:
                    message.invoiceTotalAmount = reader.float();
                    break;
                case /* float user_billable_hours */ 4:
                    message.userBillableHours = reader.float();
                    break;
                case /* float user_non_billable_hours */ 5:
                    message.userNonBillableHours = reader.float();
                    break;
                case /* float user_job_time */ 6:
                    message.userJobTime = reader.float();
                    break;
                case /* float user_travel_time */ 7:
                    message.userTravelTime = reader.float();
                    break;
                case /* float total_job_billable_hours */ 8:
                    message.totalJobBillableHours = reader.float();
                    break;
                case /* float total_job_non_billable_hours */ 9:
                    message.totalJobNonBillableHours = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string job_name = 1; */
        if (message.jobName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.jobName);
        /* int32 job_number = 2; */
        if (message.jobNumber !== 0)
            writer.tag(2, WireType.Varint).int32(message.jobNumber);
        /* float invoice_total_amount = 3; */
        if (message.invoiceTotalAmount !== 0)
            writer.tag(3, WireType.Bit32).float(message.invoiceTotalAmount);
        /* float user_billable_hours = 4; */
        if (message.userBillableHours !== 0)
            writer.tag(4, WireType.Bit32).float(message.userBillableHours);
        /* float user_non_billable_hours = 5; */
        if (message.userNonBillableHours !== 0)
            writer.tag(5, WireType.Bit32).float(message.userNonBillableHours);
        /* float user_job_time = 6; */
        if (message.userJobTime !== 0)
            writer.tag(6, WireType.Bit32).float(message.userJobTime);
        /* float user_travel_time = 7; */
        if (message.userTravelTime !== 0)
            writer.tag(7, WireType.Bit32).float(message.userTravelTime);
        /* float total_job_billable_hours = 8; */
        if (message.totalJobBillableHours !== 0)
            writer.tag(8, WireType.Bit32).float(message.totalJobBillableHours);
        /* float total_job_non_billable_hours = 9; */
        if (message.totalJobNonBillableHours !== 0)
            writer.tag(9, WireType.Bit32).float(message.totalJobNonBillableHours);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JobDetails
 */
export const JobDetails = new JobDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KpiReportRequest$Type extends MessageType {
    constructor() {
        super("KpiReportRequest", [
            { no: 1, name: "user_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "date_range", kind: "message", T: () => DateRange }
        ]);
    }
    create(value) {
        const message = { userIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 user_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.userIds.push(reader.int32());
                    else
                        message.userIds.push(reader.int32());
                    break;
                case /* DateRange date_range */ 2:
                    message.dateRange = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.dateRange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated int32 user_ids = 1; */
        if (message.userIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.userIds.length; i++)
                writer.int32(message.userIds[i]);
            writer.join();
        }
        /* DateRange date_range = 2; */
        if (message.dateRange)
            DateRange.internalBinaryWrite(message.dateRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message KpiReportRequest
 */
export const KpiReportRequest = new KpiReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeKpiList$Type extends MessageType {
    constructor() {
        super("EmployeeKpiList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EmployeeKpi },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated EmployeeKpi results */ 1:
                    message.results.push(EmployeeKpi.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated EmployeeKpi results = 1; */
        for (let i = 0; i < message.results.length; i++)
            EmployeeKpi.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeKpiList
 */
export const EmployeeKpiList = new EmployeeKpiList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeHoursRevenue$Type extends MessageType {
    constructor() {
        super("EmployeeHoursRevenue", [
            { no: 1, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "technician_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "tech_assist", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "total_billable_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "total_non_billable_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "most_recent_invoice_amount", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "date_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "is_callback", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "technician_user_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "event_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "event_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "total_job_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 16, name: "total_travel_time", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value) {
        const message = { eventId: 0, technicianUserId: 0, techAssist: false, totalBillableTime: 0, totalNonBillableTime: 0, mostRecentInvoiceAmount: 0, dateStarted: "", isCallback: false, technicianUserIdList: "", eventIdList: "", dateRange: [], dateTarget: [], fieldMask: [], eventName: "", totalJobTime: 0, totalTravelTime: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 event_id */ 1:
                    message.eventId = reader.int32();
                    break;
                case /* int32 technician_user_id */ 2:
                    message.technicianUserId = reader.int32();
                    break;
                case /* bool tech_assist */ 3:
                    message.techAssist = reader.bool();
                    break;
                case /* float total_billable_time */ 4:
                    message.totalBillableTime = reader.float();
                    break;
                case /* float total_non_billable_time */ 5:
                    message.totalNonBillableTime = reader.float();
                    break;
                case /* float most_recent_invoice_amount */ 6:
                    message.mostRecentInvoiceAmount = reader.float();
                    break;
                case /* string date_started */ 7:
                    message.dateStarted = reader.string();
                    break;
                case /* bool is_callback */ 8:
                    message.isCallback = reader.bool();
                    break;
                case /* string technician_user_id_list */ 9:
                    message.technicianUserIdList = reader.string();
                    break;
                case /* string event_id_list */ 10:
                    message.eventIdList = reader.string();
                    break;
                case /* repeated string date_range */ 11:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 12:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string field_mask */ 13:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string event_name */ 14:
                    message.eventName = reader.string();
                    break;
                case /* float total_job_time */ 15:
                    message.totalJobTime = reader.float();
                    break;
                case /* float total_travel_time */ 16:
                    message.totalTravelTime = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 event_id = 1; */
        if (message.eventId !== 0)
            writer.tag(1, WireType.Varint).int32(message.eventId);
        /* int32 technician_user_id = 2; */
        if (message.technicianUserId !== 0)
            writer.tag(2, WireType.Varint).int32(message.technicianUserId);
        /* bool tech_assist = 3; */
        if (message.techAssist !== false)
            writer.tag(3, WireType.Varint).bool(message.techAssist);
        /* float total_billable_time = 4; */
        if (message.totalBillableTime !== 0)
            writer.tag(4, WireType.Bit32).float(message.totalBillableTime);
        /* float total_non_billable_time = 5; */
        if (message.totalNonBillableTime !== 0)
            writer.tag(5, WireType.Bit32).float(message.totalNonBillableTime);
        /* float most_recent_invoice_amount = 6; */
        if (message.mostRecentInvoiceAmount !== 0)
            writer.tag(6, WireType.Bit32).float(message.mostRecentInvoiceAmount);
        /* string date_started = 7; */
        if (message.dateStarted !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dateStarted);
        /* bool is_callback = 8; */
        if (message.isCallback !== false)
            writer.tag(8, WireType.Varint).bool(message.isCallback);
        /* string technician_user_id_list = 9; */
        if (message.technicianUserIdList !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.technicianUserIdList);
        /* string event_id_list = 10; */
        if (message.eventIdList !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.eventIdList);
        /* repeated string date_range = 11; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 12; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string field_mask = 13; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string event_name = 14; */
        if (message.eventName !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.eventName);
        /* float total_job_time = 15; */
        if (message.totalJobTime !== 0)
            writer.tag(15, WireType.Bit32).float(message.totalJobTime);
        /* float total_travel_time = 16; */
        if (message.totalTravelTime !== 0)
            writer.tag(16, WireType.Bit32).float(message.totalTravelTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeHoursRevenue
 */
export const EmployeeHoursRevenue = new EmployeeHoursRevenue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeHoursRevenueList$Type extends MessageType {
    constructor() {
        super("EmployeeHoursRevenueList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EmployeeHoursRevenue },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated EmployeeHoursRevenue results */ 1:
                    message.results.push(EmployeeHoursRevenue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated EmployeeHoursRevenue results = 1; */
        for (let i = 0; i < message.results.length; i++)
            EmployeeHoursRevenue.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeHoursRevenueList
 */
export const EmployeeHoursRevenueList = new EmployeeHoursRevenueList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WeeklyEmployeeReportRequest$Type extends MessageType {
    constructor() {
        super("WeeklyEmployeeReportRequest", [
            { no: 1, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "department_id_list", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "date_range", kind: "message", T: () => DateRange }
        ]);
    }
    create(value) {
        const message = { status: "", departmentIdList: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string status */ 1:
                    message.status = reader.string();
                    break;
                case /* repeated int32 department_id_list */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.departmentIdList.push(reader.int32());
                    else
                        message.departmentIdList.push(reader.int32());
                    break;
                case /* DateRange date_range */ 3:
                    message.dateRange = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.dateRange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string status = 1; */
        if (message.status !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.status);
        /* repeated int32 department_id_list = 2; */
        if (message.departmentIdList.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.departmentIdList.length; i++)
                writer.int32(message.departmentIdList[i]);
            writer.join();
        }
        /* DateRange date_range = 3; */
        if (message.dateRange)
            DateRange.internalBinaryWrite(message.dateRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WeeklyEmployeeReportRequest
 */
export const WeeklyEmployeeReportRequest = new WeeklyEmployeeReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeOffMetricRequest$Type extends MessageType {
    constructor() {
        super("TimeOffMetricRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "date_range", kind: "message", T: () => DateRange }
        ]);
    }
    create(value) {
        const message = { userId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* DateRange date_range */ 2:
                    message.dateRange = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.dateRange);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* DateRange date_range = 2; */
        if (message.dateRange)
            DateRange.internalBinaryWrite(message.dateRange, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeOffMetricRequest
 */
export const TimeOffMetricRequest = new TimeOffMetricRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeOffMetric$Type extends MessageType {
    constructor() {
        super("TimeOffMetric", [
            { no: 1, name: "sum_non_pto_time", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "sum_pto_time", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "time_off_approved", kind: "message", T: () => TimeoffRequestList }
        ]);
    }
    create(value) {
        const message = { sumNonPtoTime: 0, sumPtoTime: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double sum_non_pto_time */ 1:
                    message.sumNonPtoTime = reader.double();
                    break;
                case /* double sum_pto_time */ 2:
                    message.sumPtoTime = reader.double();
                    break;
                case /* TimeoffRequestList time_off_approved */ 3:
                    message.timeOffApproved = TimeoffRequestList.internalBinaryRead(reader, reader.uint32(), options, message.timeOffApproved);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* double sum_non_pto_time = 1; */
        if (message.sumNonPtoTime !== 0)
            writer.tag(1, WireType.Bit64).double(message.sumNonPtoTime);
        /* double sum_pto_time = 2; */
        if (message.sumPtoTime !== 0)
            writer.tag(2, WireType.Bit64).double(message.sumPtoTime);
        /* TimeoffRequestList time_off_approved = 3; */
        if (message.timeOffApproved)
            TimeoffRequestList.internalBinaryWrite(message.timeOffApproved, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimeOffMetric
 */
export const TimeOffMetric = new TimeOffMetric$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallbackMetrics$Type extends MessageType {
    constructor() {
        super("CallbackMetrics", [
            { no: 1, name: "jobs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CallbackSummary }
        ]);
    }
    create(value) {
        const message = { jobs: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CallbackSummary jobs */ 1:
                    message.jobs.push(CallbackSummary.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CallbackSummary jobs = 1; */
        for (let i = 0; i < message.jobs.length; i++)
            CallbackSummary.internalBinaryWrite(message.jobs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallbackMetrics
 */
export const CallbackMetrics = new CallbackMetrics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallbackSummary$Type extends MessageType {
    constructor() {
        super("CallbackSummary", [
            { no: 1, name: "job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "summary_a", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "summary_b", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { jobNumber: "", summaryA: "", summaryB: "", eventId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string job_number */ 1:
                    message.jobNumber = reader.string();
                    break;
                case /* string summary_a */ 2:
                    message.summaryA = reader.string();
                    break;
                case /* string summary_b */ 3:
                    message.summaryB = reader.string();
                    break;
                case /* int32 event_id */ 4:
                    message.eventId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string job_number = 1; */
        if (message.jobNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.jobNumber);
        /* string summary_a = 2; */
        if (message.summaryA !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.summaryA);
        /* string summary_b = 3; */
        if (message.summaryB !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.summaryB);
        /* int32 event_id = 4; */
        if (message.eventId !== 0)
            writer.tag(4, WireType.Varint).int32(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallbackSummary
 */
export const CallbackSummary = new CallbackSummary$Type();
/**
 * @generated ServiceType for protobuf service ReportService
 */
export const ReportService = new ServiceType("ReportService", [
    { name: "GetSpiffReportData", options: {}, I: SpiffReportLine, O: SpiffReport },
    { name: "GetPromptPaymentData", options: {}, I: PromptPaymentReportLine, O: PromptPaymentReport },
    { name: "GetTransactionDumpData", options: {}, I: TransactionReportLine, O: TransactionDumpReport },
    { name: "GetTimeoffReportData", options: {}, I: TimeoffReportRequest, O: TimeoffReport },
    { name: "GetReceiptJournalReport", options: {}, I: ReceiptJournalReportLine, O: ReceiptJournalReport },
    { name: "GetSalesJournalReport", options: {}, I: SalesJournalReportLine, O: SalesJournalReport },
    { name: "GetProcessedReport", options: {}, I: ProcessedReportRequest, O: ProcessedReport },
    { name: "GetCostItemReport", options: {}, I: DateRange, O: CostItemReport },
    { name: "GetBillingAuditReport", options: {}, I: DateRange, O: BillingAuditReport },
    { name: "GetInvoiceJournalReport", options: {}, I: DateRange, O: InvoiceJournalReport },
    { name: "GetJobHoursEmployeeReport", options: {}, I: DateRange, O: JobHoursEmployeeReport },
    { name: "GetDailyJobReport", options: {}, I: Int32, O: DailyJobReport },
    { name: "GetPromptPaymentSummary", options: {}, I: PromptPaymentSummaryRequest, O: PromptPaymentSummaryResponse },
    { name: "CreateEmailsForPromptPayment", options: {}, I: PromptPaymentSummaryRequest, O: String$ },
    { name: "ParseTokenForRequest", options: {}, I: String$, O: PromptPaymentSummaryRequest },
    { name: "GetPromptPaymentDetails", options: {}, I: PromptPaymentSummaryRequest, O: PromptPaymentDetailsResponse },
    { name: "GetPromptPaymentListFinalized", options: {}, I: PromptPaymentListFinalized, O: PromptPaymentListFinalizedResponse },
    { name: "Create", options: {}, I: Report, O: Report },
    { name: "Update", options: {}, I: Report, O: ReportFile },
    { name: "Get", options: {}, I: ReportFile, O: ReportFile },
    { name: "BatchGet", options: {}, I: ReportFile, O: ReportFileList },
    { name: "GetAllReadyToBeProcessedItems", options: {}, I: DateRange, O: CostItemReport },
    { name: "ProcessAllApproved", options: {}, I: DateRange, O: CostItemReport },
    { name: "GetWeeklyEmployeeReport", options: {}, I: WeeklyEmployeeReportRequest, O: TimesheetAuditReportCollection },
    { name: "GetEmployeeKPI", options: {}, I: EmployeeKpiRequest, O: EmployeeKpi },
    { name: "KpiReport", options: {}, I: KpiReportRequest, O: EmployeeKpiList },
    { name: "GetEmployeeHoursRevenue", options: {}, I: EmployeeHoursRevenue, O: EmployeeHoursRevenue },
    { name: "GetBatchEmployeeHoursRevenue", options: {}, I: EmployeeHoursRevenue, O: EmployeeHoursRevenueList },
    { name: "GetTimeOffMetric", options: {}, I: TimeOffMetricRequest, O: TimeOffMetric },
    { name: "GetCallbackMetrics", options: {}, I: Event, O: CallbackMetrics }
]);
